import React from 'react';
import { useStyletron } from 'baseui';
import { StyleObject } from 'styletron-react';

interface CustomizableImageProps {
  height?: string;
  width?: string;
  src: string;
  alt: string;
  style?: StyleObject;
}

const CustomizableImage = ({
  style,
  height,
  width,
  src,
  alt
}: CustomizableImageProps) => {
  const [css] = useStyletron();

  return (
    <div
      className={css({
        background: 'transparent',
        zIndex: '1',
        position: 'relative',
        ...style
      })}
    >
      <img src={src} alt={alt} className={css({ height, width })} />
    </div>
  );
};

export default CustomizableImage;
