import React from 'react';
import { Modal, ROLE } from 'baseui/modal';
import { Card, StyledBody } from 'baseui/card';
import { ReadMoreObject } from 'newHirePortalRedesign/newHire/features/yourFirstWeek/data/cash';
import { allDirectionStyle } from 'newHirePortalRedesign/newHire/util/styleHelpers';

interface ReadMoreModalProps {
  readMoreData: ReadMoreObject;
  handleOnClose: () => void;
  isModalOpen: boolean;
}

const ReadMoreModal = ({
  readMoreData,
  handleOnClose,
  isModalOpen
}: ReadMoreModalProps) => (
  <Modal
    onClose={handleOnClose}
    closeable
    isOpen={isModalOpen}
    role={ROLE.dialog}
    overrides={{
      Dialog: {
        style: {
          width: '625px'
        }
      }
    }}
  >
    <Card
      overrides={{
        Root: {
          style: {
            ...allDirectionStyle({
              style: 'none',
              prop: 'border'
            })
          }
        },
        Title: {
          style: ({ $theme }) => ({
            ...$theme.typography.HeadingXSmall,
            color: $theme.colors.contentPrimary,
            paddingTop: 0,
            paddingRight: '42px',
            paddingBottom: 0,
            paddingLeft: '42px'
          })
        },
        Body: {
          style: () => ({
            paddingTop: 0,
            paddingBottom: '42px',
            paddingLeft: '42px',
            paddingRight: '42px'
          })
        }
      }}
      headerImage={{ alt: readMoreData.imgAlt, src: readMoreData.modalImgSrc }}
      title={readMoreData.name}
    >
      <StyledBody>{readMoreData.description}</StyledBody>
    </Card>
  </Modal>
);
export default ReadMoreModal;
