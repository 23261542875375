import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './profile/slice';
import userReducer from './user/slice';
import shippingAddressReducer from './shippingAddress/slice';
import coreMemberReducer from './coreMembers/slice';
import usernamesReducer from './username/slice';
import selectedUsernameReducer from './selectedUsername/slice';
import healthCheckReducer from './healthCheck/slice';
import hardwareSelectionReducer from './hardware/slice';
import selectedHardwaresReducer from './selectedHardwares/slice';

export default configureStore({
  reducer: {
    user: userReducer,
    shippingAddress: shippingAddressReducer,
    coreMember: coreMemberReducer,
    profile: profileReducer,
    healthCheck: healthCheckReducer,
    usernames: usernamesReducer,
    selectedUsername: selectedUsernameReducer,
    hardwareSelection: hardwareSelectionReducer,
    selectedHardwares: selectedHardwaresReducer
  }
});
