import React from 'react';
import { Block } from 'baseui/block';
import { MonoParagraphMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { THEME_NAME, SQUARE_MAIL_ID } from '../../../../util/enums';
import AlertInfoWithEmailListItem from './AlertInfoWithEmailListItem';

const NoteDetails = () => {
  const [, theme] = useStyletron();
  return (
    <Block data-testid='note-details' width='80%'>
      <MonoParagraphMedium>Note:</MonoParagraphMedium>
      {theme.name === THEME_NAME.CASH ? (
        <AlertInfoWithEmailListItem
          dataTestId='alert-info-for-swag-package'
          text='The Cash swag package will be at your doorstep within 6 weeks of your start date.'
          typographyType={theme.typography.ParagraphMedium}
          styles={{
            fontWeight: '300',
            color: theme.colors.mono600
          }}
        />
      ) : null}

      <AlertInfoWithEmailListItem
        dataTestId='alert-info-for-it-equipment'
        text={`If your IT equipment is not delivered by the last saturday before your
                start date, please write to`}
        mailId={SQUARE_MAIL_ID.HARDWARE}
        suffixText='for alternate options.'
        typographyType={theme.typography.ParagraphMedium}
        styles={{
          fontWeight: '300',
          color: theme.colors.mono600
        }}
      />

      {theme.name === THEME_NAME.CASH ? (
        <AlertInfoWithEmailListItem
          dataTestId='alert-info-for-monitor-delivery'
          text='Due to a global monitor shortage, your computer monitor delivery may be slightly delayed. Please know this does not impact your start date or onboarding, as you will still receive your Block-issued laptop for Day 1.'
          typographyType={theme.typography.ParagraphMedium}
          styles={{
            fontWeight: '300',
            color: theme.colors.mono600
          }}
        />
      ) : null}
    </Block>
  );
};
export default NoteDetails;
