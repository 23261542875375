import { styled } from 'baseui';
import { Block } from 'baseui/block';

export const Divider = styled('div', ({ $theme }) => ({
  [$theme.mediaQuery.small]: {
    borderTop: `1px solid ${$theme.colors.mono300}`,
    marginTop: $theme.sizing.scale400,
    marginRight: 0,
    marginBottom: '30px',
    marginLeft: 0
  },
  [$theme.mediaQuery.medium]: {
    borderTop: `1px solid ${$theme.colors.mono300}`,
    marginTop: $theme.sizing.scale400,
    marginRight: 0,
    marginBottom: '30px',
    marginLeft: 0
  },
  [$theme.mediaQuery.large]: {
    borderLeft: `1px solid ${$theme.colors.mono300}`,
    marginTop: 0,
    marginRight: '65px',
    marginBottom: 0,
    marginLeft: '45px'
  }
}));

export const CardContainer = styled(Block, ({ $theme }) => ({
  display: 'flex',
  width: '100%',
  flex: 1,
  justifyContent: 'space-between',
  [$theme.mediaQuery.small]: {
    flexDirection: 'column'
  },
  [$theme.mediaQuery.medium]: {
    flexDirection: 'column'
  },
  [$theme.mediaQuery.large]: {
    flexDirection: 'row'
  }
}));

export const narrowCardStyle = {
  Root: {
    style: ({ $theme }: ThemeProps) => ({
      [$theme.mediaQuery.small]: {
        width: '100%',
        marginTop: 0,
        marginRight: 0,
        marginBottom: $theme.sizing.scale700,
        marginLeft: 0
      },
      [$theme.mediaQuery.medium]: {
        width: '100%',
        marginTop: 0,
        marginRight: 0,
        marginBottom: $theme.sizing.scale700,
        marginLeft: 0
      },
      [$theme.mediaQuery.large]: {
        width: '325px',
        marginTop: 0,
        marginRight: $theme.sizing.scale700,
        marginBottom: 0,
        marginLeft: 0
      }
    })
  }
};

export const wideCardStyle = {
  Root: {
    style: ({ $theme }: ThemeProps) => ({
      [$theme.mediaQuery.small]: {
        width: '100%'
      },
      [$theme.mediaQuery.medium]: {
        width: '100%'
      },
      [$theme.mediaQuery.large]: {
        width: '534px'
      }
    })
  }
};
