import React from 'react';
import { useSelector } from 'react-redux';

import { isMnAEmployeeSelector } from 'newHirePortal/newHire/store/user/selectors';
import MnAMessages from './MnAMessages';
import BlockDefaultMessages from './BlockDefaultMessages';

const BlockMessages = () => {
  const isMnAEmployee = useSelector(isMnAEmployeeSelector);

  return isMnAEmployee ? <MnAMessages /> : <BlockDefaultMessages />;
};

export default BlockMessages;
