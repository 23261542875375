export const HARDWARE_ITEMS = {
  LAPTOP: 'laptop',
  MONITOR: 'monitor',
  MOUSE: 'mouse',
  HEADSET: 'headset',
  KEYBOARD: 'keyboard',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  ADAPTER: 'adapter'
};

export const KEYBOARD_LANGUAGE = 'keyboardLanguage';
export const ENGLISH_LANG_CHOICE_VALUE = 'English';
export const OPT_OUT = 'Do not want';
export const MONITOR_AND_ADAPTER = 'Monitor & Adapter';
export const CONTINGENT = 'contingent';
export const NOT_ELIGIBLE_HARDWARE_ERROR =
  'ApiError::HardwareSelection::NotEligibleHardwareFound';

export const DEFAULT_HARDWARE_CHOICE = {
  DEFAULT_VALUE: 'Default',
  SQ_PART_NUMBER: '/a',
  ERROR_MSG: 'Required device missing from the BoM'
};

export const OPT_OUT_CHOICE = {
  SQ_PART_NUMBER: '/dnw',
  VALUE: OPT_OUT
};

export const IMAC = {
  SELECTED: 'I want iMac',
  NOT_SELECTED: "I don't want iMac",
  NAME: 'imac'
};

export const MANUFACTURERS = {
  APPLE: 'Apple',
  LOGITECH: 'Logitech'
};
