import React from 'react';
import { Icon } from 'baseui/icon';

const Clipboard = ({ ...props }) => {
  const { title = 'Clipboard', size, color, ...restProps } = props;
  return (
    <Icon
      viewBox='0 0 24 24'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 0C7.44772 0 7 0.447715 7 1V2H4C3.44772 2 3 2.44772 3 3V23C3 23.5523 3.44772 24 4 24H20C20.5523 24 21 23.5523 21 23V3C21 2.44772 20.5523 2 20 2H17V1C17 0.447715 16.5523 0 16 0H8ZM17 4V5C17 5.55228 16.5523 6 16 6H8C7.44772 6 7 5.55228 7 5V4H5V22H19V4H17ZM15 4H9V2H15V4ZM9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11ZM8 16C8.55228 16 9 15.5523 9 15C9 14.4477 8.55228 14 8 14C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16ZM9 19C9 19.5523 8.55228 20 8 20C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18C8.55228 18 9 18.4477 9 19ZM12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10H12ZM11 15C11 14.4477 11.4477 14 12 14H16C16.5523 14 17 14.4477 17 15C17 15.5523 16.5523 16 16 16H12C11.4477 16 11 15.5523 11 15ZM12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20H16C16.5523 20 17 19.5523 17 19C17 18.4477 16.5523 18 16 18H12Z'
      />
    </Icon>
  );
};

export default Clipboard;
