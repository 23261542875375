import React from 'react';
import EmailSupport from '../emailSupport/EmailSupport';

interface EmailWithTextProps {
  text: string;
  mailId: string;
  suffixText: string;
}

const EmailWithText = ({ text, mailId, suffixText }: EmailWithTextProps) => (
  <span>
    {text}:
    <EmailSupport email={mailId} />. {suffixText}
  </span>
);

export default EmailWithText;
