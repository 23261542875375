import React from 'react';
import { ParagraphLarge } from 'baseui/typography';

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z0-9]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = (str) =>
  str.replace(/([-_][a-z0-9])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', '')
  );

export const transformKeysToSnake = (object) =>
  Object.fromEntries(
    Object.entries(object).map(([k, v]) => [camelToSnakeCase(k), v])
  );

export const transformKeysToCamel = (object) =>
  Object.fromEntries(
    Object.entries(object).map(([k, v]) => [snakeToCamelCase(k), v])
  );

export const daysDiff = (utcDate) => {
  const diff = new Date(utcDate).getTime() - new Date().getTime();
  return Math.ceil(diff / (1000 * 3600 * 24));
};

export const NewlineText = (texts) => {
  if (typeof texts !== 'string') {
    return texts;
  }
  const text = texts
    .split('\n')
    .map((i) => <ParagraphLarge key={i}>{i}</ParagraphLarge>);
  return text;
};

const allTextFromElement = (element) => {
  let text = '';
  if (typeof element.props.children === 'string') {
    return element.props.children;
  }
  element.props.children.forEach((child) => {
    if (typeof child === 'string') {
      text += child;
    } else {
      text += allTextFromElement(child);
    }
  });
  return text;
};

export const SmallMessage = (msg) => {
  if (typeof msg !== 'string') {
    msg = allTextFromElement(msg);
  }
  const smallMsg = msg.slice(0, 165);
  return smallMsg;
};

export const nameFromEmail = (email) =>
  email ? email.split('@')[0] : 'unknown';

export const capitalizeFirstLetter = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
