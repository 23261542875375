import React from 'react';
import { Icon } from 'baseui/icon';

const SearchTool = ({ ...props }) => {
  const {
    title = 'search tool',
    size = 80,
    color = 'white',
    ...restProps
  } = props;
  return (
    <Icon
      viewBox='0 0 80 60'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.5 25H13C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1H67C73.6274 1 79 6.37258 79 13C79 17.518 76.5031 21.4529 72.814 23.5M9 7L14 19L20 7L26 19L32 7L38 19M65.2452 41.4918L67.792 46.7008M70.502 47.7368C70.0997 46.7529 68.8701 46.3309 67.8537 46.8278C66.8698 47.2301 66.4477 48.4597 66.9446 49.4761L70.9201 57.6073C71.3225 58.5912 72.5521 59.0132 73.5685 58.5163C74.5524 58.1139 74.9745 56.8844 74.4775 55.868L70.502 47.7368ZM63.2949 15.4478C56.012 12.948 48.4215 16.5804 45.8892 23.9579C43.3893 31.2408 46.9216 39.4312 54.2045 41.9311C61.4874 44.431 69.8726 40.3312 72.3725 33.0483C74.9048 25.6709 70.5778 17.9477 63.2949 15.4478ZM62.0909 19.2636C56.8888 17.478 51.4582 20.0545 49.6401 25.3511C47.8544 30.5532 50.3956 36.3946 55.5977 38.1802C60.7998 39.9659 66.7711 37.0464 68.5567 31.8443C70.3748 26.5476 67.2929 21.0492 62.0909 19.2636Z'
        stroke='#D936B0'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
// fill="#1A1A1A"
export default SearchTool;
