import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from 'baseui';
import HardwareTitle from '../HardwareTitle';
import IpadIcon from '../../../../../../components/icons/Ipad';
import { getIpad } from '../../../../store/hardware/selectors';
import { addIpad } from '../../../../store/hardware/slice';

const Wrapper = styled('div', ({ $theme }) => ({
  border: `1px solid ${$theme.colors.mono300}`,
  ':hover': { border: `2px solid ${$theme.colors.backgroundAccent}` },
  borderRadius: '8px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px'
}));

const PickIpad = () => {
  const dispatch = useDispatch();
  const ipadData = useSelector(getIpad);

  useEffect(() => {
    dispatch(addIpad(ipadData));
  }, [ipadData]);

  return (
    <Wrapper data-testid='pick-ipad'>
      <HardwareTitle
        icon={<IpadIcon size={30} />}
        deviceName='Ipad'
        infoText={['Included by default for Sales employees']}
      />
    </Wrapper>
  );
};

export default PickIpad;
