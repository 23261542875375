import React from 'react';
import { useStyletron } from 'baseui';

import MyGoLinks from 'newHirePortal/newHire/components/MyGoLinks';
import Item from 'newHirePortal/newHire/components/Item';
import Benefits from 'assets/docs/Benefits.pdf';

const BlockDefaultMessages = () => {
  const [css, theme] = useStyletron();

  return (
    <>
      <Item>
        <strong>IT Office Hours:</strong> On your start date, you will receive
        instructions via email to set up your laptop. We recommend beginning the
        setup process between 8:00-9:00am your local time{' '}
        <strong>on your start date. Join the IT Office Hours on Day 1</strong>{' '}
        if you need technical support from a member of our IT Team.
      </Item>
      <Item>
        <strong>Onboarding Sessions:</strong> Onboarding will be conducted
        virtually via Google Meet video call during your first week. You’ll
        receive calendar invites to your work email on your Day 1 with specific
        times for each of the below sessions:
      </Item>
      <div className={css({ marginLeft: '40px' })}>
        <Item color={theme.colors.accent50}>
          <strong>Building Block Orientation </strong> - On Day 2, this live
          session will walk you through the company&apos;s history, purpose, and
          culture. As a part of this, you’ll have the chance to make new
          connections with other new hires.
        </Item>
        <Item color={theme.colors.accent50}>
          <strong>Jack Ask Me Anything Session</strong> - In this live session
          you will have the opportunity to meet with the Head of Block, Jack
          Dorsey, and ask him your top-of-mind questions.
        </Item>
        <Item color={theme.colors.accent50}>
          <strong>New Hire Equity Session</strong> - This live session will give
          you an overview of RSUs (restricted stock units), ESPP, and all things
          related to Block’s Equity Program. This session runs monthly.
        </Item>
      </div>
      <Item>
        <strong>Benefits:</strong> You will receive a new hire benefits task in
        your Workday inbox on Day 2. You will have 60 days from your start date
        to complete this task.
      </Item>
      <Item>
        <strong>This portal will expire in 10 days from your start date</strong>
        , at which point,{' '}
        <span>
          <MyGoLinks goLink='go/onboarding ' source='http://go/$newhire' />
        </span>
        will be your hub for all things onboarding. Note: this go/link will work
        for you once you have your corporate login credentials. Depending on
        your team/role, you may also receive additional calendar invites for
        function-specific orientation sessions.
      </Item>
      <Item>
        Finally, <strong>check out this helpful resource:</strong>{' '}
        <span>
          <MyGoLinks goLink='Pre-Onboarding Benefits FAQ ' source={Benefits} />
        </span>
        for more details on Block’s benefits, badge request, offices, payroll,
        Right-to-Work, background screening process, IT/Laptop Self-Service
        Onboarding, laptop equipment delay help, and more!
      </Item>
    </>
  );
};

export default BlockDefaultMessages;
