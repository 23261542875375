import React, { useState } from 'react';
import { HeadingLarge } from 'baseui/typography';
import Bio from 'newHirePortalRedesign/newHire/features/bio/Bio';
import ProfileImage from 'newHirePortalRedesign/newHire/features/profileImage/ProfileImage';
import {
  JustifyContentDiv,
  MyStyledLink,
  Divider
} from 'newHirePortalRedesign/newHire/util/styleHelpers';
import PrivacyNotice from 'assets/docs/PrivacyNotice.pdf';
import CustomButton from 'newHirePortalRedesign/newHire/component/customButton/CustomButton';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'newHirePortalRedesign/newHire/store/hooks';
import { toaster } from 'baseui/toast';
import { postProfile } from 'newHirePortalRedesign/newHire/features/profileSubmit/store/thunk/thunk';

const fullWidthStyle = {
  width: '100%'
};

export interface ImageObjType {
  file: null | File;
  imageSrc: undefined | string | null;
  imageName: string;
  imageField: null | React.RefObject<HTMLInputElement>;
}

const ProfileSubmit = () => {
  const [imageObj, setImageObj] = useState<ImageObjType>({
    file: null,
    imageSrc: undefined,
    imageName: '',
    imageField: null
  });
  const [bio, setBio] = useState<string>('');
  const dispatch = useAppDispatch();

  const onReset = (): void => {
    setImageObj({
      file: null,
      imageSrc: undefined,
      imageName: '',
      imageField: null
    });
    setBio('');
  };

  const onConfirm = () => {
    dispatch(
      postProfile({
        bioContent: bio,
        profileImage: imageObj.file
      })
    )
      .then(unwrapResult)
      .then(() => {
        // TODO: Need to add function which complete the profile task once user api implemented.
        // TODO: create a custom toaster component and toaster container to reuse for all pages similar to NHP
        toaster.positive('Submitted successfully!');
      })
      .catch(() => {
        // TODO: create a custom toaster component and toaster container to reuse for all pages similar to NHP
        toaster.negative('Submission failed. Please try again.');
      });
  };

  return (
    <>
      <HeadingLarge>Set up your Profile</HeadingLarge>
      <Bio bio={bio} setBio={setBio} />
      <ProfileImage imageObj={imageObj} setImageObj={setImageObj} />
      <Divider />
      <MyStyledLink
        href={PrivacyNotice}
        download='Square Employee Privacy Notice'
        target='_blank'
      >
        Read our Privacy Notice here
      </MyStyledLink>
      <CustomButton
        baseButtonStyleOverride={fullWidthStyle}
        handleClick={onConfirm}
      >
        Continue to confirmation
      </CustomButton>
      <JustifyContentDiv>
        <MyStyledLink onClick={onReset}>Clear all and reset</MyStyledLink>
      </JustifyContentDiv>
    </>
  );
};

export default ProfileSubmit;
