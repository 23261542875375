import React, { ReactNode } from 'react';
import { LabelLarge, LabelXSmall, ParagraphMedium } from 'baseui/typography';
import { useStyletron } from 'baseui';
import { Block, BlockProps } from 'baseui/block';
import { StyleObject } from 'styletron-react';

interface TitleWithDescriptionProps {
  title: ReactNode;
  label?: string;
  description: ReactNode;
  labelStyleOverride?: StyleObject;
  titleStyle?: StyleObject;
  descriptionStyle?: StyleObject;
  opacity?: number;
}

const TitleWithDescription = ({
  title,
  label,
  description,
  flexDirection = ['column', 'column', 'column'],
  labelStyleOverride = {},
  titleStyle,
  descriptionStyle,
  opacity = 1,
  ...restProps
}: BlockProps & TitleWithDescriptionProps) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      className={css({
        opacity,
        display: 'flex'
      })}
      flexDirection={flexDirection}
      {...restProps}
    >
      <LabelLarge $style={{ ...titleStyle }}> {title}</LabelLarge>
      {label && (
        <LabelXSmall
          data-testid='custom-label'
          className={css({
            color: theme.colors.accent50,
            paddingTop: theme.sizing.scale300,
            paddingBottom: theme.sizing.scale300,
            ...labelStyleOverride
          })}
        >
          {label}
        </LabelXSmall>
      )}

      <ParagraphMedium
        $style={{
          marginTop: theme.sizing.scale300,
          display: 'flex',
          ...descriptionStyle
        }}
      >
        {description}
      </ParagraphMedium>
    </Block>
  );
};

export default TitleWithDescription;
