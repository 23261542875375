import { API_PATH_PREFIX } from 'newHirePortalRedesign/newHire/api/constants';

export const SUBMIT_BIO_PATH = `${API_PATH_PREFIX}/user/submit_bio`;

export interface PostProfileInfoProps {
  bioContent: string;
  profileImage: File | null;
}

export const postProfileInfo = async ({
  bioContent,
  profileImage
}: PostProfileInfoProps) => {
  const data = new FormData();
  data.set('bio_content', bioContent);
  if (profileImage) data.set('profile_image', profileImage);

  return fetch(SUBMIT_BIO_PATH, {
    method: 'POST',
    referrerPolicy: 'no-referrer',
    body: data
  });
};
