import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { MonoParagraphLarge } from 'baseui/typography';

const PresentationErrorPage = ({ dataTestId = 'shipping' }) => {
  const [, theme] = useStyletron();
  return (
    <Block data-testid={`${dataTestId}-error-page`}>
      <MonoParagraphLarge>
        Thank you! You have submitted your shipping details successfully.
      </MonoParagraphLarge>

      <MonoParagraphLarge color={theme.colors.backgroundNegative}>
        Sorry! We are unable to fetch your details at this time. Please refresh
        the page to retry.
      </MonoParagraphLarge>
    </Block>
  );
};

export default PresentationErrorPage;
