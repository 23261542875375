import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';
import { THEME_NAME } from '../../newHirePortal/newHire/util/enums';

const UnderMaintTool = ({ ...props }) => {
  const {
    title = 'Under Maintenance Tool',
    size = 80,
    color = 'white',
    ...restProps
  } = props;
  const [, theme] = useStyletron();
  return (
    <Icon
      viewBox='0 0 80 80'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.9992 36.9L70.0992 11.8L73.2992 10.8L77.1992 4.8L74.2992 2L68.2992
        5.8L67.2992 9L42.1992 34.1M28 49.7L29.4 51.2M21.3008 53.8L7.30078 67.8M46.0996
        51.7999V54.5999L67.2996 75.8C69.9996 78.5 74.4996 78.5 77.1996 75.8C79.8996 73.1
        79.8996 68.6 77.1996 65.9L55.9996 44.7H53.1996M46.0996 51.7999L53.1996 44.7M46.0996
        51.7999L27.1 33.5L22.9 29.3C20.9 27.3 17.8 27.3 15.8 29.3C14.4 30.7 14 32.9 14.7 34.7L9.5
        39.9L1 31.4L6.5 25.9C8.3 26.6 10.4 26.2 11.9 24.8C13.3 23.4 13.7 21.2 13 19.4L22.9 9.49997C29.6
        2.79997 37.1 2.59997 43.9 2.89997C45.3 2.99997 46.4 4.29997 46.3 5.69997C46.2 7.09997 44.9 8.19997
        43.5 8.09997C40.4 7.79997 36.8 8.79997 34.4 11.2C32.9 12.7 31.2 15.3 30.8 17.3C30 20.8 31.5 23.7 34.2
        26.4L53.1996 44.7M35.0992 41.1001L32.1992 44.1001M35 46.9L38 44M25.1992 57.8L11.1992 71.8M32.2004
        54H37.9004L39.3004 51.2001L28.0004 39.8L25.1004 41.3V46.9001H22.3004L3.30039
        65.9001C0.600391 68.6001 0.600391 73.1 3.30039 75.8C6.00039 78.5 10.5004 78.5 13.2004 75.8L32.2004 56.8V54Z'
        stroke={theme.name === THEME_NAME.CASH ? '#00B728' : '#8716D9'}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};

export default UnderMaintTool;
