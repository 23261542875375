import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const MouseIcon = ({ ...props }) => {
  const { title = 'Mouse icon', size = 22, color, ...restProps } = props;
  const [, theme] = useStyletron();
  return (
    <Icon
      viewBox='0 0 22 26'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        d='M1 8.72727C1 4.45962 4.45962 1 8.72727 1C12.9949 1 16.4545 4.45962 16.4545 8.72727V16C16.4545 19.866 13.3205 23 9.45455 23H8C4.134 23 1 19.866 1 16V8.72727Z'
        stroke={theme.colors.backgroundAccent}
        strokeWidth='2'
        fill={theme.colors.mono100}
      />
      <rect
        x='8.22763'
        y='3.7706'
        width='1.18182'
        height='3.90909'
        rx='0.590909'
        stroke={theme.colors.backgroundAccent}
        strokeWidth='1.18182'
        fill={theme.colors.mono100}
      />
      <line
        y1='10.0898'
        x2='17.4545'
        y2='10.0898'
        stroke={theme.colors.backgroundAccent}
        strokeWidth='2'
      />
    </Icon>
  );
};

export default MouseIcon;
