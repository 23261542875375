import { createSlice } from '@reduxjs/toolkit';
import { getCoreMember } from 'newHirePortalRedesign/newHire/features/banner/store/thunk/thunk';
import { API_STATUS } from 'newHirePortalRedesign/newHire/util/constants';
import { CoreMemberType } from 'newHirePortalRedesign/newHire/features/banner/store/selectors/selectors';

interface coreMemberState {
  loadStatus: (typeof API_STATUS)[keyof typeof API_STATUS];
  data: CoreMemberType;
  error: string;
  errorCode: string;
}

export const initialState: coreMemberState = {
  loadStatus: API_STATUS.IDLE,
  data: {
    employee_id: null,
    cost_center: null,
    email: null,
    first_name: null,
    last_name: null,
    profile_image: null,
    team_code: null
  },
  error: '',
  errorCode: ''
};

const coreMemberSlice = createSlice({
  name: 'coreMember',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoreMember.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(getCoreMember.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = action.payload;
      })
      .addCase(getCoreMember.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
          state.errorCode = action.payload.code;
        } else {
          state.error = action.error.message || 'Something went wrong';
        }
      });
  }
});

const { reducer } = coreMemberSlice;

export default reducer;
