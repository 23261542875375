import React from 'react';
import { styled, useStyletron } from 'baseui';
import UserAvatar from './UserAvatar';

const UseravatarWithBg = ({ completed, size = 24, ...props }) => {
  const [, theme] = useStyletron();

  const IconWrap = styled('div', () => ({
    background: theme.colors.backgroundAccent,
    padding: '8px',
    borderRadius: '6px',
    width: '24px',
    height: '23px'
  }));

  return (
    <IconWrap>
      <UserAvatar size={size} {...props} />
    </IconWrap>
  );
};

export default UseravatarWithBg;
