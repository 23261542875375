import { LightTheme } from 'baseui';
import {
  yourFirstWeekData as yourFirstWeekCashData,
  YourFirstWeekObject
} from 'newHirePortalRedesign/newHire/features/yourFirstWeek/data/cash';
import { BU_NAME } from 'newHirePortalRedesign/newHire/util/constants';

export const useFirstWeekDataByTheme = (
  theme: typeof LightTheme
): YourFirstWeekObject[] => {
  const yourFirstWeekDataMap = {
    [BU_NAME.CASH]: yourFirstWeekCashData,
    default: []
  };

  return yourFirstWeekDataMap[theme.name] || yourFirstWeekDataMap.default;
};
