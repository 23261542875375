import React from 'react';
import { useSelector } from 'react-redux';
import { styled, useStyletron } from 'baseui';
import { usernameSelectTaskCompleted } from '../../../store/user/selectors';
import UsernameSelection from './UsernameSelection';
import PresentationPage from './PresentationPage';
import RehireUsernamePage from './RehireUsernamePage';
import slime from '../../../../../assets/images/slime.png';
import pyramid from '../../../../../assets/images/pyramid.png';
import CashImgWrap from '../../../components/CashImgWrap';
import { THEME_NAME, HIRE_TYPE } from '../../../util/enums';
import { nameFromEmail } from '../../../util/dataParseHelpers';

const FormWrap = styled('div', () => ({
  position: 'relative'
}));

const Page = () => {
  const isTaskCompleted = useSelector(usernameSelectTaskCompleted);
  const userData = useSelector((state) => state.user.data);
  const [, theme] = useStyletron();
  let pageContent;

  if (userData.hire_type !== HIRE_TYPE.NEWHIRE) {
    pageContent = (
      <RehireUsernamePage workEmail={nameFromEmail(userData.work_email)} />
    );
  } else {
    pageContent = isTaskCompleted ? (
      <PresentationPage />
    ) : (
      <UsernameSelection />
    );
  }

  return (
    <>
      {theme.name === THEME_NAME.CASH ? (
        <FormWrap>
          <CashImgWrap
            position='absolute'
            top='600px'
            left='-150px'
            src={pyramid}
            alt='pyramid'
          />
          <CashImgWrap
            position='absolute'
            right='-38px'
            top='250px'
            src={slime}
            alt='slime'
            zIndex='-1'
          />
        </FormWrap>
      ) : (
        ' '
      )}
      {pageContent}
    </>
  );
};

export default Page;
