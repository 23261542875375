import React from 'react';
import { Select, SIZE } from 'baseui/select';
import { ChevronDown, ChevronUp } from 'baseui/icon';
import { ENGLISH_LANG_CHOICE_VALUE } from 'newHirePortal/newHire/util/hardwareContants';
import { allDirectionStyle } from 'newHirePortal/newHire/util/styleHelpers';

const getDisplayLabel = ({ option }) => {
  const langName = option.name;
  if (langName === ENGLISH_LANG_CHOICE_VALUE) {
    return `${ENGLISH_LANG_CHOICE_VALUE}-US`;
  }
  return langName;
};

const KeyboardLanguageDropdown = ({ onChange, value, options, disabled }) => (
  <Select
    disabled={disabled}
    options={options}
    clearable={false}
    valueKey='sq_part_number'
    size={SIZE.large}
    onChange={onChange}
    value={value}
    getOptionLabel={getDisplayLabel}
    getValueLabel={getDisplayLabel}
    placeholder='Keyboard Language'
    overrides={{
      Root: {
        style: ({ $theme }) => ({
          display: 'flex',
          alignSelf: 'flex-end',
          marginTop: $theme.sizing.scale700,
          outline: `${$theme.colors.mono300} solid 1px`,
          height: '100%',
          ...allDirectionStyle({
            radius: $theme.sizing.scale200,
            prop: 'border'
          }),
          [$theme.mediaQuery.large]: {
            width: '30%'
          },
          [$theme.mediaQuery.medium]: {
            width: '100%'
          }
        }),
        props: { 'data-testid': 'keyboard-lang-select' }
      },
      ControlContainer: {
        style: ({ $theme, $isFocused }) => ({
          backgroundColor: $theme.colors.mono100,
          display: 'flex',
          alignItems: 'center',
          ...allDirectionStyle({
            radius: $theme.sizing.scale200,
            prop: 'border',
            color: $isFocused
              ? $theme.colors.backgroundAccent
              : $theme.colors.primary50
          })
        }),
        props: { 'data-testid': 'keyboard-lang-select-control' }
      },
      Placeholder: {
        style: ({ $theme, $disabled }) => {
          let styles = {
            ...$theme.typography.MonoParagraphMedium,
            color: $theme.colors.contentPrimary
          };
          if ($disabled) {
            styles = { ...styles, color: 'none' };
          }
          return styles;
        }
      },
      DropdownListItem: {
        style: ({ $theme, $isHighlighted }) => ({
          ...$theme.typography.ParagraphMedium,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: $isHighlighted ? $theme.colors.accent50 : 'none',
          paddingTop: '17px',
          paddingBottom: '17px',
          marginTop: $isHighlighted ? $theme.sizing.scale400 : 0,
          borderBottom: `1px solid ${$theme.colors.mono200}`,
          ...allDirectionStyle({
            radius: $theme.sizing.scale200,
            prop: 'border'
          })
        }),
        props: {
          'data-testid': 'keyboard-lang-select-dropdown-list-item',
          'data-dd-action-name': 'Clicked to Select Keyboard Language'
        }
      },
      Dropdown: {
        style: () => ({
          ...allDirectionStyle({ radius: '7px', prop: 'border' })
        })
      },
      IconsContainer: {
        component: ({ $isOpen }) =>
          $isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />
      }
    }}
  />
);

export default KeyboardLanguageDropdown;
