import React from 'react';
import { styled, useStyletron } from 'baseui';
import { useAppSelector } from 'newHirePortalRedesign/newHire/store/hooks';
import {
  isAllWorkdayAndHumanTasksDone,
  workdayTasksLoadState
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/store/selectors/selectors';
import { ParagraphMedium } from 'baseui/typography';
import Check from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/icons/Check';
import { CapitalizedThemeName } from 'newHirePortalRedesign/newHire/Styles';
import CustomSkeleton from 'newHirePortalRedesign/newHire/component/customSkeleton/CustomSkeleton';
import { SkeletonWrapper } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/Styles';
import { API_STATUS } from 'utils/api_helpers';

interface TaskCompletionStatusNoticeProps {
  apiFailedCallCount: number;
}

export const NoticeWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  gap: '11px',
  marginBottom: $theme.sizing.scale1000
}));

const TaskCompletionStatusNotice = ({
  apiFailedCallCount
}: TaskCompletionStatusNoticeProps) => {
  const isAllTasksCompleted = useAppSelector(isAllWorkdayAndHumanTasksDone);
  const workdayTasksLoadStatus = useAppSelector(workdayTasksLoadState);
  const [css, theme] = useStyletron();

  let taskCompletionStatus = null;

  if (workdayTasksLoadStatus === API_STATUS.FAILED) {
    taskCompletionStatus = (
      <ParagraphMedium paddingBottom={theme.sizing.scale800}>
        We are working on fetching your pending Workday tasks. Please revisit
        the page later.
      </ParagraphMedium>
    );
  }

  if (workdayTasksLoadStatus === API_STATUS.COMPLETED) {
    taskCompletionStatus = isAllTasksCompleted ? (
      <NoticeWrapper>
        <Check />
        <ParagraphMedium margin={0}>
          <span className={css({ display: 'block', marginBottom: '12px' })}>
            All your tasks are complete!
          </span>
          You’ll receive employee access on your first day with your corporate
          credentials.
        </ParagraphMedium>
      </NoticeWrapper>
    ) : (
      <ParagraphMedium paddingBottom={theme.sizing.scale800}>
        Please complete all onboarding tasks listed below prior to Day 1 at{' '}
        <CapitalizedThemeName /> App.
      </ParagraphMedium>
    );
  }

  if (
    workdayTasksLoadStatus === API_STATUS.LOADING &&
    apiFailedCallCount === 0
  ) {
    taskCompletionStatus = (
      <SkeletonWrapper>
        <CustomSkeleton
          dataTestId='task-completion-status-loader'
          rows={1}
          rootStyleOverride={{ width: '644px' }}
          rowStyleOverride={{
            height: '28px',
            marginBottom: '28px',
            marginTop: '8px'
          }}
        />
      </SkeletonWrapper>
    );
  }

  return taskCompletionStatus;
};

export default TaskCompletionStatusNotice;
