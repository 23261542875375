import { useState, useEffect, RefObject } from 'react';
import { SCROLL_DIRECTION } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/constants';

export const useScrollDirection = (
  ref: RefObject<HTMLElement>,
  isOverflow: boolean
): keyof typeof SCROLL_DIRECTION => {
  const [scrollDirection, setScrollDirection] = useState<
    keyof typeof SCROLL_DIRECTION
  >(SCROLL_DIRECTION.left);

  useEffect(() => {
    const { current } = ref;

    const onScroll = () => {
      if (current) {
        let newScrollDirection: keyof typeof SCROLL_DIRECTION =
          SCROLL_DIRECTION.left;

        const isScrollRight =
          current.offsetWidth + current.scrollLeft >= current.scrollWidth;
        const isScrollInbetween =
          current.scrollLeft > 0 &&
          current.offsetWidth + current.scrollLeft < current.scrollWidth;

        if (isScrollRight) {
          newScrollDirection = SCROLL_DIRECTION.right;
        } else if (isScrollInbetween) {
          newScrollDirection = SCROLL_DIRECTION.inbetween;
        }

        setScrollDirection(newScrollDirection);
      }
    };

    if (isOverflow && current) current.addEventListener('scroll', onScroll);

    return () => {
      if (current) current.removeEventListener('scroll', onScroll);
    };
  }, [isOverflow, ref]);

  return scrollDirection;
};
