import React, { ReactNode } from 'react';
import { useStyletron } from 'baseui';
import { responsiveHeadingStyle } from 'newHirePortalRedesign/newHire/Styles';
import { Heading } from 'baseui/heading';
import { ParagraphMedium } from 'baseui/typography';
import { StyleObject } from 'styletron-react';

interface HeadingWithSubTextProps {
  heading: ReactNode;
  subText?: ReactNode;
  overridesHeadingStyles?: StyleObject;
  overridesSubTextStyles?: StyleObject;
}

const HeadingWithSubText = ({
  heading,
  subText,
  overridesHeadingStyles,
  overridesSubTextStyles
}: HeadingWithSubTextProps) => {
  const [css, theme] = useStyletron();

  return (
    <>
      <Heading
        className={css({
          marginBottom: subText ? '0' : 'none',
          ...responsiveHeadingStyle(theme),
          ...overridesHeadingStyles
        })}
      >
        {heading}
      </Heading>
      {subText && (
        <ParagraphMedium
          data-testid='sub-text'
          className={css({
            paddingBottom: theme.sizing.scale600,
            ...overridesSubTextStyles
          })}
        >
          {subText}
        </ParagraphMedium>
      )}
    </>
  );
};

export default HeadingWithSubText;
