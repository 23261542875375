import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUsernames as getUsernamesApi } from '../../api/usernames';
import { handleApiResponse } from '../../../../utils/api_helpers';
import { postUsername as postUsernameApi } from '../../api/username';

export const getUsernames = createAsyncThunk(
  'usernames/show',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await getUsernamesApi();
      const result = await handleApiResponse(response);
      if (result.ok) {
        return result.data;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const postUsername = createAsyncThunk(
  'username/post',
  async ({ selectedUsername }, { rejectWithValue }) => {
    try {
      const response = await postUsernameApi({ selectedUsername });
      const result = await handleApiResponse(response);
      if (result.ok) {
        return result.data;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
