import React from 'react';
import { useStyletron, styled } from 'baseui';

const LanguageTitleInfo = styled(
  'div',
  ({ $marginLeft, $marginTop, $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: $marginLeft,
    marginTop: $marginTop,
    marginBottom: '15px',
    borderBottom: `1px solid ${$theme.colors.mono200}`,
    width: '95%'
  })
);

const TitleInfo = ({ heading, subText, marginLeft, marginTop }) => {
  const [css, theme] = useStyletron();
  return (
    <LanguageTitleInfo $marginLeft={marginLeft} $marginTop={marginTop}>
      <span
        className={css({
          ...theme.typography.MonoParagraphMedium,
          lineHeight: '22px',
          paddingBottom: '6px',
          color: theme.colors.contentAccent
        })}
      >
        {heading}
      </span>
      <span
        className={css({
          ...theme.typography.ParagraphSmall,
          color: theme.colors.mono500,
          marginBottom: '5px'
        })}
      >
        {subText}
      </span>
    </LanguageTitleInfo>
  );
};

export default TitleInfo;
