import React from 'react';
import { withStyle } from 'baseui';
import { StyledLink } from 'baseui/link';
import PrivacyNotice from '../../../assets/docs/PrivacyNotice.pdf';

const PrivacyLink = ({ marginTop }) => {
  const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
    ...$theme.typography.MonoParagraphMedium,
    marginTop,
    display: 'block',
    color: $theme.colors.emphasis_text,
    textDecoration: 'none',
    cursor: 'pointer'
  }));
  return (
    <MyStyledLink
      href={PrivacyNotice}
      download='Square Employee Privacy Notice'
      target='_blank'
      data-dd-action-name='Square Employee Privacy Notice'
    >
      Read our Privacy Notice here
    </MyStyledLink>
  );
};

export default PrivacyLink;
