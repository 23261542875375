export const SHIPPING_URL_PATH = '/your_info_shipping';
export const PROFILE_URL_PATH = '/your_info_bio';
export const USERNAME_URL_PATH = '/your_info_username';
export const HARDWARE_URL_PATH = '/your_info_hardware';
export const SHIPPING_PAGE_TITLE = 'Shipping Questionnaire';
export const PROFILE_PAGE_TITLE = 'Profile';
export const USERNAME_PAGE_TITLE = 'Username Selection';
export const HARDWARE_PAGE_TITLE = 'Hardware Selection';
export const HARDWARE_SHIPPING_PAGE_TITLE = 'Hardware & Shipping';

export const SIDE_NAV_ROUTES = [
  {
    title: 'Dashboard',
    itemId: '/',
    path: '/',
    other_paths: []
  },
  {
    title: 'Your Information',
    itemId: SHIPPING_URL_PATH,
    path: SHIPPING_URL_PATH,
    other_paths: [PROFILE_URL_PATH, HARDWARE_URL_PATH, USERNAME_URL_PATH]
  }
];

// Try to find a side nav to match the path, using other_paths if fuzzy is true
// This is to find and show the highlighted side nav item
export const sideNavSearch = (pathname, fuzzy = false) => {
  const exactMatch = SIDE_NAV_ROUTES.find((nav) => nav.path === pathname);
  if (exactMatch) {
    return exactMatch.path;
  }
  if (!fuzzy) return '/';

  const fuzzyMatch = SIDE_NAV_ROUTES.find((nav) =>
    nav.other_paths.find((other) => other === pathname)
  );
  return fuzzyMatch ? fuzzyMatch.itemId : '/';
};

// Note: If we are adding any more routes here, the please also add the same route
// to `config/routes.rb#60` file.

// this array also entails the sequence of the task appeared on the UI
export const ROUTES = [
  {
    path: SHIPPING_URL_PATH,
    title: SHIPPING_PAGE_TITLE,
    alias: ['Shipping Address'],
    optional: false
  },
  {
    path: SHIPPING_URL_PATH,
    title: HARDWARE_SHIPPING_PAGE_TITLE,
    optional: false
  },
  // {
  //   path: HARDWARE_URL_PATH,
  //   title: HARDWARE_PAGE_TITLE,
  //   optional: false
  // },
  {
    path: USERNAME_URL_PATH,
    title: USERNAME_PAGE_TITLE,
    optional: true
  },
  {
    path: PROFILE_URL_PATH,
    title: PROFILE_PAGE_TITLE,
    optional: true
  }
];

export const isNameMatchRoute = (route, name) =>
  route.title === name || (route.alias && route.alias.includes(name));

export const getPathByName = (name) => {
  const dest = ROUTES.find((route) => isNameMatchRoute(route, name));
  return dest ? dest.path : '/';
};

export const getRouteDisplayTitle = (route) => {
  const isOptional = route.optional === true;
  return isOptional ? `${route.title}(optional)` : route.title;
};

// External Routes
export const WORKDAY_TEMP_LINK =
  'https://wd5.myworkday.com/squareinc/login.flex?redirect=n';
