import React from 'react';
import { HeadingXXLarge } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';
import Progress from './Progress';
import MsgBanner from './MsgBanner';
import BeforeDayOne from './BeforeDayOne/BeforeDayOne';
import UsefulLinks from './UsefulLinks/UsefulLinks';
import WeekOne from './WeekOne/WeekOne';
import { THEME_NAME } from '../../util/enums';
import windowSpace from '../../../../assets/images/windowSpace.png';
import bridge from '../../../../assets/images/bridge.png';
import crossUp from '../../../../assets/images/crossUp.png';
import holeClouds from '../../../../assets/images/holeclouds.png';
import CashImgWrap from '../../components/CashImgWrap';

const Wrap = styled('div', () => ({
  position: 'relative'
}));

const Dashboard = () => {
  const [, theme] = useStyletron();
  return (
    <>
      <HeadingXXLarge $style={{ padding: '10px 0' }}>Dashboard</HeadingXXLarge>
      <Progress />
      {theme.name === THEME_NAME.CASH ? (
        <>
          <Wrap>
            <CashImgWrap
              position='absolute'
              top='-15px'
              left='-20px'
              src={windowSpace}
              alt='window-space'
            />
            <MsgBanner />
          </Wrap>

          <Wrap>
            <CashImgWrap
              position='absolute'
              top='-75px'
              right='8px'
              src={bridge}
              alt='bridge'
            />
            <BeforeDayOne />
          </Wrap>

          <Wrap>
            <CashImgWrap
              position='absolute'
              top='-40px'
              left='-60px'
              src={crossUp}
              alt='cross-up'
            />
            <WeekOne />
          </Wrap>

          <Wrap>
            <CashImgWrap
              position='absolute'
              top='-50px'
              right='-40px'
              height='63px'
              src={holeClouds}
              alt='hole-clouds'
            />
            <UsefulLinks />
          </Wrap>
        </>
      ) : (
        <>
          <MsgBanner />
          <BeforeDayOne />
          <WeekOne />
          <UsefulLinks />
        </>
      )}
    </>
  );
};

export default Dashboard;
