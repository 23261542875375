import { StyleObject } from 'styletron-react';
import { styled, withStyle } from 'baseui';
import { StyledLink } from 'baseui/link';

export interface ResponsiveTheme {
  breakpoints: {
    [key: string]: number;
  };
  mediaQuery: {
    [key: string]: string;
  };
}

interface MediaQueryStyles {
  [key: string]: React.CSSProperties | undefined;
}

interface MediaQueryStylesArgs {
  $theme: ResponsiveTheme;
  mediaSizes: string[];
  styles?: React.CSSProperties;
}

interface AllDirectionStyleArgs {
  prop: string;
  color?: string;
  width?: string;
  style?: string;
  radius?: string;
}

export const allDirectionStyle = ({
  prop,
  color,
  width,
  style,
  radius
}: AllDirectionStyleArgs): StyleObject => {
  let styleObj = {};

  if (color) {
    styleObj = {
      ...styleObj,
      [`${prop}LeftColor`]: color,
      [`${prop}RightColor`]: color,
      [`${prop}BottomColor`]: color,
      [`${prop}TopColor`]: color
    };
  }

  if (style) {
    styleObj = {
      ...styleObj,
      [`${prop}LeftStyle`]: style,
      [`${prop}RightStyle`]: style,
      [`${prop}BottomStyle`]: style,
      [`${prop}TopStyle`]: style
    };
  }

  if (width) {
    styleObj = {
      ...styleObj,
      [`${prop}LeftWidth`]: width,
      [`${prop}RightWidth`]: width,
      [`${prop}BottomWidth`]: width,
      [`${prop}TopWidth`]: width
    };
  }

  if (radius) {
    styleObj = {
      ...styleObj,
      [`${prop}TopLeftRadius`]: radius,
      [`${prop}TopRightRadius`]: radius,
      [`${prop}BottomLeftRadius`]: radius,
      [`${prop}BottomRightRadius`]: radius
    };
  }
  return styleObj;
};

export const mediaQueryStyles = ({
  $theme,
  mediaSizes,
  styles
}: MediaQueryStylesArgs): MediaQueryStyles =>
  mediaSizes.reduce((hash: MediaQueryStyles, size: string) => {
    hash[$theme.mediaQuery[size]] = styles;
    return hash;
  }, {});

export const Divider = styled('div', ({ $theme }) => ({
  borderBottom: `1px solid ${$theme.colors.mono200}`,
  marginTop: $theme.sizing.scale1000
}));

export const JustifyContentDiv = styled('div', () => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '.25rem',
  marginTop: '30px'
}));

export const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
  ...$theme.typography.ParagraphMedium,
  display: 'block',
  color: $theme.colors.buttonPrimaryFill,
  cursor: 'pointer',
  fontWeight: 'bold',
  marginBottom: $theme.sizing.scale1000,
  marginTop: $theme.sizing.scale1000
}));
