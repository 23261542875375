import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const DektopIcon = ({ ...props }) => {
  const { title = 'Desktop icon', size = 32, color, ...restProps } = props;
  const [, theme] = useStyletron();
  return (
    <Icon
      viewBox='0 0 30 27'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        d='M26.7999 1H3.1999C0.875905 1 0.999906 2.8425 0.999906 5.125V19.125C0.999906 21.4075 0.499882 23 3.19991 23H6.99988H10.9999V26H18.9999V23H22.9999H26.9999C29.4999 23 28.9999 21.4075 28.9999 19.125V5.125C28.9999 2.8425 29.1239 1 26.7999 1ZM26.1999 17.125C26.1999 17.8813 25.5699 18.5 24.7999 18.5H14.9999H5.1999C4.4299 18.5 3.79991 17.8813 3.79991 17.125V5.125C3.79991 4.36875 4.4299 3.75 5.1999 3.75H24.7999C25.5699 3.75 26.1999 4.36875 26.1999 5.125V17.125Z'
        strokeWidth='0.6'
        stroke={theme.colors.backgroundAccent}
        fill={theme.colors.mono100}
      />
      <rect
        x='8.22763'
        y='3.7706'
        width='1.18182'
        height='3.90909'
        rx='0.590909'
        stroke={theme.colors.backgroundAccent}
        strokeWidth='1.18182'
        fill={theme.colors.mono100}
      />
      <line
        y1='10.0898'
        x2='17.4545'
        y2='10.0898'
        stroke={theme.colors.backgroundAccent}
        strokeWidth='2'
      />
    </Icon>
  );
};

export default DektopIcon;
