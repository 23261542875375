import { Theme } from 'baseui';
import { StyleObject } from 'styletron-react';
import { allDirectionStyle } from 'newHirePortalRedesign/newHire/util/styleHelpers';

const getResponsiveCardStyle = (theme: Theme): StyleObject => ({
  [theme.mediaQuery.small]: {
    margin: `0px 0px ${theme.sizing.scale800} 0px`
  },
  [theme.mediaQuery.medium]: {
    margin: `${theme.sizing.scale800} ${theme.sizing.scale800} ${theme.sizing.scale900} ${theme.sizing.scale800}`
  },
  [theme.mediaQuery.large]: {
    margin: `${theme.sizing.scale800} ${theme.sizing.scale800} ${theme.sizing.scale900} ${theme.sizing.scale800}`
  },

  ':only-child  > div > div:nth-child(2)': {
    display: 'flex',
    justifyContent: 'center'
  }
});

const getResponsiveButtonStyle = (
  theme: Theme,
  isTaskCompleted: boolean
): StyleObject => {
  const styleBasedOnTaskCompletion = isTaskCompleted
    ? {
        backgroundColor: theme.colors.white,
        color: theme.colors.buttonPrimaryFill,
        ...allDirectionStyle({
          width: '1px',
          prop: 'border',
          style: 'solid',
          color: theme.colors.buttonPrimaryFill
        }),
        ':hover': {
          backgroundColor: theme.colors.buttonPrimaryFill,
          color: theme.colors.white
        }
      }
    : {};

  return {
    ...styleBasedOnTaskCompletion,
    [theme.mediaQuery.small]: {
      width: 'calc(100% - 48px)'
    },
    [theme.mediaQuery.medium]: {
      width: '100%'
    },
    [theme.mediaQuery.large]: {
      width: '100%'
    }
  };
};

export { getResponsiveCardStyle, getResponsiveButtonStyle };
