import React, { useEffect, useRef } from 'react';
import { FlexGrid } from 'baseui/flex-grid';
import { useStyletron } from 'baseui';
import {
  useAppSelector,
  useAppDispatch
} from 'newHirePortalRedesign/newHire/store/hooks';
import Workday from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/Workday';
import { HeadingLevel, Heading } from 'baseui/heading';
import ScrollButtonIcon from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/components/scrollButtonIcon/ScrollButtonIcon';
import { ChevronRight, ChevronLeft } from 'baseui/icon';
import { useScrollFadeEffects } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/hooks/useScrollFadeEffects/useScrollFadeEffects';
import HumanTasks from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/humanTasks/HumanTasks';
import { workdayTasksLoadState } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/store/selectors/selectors';
import { API_STATUS } from 'utils/api_helpers';
import { getUserWorkdayTasks } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/thunk/thunk';
import { responsiveHeadingStyle } from 'newHirePortalRedesign/newHire/Styles';
import TaskCompletionStatusNotice from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/taskCompletionStatusNotice/TaskCompletionStatusNotice';

const TasksBeforeDayOne = () => {
  const scrollSlideRight = +50;
  const scrollSlideLeft = -50;

  const [css, theme] = useStyletron();
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isFadeLeft, isFadeRight } = useScrollFadeEffects(scrollRef);
  const workdayTasksLoadStatus = useAppSelector(workdayTasksLoadState);
  const apiFailedCallCount = useAppSelector(
    (state) => state.workdayTasks.countApiFailedCall
  );

  const handleScroll = (scrollOffset: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
    if (workdayTasksLoadStatus === API_STATUS.IDLE) {
      dispatch(getUserWorkdayTasks());
    }
  }, [workdayTasksLoadStatus]);

  return (
    <>
      <HeadingLevel>
        <Heading className={css({ ...responsiveHeadingStyle(theme) })}>
          Tasks before day 1
        </Heading>
      </HeadingLevel>
      <TaskCompletionStatusNotice apiFailedCallCount={apiFailedCallCount} />

      <div
        className={css({
          position: 'relative',
          WebkitMaskImage: isFadeRight
            ? ' linear-gradient(256deg, rgba(255, 255, 255, 0), rgba(204,204,204,1) 10%)'
            : 'none'
        })}
      >
        {isFadeLeft && (
          <ScrollButtonIcon handleClick={() => handleScroll(scrollSlideLeft)}>
            <ChevronLeft size={37} color='black' />
          </ScrollButtonIcon>
        )}

        <FlexGrid
          ref={scrollRef}
          gridGap={theme.sizing.scale800}
          overrides={{
            Block: {
              style: ({ $theme }) => ({
                overflowX: 'scroll',
                '::-webkit-scrollbar': { display: 'none' },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                scrollBehavior: 'smooth',
                WebkitMaskImage: isFadeLeft
                  ? 'linear-gradient(93deg, rgba(242,242,242,0) , rgba(204,204,204,1) 10%)'
                  : 'none',
                [$theme.mediaQuery.medium]: {
                  flexWrap: 'nowrap'
                },
                [$theme.mediaQuery.large]: {
                  flexWrap: 'nowrap'
                }
              })
            }
          }}
        >
          <Workday apiFailedCallCount={apiFailedCallCount} />
          <HumanTasks />
        </FlexGrid>

        {isFadeRight && (
          <ScrollButtonIcon
            overideStyle={{ left: '95%' }}
            handleClick={() => handleScroll(scrollSlideRight)}
          >
            <ChevronRight size={37} color='black' />
          </ScrollButtonIcon>
        )}
      </div>
    </>
  );
};

export default TasksBeforeDayOne;
