import { useState, useLayoutEffect, RefObject } from 'react';

export const useIsHeightOverflow = (
  elementRef: RefObject<HTMLElement>
): boolean => {
  const [isHeightOverflow, setIsHeightOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const { current } = elementRef;

    const trigger = () => {
      if (current) {
        const hasOverflow = current.scrollHeight > current.clientHeight;

        setIsHeightOverflow(hasOverflow);
      }
    };

    trigger();
  }, [elementRef]);

  return isHeightOverflow;
};
