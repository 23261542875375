/* eslint-disable max-len */
export const findHardwareByDSQPN = (hardwareArray, sqPartNumber) =>
  hardwareArray.find((hardware) => hardware.sq_part_number === sqPartNumber);

export const findHardwareByLangOption = (
  hardwareArray,
  parentDeviceDefaultLangOption
) =>
  hardwareArray
    .filter((hardware) =>
      hardware.language_choices.some(
        (lang) => lang.sq_part_number === parentDeviceDefaultLangOption
      )
    )
    .pop();

export const findLanguageBySQPN = (langArray, sqPartNumber) =>
  langArray.find((lang) => lang.sq_part_number === sqPartNumber);
