import { useStyletron } from 'baseui';
import React from 'react';
import { ParagraphLarge } from 'baseui/typography';
import UnderMaintTool from '../../../../components/icons/UnderMaintTool';
import HeadLine from './Headline';
import MiscCommonFrame from './MiscCommonFrame';

const MaintenancePage = () => {
  const [css] = useStyletron();

  return (
    <MiscCommonFrame>
      <HeadLine fontWeight='300'>Under Maintenance</HeadLine>

      <span
        className={css({
          margin: '60px'
        })}
      >
        <UnderMaintTool />
      </span>

      <ParagraphLarge marginBottom={0} marginTop={0}>
        The website is currently down for scheduled maintenance.
      </ParagraphLarge>

      <ParagraphLarge marginTop={0}>
        We expect to be back in a couple of hours. Thank you for your patience.
      </ParagraphLarge>
    </MiscCommonFrame>
  );
};

export default MaintenancePage;
