import { useMemo } from 'react';
import selectUsername from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/assets/cash/selectUsername.png';
import setUpProfile from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/assets/cash/setUpProfile.png';
import selectHardware from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/assets/cash/selectHardware.png';
import { HumanTask } from 'newHirePortalRedesign/newHire/app/store/slice/slice';
import { TASK_NAME } from 'newHirePortalRedesign/newHire/util/constants';
import {
  USERNAME_URL_PATH,
  PROFILE_URL_PATH
} from 'newHirePortalRedesign/newHire/util/routes';
import { responsiveImageStyle } from 'newHirePortalRedesign/newHire/Styles';
import {
  TASK_STATUS,
  completeLabel
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/constants';
import { StyleObject } from 'styletron-react';
import {
  dateWithDayInMonth,
  utcToLocal
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/utils/dateHelpers/dateHelpers';

interface NormalizedHumanTask {
  title: string;
  optional: boolean;
  description: string;
  imgSrc: string;
  imgAltText: string;
  taskList: string[];
  targetLink: string;
}

const selectHardwareTask: NormalizedHumanTask = {
  title: 'Select hardware',
  optional: false,
  description:
    'Once your equipment is ordered, we will email you a tracking number so you know when it’s coming.',
  imgSrc: selectHardware,
  imgAltText: 'Select hardware',
  taskList: [
    'Select your laptop and any other necessary hardware',
    'Fill out the shipping questionaire'
  ],
  targetLink: '#'
};

const selectUsernameTask: NormalizedHumanTask = {
  title: 'Select your username',
  optional: true,
  description:
    'Pick the username of your choice. We’ll have it set up for you before you start at Cash.',
  imgSrc: selectUsername,
  imgAltText: 'Select username',
  taskList: ['Select your username from a few choices'],
  targetLink: USERNAME_URL_PATH
};

const setUpProfileTask: NormalizedHumanTask = {
  title: 'Set up your profile',
  optional: true,
  description:
    'You’ll receive employee access on your first day with your corporate credentials.',
  imgSrc: setUpProfile,
  imgAltText: 'Set your profile',
  taskList: [
    'Upload your image so people can recognize you',
    'Fill out your profile to get up and running'
  ],
  targetLink: PROFILE_URL_PATH
};

export type HydratedHumanTask = NormalizedHumanTask &
  HumanTask & {
    imageStyle: StyleObject;
    dueLabel: string;
    opacity: number;
    isTaskCompleted: boolean;
  };

export const useGetNormalizedHumanTasks = (
  humanTasks: HumanTask[]
): HydratedHumanTask[] => {
  const hydratedHumanTasks = useMemo(
    () =>
      humanTasks
        .map((task) => {
          const isTaskCompleted = task.state === TASK_STATUS.COMPLETED;
          const opacity = isTaskCompleted ? 0.5 : 1;
          const imageStyle = { ...responsiveImageStyle, opacity };

          const dueLabel = isTaskCompleted
            ? completeLabel
            : task.due_date &&
              `Due ${dateWithDayInMonth(
                utcToLocal(task.due_date).toDateString()
              )}`;

          const { [task.name]: taskTemplate } = {
            [TASK_NAME.HARDWARE]: selectHardwareTask,
            [TASK_NAME.USERNAME]: selectUsernameTask,
            [TASK_NAME.PROFILE]: setUpProfileTask
          };

          return (
            taskTemplate && {
              ...taskTemplate,
              ...task,
              imageStyle,
              dueLabel,
              opacity,
              isTaskCompleted
            }
          );
        })
        .filter((task) => task),
    [humanTasks]
  );

  return hydratedHumanTasks;
};
