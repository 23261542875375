import React, { Dispatch, SetStateAction, useRef, ChangeEvent } from 'react';
import { ParagraphMedium } from 'baseui/typography';
import { toaster } from 'baseui/toast';
import { useStyletron } from 'baseui';
import { KIND } from 'baseui/button';
import CustomButton from 'newHirePortalRedesign/newHire/component/customButton/CustomButton';
import ShareIcon from 'newHirePortalRedesign/newHire/features/profileImage/icon/shareIcon';
import { ImageObjType } from 'newHirePortalRedesign/newHire/features/profileSubmit/ProfileSubmit';

const ProfileImage = ({
  setImageObj,
  imageObj
}: {
  setImageObj: Dispatch<SetStateAction<ImageObjType>>;
  imageObj: ImageObjType;
}) => {
  const [css, theme] = useStyletron();
  const reader = new FileReader();
  const inputRef = useRef<HTMLInputElement>(null);

  let currentFile: File;
  let name: string;

  reader.onload = (e) => {
    setImageObj({
      ...imageObj,
      imageSrc: e.target?.result as string,
      imageName: name,
      file: currentFile,
      imageField: inputRef
    });
  };

  const onImageUploaded = (e: ChangeEvent<HTMLInputElement>): void => {
    const fileInput = e.target;
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      return;
    }

    const file: File = fileInput.files[0];
    const oneMb: number = 1024 * 1024;
    if (file) {
      if (file.size > oneMb) {
        // TODO: create a custom toaster component and toaster container to reuse for all pages similar to NHP
        toaster.negative('File size exceeds 1MB');
        return;
      }
      reader.readAsDataURL(file);
      currentFile = file;
      name = file.name;
    }
  };

  const baseButtonStyleOverride = {
    color: 'black',
    backgroundColor: 'white',
    border: `2px solid ${theme.colors.buttonPrimaryFill}`,
    width: '100%'
  };

  return (
    <>
      <ParagraphMedium>
        <b>Profile Image</b>
      </ParagraphMedium>
      <ParagraphMedium>
        Follow the following guidelines when submitting a photo:
      </ParagraphMedium>
      <ul>
        <li>
          <ParagraphMedium>
            Photo must be JPEG, PNG, or GIF file type
          </ParagraphMedium>
        </li>
        <li>
          <ParagraphMedium>Maximum file size accepted is 1MB.</ParagraphMedium>
        </li>
      </ul>
      <input
        ref={inputRef}
        type='file'
        accept='.jpeg,.png,.gif'
        onChange={onImageUploaded}
        hidden
        data-testid='hidden-input-field'
      />

      {!imageObj.imageSrc && (
        <CustomButton
          baseButtonStyleOverride={baseButtonStyleOverride}
          kind={KIND.secondary}
          handleClick={() => inputRef.current?.click()}
        >
          <ShareIcon />
          &nbsp; Upload profile image
        </CustomButton>
      )}

      {imageObj.imageSrc && (
        <div>
          <div
            className={css({
              marginRight: theme.sizing.scale2400
            })}
          >
            <img
              className={css({
                width: '320px',
                borderRadius: '6px'
              })}
              src={imageObj.imageSrc}
              alt='uploaded profile'
            />
          </div>

          <div>
            <ParagraphMedium color={theme.colors.mono500} marginBottom='6px'>
              Image Preview
            </ParagraphMedium>
            <div
              className={css({
                display: 'flex'
              })}
            >
              <ParagraphMedium
                marginTop={0}
                marginRight={theme.sizing.scale1200}
              >
                {' '}
                {imageObj.imageName}
              </ParagraphMedium>
              <ParagraphMedium
                marginTop={0}
                onClick={() => inputRef.current?.click()}
                color={theme.colors.contentAccent}
                className={css({
                  cursor: 'pointer'
                })}
              >
                Upload again
              </ParagraphMedium>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileImage;
