import React from 'react';
import { Block } from 'baseui/block';
import HardwareTitleWithIcon, {
  HardwareTitleInfo
} from '../HardwareTitleWithIcon';

import LaptopIcon from '../../../../../../components/icons/Laptop';
import { OuterWapper } from '../CommonStyle';

const LaptopDetails = ({ laptopData, laptopLanguageData }) => (
  <OuterWapper>
    <Block display='grid' gridTemplateColumns='auto auto'>
      {laptopData ? (
        <HardwareTitleWithIcon
          dataTestId='laptop-component'
          icon={<LaptopIcon size={20} />}
          deviceCategory='Laptop'
          selectedDeviceName={laptopData.display_name}
        />
      ) : null}
      {laptopLanguageData ? (
        <HardwareTitleInfo
          dataTestId='laptop-language-component'
          heading='Laptop Language'
          subText={laptopLanguageData}
        />
      ) : null}
    </Block>
  </OuterWapper>
);
export default LaptopDetails;
