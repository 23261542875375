import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled, useStyletron } from 'baseui';
import { Radio } from 'baseui/radio';
import { MonoParagraphMedium } from 'baseui/typography';
import {
  HARDWARE_ITEMS,
  IMAC,
  OPT_OUT_CHOICE
} from 'newHirePortal/newHire/util/hardwareContants';
import { selectDesktop } from 'newHirePortal/newHire/store/hardware/slice';
import {
  imacDevice,
  createOptOutChoiceObject
} from 'newHirePortal/newHire/store/hardware/selectors';
import StyledRadioGroup, {
  radioOverrides
} from 'newHirePortal/newHire/containers/YourInfo/HardwareShipping/StyledRadioGroup';
import DektopIcon from 'components/icons/Desktop';
import IconsWithBg from '../../../../../../components/icons/IconsWithBg';

const radioStyleOverrides = radioOverrides({ width: '165px' });

const Wrapper = styled('div', ({ $theme }) => ({
  border: `1px solid ${$theme.colors.mono300}`,
  ':hover': { border: `2px solid ${$theme.colors.backgroundAccent}` },
  borderRadius: '8px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px'
}));

const LogoInfoWrap = styled('div', () => ({
  display: 'flex',
  alignItems: 'center'
}));

const PickDesktop = () => {
  const [css, theme] = useStyletron();
  const dispatch = useDispatch();
  const iMac = useSelector(imacDevice);
  const selectedDesktop = useSelector(
    (state) => state.hardwareSelection.selectedHardware.desktop
  );

  useEffect(() => {
    if (!selectedDesktop) {
      dispatch(selectDesktop(iMac));
    }
  }, []);

  const handleChange = (value) => {
    dispatch(
      selectDesktop(
        value === OPT_OUT_CHOICE.SQ_PART_NUMBER
          ? createOptOutChoiceObject(HARDWARE_ITEMS.DESKTOP)
          : iMac
      )
    );
  };

  return (
    <>
      {iMac && (
        <Wrapper data-testid='pick-desktop'>
          <LogoInfoWrap>
            <IconsWithBg logo={<DektopIcon />} />
            <span
              className={css({
                ...theme.typography.MonoParagraphMedium,
                color: theme.colors.contentAccent,
                marginLeft: '15px'
              })}
            >
              iMac
            </span>
            <StyledRadioGroup
              currentValue={selectedDesktop?.sq_part_number}
              setCurrentValue={handleChange}
              testId='imac-desktop-radio-group'
              radioBtnName='Desktop'
            >
              <Radio
                overrides={radioStyleOverrides}
                value={iMac.sq_part_number}
              >
                <MonoParagraphMedium>{IMAC.SELECTED}</MonoParagraphMedium>
              </Radio>
              <Radio
                overrides={radioStyleOverrides}
                value={OPT_OUT_CHOICE.SQ_PART_NUMBER}
              >
                <MonoParagraphMedium>{IMAC.NOT_SELECTED}</MonoParagraphMedium>
              </Radio>
            </StyledRadioGroup>
          </LogoInfoWrap>
        </Wrapper>
      )}
    </>
  );
};

export default PickDesktop;
