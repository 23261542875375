import { createSlice } from '@reduxjs/toolkit';
import { getHardwares } from './thunk';
import { API_STATUS } from '../../util/enums';

export const InitialHardwareSelectionState = {
  laptop: null,
  laptopLanguage: null,
  monitor: null,
  keyboardLanguage: null,
  desktop: null,
  specialRequirements: '',
  mouse: null,
  keyboard: null,
  headset: null,
  ipad: null,
  adapter: null
};
const initialDataObject = {
  devices: [],
  device_errors: [],
  language_choice_errors: [],
  error: ''
};

const hardwareSelectionSlice = createSlice({
  name: 'hardwareSelection',
  initialState: {
    loadStatus: API_STATUS.IDLE,
    data: initialDataObject,
    error: '',
    selectedHardware: InitialHardwareSelectionState,
    updateHardwareInputFieldValues: {
      monitorChoice: null
    }
  },
  reducers: {
    // dispatch(selectLaptop(laptopObject)) to trigger
    selectLaptop: (state, action) => {
      state.selectedHardware.laptop = action.payload;
    },
    selectLanguage: (state, action) => {
      state.selectedHardware.laptopLanguage = action.payload;
    },
    selectMonitor: (state, action) => {
      state.selectedHardware.monitor = action.payload;
    },
    selectDesktop: (state, action) => {
      state.selectedHardware.desktop = action.payload;
    },
    addSpecialRequirements: (state, action) => {
      state.selectedHardware.specialRequirements = action.payload;
    },
    addIpad: (state, action) => {
      state.selectedHardware.ipad = action.payload;
    },
    setDesktopChoice: (state, action) => {
      state.updateHardwareInputFieldValues.desktopChoice = action.payload;
    },
    setMonitorChoice: (state, action) => {
      state.updateHardwareInputFieldValues.monitorChoice = action.payload;
    },
    addAdapter: (state, action) => {
      state.selectedHardware.adapter = action.payload;
    },
    updateSelectedHardware: (state, action) => {
      state.selectedHardware = { ...state.selectedHardware, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHardwares.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(getHardwares.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = action.payload;
      })
      .addCase(getHardwares.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
          state.errorCode = action.payload.code;
        } else {
          state.error = action.error.message;
        }
      });
  }
});

const { actions, reducer } = hardwareSelectionSlice;

const {
  selectLaptop,
  selectLanguage,
  selectMonitor,
  selectDesktop,
  addSpecialRequirements,
  addIpad,
  setMonitorChoice,
  addAdapter,
  updateSelectedHardware,
  resetPeripheralsFromSelectedHardware
} = actions;

export {
  selectLaptop,
  selectLanguage,
  selectMonitor,
  selectDesktop,
  addSpecialRequirements,
  addIpad,
  setMonitorChoice,
  addAdapter,
  updateSelectedHardware,
  resetPeripheralsFromSelectedHardware
};

export default reducer;
