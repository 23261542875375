import { createSlice } from '@reduxjs/toolkit';
import { postProfile } from 'newHirePortalRedesign/newHire/features/profileSubmit/store/thunk/thunk';
import { API_STATUS } from 'newHirePortalRedesign/newHire/util/constants';

export interface ProfileState {
  postStatus: (typeof API_STATUS)[keyof typeof API_STATUS];
  error: string;
  errorCode: string;
}

export const initialState: ProfileState = {
  postStatus: API_STATUS.IDLE,
  error: '',
  errorCode: ''
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postProfile.pending, (state) => {
        state.postStatus = API_STATUS.LOADING;
      })
      .addCase(postProfile.fulfilled, (state) => {
        state.postStatus = API_STATUS.COMPLETED;
      })
      .addCase(postProfile.rejected, (state, action) => {
        state.postStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
          state.errorCode = action.payload.code;
        } else {
          state.error = action.error.message || 'Something went wrong';
        }
      });
  }
});

const { reducer } = profileSlice;

export default reducer;
