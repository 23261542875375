import React from 'react';
import { useStyletron, styled } from 'baseui';

import { WORKDAY_TEMP_LINK } from 'newHirePortal/newHire/util/routes';
import MyGoLinks from 'newHirePortal/newHire/components/MyGoLinks';
import Item from 'newHirePortal/newHire/components/Item';

const ItemContainer = styled('div', ({ $theme }) => ({
  marginLeft: $theme.sizing.scale1600
}));

const MnAMessages = () => {
  const [, theme] = useStyletron();

  return (
    <>
      <Item>
        Onboarding will be conducted virtually via Google Meet video call.
        Please note: Google Meet works best using a Chrome web browser and
        you’ll need your video and audio on. You&apos;`ll receive calendar
        invites to your personal email with these links and specific times for
        each of the below sessions:
      </Item>
      <ItemContainer>
        <Item color={theme.colors.accent50}>
          <b>Laptop Setup Instructions:</b> You will receive an email to your
          personal email on Day 1 with instructions to set up your password.
          Instructions will be sent to your personal email at 7:00am your local
          time. We recommend beginning the set-up process between 8:00am-9:00am
          your local time. Once you have successfully created your password, you
          can set up your computer by following the instructions at{' '}
          <span>
            <MyGoLinks
              goLink='https://newcomputer.square.com/'
              source='https://newcomputer.square.com/'
            />
          </span>
        </Item>
        <Item color={theme.colors.accent50}>
          <b>IT Office Hours:</b> You will be receiving a calendar invite to
          office hours to help set up your laptop and any other IT related
          troubleshooting.
        </Item>
        <Item color={theme.colors.accent50}>
          <b>Orientation:</b> You will receive a calendar invitation from the
          learning development (L&D) team for an orientation called
          &ldquo;Building Block&rdquo;, which takes place during your first week
          and gives you the opportunity to learn about our organization,
          history, operating model, and an opportunity to meet other new hires.
          If you do not receive the invite, please contact your recruiter.
        </Item>
        <Item color={theme.colors.accent50}>
          <b>New Hire Welcome Packet:</b> Keep a lookout for your new hire
          welcome packet as well as other meeting invites arranged by your
          hiring manager. Depending on your team/role, you may also receive
          additional calendar invites for function-specific orientation
          sessions.
        </Item>
      </ItemContainer>
      <Item>
        Benefits: You will receive a document in your Workday inbox prior to
        your start date. You will have 30 days from your start date to complete
        this task. If you have any questions, please see the benefits bulletin
        in the{' '}
        <span>
          <MyGoLinks goLink='Workday Dashboard' source={WORKDAY_TEMP_LINK} />
        </span>
      </Item>
      <Item>
        This New Hire Portal link will expire in 10 days from your start date,
        at which point,{' '}
        <span>
          <MyGoLinks goLink='go/onboarding' source='http://go/onboarding' />
        </span>{' '}
        will be your hub for all things onboarding. Note: this go/link will work
        for you once you have your corporate login credentials.
      </Item>
    </>
  );
};

export default MnAMessages;
