import React from 'react';
import { ListItem, ListItemLabel, ARTWORK_SIZES } from 'baseui/list';
import { useStyletron, styled } from 'baseui';
import { Block } from 'baseui/block';
import Alert from 'baseui/icon/alert';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DueDateText from '../../../components/DueDateText';
import Check from '../../../../../components/icons/Check';
import { daysDiff } from '../../../util/dataParseHelpers';
import { isTaskOptional } from '../../../store/user/helpers';
import { dateWithDayInMonth, utcToLocal } from '../../../util/dateHelpers';
import { getTitleBasedOnHardwareVisibility } from '../../../store/hardware/selectors';

const StyledTask = styled('span', ({ $theme }) => ({
  color: $theme.colors.mono600,
  ...$theme.typography.MonoParagraphSmall,
  fontSize: '13px',
  textDecoration: 'inherit'
}));

const StatusIcon = ({ dueDate, completed, isOptionalTask }) => {
  let icon;
  const [, theme] = useStyletron();
  const diff = daysDiff(new Date(`${dueDate} UTC`));

  if (completed) {
    icon = <Check size={16} color={theme.colors.success_fill} />;
  } else if (diff <= 0 && !isOptionalTask) {
    // Overdue - passed due date or it's today
    icon = <Alert size={20} color={theme.colors.backgroundWarning} />;
  } else {
    // Due in the future OR No due date (diff is NaN)
    icon = <Check size={16} color={theme.colors.mono300} />;
  }
  return (
    <Block
      overrides={{
        Block: {
          style: {
            position: 'relative',
            width: '100px',
            display: 'flex',
            justifyContent: 'center',
            '::before': {
              borderLeft: `2px solid ${theme.colors.mono200}`,
              display: 'inline-block',
              content: '""',
              position: 'absolute',
              [theme.mediaQuery.large]: {
                height: '56px',
                marginTop: '-55px',
                left: '31px'
              },
              [theme.mediaQuery.medium]: {
                height: '80px',
                marginTop: '-80px',
                left: '30px'
              }
            }
          }
        }
      }}
    >
      {icon}
    </Block>
  );
};

const MyListItem = ({
  taskName,
  dueDate,
  completed,
  targetLink,
  internalLink = false
}) => {
  const [, theme] = useStyletron();
  const history = useHistory();
  const name = useSelector((state) =>
    getTitleBasedOnHardwareVisibility(state, taskName)
  );

  return (
    <>
      <ListItem
        artwork={() => (
          <StatusIcon
            completed={completed}
            dueDate={dueDate}
            isOptionalTask={isTaskOptional(taskName)}
          />
        )}
        artworkSize={ARTWORK_SIZES.MEDIUM}
        overrides={{
          Content: {
            style: () => ({
              borderBottomStyle: 'none'
            })
          },
          Root: {
            style: {
              [theme.mediaQuery.small]: {
                marginBottom: theme.sizing.scale2400
              },
              [theme.mediaQuery.medium]: {
                marginBottom: theme.sizing.scale850
              },
              [theme.mediaQuery.large]: {
                marginBottom: 0
              }
            },
            props: { 'data-testid': 'my-list-item-component' }
          }
        }}
      >
        <ListItemLabel
          description={dateWithDayInMonth(utcToLocal(dueDate).toDateString())}
          overrides={{
            LabelDescription: {
              component: ({ children }) => (
                <DueDateText
                  isOptionalTask={isTaskOptional(taskName)}
                  dueDate={dueDate}
                >
                  {children}
                </DueDateText>
              )
            }
          }}
        >
          <StyledTask
            href={targetLink || '#'}
            $as='a'
            target='_blank'
            data-dd-action-name={`Task - ${taskName} `}
            onClick={(e) => {
              if (internalLink) {
                e.preventDefault();
                history.push(targetLink);
              }
            }}
          >
            {name}
          </StyledTask>
        </ListItemLabel>
      </ListItem>
    </>
  );
};

export default MyListItem;
