import React, { ReactNode } from 'react';
import { styled, useStyletron } from 'baseui';

export const OnboardingContentWrapper = ({
  children
}: {
  children: ReactNode;
}) => {
  const [css, theme] = useStyletron();

  return (
    <section
      className={css({
        minWidth: 0,
        boxSizing: 'border-box',
        maxWidth: '1440px',
        margin: 'auto',
        [theme.mediaQuery.small]: {
          padding: `0 ${theme.sizing.scale800}`
        },
        [theme.mediaQuery.large]: {
          padding: `0  ${theme.sizing.scale1600}`
        },
        [theme.mediaQuery.medium]: {
          padding: `0  ${theme.sizing.scale1600}`
        }
      })}
    >
      {children}
    </section>
  );
};

export const Divider = styled('div', ({ $theme }) => ({
  borderTop: `1px solid ${$theme.colors.mono300}`,
  marginTop: $theme.sizing.scale1200,
  marginBottom: $theme.sizing.scale1200
}));
