import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyletron, styled } from 'baseui';
import DashboardTaskList from './DashBoardTaskList';
import {
  workdayTaskLoadStatus,
  workdayTasks
} from '../../../store/user/selectors';
import { API_STATUS, THEME_NAME } from '../../../util/enums';
import octo from '../../../../../assets/images/octo.png';
import CashImgWrap from '../../../components/CashImgWrap';
import { getUserWorkdayTasks } from '../../../store/user/thunk';

const Wrapper = styled('div', () => ({
  position: 'relative'
}));

const WorkDayTaskList = () => {
  const [, theme] = useStyletron();
  const dispatch = useDispatch();
  const taskLoadStatus = useSelector(workdayTaskLoadStatus);
  const tasks = useSelector(workdayTasks);

  useEffect(() => {
    if (taskLoadStatus === API_STATUS.IDLE) {
      dispatch(getUserWorkdayTasks());
    }
  }, [taskLoadStatus]);

  return (
    <Wrapper>
      <DashboardTaskList
        tasks={tasks}
        listHeading='Tasks to be completed in Workday'
        loadingStatus={taskLoadStatus}
        loadError=' '
      />
      {theme.name === THEME_NAME.CASH ? (
        <CashImgWrap
          left='280px'
          position='absolute'
          width='120px'
          src={octo}
          alt='octogen'
        />
      ) : null}
    </Wrapper>
  );
};

export default WorkDayTaskList;
