import { Theme, styled } from 'baseui';
import { Block } from 'baseui/block';

export const BannerContainer = styled(Block, ({ $theme }) => ({
  zIndex: 0,
  width: '100%',
  position: 'relative',
  paddingBottom: $theme.sizing.scale400,
  backgroundColor: $theme.colors.positive50
}));

export const responsiveHeadingStyle = (theme: Theme) => ({
  ...theme.typography.HeadingMedium,
  [theme.mediaQuery.small]: {
    ...theme.typography.HeadingMedium
  },
  [theme.mediaQuery.medium]: {
    ...theme.typography.HeadingXLarge
  },
  [theme.mediaQuery.large]: {
    ...theme.typography.HeadingXLarge
  }
});

// TODO: Need to test this once the Banner responsive issue is fixed
export const modalDialogStyle = (theme: Theme) => ({
  [theme.mediaQuery.small]: {
    width: '100vw',
    height: '100vh'
  },
  [theme.mediaQuery.medium]: {
    width: '650px',
    height: 'auto'
  },
  [theme.mediaQuery.large]: {
    width: '650px',
    height: 'auto'
  }
});
