import React from 'react';

const ShareIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.7071 3.29289C12.3166 2.90237 11.6834 2.90237 11.2929 3.29289L7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L11 6.41421V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V6.41421L15.2929 8.70711C15.6834 9.09763 16.3166 9.09763 16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L12.7071 3.29289ZM4 14.5C4 13.9477 3.55228 13.5 3 13.5C2.44772 13.5 2 13.9477 2 14.5V20.5C2 21.0523 2.44772 21.5 3 21.5H21C21.5523 21.5 22 21.0523 22 20.5V14.5C22 13.9477 21.5523 13.5 21 13.5C20.4477 13.5 20 13.9477 20 14.5V19.5H4V14.5Z'
      fill='black'
    />
  </svg>
);

export default ShareIcon;
