import React, { ReactNode } from 'react';
import { useStyletron, styled } from 'baseui';

interface getWebkitMaskImageArgs {
  isHeightOverflow: boolean;
  isExpanded: boolean;
}

interface TaskListContainerProps extends getWebkitMaskImageArgs {
  listRef: React.RefObject<HTMLDivElement>;
  children: ReactNode;
}

const getWebkitMaskImage = ({
  isHeightOverflow,
  isExpanded
}: getWebkitMaskImageArgs): string => {
  if (isHeightOverflow) {
    return isExpanded
      ? 'none'
      : 'linear-gradient(180deg, #000 70%, transparent)';
  }
  return 'none';
};

const TaskListContainer = ({
  isExpanded,
  isHeightOverflow,
  listRef,
  children
}: TaskListContainerProps) => {
  const [css, theme] = useStyletron();
  return (
    <div
      data-testid='tasklist-container'
      ref={listRef}
      className={css({
        borderTop: `1px solid ${theme.colors.mono300}`,
        overflow: 'hidden',
        height: isExpanded ? 'auto' : '149px',
        WebkitMaskImage: getWebkitMaskImage({ isHeightOverflow, isExpanded }),
        [theme.mediaQuery.small]: {
          margin: theme.sizing.scale800
        },
        [theme.mediaQuery.medium]: {
          margin: `${theme.sizing.scale800} 0px`
        },
        [theme.mediaQuery.large]: {
          margin: `${theme.sizing.scale800} 0px`
        }
      })}
    >
      {children}
    </div>
  );
};

const CustomButton = styled('button', ({ $theme }) => ({
  ...$theme.typography.ParagraphMedium,
  border: 'none',
  background: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  [$theme.mediaQuery.small]: {
    marginLeft: $theme.sizing.scale650
  },
  [$theme.mediaQuery.medium]: {
    marginLeft: 0
  },
  [$theme.mediaQuery.large]: {
    marginLeft: 0
  }
}));

export { TaskListContainer, CustomButton };
