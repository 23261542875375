import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const Check = () => {
  const [, theme] = useStyletron();
  return (
    <Icon
      viewBox='0 0 24 24'
      title='Check'
      size={theme.sizing.scale800}
      color={theme.colors.buttonPrimaryFill}
      overrides={{
        Svg: {
          style: () => ({
            flexShrink: 0
          })
        }
      }}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0
          5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.6846 9.72894C18.0872 9.35086 18.107
          8.71801 17.7289 8.31543C17.3509 7.91284 16.718 7.89298 16.3154 8.27106L10.6111 13.6282L7.68457
          10.8798C7.28199 10.5017 6.64914 10.5215 6.27106 10.9241C5.89298 11.3267 5.91284 11.9596 6.31543 12.3376L9.92654
          15.7289C10.3114 16.0904 10.9109 16.0904 11.2957 15.7289L17.6846 9.72894Z'
      />
    </Icon>
  );
};

export default Check;
