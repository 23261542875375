import {
  primitives as cashPrim,
  overrides as cashOverride
} from 'newHirePortalRedesign/newHire/config/themes/cash';

import {
  primitives as defaultPrim,
  overrides as defaultOverride
} from 'newHirePortalRedesign/newHire/config/themes/default';

import {
  primitives as tidalPrim,
  overrides as tidalOverride
} from 'newHirePortalRedesign/newHire/config/themes/tidal';

import {
  primitives as squarePrim,
  overrides as squareOverride
} from 'newHirePortalRedesign/newHire/config/themes/square';

import { BU_NAME } from 'newHirePortalRedesign/newHire/util/constants';

export interface GetThemeArgs {
  businessUnit: string | undefined;
}

export interface ThemePrimitives {
  cashFontFamily?: string;
  primaryFontFamily?: string;
}

export interface ThemeOverrides {
  [key: string]: string | Record<string, unknown>;
}

export interface GetTheme {
  primitives: ThemePrimitives;
  overrides: ThemeOverrides;
}
const getTheme = ({ businessUnit }: GetThemeArgs): GetTheme => {
  let primitives;
  let overrides;
  /* eslint-disable no-fallthrough */
  switch (businessUnit) {
    case BU_NAME.CASH:
      primitives = cashPrim;
      overrides = cashOverride;
      break;
    case BU_NAME.TIDAL:
      primitives = tidalPrim;
      overrides = tidalOverride;
      break;
    case BU_NAME.SQ_GENERAL:
      primitives = squarePrim;
      overrides = squareOverride;
      break;
    default:
      primitives = defaultPrim;
      overrides = defaultOverride;
  }
  /* eslint-enable no-fallthrough */
  return {
    primitives,
    overrides
  };
};

export default getTheme;
