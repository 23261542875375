import React, { ReactNode, JSX } from 'react';
import { LabelXSmall } from 'baseui/typography';
import { Block, BlockProps } from 'baseui/block';
import { useStyletron } from 'baseui';
import { StyleObject } from 'styletron-react';

export interface ImageWithDescriptionProps {
  image: JSX.Element;
  children: ReactNode;
  text?: string;
  style?: StyleObject;
  flexDirection?: BlockProps['flexDirection'];
}

const ImageWithDescription = ({
  image,
  children,
  text,
  style,
  flexDirection = ['column', 'column', 'row'],
  ...restProps
}: ImageWithDescriptionProps & BlockProps) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      display='flex'
      flexDirection={flexDirection}
      gridGap={theme.sizing.scale800}
      {...restProps}
    >
      <div
        className={css({
          borderRadius: theme.borders.radius300,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          ...style
        })}
      >
        {text && (
          <LabelXSmall
            data-testid='custom-text'
            className={css({
              background: theme.colors.white,
              top: theme.sizing.scale500,
              padding: `${theme.sizing.scale100} ${theme.sizing.scale300} ${theme.sizing.scale100} ${theme.sizing.scale300}`,
              borderRadius: theme.sizing.scale100,
              position: 'absolute',
              left: theme.sizing.scale500,
              zIndex: 1
            })}
          >
            {text}
          </LabelXSmall>
        )}
        {image}
      </div>
      {children}
    </Block>
  );
};

export default ImageWithDescription;
