import React from 'react';
import { useStyletron } from 'baseui';
import CustomModal from 'newHirePortalRedesign/newHire/features/banner/components/modal/customModal/CustomModal';
import CustomHeaderWithAvatar from 'newHirePortalRedesign/newHire/features/banner/components/customHeaderWithAvatar/CustomHeaderWithAvatar';
import { modalDialogStyle } from 'newHirePortalRedesign/newHire/features/banner/Styles';

interface CoreMemberData {
  name: string;
  id: string;
  message: React.ReactNode | string | Element;
  buName: string;
  signatureImgSrc: string;
}
interface BuLeadModalProps {
  buLeadsData: CoreMemberData;
  isModalOpen: boolean;
  coreMemberImage: string;
  empName: string;
  handleOnClose: () => void;
}

const ModalFooter = ({ signatureImgSrc }: { signatureImgSrc: string }) => (
  <img src={signatureImgSrc} alt='signature' width='145px' height='100px' />
);

const BuLeadModal = ({
  buLeadsData,
  isModalOpen,
  coreMemberImage,
  empName,
  handleOnClose
}: BuLeadModalProps) => {
  const [, theme] = useStyletron();
  return (
    <CustomModal
      isOpen={isModalOpen}
      handleOnClose={handleOnClose}
      headerSection={
        <CustomHeaderWithAvatar
          imageSrc={`data:image/png;base64,${coreMemberImage}`}
          fullName={empName}
          leadName={buLeadsData.name}
        />
      }
      bodySection={buLeadsData.message}
      footerSection={
        <ModalFooter signatureImgSrc={buLeadsData.signatureImgSrc} />
      }
      dialogStyleOverride={modalDialogStyle(theme)}
    />
  );
};

export default BuLeadModal;
