import React, { Fragment } from 'react';
import HeadingWithSubText from 'newHirePortalRedesign/newHire/component/headingWithSubText/HeadingWithSubText';
import { Card } from 'baseui/card';
import CustomImageWithDescription from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/components/customImageWithDescription/CustomImageWithDescription';
import { useStyletron } from 'baseui';
import TitleWithDescription from 'newHirePortalRedesign/newHire/component/titleWithDescription/TitleWithDescription';
import { usefulResourcesCashData } from 'newHirePortalRedesign/newHire/features/usefulResources/data/usefulResourcesCashData';
import { HeadingLevel } from 'baseui/heading';
import { textEllipsis } from 'newHirePortalRedesign/newHire/Styles';

import {
  CardContainer,
  Divider,
  narrowCardStyle,
  wideCardStyle
} from './Styles';

const UsefulResources = () => {
  const [, theme] = useStyletron();

  return (
    <>
      <HeadingLevel>
        <HeadingWithSubText heading='Useful resources' />
      </HeadingLevel>
      <CardContainer>
        {usefulResourcesCashData.map((usefulResourcesData) => (
          <Fragment key={usefulResourcesData.title}>
            {usefulResourcesData.cardType === 'wide' && <Divider />}
            <Card
              overrides={
                usefulResourcesData.cardType === 'narrow'
                  ? narrowCardStyle
                  : wideCardStyle
              }
            >
              <CustomImageWithDescription
                height='auto'
                flexDirection='column'
                image={
                  <img
                    src={usefulResourcesData.image}
                    alt={usefulResourcesData.altText}
                    width='100%'
                    height='142px'
                  />
                }
                overrideStyle={{
                  [theme.mediaQuery.small]: {
                    height: '142px'
                  },
                  [theme.mediaQuery.medium]: {
                    height: '142px'
                  },
                  [theme.mediaQuery.large]: {
                    height: '142px'
                  }
                }}
              >
                <TitleWithDescription
                  title={usefulResourcesData.title}
                  description={usefulResourcesData.description}
                  titleStyle={{
                    ...textEllipsis,
                    fontSize: theme.sizing.scale600,
                    lineHeight: theme.sizing.scale700
                  }}
                  descriptionStyle={{
                    color: theme.colors.mono700
                  }}
                />
              </CustomImageWithDescription>
            </Card>
          </Fragment>
        ))}
      </CardContainer>
    </>
  );
};

export default UsefulResources;
