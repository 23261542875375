import React from 'react';
import { styled, useStyletron } from 'baseui';
import UseravatarWithBg from '../../../../../components/icons/UseravatarWithBg';

const LogoInfoWrap = styled('div', ({ $theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${$theme.colors.mono200}`,
  marginBottom: '20px',
  alignItems: 'flex-start'
}));

const CurrentUsername = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '15px',
  marginBottom: '15px'
}));

const CurrentInfo = ({ defaultUsername }) => {
  const [css, theme] = useStyletron();
  return (
    <LogoInfoWrap>
      <UseravatarWithBg />
      <CurrentUsername>
        <span
          className={css({
            ...theme.typography.MonoParagraphLarge,
            lineHeight: '17px',
            paddingBottom: '5px',
            color: theme.colors.contentAccent
          })}
        >
          {defaultUsername}
        </span>
        <span
          className={css({
            ...theme.typography.ParagraphMedium,
            color: theme.colors.mono500
          })}
        >
          Your Username
        </span>
      </CurrentUsername>
    </LogoInfoWrap>
  );
};

export default CurrentInfo;
