import { useStyletron } from 'baseui';
import React from 'react';

const HeadLine = ({ children, ...props }) => {
  const [css, theme] = useStyletron();
  return (
    <p
      className={css({
        fontWeight: '700',
        lineHeight: '77px',
        fontSize: '34px',
        margin: '24px 0 0 0',
        fontFamily: theme.fontFamily,
        ...props
      })}
    >
      {children}
    </p>
  );
};

export default HeadLine;
