import React from 'react';
import { useLocation } from 'react-router-dom';
import { useStyletron, styled } from 'baseui';
import { THEME_NAME } from '../util/enums';
import {
  SHIPPING_URL_PATH,
  PROFILE_URL_PATH,
  USERNAME_URL_PATH
} from '../util/routes';
import CashImgWrap from './CashImgWrap';

const IconWrap = styled('div', () => ({
  position: 'relative'
}));

const CashPathIcon = ({
  shipSrc,
  shipAlt,
  right,
  left,
  shipTop,
  profileTop,
  profileSrc,
  profileAlt
}) => {
  const [, theme] = useStyletron();
  const location = useLocation();
  let IconContent;

  if (theme.name === THEME_NAME.CASH) {
    if (
      location.pathname === SHIPPING_URL_PATH ||
      location.pathname === USERNAME_URL_PATH
    ) {
      IconContent = (
        <CashImgWrap
          position='absolute'
          right={right}
          left={left}
          top={shipTop}
          src={shipSrc}
          alt={shipAlt}
        />
      );
    }
    if (location.pathname === PROFILE_URL_PATH) {
      IconContent = (
        <CashImgWrap
          position='absolute'
          right={right}
          left={left}
          top={profileTop}
          src={profileSrc}
          alt={profileAlt}
        />
      );
    }
  }
  return <IconWrap>{IconContent}</IconWrap>;
};

export default CashPathIcon;
