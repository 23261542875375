import {
  primitives as defaultPrim,
  overrides as defaultOverride
} from './default';
import { THEME_NAME } from '../../util/enums';
import CashAppLogo from '../../../../assets/images/cashAppIcon.png';

const primitives = defaultPrim;

const baseColors = {
  // Emphasis
  accent50: '#E5FFEE', // Square Emphasis Emphasis/40
  accent100: '#0BEA5E', // Cash Mint - Square Emphasis Emphasis/30
  accent200: '#00D633', // Square Emphasis Emphasis/20
  accent300: '#00B728', // Square Emphasis Emphasis/10
  backgroundAccent: '#00D633', // Cash Green - Square Emphasis Fill
  contentAccent: '#00B728', // Cash Accent Green - Square Emphasis Text

  contentPrimary: '#282826'
};

const squareMarketColors = {
  // emphasis color scheme
  emphasis_10: baseColors.accent300,
  emphasis_20: baseColors.accent200,
  emphasis_30: baseColors.accent100,
  emphasis_40: baseColors.accent50,
  emphasis_text: baseColors.contentAccent,
  emphasis_fill: baseColors.backgroundAccent
};

const colors = {
  ...defaultOverride.colors,
  ...baseColors,
  ...squareMarketColors
};

const componentStyles = {
  button: {
    borderRadius: '26px'
  }
};

const otherProperties = {
  favicon: CashAppLogo,
  displayName: 'Cash',
  displayAppName: 'Cash App',
  supportEmail: 'askpeople@squareup.com'
};

const overrides = {
  ...defaultOverride,
  name: THEME_NAME.CASH,
  colors,
  ...componentStyles,
  ...otherProperties
};

const config = { primitives, overrides };

export { primitives, overrides };

export default config;
