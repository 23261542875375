import { useSelector } from 'react-redux';
import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { API_STATUS } from './util/enums';
import LoadingPage from './containers/MiscPages/LoadingPage';
import ErrorPage from './containers/MiscPages/ErrorPage';
import {
  isUserExpiredAccess,
  userLoadStatus as userLoadStatusSelector
} from './store/user/selectors';
import LinkExpiredPage from './containers/MiscPages/LinkExpiredPage';
import Main from './Main';

const UserApp = () => {
  const userLoadStatus = useSelector(userLoadStatusSelector);
  const expiredAccess = useSelector(isUserExpiredAccess);
  const userData = useSelector((state) => state.user.data);

  if (userLoadStatus === API_STATUS.COMPLETED) {
    datadogRum.setUser({
      id: userData.id,
      business_unit: userData.business_unit,
      access_expired: userData.access_expired
    });

    return !expiredAccess ? <Main /> : <LinkExpiredPage />;
  }
  if (userLoadStatus === API_STATUS.FAILED) {
    return <ErrorPage />;
  }
  return <LoadingPage />;
};

export default UserApp;
