import React from 'react';
import { MonoParagraphLarge } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';
import PrivacyLink from '../../../components/PrivacyLink';
import BackToDashboardButton from '../../../components/BackToDashboardButton';

const Highlight = styled('a', ({ $theme }) => ({
  color: $theme.colors.contentAccent,
  textDecoration: 'inherit',
  marginLeft: '4px'
}));
const LinkWrap = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const PresentationPage = () => {
  const [, theme] = useStyletron();

  return (
    <>
      <MonoParagraphLarge>
        Thanks for submitting your profile information.
      </MonoParagraphLarge>

      <MonoParagraphLarge>
        To make any changes, please contact
        <Highlight href={`mailto: ${theme.supportEmail}`}>
          {theme.supportEmail}
        </Highlight>
      </MonoParagraphLarge>

      <LinkWrap>
        <PrivacyLink marginTop='36px' />
        <BackToDashboardButton compName='Profile Submitted' />
      </LinkWrap>
    </>
  );
};

export default PresentationPage;
