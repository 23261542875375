import React from 'react';
import { useStyletron } from 'baseui';
import { BU_NAME } from 'newHirePortalRedesign/newHire/util/constants';
import CashImgWrap from './cashImgWrap/CashImgWrap';

const ImageByTheme = () => {
  const [, theme] = useStyletron();
  switch (theme.name) {
    case BU_NAME.CASH:
      return <CashImgWrap />;
    default:
      return <></>;
  }
};

export default ImageByTheme;
