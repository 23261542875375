import React from 'react';
import { mediaQueryStyles } from 'newHirePortalRedesign/newHire/util/styleHelpers';
import { Cell } from 'baseui/layout-grid';

interface CustomCellProps {
  span: number[];
  children?: React.ReactNode;
  styles?: React.CSSProperties;
}

export const CustomCell = ({ span, styles, children }: CustomCellProps) => (
  <Cell
    span={span}
    overrides={{
      Cell: {
        style: ({ $theme }) => ({
          ...mediaQueryStyles({
            $theme,
            mediaSizes: ['small', 'medium', 'large'],
            styles
          })
        })
      }
    }}
  >
    {children}
  </Cell>
);
