import React from 'react';

const SquareOneVideo = (
  <iframe
    src='https://cdnapisec.kaltura.com/p/3193793/sp/319379300/embedIframeJs/uiconf_id/47398243/partner_id/3193793?iframeembed=true&playerId=kaltura_player_1639410470&entry_id=1_9ouuy7k4'
    width='640'
    height='480'
    title="Jack's Square One Video"
    frameBorder='0'
    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
    allowFullScreen
  />
);

export default SquareOneVideo;
