import * as React from 'react';
import { StyleObject } from 'styletron-react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ROLE } from 'baseui/modal';

interface CustomModalProps {
  bodySection: JSX.Element;
  headerSection?: JSX.Element;
  footerSection?: JSX.Element;
  dialogStyleOverride?: StyleObject;
  closeStyleOverride?: StyleObject;
  isOpen: boolean;
  handleOnClose: () => void;
}

const CustomModal = ({
  headerSection,
  bodySection,
  footerSection,
  isOpen,
  handleOnClose,
  dialogStyleOverride = {},
  closeStyleOverride = {}
}: CustomModalProps) => (
  <Modal
    onClose={handleOnClose}
    autoFocus={false}
    closeable
    isOpen={isOpen}
    role={ROLE.dialog}
    overrides={{
      Dialog: {
        style: () => ({
          padding: '20px 20px 20px 20px',
          ...dialogStyleOverride
        })
      },
      Close: {
        style: () => ({
          ...closeStyleOverride
        })
      }
    }}
  >
    <ModalHeader>{headerSection}</ModalHeader>
    <ModalBody>{bodySection}</ModalBody>
    <ModalFooter>{footerSection}</ModalFooter>
  </Modal>
);

export default CustomModal;
