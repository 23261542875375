import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MonoParagraphLarge } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useSelector } from 'react-redux';
import crow from '../../../assets/images/crow.png';
import { THEME_NAME } from '../util/enums';
import CashImgWrap from '../components/CashImgWrap';
import {
  ROUTES,
  USERNAME_PAGE_TITLE,
  getRouteDisplayTitle,
  HARDWARE_SHIPPING_PAGE_TITLE,
  SHIPPING_PAGE_TITLE
} from '../util/routes';
import {
  isUsernameSelectionEnabled,
  isHardwareSelectionEnabled
} from '../store/user/selectors';
import { getTitleBasedOnHardwareVisibility } from '../store/hardware/selectors';

const Container = styled('div', ({ $theme }) => ({
  height: $theme.sizing.scale1000,
  backgroundColor: $theme.colors.mono200,
  padding: '4px 32px',
  borderRadius: '6px',
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: $theme.sizing.scale900,
  marginBottom: $theme.sizing.scale900
}));

const IconWrap = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  alignItems: 'center'
}));

const Segment = ({ active, title, widthRatio, onClick, maxWidth }) => {
  const [, theme] = useStyletron();
  const titleName = useSelector((state) =>
    getTitleBasedOnHardwareVisibility(state, title)
  );
  let style = {
    maxWidth,
    width: `${widthRatio}%`,
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.mono200,
    cursor: 'pointer'
  };

  if (active) {
    style = {
      ...style,
      boxShadow: theme.shadow.shadowStatic,
      backgroundColor: theme.colors.mono100
    };
  }

  const TitleCompWrapper = ({ children }) => {
    if (theme.name === THEME_NAME.CASH && active) {
      return (
        <IconWrap>
          <CashImgWrap
            position='absolute'
            top='125px'
            src={crow}
            alt={crow}
            height='26px'
            width='32px'
          />
          {children}
        </IconWrap>
      );
    }
    return <>{children}</>;
  };

  return (
    <Block overrides={{ Block: { style } }} onClick={onClick}>
      <TitleCompWrapper>
        <MonoParagraphLarge>{titleName}</MonoParagraphLarge>
      </TitleCompWrapper>
    </Block>
  );
};

const FilteredRoutes = () =>
  ROUTES.filter((route) => {
    if (route.title === USERNAME_PAGE_TITLE) {
      return useSelector(isUsernameSelectionEnabled);
    }
    if (route.title === HARDWARE_SHIPPING_PAGE_TITLE) {
      return useSelector(isHardwareSelectionEnabled);
    }
    if (route.title === SHIPPING_PAGE_TITLE) {
      return !useSelector(isHardwareSelectionEnabled);
    }
    return true;
  });

const BreadCrumb = () => {
  const location = useLocation();
  const filteredRoutes = FilteredRoutes();
  const widthRatio = 100 / filteredRoutes.length;
  const history = useHistory();

  return (
    <Container>
      {filteredRoutes.map((route) => (
        <Segment
          title={getRouteDisplayTitle(route)}
          active={location.pathname === route.path}
          key={route.path}
          widthRatio={widthRatio}
          onClick={() => history.push(route.path)}
          data-dd-action-name={`Breadcrumbs - ${getRouteDisplayTitle(route)} `}
        />
      ))}
    </Container>
  );
};

export default BreadCrumb;
