import React from 'react';
import ProfileSubmit from 'newHirePortalRedesign/newHire/features/profileSubmit/ProfileSubmit';
import YourProfile from 'newHirePortalRedesign/newHire/features/YourProfile/YourProfile';
import ToastWrapper from 'newHirePortalRedesign/newHire/component/toasterWrapper/ToasterWrapper';
import { Block } from 'baseui/block';

interface ProfileProps {
  completed?: boolean;
}

const Profile = ({ completed = false }: ProfileProps) => (
  <ToastWrapper>
    {/** TODO: Need to call function which shows the task is completed or not once user api implemented */}
    <Block
      width={['auto', '100%', '40%']}
      paddingLeft={['20px', '20px', '100px']}
      paddingRight={['20px', '20px', 'auto']}
    >
      {completed ? <YourProfile /> : <ProfileSubmit />}
    </Block>
  </ToastWrapper>
);
export default Profile;
