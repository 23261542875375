import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useStyletron } from 'baseui';
import { ParagraphMedium, HeadingXLarge } from 'baseui/typography';
import { allDirectionStyle } from '../../../../util/styleHelpers';
import AccentButton from '../../../../components/AccentButton';

const ErrorModal = ({
  isOpen,
  onConfirm,
  modalHeader = 'Device selection is no longer valid.',
  modalBodyHeadLine = 'Please go back and change your selection. '
}) => {
  const [, theme] = useStyletron();

  return (
    <Modal
      isOpen={isOpen}
      closeable={false}
      unstable_ModalBackdropScroll
      animate
      overrides={{
        Dialog: {
          style: { ...allDirectionStyle({ radius: '12px', prop: 'border' }) }
        },
        Close: () => null
      }}
    >
      <ModalHeader>
        <HeadingXLarge>{modalHeader}</HeadingXLarge>
      </ModalHeader>
      <ModalBody>
        <ParagraphMedium>{modalBodyHeadLine}</ParagraphMedium>
      </ModalBody>
      <ModalFooter
        $style={{
          display: 'flex',
          justifyContent: 'right',
          marginBottom: theme.margins.normal
        }}
      >
        <AccentButton onClick={onConfirm} label='Reselect hardware' />
      </ModalFooter>
    </Modal>
  );
};

export default ErrorModal;
