import { createSlice } from '@reduxjs/toolkit';
import { getUsername } from './thunk';
import { API_STATUS } from '../../util/enums';

const selectedUsernameSlice = createSlice({
  name: 'selectedUsername',
  initialState: {
    loadStatus: API_STATUS.IDLE,
    data: {
      username: null
    },
    error: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsername.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(getUsername.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = action.payload;
      })
      .addCase(getUsername.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      });
  }
});

const { reducer } = selectedUsernameSlice;

export default reducer;
