import React from 'react';
import { styled, withStyle } from 'baseui';
import { HeadingXLarge } from 'baseui/typography';
import { StyledLink } from 'baseui/link';
import ShowAllModal from './ShowAllModal';
import { LABELS } from '../util/coreMemMsg';

const InfoWrapper = ({ Header, children, ModalHead, Modalcontent, height }) => {
  const Wrapper = styled('div', ({ $theme }) => ({
    padding: '24px 24px 8px 16px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '6px',
    border: `1px solid ${$theme.colors.mono300}`,
    marginBottom: '30px',
    height
  }));

  const LinkWrapper = styled('div', ({ $theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: $theme.sizing.scale600,
    marginBottom: '-5px'
  }));

  const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
    color: $theme.colors.emphasis_fill,
    textDecoration: 'none',
    cursor: 'pointer',
    alignSelf: 'center'
  }));
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Wrapper>
      <LinkWrapper>
        <HeadingXLarge $style={{ marginTop: 0, marginBottom: 0 }}>
          {Header}
        </HeadingXLarge>
        <MyStyledLink
          onClick={() => setIsOpen(true)}
          data-dd-action-name={ModalHead}
        >
          {LABELS.SHOW_ALL}
        </MyStyledLink>
      </LinkWrapper>
      {children}
      <ShowAllModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        ModalHead={ModalHead}
        Modalcontent={Modalcontent}
      />
    </Wrapper>
  );
};

export default InfoWrapper;
