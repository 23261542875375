import React from 'react';
import { ListItem } from 'baseui/list';
import { useStyletron, styled } from 'baseui';
import { MonoParagraphLarge } from 'baseui/typography';
import {
  isTaskComplete,
  isAllTaskCompleted
} from '../../../store/user/helpers';
import ClipboardWithBackground from '../../../../../components/icons/ClipboardWithBackground';
import MyListItem from './MyListItem';
import { API_STATUS } from '../../../util/enums';
import LoadingIndicator from '../../../components/LoadingIndicator';

const DashboardTaskList = ({
  tasks,
  listHeading,
  loadingStatus,
  loadError = 'Loading Error'
}) => {
  let content;
  const [css, theme] = useStyletron();
  const taskGreen = tasks.length > 0 && isAllTaskCompleted(tasks);

  const StyledHeading = styled('p', ({ $theme }) => ({
    color: taskGreen
      ? $theme.colors.success_text
      : $theme.colors.contentPrimary,
    ...$theme.typography.HeadingLarge
  }));

  if (loadingStatus === API_STATUS.FAILED) {
    content = <MonoParagraphLarge>{loadError}</MonoParagraphLarge>;
  } else if (loadingStatus === API_STATUS.COMPLETED || tasks.length > 0) {
    content = (
      <>
        {tasks.map((task) => (
          <MyListItem
            key={task.name}
            taskName={task.name}
            dueDate={task.due_date}
            targetLink={task.targetLink}
            internalLink={task.isInternalLink}
            completed={isTaskComplete(task)}
          />
        ))}
      </>
    );
  } else {
    content = <LoadingIndicator width='50%' />;
  }

  return (
    <>
      <ul
        className={css({
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          marginTop: 0,
          position: 'relative'
        })}
      >
        <ListItem
          artwork={() => <ClipboardWithBackground completed={taskGreen} />}
          overrides={{
            Content: {
              style: () => ({
                borderBottomStyle: 'none',
                paddingRight: 0
              })
            },
            ArtworkContainer: {
              style: () => ({ marginRight: '8px' })
            },
            Root: {
              style: { marginBottom: theme.sizing.scale200 }
            }
          }}
        >
          <StyledHeading>
            <span>{listHeading}</span>
          </StyledHeading>
        </ListItem>

        {content}
      </ul>
    </>
  );
};

export default DashboardTaskList;
