import React from 'react';
import { useHistory } from 'react-router-dom';
import AccentButton from './AccentButton';

const BackToDashboardButton = ({ compName }) => {
  const history = useHistory();
  return (
    <AccentButton
      label='Back to Dashboard'
      onClick={() => history.push('/')}
      float='right'
      marginTop='24px'
      buttonNameForTracking={`Back to Dashboard in ${compName} Page`}
    />
  );
};

export default BackToDashboardButton;
