import React, { useState, useRef } from 'react';
import { useStyletron } from 'baseui';
import { useIsHeightOverflow } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/hooks/useIsHeightOverflow/useIsHeightOverflow';
import { ChevronDown, ChevronUp } from 'baseui/icon';
import CustomCard from 'newHirePortalRedesign/newHire/component/customCard/CustomCard';
import TitleWithDescription from 'newHirePortalRedesign/newHire/component/titleWithDescription/TitleWithDescription';
import {
  TaskListContainer,
  CustomButton
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/completedWorkdayCard/Styles';
import CustomImageWithDescription from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/components/customImageWithDescription/CustomImageWithDescription';
import workdayLogo from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/assets/workdayLogo.png';
import TaskListItem from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/completedWorkdayCard/taskListItem/TaskListItem';
import {
  TASK_LABEL,
  completeLabel
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/constants';
import { useAppSelector } from 'newHirePortalRedesign/newHire/store/hooks';
import { hydratedWorkdayTasks } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/selectors/selectors';
import { isAllWorkdayTasksDone } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/store/selectors/selectors';

const CompletedWorkdayCard = () => {
  const [css, theme] = useStyletron();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const listRef = useRef<HTMLDivElement>(null);
  const tasks = useAppSelector(hydratedWorkdayTasks);
  const areAllTasksDone = useAppSelector(isAllWorkdayTasksDone);
  const isHeightOverflow = useIsHeightOverflow(listRef);

  const opacityBasedOnTaskCompletion = areAllTasksDone ? 0.5 : 1;
  const dueAsapLabel = 'Due ASAP';

  return (
    <CustomCard
      rootStyleOverride={{ minHeight: '416px' }}
      contentStyleOverride={{
        [theme.mediaQuery.small]: {
          margin: `0 0 ${theme.sizing.scale800}`
        },
        [theme.mediaQuery.medium]: {
          margin: theme.sizing.scale800
        },
        [theme.mediaQuery.large]: {
          margin: theme.sizing.scale800
        }
      }}
      bodySection={
        <>
          <CustomImageWithDescription
            overrideStyle={{
              background: theme.colors.backgroundAccentLight
            }}
            image={
              <img
                src={workdayLogo}
                alt='workday Logo'
                className={css({
                  opacity: opacityBasedOnTaskCompletion,
                  mixBlendMode: 'darken',
                  padding: theme.sizing.scale200,
                  marginTop: '38px',
                  width: '143px',
                  height: '75px'
                })}
              />
            }
            text={areAllTasksDone ? completeLabel : dueAsapLabel}
          >
            <TitleWithDescription
              title='Workday tasks'
              label={TASK_LABEL.required}
              description='Log onto workday to complete these tasks. Credentials for your Workday login are sent via email.'
              marginLeft={[theme.sizing.scale800, theme.sizing.scale800, 0]}
              marginRight={[theme.sizing.scale800, theme.sizing.scale800, 0]}
              opacity={opacityBasedOnTaskCompletion}
            />
          </CustomImageWithDescription>

          {tasks.length > 0 && (
            <TaskListContainer
              listRef={listRef}
              isExpanded={isExpanded}
              isHeightOverflow={isHeightOverflow}
            >
              {tasks.map((task) => (
                <TaskListItem
                  key={task.name}
                  task={task}
                  opacity={opacityBasedOnTaskCompletion}
                />
              ))}
            </TaskListContainer>
          )}
          {isHeightOverflow && (
            <CustomButton onClick={() => setIsExpanded((prev) => !prev)}>
              {isExpanded ? (
                <>
                  Show less <ChevronUp size={theme.sizing.scale900} />
                </>
              ) : (
                <>
                  Show more <ChevronDown size={theme.sizing.scale900} />
                </>
              )}
            </CustomButton>
          )}
        </>
      }
    />
  );
};

export default CompletedWorkdayCard;
