import React from 'react';
import { Cell, ALIGNMENT } from 'baseui/layout-grid';
import MainLogo from 'newHirePortalRedesign/newHire/component/mainLogo/MainLogo';
import { Button, SHAPE, SIZE, KIND } from 'baseui/button';
import { NavWrapper } from 'newHirePortalRedesign/newHire/features/mainNav/Style';
import { useStyletron } from 'baseui';
import { useHistory } from 'react-router-dom';

const MainNav = () => {
  const [, theme] = useStyletron();
  const history = useHistory();

  return (
    <NavWrapper>
      <Cell
        span={[0.5, 0.5, 0.5]}
        align={ALIGNMENT.center}
        overrides={{
          Cell: { style: () => ({ textAlign: 'left' }) }
        }}
      >
        <MainLogo size={26} handleClick={() => history.push('/')} />
      </Cell>
      <Cell
        span={[2, 1.5, 1.5]}
        align={ALIGNMENT.center}
        overrides={{
          Cell: { style: () => ({ textAlign: 'right' }) }
        }}
      >
        <Button
          kind={KIND.secondary}
          size={SIZE.mini}
          shape={SHAPE.pill}
          overrides={{
            BaseButton: {
              style: () => ({
                ...theme.typography.LabelMedium
              })
            }
          }}
        >
          Helpful resources
        </Button>
      </Cell>
    </NavWrapper>
  );
};
export default MainNav;
