// NOTE: Temporarily disabling datadog for dev environment
// to reduce number of monthly sessions.
import { datadogRum } from '@datadog/browser-rum';

// Set DEBUG to true to test out events locally.
const DEBUG = false;

export const init = ({
  applicationId,
  clientToken,
  trackInteractions = false,
  serviceName,
  version
}) => {
  const enabled = DEBUG || process.env.NODE_ENV !== 'development';
  if (enabled) {
    let env;

    if (process.env.NODE_ENV === 'production') {
      env = /new-hire-hub.stage.*/.test(window.location.host)
        ? 'staging'
        : 'production';
    } else {
      env = 'development';
    }

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com', // The Datadog site of your organization. US: datadoghq.com, EU: datadoghq.eu
      env,
      sampleRate: 100,
      service: serviceName,
      trackInteractions,
      version
    });
  }
};
