import React from 'react';
import { Icon } from 'baseui/icon';

const IpadIcon = ({ ...props }) => {
  const { title = 'Ipad logo', size, ...restProps } = props;

  return (
    <Icon viewBox='0 0 20 25' title={title} size={size} {...restProps}>
      <path
        d='M17.5 3.75C17.5 3.0625 16.9375 2.5 16.25 2.5H3.75C3.0625 2.5 2.5 3.0625 2.5 3.75V21.25C2.5 21.9375 3.0625 22.5 3.75 22.5H16.25C16.9375 22.5 17.5 21.9375 17.5 21.25V3.75ZM3.75 0H16.25C18.325 0 20 1.675 20 3.75V21.25C20 23.325 18.325 25 16.25 25H3.75C1.675 25 0 23.325 0 21.25V3.75C0 1.675 1.675 0 3.75 0ZM8.125 18.125C8.125 17.0875 8.9625 16.25 10 16.25C11.0375 16.25 11.875 17.0875 11.875 18.125C11.875 19.1625 11.0375 20 10 20C8.9625 20 8.125 19.1625 8.125 18.125Z'
        fill='#E5F0FF'
      />
    </Icon>
  );
};
export default IpadIcon;
