const ERROR_MESSAGE = {
  DEFAULT_ERROR_MSG_TITLE: 'Something went wrong',
  DEFAULT_ERROR_MSG:
    'Sorry! The link is not working right now, please retry at a later time.',
  SESSION_EXPIRED_TITLE: 'Session Expired',
  SESSION_EXPIRED_MSG:
    'The session you are trying to continue has expired, please reopen the link using the email sent by us',
  LINK_EXPIRED_TITLE: 'Link Expired',
  LINK_EXPIRED_MSG: 'The link you are trying to access is no longer available.',
  LINK_NOT_AVAILABLE_TITLE: 'Link not available',
  LINK_NOT_AVAILABLE_MSG:
    'Sorry! This link is no longer valid and cannot be accessed anymore.'
};

const NEW_HIRE_PORTAL = 'New Hire Portal';
const HARDWARE = 'Hardware';
const UNAUTHORIZED_ERROR_CODE = 401;

const SESSION_EXPIRED = 'session expired';
const LINK_EXPIRED = 'link expired';

const UNAUTHORIZED = 'Unauthorized';

const ENGINE_NAME = {
  HARDWARE_MANAGER: 'hardware_manager'
};

export {
  ERROR_MESSAGE,
  NEW_HIRE_PORTAL,
  UNAUTHORIZED_ERROR_CODE,
  ENGINE_NAME,
  SESSION_EXPIRED,
  LINK_EXPIRED,
  UNAUTHORIZED,
  HARDWARE
};
