import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const KeyboardIcon = ({ ...props }) => {
  const { title = 'keyboard icon', size = 22, ...restProps } = props;
  const [, theme] = useStyletron();

  return (
    <Icon viewBox='0 0 24 17' title={title} size={size} {...restProps}>
      <path
        d='M21.6 3.6C21.6 2.94 21.06 2.4 20.4 2.4H3.6C2.94 2.4 2.4 2.94 2.4 3.6V13.2C2.4 13.86 2.94 14.4 3.6 14.4H20.4C21.06 14.4 21.6 13.86 21.6 13.2V3.6ZM3.6 0H20.4C22.392 0 24 1.608 24 3.6V13.2C24 15.192 22.392 16.8 20.4 16.8H3.6C1.608 16.8 0 15.192 0 13.2V3.6C0 1.608 1.608 0 3.6 0ZM3.6 10.8H20.4V13.2H3.6V10.8ZM20.4 7.2H18V9.6H20.4V7.2ZM14.4 7.2H16.8V9.6H14.4V7.2ZM13.2 7.2H10.8V9.6H13.2V7.2ZM7.2 7.2H9.6V9.6H7.2V7.2ZM6 7.2H3.6V9.6H6V7.2ZM18 3.6H20.4V6H18V3.6ZM16.8 3.6H14.4V6H16.8V3.6ZM10.8 3.6H13.2V6H10.8V3.6ZM9.6 3.6H7.2V6H9.6V3.6ZM3.6 3.6H6V6H3.6V3.6Z'
        fill={theme.colors.backgroundAccent}
      />
    </Icon>
  );
};
export default KeyboardIcon;
