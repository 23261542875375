import React from 'react';
import { useStyletron } from 'baseui';
import { ParagraphSmall } from 'baseui/typography';
import { Wrapper } from '../containers/YourInfo/HardwareShipping/CommonStyle';
import AlertInfoWithEmailListItem from '../containers/YourInfo/HardwareShipping/SubmittedHardwareAndShipping/AlertInfoWithEmailListItem';

const HandsUpNotes = ({ headerText = '', overrideWrapperStyle }) => {
  const [, theme] = useStyletron();

  const wrapperStyle = {
    maxWidth: '590px',
    backgroundColor: theme.colors.yellow100,
    padding: '25px',
    marginBottom: '3%',
    ...overrideWrapperStyle
  };

  return (
    <Wrapper data-testid='hands-up-notes' $style={wrapperStyle}>
      <AlertInfoWithEmailListItem
        dataTestId='notes-header'
        text={headerText || `Just a heads up!`}
        typographyType={theme.typography.MonoParagraphMedium}
        iconColor={theme.colors.yellow400}
        styles={{
          marginLeft: '0px',
          fontWeight: 700,
          minHeight: '0px',
          paddingBottom: '0px'
        }}
      />
      <ParagraphSmall
        color={theme.colors.primary600}
        data-testid='notes-for-monitor-delivery'
      >
        Due to a global monitor shortage, your computer monitor delivery may be
        slightly delayed. Please know this does not impact your start date or
        onboarding, as you will still receive your Block-issued laptop for Day
        1.
      </ParagraphSmall>
      <ParagraphSmall
        color={theme.colors.primary600}
        data-testid='notes-for-whf-kits'
      >
        Current inventory of size small shirts are depleted for WFH kits (new
        hire swag). We will begin backfilling orders as soon as possible, but
        please note this may cause a delay in the arrival of your swag.
      </ParagraphSmall>
    </Wrapper>
  );
};

export default HandsUpNotes;
