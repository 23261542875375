import React from 'react';
import { useStyletron } from 'baseui';
import ImageWithDescription, {
  ImageWithDescriptionProps
} from 'newHirePortalRedesign/newHire/component/imageWithDescription/ImageWithDescription';
import { StyleObject } from 'styletron-react';
import { BlockProps } from 'baseui/block';

interface CustomImageWithDescriptionProps extends BlockProps {
  overrideStyle?: StyleObject;
}

const CustomImageWithDescription = ({
  overrideStyle = {},
  children,
  ...props
}: CustomImageWithDescriptionProps & ImageWithDescriptionProps) => {
  const [, theme] = useStyletron();

  return (
    <ImageWithDescription
      height={['auto', 'auto', '136px']}
      style={{
        [theme.mediaQuery.small]: {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          height: '120px'
        },
        [theme.mediaQuery.medium]: {
          borderBottomLeftRadius: theme.borders.radius300,
          borderBottomRightRadius: theme.borders.radius300,
          height: '136px'
        },
        [theme.mediaQuery.large]: {
          borderBottomLeftRadius: theme.borders.radius300,
          borderBottomRightRadius: theme.borders.radius300,
          height: '136px'
        },
        minWidth: '179px',
        ...overrideStyle
      }}
      {...props}
    >
      {children}
    </ImageWithDescription>
  );
};

export default CustomImageWithDescription;
