import React from 'react';
import { styled, useStyletron } from 'baseui';
import AccentAlert from '../../../../../components/icons/AccentAlert';

const InfoWrap = styled('div', ({ marginLeft }) => ({
  display: 'flex',
  marginBottom: '20px',
  alignItems: 'baseline',
  marginLeft
}));

const Info = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '20px'
}));

const StyledText = ({ children }) => {
  const [css, theme] = useStyletron();
  return (
    <span
      className={css({
        ...theme.typography.ParagraphLarge,
        paddingBottom: '17px',
        fontWeight: '300',
        color: theme.colors.mono600
      })}
    >
      {children}
    </span>
  );
};

const AlertInfo = ({ infoLines = [], marginLeft }) => {
  const [css] = useStyletron();

  return (
    <InfoWrap>
      <span className={css({ lineHeight: '24px' })}>
        <AccentAlert
          className={css({ verticalAlign: 'text-top', marginLeft })}
        />
      </span>
      <Info>
        {infoLines.map((line) => (
          <StyledText key={line}>{line}</StyledText>
        ))}
      </Info>
    </InfoWrap>
  );
};

export default AlertInfo;
