import React from 'react';
import {
  OPT_OUT_CHOICE,
  IMAC
} from 'newHirePortal/newHire/util/hardwareContants';
import HardwareTitleWithIcon from '../HardwareTitleWithIcon';
import { OuterWapper } from '../CommonStyle';
import DektopIcon from '../../../../../../components/icons/Desktop';

const DesktopDetails = ({ desktopData }) => (
  <>
    {desktopData &&
    desktopData.sq_part_number !== OPT_OUT_CHOICE.SQ_PART_NUMBER ? (
      <OuterWapper>
        <HardwareTitleWithIcon
          dataTestId='desktop-component'
          icon={<DektopIcon size={20} />}
          deviceCategory='iMac'
          selectedDeviceName={
            desktopData.display_name ? IMAC.SELECTED : IMAC.NOT_SELECTED
          }
        />
      </OuterWapper>
    ) : null}
  </>
);
export default DesktopDetails;
