import React, { useEffect } from 'react';
import { BaseProvider, styled } from 'baseui';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SESSION_EXPIRED, UNAUTHORIZED } from 'utils/constants';
import {
  isMaintenanceModeEnabled,
  isMaintenanceBannerEnabled,
  userAppThemeObject,
  userLoadStatus as userLoadStatusSelector,
  getApiErrorOccurred
} from 'newHirePortal/newHire/store/user/selectors';
import { API_STATUS } from './util/enums';
import { healthCheck } from './store/healthCheck/thunk';
import { isHealthCheckOk } from './store/healthCheck/selectors';
import MaintenancePage from './containers/MiscPages/MaintenancePage';
import LoadingPage from './containers/MiscPages/LoadingPage';
import UserApp from './UserApp';
import ErrorPage from './containers/MiscPages/ErrorPage';
import { getUser } from './store/user/thunk';
import BannerMessage from './containers/MiscPages/BannerMessage';

const MainContainer = styled('div', ({ $theme }) => ({
  paddingBottom: $theme.sizing.scale1600
}));

const setFavicon = (theme) => {
  const favicon = document.getElementById('favicon');
  if (favicon && theme.favicon !== new URL(favicon.href).pathname) {
    favicon.href = theme.favicon;
  }
};

const App = () => {
  let content = <LoadingPage />;
  const dispatch = useDispatch();
  const userLoadStatus = useSelector(userLoadStatusSelector);
  const hasApiError = useSelector(getApiErrorOccurred);
  const healthCheckLoadStatus = useSelector(
    (state) => state.healthCheck.loadStatus
  );
  const theme = useSelector(userAppThemeObject);
  const error = useSelector((state) => state.healthCheck.error);
  const isMaintenanceMode = useSelector(isMaintenanceModeEnabled);
  const isBannerEnabled = useSelector(isMaintenanceBannerEnabled);
  const serviceOnline = useSelector(isHealthCheckOk);
  const welcome = new URLSearchParams(useLocation().search).get('welcome');

  setFavicon(theme);

  useEffect(() => {
    if (userLoadStatus === API_STATUS.IDLE) {
      dispatch(getUser(welcome));
    }
  }, [userLoadStatus]);

  useEffect(() => {
    if (healthCheckLoadStatus === API_STATUS.IDLE) {
      dispatch(healthCheck());
    }
  }, [healthCheckLoadStatus]);

  if (healthCheckLoadStatus === API_STATUS.COMPLETED) {
    content =
      serviceOnline && !isMaintenanceMode ? <UserApp /> : <MaintenancePage />;
  }

  if (
    healthCheckLoadStatus === API_STATUS.FAILED ||
    hasApiError === SESSION_EXPIRED ||
    hasApiError === UNAUTHORIZED
  ) {
    content = <ErrorPage error={error} />;
  }

  return (
    <BaseProvider theme={theme}>
      {isBannerEnabled && <BannerMessage />}
      <MainContainer>{content}</MainContainer>
    </BaseProvider>
  );
};

export default App;
