import React from 'react';
import { useStyletron } from 'baseui';
import SquareLogo from 'components/icons/SquareLogo';
import CashAppLogo from 'assets/images/cashAppIcon.png';
import TidalLogo from 'assets/images/tidalLogo.svg';
import BlockLogo from 'assets/images/blockLogo.gif';
import { THEME_NAME } from 'newHirePortalRedesign/newHire/util/constants';

interface MainLogoProps {
  size: number;
  handleClick?: () => void;
}

const MainLogo: React.FC<MainLogoProps> = ({ size, handleClick }) => {
  const [, theme] = useStyletron();
  let component;
  switch (theme.name) {
    case THEME_NAME.CASH:
      component = (
        <div onClick={handleClick}>
          <img
            src={CashAppLogo}
            alt='cashapp_logo'
            height={size}
            width={size}
          />
        </div>
      );
      break;
    case THEME_NAME.TIDAL:
      component = (
        <div onClick={handleClick}>
          <img src={TidalLogo} alt='tidal_logo' height='35' width='73' />
        </div>
      );
      break;
    case THEME_NAME.SQUARE:
      component = (
        <div onClick={handleClick}>
          <SquareLogo size={size} />
        </div>
      );
      break;
    case THEME_NAME.BLOCK:
      component = (
        <div onClick={handleClick}>
          <img
            src={BlockLogo}
            alt='block_logo'
            height={size + 8}
            width={size + 8}
          />
        </div>
      );
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn(`Logo not configured for this theme - ${theme.name}`);
      component = <></>;
  }
  return component;
};

export default MainLogo;
