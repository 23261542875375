import React from 'react';
import { useSelector } from 'react-redux';
import { hardwareTaskCompleted } from '../../../store/user/selectors';
import HardwareShippingPage from './HardwareShippingPage';
import PresentationPage from './PresentationPage';
import ConfirmationPage from './ConfirmationPage';

const HardwareComp = () => {
  const isOnConfirmPage = useSelector(
    (state) => state.shippingAddress.isOnConfirmPage
  );

  return (
    <>{isOnConfirmPage ? <ConfirmationPage /> : <HardwareShippingPage />}</>
  );
};

const Page = () => {
  const completed = useSelector(hardwareTaskCompleted);
  return <>{completed ? <PresentationPage /> : <HardwareComp />}</>;
};

export default Page;
