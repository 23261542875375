import React from 'react';
import { useStyletron } from 'baseui';
import SquareLogo from '../../../components/icons/SquareLogo';
import CashAppLogo from '../../../assets/images/cashAppIcon.png';
import TidalLogo from '../../../assets/images/tidalLogo.svg';
import BlockLogo from '../../../assets/images/blockLogo.gif';
import { THEME_NAME } from '../util/enums';

const MainLogo = ({ size, ...props }) => {
  const [, theme] = useStyletron();
  let component;
  switch (theme.name) {
    case THEME_NAME.CASH:
      component = (
        <img src={CashAppLogo} alt='cashapp_logo' height={size} width={size} />
      );
      break;
    case THEME_NAME.TIDAL:
      component = (
        <img src={TidalLogo} alt='tidal_logo' height='35' width='73' />
      );
      break;
    case THEME_NAME.SQUARE:
      component = <SquareLogo size={size} {...props} />;
      break;
    case THEME_NAME.BLOCK:
      component = (
        <img
          src={BlockLogo}
          alt='block_logo'
          height={size + 8}
          width={size + 8}
        />
      );
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn(`Logo not configured for this theme - ${theme.name}`);
      component = <></>;
  }
  return component;
};

export default MainLogo;
