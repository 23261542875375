import React from 'react';
import { useSelector } from 'react-redux';
import {
  tabletDevice,
  isTabletEnable
} from '../../../../store/hardware/selectors';
import HardwareDetailsDisplay from './HardwareDetailsDisplay';

const SelectedHardwareDetails = ({ wrapperStyle }) => {
  const selectedHardwares = useSelector(
    (state) => state.hardwareSelection.selectedHardware
  );
  const desktopData = selectedHardwares.desktop;
  const laptopData = selectedHardwares.laptop;
  const laptopLanguageData = selectedHardwares.laptopLanguage.name;
  const monitorData = selectedHardwares.monitor;
  const isTabletEnableFlag = useSelector(isTabletEnable);
  const tabletData = useSelector(tabletDevice);
  const keyboardData = selectedHardwares.keyboard;
  const keyboardLanguageData = selectedHardwares.keyboardLanguage;
  const { mouse, headset } = selectedHardwares;
  const laptop = {
    ...laptopData,
    language: laptopLanguageData
  };

  const keyboard = keyboardData
    ? {
        ...keyboardData,
        language: keyboardLanguageData?.name
      }
    : null;

  return (
    <HardwareDetailsDisplay
      desktop={desktopData}
      laptop={laptop}
      monitor={monitorData}
      tablet={isTabletEnableFlag ? tabletData : null}
      mouse={mouse}
      headset={headset}
      keyboard={keyboard}
      wrapperStyle={wrapperStyle}
      testId='selected-hardware-details'
    />
  );
};

export default SelectedHardwareDetails;
