import React from 'react';
import { Skeleton } from 'baseui/skeleton';
import { StyleObject } from 'styletron-react';
import { allDirectionStyle } from 'newHirePortalRedesign/newHire/util/styleHelpers';

interface CustomSkeletonProps {
  rows?: number;
  rootStyleOverride?: StyleObject;
  rowStyleOverride?: StyleObject;
  dataTestId: string;
}

const CustomSkeleton = ({
  rows,
  rootStyleOverride = {},
  rowStyleOverride = {},
  dataTestId
}: CustomSkeletonProps) => (
  <Skeleton
    rows={rows}
    animation
    overrides={{
      Root: {
        props: { 'data-testid': dataTestId },
        style: ({ $theme }) => ({
          ...allDirectionStyle({
            radius: $theme.borders.radius300,
            prop: 'border'
          }),
          ...rootStyleOverride
        })
      },
      Row: rows
        ? {
            style: ({ $theme }) => ({
              ...allDirectionStyle({
                radius: $theme.borders.radius300,
                prop: 'border'
              }),
              ...rowStyleOverride
            })
          }
        : {}
    }}
  />
);

export default CustomSkeleton;
