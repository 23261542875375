import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const CircleIcon = ({ cx, cy }) => {
  const [, theme] = useStyletron();
  return (
    <circle
      cx={cx}
      cy={cy}
      r='3.75486'
      stroke='#E5F0FF'
      fill={theme.colors.backgroundAccent}
      strokeWidth='2'
    />
  );
};

const PeripheralsIcon = ({ ...props }) => {
  const { title = 'Peripherals icon', size, color, ...restProps } = props;
  const [, theme] = useStyletron();
  return (
    <Icon
      viewBox='0 0 28 28'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <CircleIcon cx='4.75486' cy='15.3799' />
      <CircleIcon cx='23.2451' cy='11.6806' />
      <CircleIcon cx='13.7353' cy='4.75486' />
      <line
        x1='14.168'
        y1='26.4922'
        x2='14.168'
        y2='8.65827'
        stroke='#E5F0FF'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M23.247 15.9062V19.6045H14.0012V23.5669H4.75586V19.6045'
        stroke='#E5F0FF'
        fill={theme.colors.backgroundAccent}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
export default PeripheralsIcon;
