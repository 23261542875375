import {
  primitives as cashPrim,
  overrides as cashOverride
} from './themes/cash';

import {
  primitives as defaultPrim,
  overrides as defaultOverride
} from './themes/default';

import {
  primitives as tidalPrim,
  overrides as tidalOverride
} from './themes/tidal';

import {
  primitives as squarePrim,
  overrides as squareOverride
} from './themes/square';

import { BU_NAME } from '../util/enums';

const getTheme = ({ businessUnit }) => {
  let primitives;
  let overrides;
  /* eslint-disable no-fallthrough */
  switch (businessUnit) {
    case BU_NAME.CASH:
      primitives = cashPrim;
      overrides = cashOverride;
      break;
    case BU_NAME.TIDAL:
      primitives = tidalPrim;
      overrides = tidalOverride;
      break;
    case BU_NAME.SQ_GENERAL:
      primitives = squarePrim;
      overrides = squareOverride;
      break;
    default:
      primitives = defaultPrim;
      overrides = defaultOverride;
  }
  /* eslint-enable no-fallthrough */
  return {
    primitives,
    overrides
  };
};

export default getTheme;
