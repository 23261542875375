import React from 'react';
import { Skeleton } from 'baseui/skeleton';
import TitleSkeleton from './TitleSkeleton';
import {
  RowContainer,
  ColumnContainer
} from '../commonStyle/commonStyleForSkeleton';

const SubTaskWithIconSkeleton = ({ noOfSubTasks = 1 }) =>
  [...Array(noOfSubTasks).keys()].map((item) => (
    <RowContainer key={item} data-testid='sub-task-list-with-icon-skeleton'>
      <Skeleton
        width='15px'
        height='15px'
        animation
        overrides={{
          Root: {
            style: {
              borderRadius: '50%',
              marginLeft: '20px',
              display: 'flex',
              alignSelf: 'center'
            },
            props: { 'data-testid': 'circle-icon-skeleton' }
          }
        }}
      />
      <ColumnContainer>
        <TitleSkeleton
          rows={2}
          taskHeight='10px'
          taskWidth='150px'
          subTaskWidth='100px'
        />
      </ColumnContainer>
    </RowContainer>
  ));

export default SubTaskWithIconSkeleton;
