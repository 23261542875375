import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const ArrowCircleDownIcon = ({ ...props }) => {
  const [, theme] = useStyletron();
  const {
    title = 'ArrowCircleDownIcon',
    size = 32,
    color = 'none',
    ...restProps
  } = props;

  return (
    <Icon title={title} size={size} color={color} {...restProps}>
      <circle cx='16' cy='16' r='15.5' stroke={theme.colors.mono800} />
      <path
        d='M16.5 11C16.5 10.7239 16.2761 10.5 16 10.5C15.7239 10.5 15.5 10.7239 15.5 11L16.5 11ZM15.6464 21.3536C15.8417 21.5488 16.1583 21.5488 16.3536 21.3536L19.5355 18.1716C19.7308 17.9763 19.7308 17.6597 19.5355 17.4645C19.3403 17.2692 19.0237 17.2692 18.8284 17.4645L16 20.2929L13.1716 17.4645C12.9763 17.2692 12.6597 17.2692 12.4645 17.4645C12.2692 17.6597 12.2692 17.9763 12.4645 18.1716L15.6464 21.3536ZM15.5 11L15.5 21L16.5 21L16.5 11L15.5 11Z'
        fill={theme.colors.mono1000}
      />
    </Icon>
  );
};

export default ArrowCircleDownIcon;
