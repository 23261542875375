import { createSlice } from '@reduxjs/toolkit';
import { getUsernames, postUsername } from './thunk';
import { API_STATUS } from '../../util/enums';

const usernamesSlice = createSlice({
  name: 'usernames',
  initialState: {
    loadStatus: API_STATUS.IDLE,
    postStatus: API_STATUS.IDLE,
    data: {
      usernames: [],
      performed_action: { type: '', output: '' }
    },
    error: ''
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsernames.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(getUsernames.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(getUsernames.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
          state.errorCode = action.payload.code;
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(postUsername.pending, (state) => {
        state.postStatus = API_STATUS.LOADING;
      })
      .addCase(postUsername.fulfilled, (state) => {
        state.postStatus = API_STATUS.COMPLETED;
      })
      .addCase(postUsername.rejected, (state, action) => {
        state.postStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      });
  }
});

const { reducer } = usernamesSlice;

export default reducer;
