import React from 'react';
import { Icon } from 'baseui/icon';

const XCircle = ({ ...props }) => {
  const { title = 'DeleteCircle', size, color, ...restProps } = props;
  return (
    <Icon
      viewBox='0 0 24 24'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0
        12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM7.41106
        7.41112C7.80159 7.02059 8.43475 7.0206 8.82527 7.41112L11.9999 10.5858L15.1746
        7.41112C15.5651 7.0206 16.1983 7.02059 16.5888 7.41112C16.9793 7.80164 16.9793
        8.4348 16.5888 8.82533L13.4141 12L16.5888 15.1747C16.9793 15.5652 16.9793
        16.1984 16.5888 16.5889C16.1983 16.9794 15.5651 16.9794 15.1746 16.5889L11.9999
        13.4142L8.82527 16.5889C8.43475 16.9794 7.80159 16.9794 7.41106 16.5889C7.02053
        16.1984 7.02053 15.5652 7.41105 15.1747L10.5857 12L7.41105 8.82533C7.02053 8.4348
        7.02053 7.80164 7.41106 7.41112Z'
      />
    </Icon>
  );
};

export default XCircle;
