import React from 'react';
import { useSelector } from 'react-redux';
import {
  getSubmittedHardwareDetails,
  isTabletIncludedInSubmission
} from '../../../../store/hardware/selectors';
import HardwareDetailsDisplay from './HardwareDetailsDisplay';

const SubmittedHardwareDetails = ({ wrapperStyle }) => {
  const isTabletEnableFlag = useSelector(isTabletIncludedInSubmission);
  const { laptop, desktop, monitor, tablet, mouse, headset, keyboard } =
    useSelector(getSubmittedHardwareDetails);

  return (
    <HardwareDetailsDisplay
      desktop={desktop}
      laptop={laptop}
      monitor={monitor}
      tablet={isTabletEnableFlag ? tablet : null}
      mouse={mouse}
      headset={headset}
      keyboard={keyboard}
      wrapperStyle={wrapperStyle}
      testId='presentation-hardware-details'
    />
  );
};

export default SubmittedHardwareDetails;
