import React, { Dispatch, SetStateAction } from 'react';
import { useStyletron } from 'baseui';
import {
  Divider,
  allDirectionStyle
} from 'newHirePortalRedesign/newHire/util/styleHelpers';

import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import { Textarea } from 'baseui/textarea';

const Bio = ({
  bio,
  setBio
}: {
  bio: string;
  setBio: Dispatch<SetStateAction<string>>;
}) => {
  const [, theme] = useStyletron();
  return (
    <>
      <HeadingSmall>About You</HeadingSmall>
      <ParagraphMedium>
        <b>Bio</b>
      </ParagraphMedium>
      <ParagraphMedium>
        Tell us about yourself! Please write a 3-4 sentence bio and submit the
        photo of your choosing. Your manager will use this to introduce you to
        folks at Square through email or Slack. Please note that information is
        not editable after submission.
      </ParagraphMedium>
      <Textarea
        value={bio}
        onChange={(e) => setBio(e.target.value)}
        placeholder='Write your bio here. What are your interests, hobbies and unique skills? Got any fun facts about yourself? Here’s the place to share!'
        clearOnEscape
        maxLength={1000}
        overrides={{
          InputContainer: {
            style: ({ $theme }) => ({ backgroundColor: $theme.colors.mono100 })
          },
          Root: {
            style: {
              ...allDirectionStyle({
                radius: '6px',
                prop: 'border'
              }),
              height: '190px',
              marginBottom: theme.sizing.scale950
            }
          }
        }}
      />
      <Divider />
    </>
  );
};

export default Bio;
