import React, { useEffect, useState } from 'react';
import { HeadingXLarge, ParagraphLarge } from 'baseui/typography';
import { useSelector, useDispatch } from 'react-redux';
import { styled, useStyletron } from 'baseui';
import { unwrapResult } from '@reduxjs/toolkit';
import { NOT_ELIGIBLE_HARDWARE_ERROR } from 'newHirePortal/newHire/util/hardwareContants';
import AccentButton from '../../../components/AccentButton';
import toastTrigger from '../toastTrigger';
import FormErrorLine from '../../../components/FormErrorLine';
import { hardwareSubmitted } from '../../../store/user/slice';
import { postShippingAddress } from '../../../store/shippingAddress/thunk';
import SelectedHardwareDetails from './SubmittedHardwareAndShipping/SelectedHardwareDetails';
import ShippingAddressDisplay from './SubmittedHardwareAndShipping/ShippingAddressDisplay';
import SpecialRequirementsDetails from './SpecialRequirements/SpecialRequirementsDetails';
import MonoButton from '../../../components/MonoButton';
import ConfirmModal from './SubmittedHardwareAndShipping/ConfirmModal';
import ErrorModal from './SubmittedHardwareAndShipping/ErrorModal';

import { OuterWapper } from './CommonStyle';
import { setIsOnConfirmPage } from '../../../store/shippingAddress/slice';

import { SHIPPING_URL_PATH } from '../../../util/routes';
import { THEME_NAME } from '../../../util/enums';
import HandsUpNotes from '../../../components/HandsUpNotes';
import {
  showHardwareSelection,
  checkDeviceAvailability
} from '../../../store/hardware/selectors';

const ButtonWrapper = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));
const RowContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  [$theme.mediaQuery.large]: {
    flexDirection: 'row',
    width: '95%'
  },
  [$theme.mediaQuery.medium]: {
    flexDirection: 'column',
    width: '100%'
  }
}));
const Container = styled('div', ({ style }) => ({
  ...style,
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}));

const ConfirmationPage = () => {
  const [, theme] = useStyletron();
  const isCashTheme = theme.name === THEME_NAME.CASH;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const shippingData = useSelector(
    (state) => state.shippingAddress.shippingInput
  );
  const selectedHardwares = useSelector(
    (state) => state.hardwareSelection.selectedHardware
  );

  const postShippingAddressApiLoadStatus = useSelector(
    (state) => state.shippingAddress.postStatus
  );

  const displayHardwareSelection = useSelector(showHardwareSelection);
  const missingDevicesError = useSelector(checkDeviceAvailability);

  const [submitError, setSubmitError] = React.useState(null);
  const [isError, setIsError] = useState(false);
  const [errorType, setErrorType] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = () => {
    setIsOpen(true);
  };

  const onRedirectClick = () => {
    dispatch(setIsOnConfirmPage(false));
    setIsError(false);
    window.location.href = SHIPPING_URL_PATH;
  };
  const onConfirm = () => {
    const modifiedShippingData = { ...shippingData };

    const payload = {
      shippingAddress: modifiedShippingData,
      selectedHardware: {
        ...selectedHardwares,
        specialRequirements: displayHardwareSelection
          ? selectedHardwares.specialRequirements
          : missingDevicesError
      }
    };
    dispatch(postShippingAddress(payload))
      .then(unwrapResult) // use unwrapResult to help throw the original error
      .then(() => {
        setIsOpen(false);
        dispatch(hardwareSubmitted());
        toastTrigger({ success: true });
      })
      .catch((e) => {
        // calling toast here to avoid rendering during state transition warning
        toastTrigger({
          success: false,
          failureMsg:
            'Please Verify the mandatory hardwares are selected and address is complete and try again.'
        });
        setSubmitError(e.message);
        setErrorType(e.errorType);
        setIsOpen(false);
        setIsError(true);
      });
  };

  return (
    <div data-testid='confirmation-page'>
      <HeadingXLarge $style={{ paddingLeft: '3%' }}>
        Confirm your selection
      </HeadingXLarge>
      <ParagraphLarge $style={{ paddingLeft: '3%' }}>
        Please verify all the details.
      </ParagraphLarge>

      {displayHardwareSelection ? (
        <RowContainer>
          <Container style={{ marginRight: '4%' }}>
            <SelectedHardwareDetails
              wrapperStyle={{ padding: '1% 0% 1% 6%' }}
            />
          </Container>
          <Container>
            <ShippingAddressDisplay
              color={theme.colors.contentAccent}
              wrapperStyle={{ padding: '1% 0% 1% 6%' }}
              shippingData={shippingData}
            />
            <OuterWapper $style={{ marginLeft: '6%' }} />
            <SpecialRequirementsDetails
              color={theme.colors.mono500}
              wrapperStyle={{ padding: '1% 0% 1% 6%' }}
            />

            {isCashTheme && (
              <HandsUpNotes
                headerText='Oops! We have some important items to share:'
                overrideWrapperStyle={{ marginLeft: '27px', marginTop: '27px' }}
              />
            )}
          </Container>
        </RowContainer>
      ) : (
        <RowContainer>
          <Container style={{ marginRight: '4%' }}>
            <ShippingAddressDisplay
              color={theme.colors.contentAccent}
              wrapperStyle={{ padding: '1% 0% 1% 6%' }}
              shippingData={shippingData}
            />
          </Container>
          <Container>
            {isCashTheme && (
              <HandsUpNotes
                headerText='Oops! We have some important items to share:'
                overrideWrapperStyle={{ marginLeft: '27px', marginTop: '27px' }}
              />
            )}
          </Container>
        </RowContainer>
      )}

      {submitError ? <FormErrorLine message={submitError} /> : null}
      {isError && errorType === NOT_ELIGIBLE_HARDWARE_ERROR && (
        <ErrorModal isOpen={isError} onConfirm={onRedirectClick} />
      )}
      <ButtonWrapper>
        <MonoButton
          label='Previous'
          type='previous'
          marginTop='24px'
          buttonNameForTracking='Previous on Hardware & Shipping Confirmation Page'
          onClick={() => dispatch(setIsOnConfirmPage(false))}
        />
        <AccentButton
          label='Confirm'
          type='confirm'
          marginTop='24px'
          onClick={onSubmit}
          buttonNameForTracking='Confirm on Hardware & Shipping Confirmation Page'
        />
      </ButtonWrapper>
      <ConfirmModal
        submitStatus={postShippingAddressApiLoadStatus}
        dataTestId='confirm-modal'
        isOpen={isOpen}
        onConfirm={onConfirm}
        onClose={() => setIsOpen(false)}
        dataNameToConfirm='Hardware Shipping'
      />
    </div>
  );
};
export default ConfirmationPage;
