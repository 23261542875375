import { createAsyncThunk } from '@reduxjs/toolkit';
import { postProfileInfo } from '../../api/profileSubmit';
import { handleApiResponse } from '../../../../utils/api_helpers';

export const postProfile = createAsyncThunk(
  'profile',
  async ({ bioContent, profileImage }, { rejectWithValue }) => {
    try {
      const response = await postProfileInfo({ bioContent, profileImage });
      const result = await handleApiResponse(response);
      if (result.ok) {
        return result.data;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
