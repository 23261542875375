import { styled } from 'baseui';

export const OuterWapper = styled('div', ({ $theme, $style }) => ({
  ...$style,
  paddingBottom: '10px',
  borderBottom: `1px solid ${$theme.colors.mono300}`,
  paddingTop: '10px'
}));

export const Wrapper = styled('div', ({ $style }) => ({
  ...$style,
  borderRadius: '8px'
}));

export const ContentWapper = styled('div', ({ $theme, $style }) => ({
  ...$style,
  ...$theme.typography.ParagraphMedium,
  fontWeight: '600'
}));
