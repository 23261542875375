import React from 'react';
import { useStyletron } from 'baseui';
import Input from 'react-phone-number-input/input';

const PhoneField = ({ onChange, value, error, onBlurFunc }) => {
  const [css, theme] = useStyletron();
  const styles = {
    ...theme.typography.ParagraphLarge,
    width: '100%',
    padding: '14px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: '2px solid #EEEEEE', // An exception to use hex code here to match BaseUi
    ':focus': {
      outline: 0,
      border: `2px solid ${theme.colors.backgroundAccent}`
    }
  };

  if (error) {
    styles.border = `2px solid ${theme.colors.backgroundNegative}`;
  }

  return (
    <div
      className={css({
        width: '100%'
      })}
    >
      <Input
        placeholder='Phone (e.g. +1 234 567 8910)*'
        onChange={(val) => val && onChange({ target: { value: val } })}
        value={value}
        className={css(styles)}
        onBlur={onBlurFunc}
        defaultCountry='US'
        required
      />
    </div>
  );
};

export default PhoneField;
