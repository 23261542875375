import { MonoParagraphLarge } from 'baseui/typography';
import React from 'react';
import { Radio, RadioGroup } from 'baseui/radio';
import { useStyletron } from 'baseui';

const radioStyleOverride = ({ $theme, $checked }) =>
  $checked
    ? {
        backgroundColor: $theme.colors.backgroundAccent
      }
    : {};

const YesOrNoRadio = ({
  dataTestId,
  value,
  onChange,
  disabled,
  radioTitle,
  nestedInputComp = null
}) => (
  <>
    <MonoParagraphLarge marginTop={0} marginBottom='4px'>
      {radioTitle}
    </MonoParagraphLarge>
    <RadioGroup
      disabled={disabled}
      value={value}
      onChange={onChange}
      name={radioTitle}
    >
      <Radio
        overrides={{
          RadioMarkOuter: {
            style: radioStyleOverride
          },
          Root: {
            props: { 'data-testid': `${dataTestId}-yes-radio-button` }
          }
        }}
        value='true'
      >
        Yes
      </Radio>
      {nestedInputComp}
      <Radio
        overrides={{
          RadioMarkOuter: {
            style: radioStyleOverride
          },
          Root: {
            props: { 'data-testid': `${dataTestId}-no-radio-button` }
          }
        }}
        value='false'
      >
        No
      </Radio>
    </RadioGroup>
  </>
);

export const DisplayMode = ({ value, radioTitle }) => {
  const [css] = useStyletron();
  return (
    <div className={css({ marginBottom: '24px' })}>
      <YesOrNoRadio disabled value={value} radioTitle={radioTitle} />
    </div>
  );
};
export default YesOrNoRadio;
