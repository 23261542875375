import React from 'react';
import { MonoParagraphMedium } from 'baseui/typography';
import { useSelector } from 'react-redux';
import { Wrapper, ContentWapper } from '../CommonStyle';

const SpecialRequirementsDetails = ({ wrapperStyle, ...contentStyle }) => {
  const specialRequirementsData = useSelector(
    (state) => state.hardwareSelection.selectedHardware.specialRequirements
  );
  return (
    <>
      {specialRequirementsData ? (
        <Wrapper
          data-testid='special-requirements-details'
          $style={wrapperStyle}
        >
          <MonoParagraphMedium>Special requirements:</MonoParagraphMedium>
          <ContentWapper
            data-testid='special-requirements-data'
            $style={contentStyle}
          >
            {specialRequirementsData}
          </ContentWapper>
        </Wrapper>
      ) : null}
    </>
  );
};

export default SpecialRequirementsDetails;
