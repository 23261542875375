import { THEME_NAME } from '../../util/enums';
import BlockLogo from '../../../../assets/images/blockLogo.gif';

const breakpoints = {
  small: 320,
  medium: 600,
  large: 1136
};

const ResponsiveTheme = Object.keys(breakpoints).reduce(
  (acc, key) => {
    acc.mediaQuery[
      key
    ] = `@media screen and (min-width: ${breakpoints[key]}px)`;
    return acc;
  },
  {
    breakpoints,
    mediaQuery: {}
  }
);

const primitives = {
  primaryFontFamily: 'Square Market, Helvetica, Arial, sans-serif'
};

const grid = {
  grid: {
    columns: [4, 8, 12],
    gutters: [16, 36, 24],
    margins: [16, 36, 64],
    gaps: 0,
    maxWidth: 1920
  }
};

const baseColors = {
  positive50: '#E5FFEE', // Square Success Green/40
  positive100: '#CCFFDD', // Square Success Green/30
  positive200: '#009933', // Square Success Green/20
  positive300: '#00802A', // Square Success Green/10
  backgroundPositive: '#00B23B', // Square Success Fill
  contentPositive: '#007D2A', // Square Success Text

  // Emphasis
  accent50: '#FFB89C', // Block Emphasis Emphasis/40
  accent100: '#CCE1FF', // Square Emphasis Emphasis/30
  accent200: '#005FE5', // Square Emphasis Emphasis/20
  accent300: '#0055CC', // Square Emphasis Emphasis/10
  backgroundAccent: '#C22A00', // Block Emphasis Fill
  contentAccent: '#C22A00', // Block Emphasis Text

  // Critical
  negative50: '#FFE5EA', // Square Critical Critical/40
  negative100: '#FFCCD5', // Square Critical Critical/30
  negative200: '#B2001E', // Square Critical Critical/20
  negative300: '#99001A', // Square Critical Critical/10
  backgroundNegative: '#CC0023', // Square Critical Fill
  contentNegative: '#BF0020', // Square Critical Text

  warning50: '#FFF2E6', // Square Warning/40
  warning100: '#FFE6CC', // Square Warning/30
  warning200: '#E68F39', // Square Warning/20
  warning300: '#CC8033', // Square Warning/10
  contentWarning: '#945C25',
  backgroundWarning: '#FF9F40',

  primary: '#148FCC', // Square Primary
  primary100: '#D6F1FF', // Square Primary Light
  primary700: '#0E648F', // Square Primary Dark

  yellow100: '#FFF2D9', // Yellow/100
  yellow400: '#FFC043', // Yellow/400

  // Square Grays from lightest - darkest
  mono100: '#FFFFFF',
  mono200: '#F2F4F5',
  mono300: '#D5D7D9',
  mono400: '#939CA3',
  mono500: '#66717A',
  mono600: '#323A42',
  mono700: '#151C1F',
  mono800: '#151C1F',

  // Block MnA notification
  mnaNotification: '#FBFCE1',

  contentPrimary: 'rgba(0, 0, 0, 0.9)'
};

const squareMarketColors = {
  // success color scheme
  success_10: baseColors.positive300,
  success_20: baseColors.positive200,
  success_30: baseColors.positive100,
  success_40: baseColors.positive50,
  success_text: baseColors.contentPositive,
  success_fill: baseColors.backgroundPositive,

  // warning color scheme
  warning_10: baseColors.warning300,
  warning_20: baseColors.warning200,
  warning_30: baseColors.warning100,
  warning_40: baseColors.warning50,
  warning_text: baseColors.contentWarning,
  warning_fill: baseColors.backgroundWarning,

  // emphasis color scheme
  emphasis_10: baseColors.accent300,
  emphasis_20: baseColors.accent200,
  emphasis_30: baseColors.accent100,
  emphasis_40: baseColors.accent50,
  emphasis_text: baseColors.contentAccent,
  emphasis_fill: baseColors.backgroundAccent,

  // critical color scheme
  critical_10: baseColors.negative300,
  critical_20: baseColors.negative200,
  critical_30: baseColors.negative100,
  critical_40: baseColors.negative50,
  critical_text: baseColors.contentNegative,
  critical_fill: baseColors.backgroundNegative
};

const baseTypography = {
  DisplaySmall: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '40px'
  },

  HeadingMedium: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px'
  },
  HeadingLarge: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px'
  },
  HeadingXLarge: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px'
  },
  HeadingXXLarge: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px'
  },
  // Paragraph Settings
  ParagraphSmall: {
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '18px'
  },
  ParagraphMedium: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '22px'
  },
  ParagraphLarge: {
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '24px'
  },
  // Paragraph Emphasis Settings
  MonoParagraphSmall: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px'
  },
  MonoParagraphMedium: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px'
  },
  MonoParagraphLarge: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px'
  }
};

// https://github.com/uber/baseweb/blob/570b0c01a9c6c4d0b582baf7949e0c85c4ee3f5b/documentation-site/pages/discover-more/roadmap.mdx

const squareTypography = {
  display_10: baseTypography.DisplaySmall,

  heading_5: baseTypography.HeadingMedium,
  heading_10: baseTypography.HeadingLarge,
  heading_20: baseTypography.HeadingXLarge,
  heading_30: baseTypography.HeadingXXLarge,

  paragraph_10: baseTypography.ParagraphSmall,
  paragraph_20: baseTypography.ParagraphMedium,
  paragraph_30: baseTypography.ParagraphLarge,

  paragraph_em_10: baseTypography.MonoParagraphSmall,
  paragraph_em_20: baseTypography.MonoParagraphMedium,
  paragraph_em_30: baseTypography.MonoParagraphLarge,

  number_10: baseTypography.ParagraphSmall,
  number_20: baseTypography.ParagraphMedium,
  number_30: baseTypography.ParagraphLarge,

  number_em_10: baseTypography.MonoParagraphSmall,
  number_em_20: baseTypography.MonoParagraphMedium,
  number_em_30: baseTypography.MonoParagraphLarge
};

const componentStyles = {
  button: {
    borderRadius: '6px'
  }
};

const otherProperties = {
  favicon: BlockLogo,
  displayName: 'Block',
  displayAppName: 'Block',
  supportEmail: 'mission-control@squareup.com',
  learningEmail: 'new@block.xyz'
};

const overrides = {
  ...ResponsiveTheme,

  ...grid,

  colors: {
    ...baseColors,
    ...squareMarketColors
  },

  typography: {
    ...baseTypography,
    ...squareTypography
  },
  name: THEME_NAME.BLOCK,

  shadow: {
    shadowStatic:
      '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1)'
  },

  margins: {
    normal: '16px'
  },

  fontFamily: primitives.primaryFontFamily,

  ...componentStyles,

  ...otherProperties
};

const config = {
  primitives,
  overrides
};

export { primitives, overrides };

export default config;
