import React from 'react';
import { OnboardingContentWrapper } from 'newHirePortalRedesign/newHire/pages/dashboard/Styles';
import { useStyletron } from 'baseui';
import { ParagraphSmall } from 'baseui/typography';
import ArrowCircleDownIcon from 'newHirePortalRedesign/newHire/features/banner/Icon/ArrowCircleDownIcon';
import { Block } from 'baseui/block';
import HeadingWithSubText from 'newHirePortalRedesign/newHire/component/headingWithSubText/HeadingWithSubText';
import {
  BannerContainer,
  responsiveHeadingStyle
} from 'newHirePortalRedesign/newHire/features/banner/Styles';
import { CapitalizedThemeName } from 'newHirePortalRedesign/newHire/Styles';
import ImageByTheme from 'newHirePortalRedesign/newHire/features/banner/imageByTheme/ImageByTheme';
import MessageBanner from 'newHirePortalRedesign/newHire/features/banner/messageBanner/MessageBanner';

const Banner = ({ firstWeekRef }) => {
  const [css, theme] = useStyletron();
  const headingStyle = responsiveHeadingStyle(theme);

  const goToTasksBeforeDayOne = () => {
    firstWeekRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <BannerContainer>
      <ImageByTheme />
      <OnboardingContentWrapper>
        <HeadingWithSubText
          heading={
            <>
              Hi Young!
              <br />
              Welcome to <CapitalizedThemeName />!
            </>
          }
          subText={
            <>
              Our Portal will help you to get set up on <CapitalizedThemeName />
              .
            </>
          }
          overridesHeadingStyles={{
            ...headingStyle,
            paddingBottom: 0,
            paddingTop: theme.sizing.scale900,
            marginBottom: 0
          }}
          overridesSubTextStyles={{
            paddingBottom: theme.sizing.scale600,
            ...theme.typography.ParagraphLarge
          }}
        />
        <MessageBanner />
        <Block
          className={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          })}
        >
          <ParagraphSmall
            className={css({
              paddingRight: '5px',
              cursor: 'pointer'
            })}
            onClick={goToTasksBeforeDayOne}
          >
            See tasks to do
          </ParagraphSmall>
          <ArrowCircleDownIcon onClick={goToTasksBeforeDayOne} />
        </Block>
      </OnboardingContentWrapper>
    </BannerContainer>
  );
};

export default Banner;
