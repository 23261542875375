import React from 'react';
import { useSelector } from 'react-redux';
import { useStyletron, withStyle } from 'baseui';
import { StyledLink } from 'baseui/link';
import { datadogRum } from '@datadog/browser-rum';
import { isMnAEmployeeSelector } from 'newHirePortal/newHire/store/user/selectors';
import { SQUARE_MAIL_ID } from '../../../util/enums';
import {
  isSquareTheme,
  isBlockTheme,
  isCashTheme,
  isTidalTheme
} from '../../../store/user/helpers';
import EmailSupport from '../../../components/EmailSupport';
import Item from '../../../components/Item';
import Benefits from '../../../../../assets/docs/Benefits.pdf';

const MyListItemLabel = ({ children, color }) => (
  <Item paddingTop='6px' paddingBottom='6px' minHeight='46px' color={color}>
    {children}
  </Item>
);

const EmailListItem = ({ text, mailId }) => (
  <MyListItemLabel>
    {text}:
    <EmailSupport email={mailId} />
  </MyListItemLabel>
);

const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
  textDecoration: 'inherit',
  cursor: 'pointer',
  color: $theme.colors.contentAccent,
  ':visited': $theme.colors.contentAccent
}));

const LaptopSetupInfo = ({
  linkText = 'https://newcomputer.square.com/',
  text = ''
}) => (
  <>
    {text}
    <MyStyledLink
      href='https://newcomputer.square.com/'
      target='_blank'
      onClick={(event) => {
        datadogRum.addAction(`click ${event.target.innerText}`);
      }}
    >
      {linkText}
    </MyStyledLink>
  </>
);
const LinkListItem = () => {
  const [css, theme] = useStyletron();
  const isMnAEmployee = useSelector(isMnAEmployeeSelector);
  const defaultSetupInstruction = `Laptop Setup Instructions: You will receive an email on your Day 1 
  with instructions to set up your password. Once you have successfully created your password, you can 
  set up your computer by following the instructions at `;
  let laptopSetupComponent = <LaptopSetupInfo text={defaultSetupInstruction} />;

  if (isSquareTheme(theme) || isBlockTheme(theme) || isCashTheme(theme)) {
    laptopSetupComponent = <LaptopSetupInfo linkText='Laptop Setup Info' />;
  }

  return (
    <>
      <ul
        className={css({
          width: '100%',
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 0
        })}
      >
        <MyListItemLabel>
          <MyStyledLink
            href={Benefits}
            download='Onboarding FAQ: Pre-Start Date Qs'
            target='_blank'
            onClick={(event) => {
              datadogRum.addAction(`click ${event.target.innerText}`);
            }}
          >
            Pre-Onboarding Benefits FAQ
          </MyStyledLink>
        </MyListItemLabel>
        {!isMnAEmployee && (
          <MyListItemLabel>{laptopSetupComponent}</MyListItemLabel>
        )}
        <>
          <MyListItemLabel>
            <strong>
              If you have any questions, please contact the appropriate team
              below:
            </strong>
          </MyListItemLabel>
          <div className={css({ marginLeft: '40px' })}>
            <EmailListItem
              text='For general queries'
              mailId={SQUARE_MAIL_ID.GENRAL}
            />
            <EmailListItem
              text='For immigration queries'
              mailId={SQUARE_MAIL_ID.IMMIGRATION}
            />
            <EmailListItem
              text='For IT hardware shipping queries'
              mailId={SQUARE_MAIL_ID.HARDWARE}
            />
            {isTidalTheme(theme) ? (
              <EmailListItem
                text='For new hire orientation'
                mailId={SQUARE_MAIL_ID.LEARNING}
              />
            ) : (
              <EmailListItem
                text='General Onboarding'
                mailId={
                  isCashTheme(theme) ? theme.supportEmail : theme.learningEmail
                }
              />
            )}
          </div>
        </>
      </ul>
    </>
  );
};

export default LinkListItem;
