import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';
import { withStyle, useStyletron } from 'baseui';
import { HeadingXLarge } from 'baseui/typography';
import ButtonComp from './ButtonComp';
import { allDirectionStyle } from '../util/styleHelpers';

const MyModalHeader = withStyle(ModalHeader, () => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 0
}));

const ShowAllModal = ({ isOpen, setIsOpen, ModalHead, Modalcontent }) => {
  const [css, theme] = useStyletron();
  if (isOpen) {
    return (
      <>
        <Modal
          onClose={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          closeable={false}
          unstable_ModalBackdropScroll
          overrides={{
            Root: { style: { zIndex: 10 } },
            Dialog: {
              style: {
                ...allDirectionStyle({
                  radius: '8px',
                  prop: 'border'
                }),
                display: 'flex',
                flexDirection: 'column',
                width: '664px',
                minHeight: '736px',
                height: 'auto'
              }
            }
          }}
        >
          <MyModalHeader>
            <ButtonComp
              className={css({
                marginRight: 'auto'
              })}
              onClick={() => setIsOpen(!isOpen)}
              backColor={theme.colors.mono200}
              color={theme.colors.mono800}
              borderRadius='6px'
              width='48px'
              height='48px'
              label='X'
            />
            <HeadingXLarge
              className={css({
                marginRight: 'auto',
                marginLeft: '-50px',
                marginTop: '2%',
                letterSpacing: '0.006em'
              })}
            >
              {ModalHead}
            </HeadingXLarge>
          </MyModalHeader>
          <ModalBody
            overrides={{
              Content: {
                style: {
                  marginLeft: theme.sizing.scale700
                }
              }
            }}
          >
            {Modalcontent}
          </ModalBody>
        </Modal>
      </>
    );
  }

  return null;
};

export default ShowAllModal;
