import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUser as getUserApi,
  getUserWorkdayTasks as getUserWorkdayTasksApi
} from '../../api/auth';
import { handleApiResponse } from '../../../../utils/api_helpers';

export const getUser = createAsyncThunk(
  'user/getUser',
  async (welcomeQuery, { rejectWithValue }) => {
    try {
      const response = await getUserApi({ welcome: welcomeQuery });
      const result = await handleApiResponse(response);
      if (result.ok) {
        return result.data;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserWorkdayTasks = createAsyncThunk(
  'user/getUserWorkdayTasks',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await getUserWorkdayTasksApi();
      const result = await handleApiResponse(response);
      if (result.ok) {
        return result.data;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
