import { createSlice } from '@reduxjs/toolkit';
import { postProfile } from './thunk';
import { API_STATUS } from '../../util/enums';

const profileSlice = createSlice({
  name: 'profile',
  postStatus: API_STATUS.IDLE,
  initialState: {
    bioContent: '',
    profileImage: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(postProfile.pending, (state) => {
        state.postStatus = API_STATUS.LOADING;
      })
      .addCase(postProfile.fulfilled, (state) => {
        state.postStatus = API_STATUS.COMPLETED;
      })
      .addCase(postProfile.rejected, (state, action) => {
        state.postStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      });
  }
});

const { reducer } = profileSlice;

export default reducer;
