import { createSlice } from '@reduxjs/toolkit';
import { getShippingAddress, postShippingAddress } from './thunk';
import { API_STATUS } from '../../util/enums';

export const WFH_KIT = 'wfhKit';

export const shippingInputInitialState = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  administrativeDistrictLevel1: '',
  postalCode: '',
  countryCode: '',
  email: '',
  phone: '',
  otherComments: '',
  [WFH_KIT]: 'true'
};

const shippingAddressSlice = createSlice({
  name: 'shippingAddress',
  initialState: {
    loadStatus: API_STATUS.IDLE,
    postStatus: API_STATUS.IDLE,
    data: {},
    error: '',
    errorCode: null,
    shippingInput: shippingInputInitialState,
    submitError: null,
    isOpenModal: false,
    isOnConfirmPage: false
  },
  reducers: {
    resetShippingInput: (state) => {
      state.shippingInput = shippingInputInitialState;
    },
    updateShippingInput: (state, action) => {
      state.shippingInput = { ...state.shippingInput, ...action.payload };
    },
    setSubmitError: (state, action) => {
      state.submitError = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setIsOnConfirmPage: (state, action) => {
      state.isOnConfirmPage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShippingAddress.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(getShippingAddress.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = action.payload;
      })
      .addCase(getShippingAddress.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
          state.errorCode = action.payload.code;
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(postShippingAddress.pending, (state) => {
        state.postStatus = API_STATUS.LOADING;
      })
      .addCase(postShippingAddress.fulfilled, (state) => {
        state.postStatus = API_STATUS.COMPLETED;
      })
      .addCase(postShippingAddress.rejected, (state, action) => {
        state.postStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
          state.errorCode = action.payload.code;
        } else {
          state.error = action.error.message;
        }
      });
  }
});

const { actions, reducer } = shippingAddressSlice;

const {
  resetShippingInput,
  updateShippingInput,
  setSubmitError,
  setIsModalOpen,
  setIsOnConfirmPage
} = actions;

export {
  resetShippingInput,
  updateShippingInput,
  setSubmitError,
  setIsModalOpen,
  setIsOnConfirmPage
};

export default reducer;
