import React from 'react';
import { useStyletron, styled } from 'baseui';
import IconsWithBg from '../../../../../components/icons/IconsWithBg';

const LogoInfoWrap = styled('div', () => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

const LanguageTitleInfo = styled('div', ({ $marginLeft, $marginTop }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: $marginLeft,
  marginTop: $marginTop
}));

export const HardwareTitleInfo = ({
  heading,
  subText,
  marginLeft,
  marginTop,
  dataTestId
}) => {
  const [css, theme] = useStyletron();
  return (
    <LanguageTitleInfo $marginLeft={marginLeft} $marginTop={marginTop}>
      <span
        className={css({
          ...theme.typography.ParagraphMedium,
          color: theme.colors.mono500,
          marginBottom: '5px'
        })}
      >
        {heading}
      </span>
      <span
        data-testid={dataTestId}
        className={css({
          ...theme.typography.MonoParagraphMedium,
          lineHeight: '22px',
          color: theme.colors.contentAccent
        })}
      >
        {subText}
      </span>
    </LanguageTitleInfo>
  );
};

const HardwareTitleWithIcon = ({
  icon,
  deviceCategory,
  selectedDeviceName,
  dataTestId
}) => (
  <LogoInfoWrap>
    <IconsWithBg logo={icon} width='36px' height='36px' />
    <HardwareTitleInfo
      heading={deviceCategory}
      subText={selectedDeviceName}
      marginLeft='18px'
      dataTestId={dataTestId}
    />
  </LogoInfoWrap>
);

export default HardwareTitleWithIcon;
