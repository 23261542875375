import React from 'react';
import { ParagraphSmall } from 'baseui/typography';
import { useStyletron } from 'baseui';

const DueDateText = ({ isOptionalTask, dueDate, children }) => {
  const [css, theme] = useStyletron();
  if (!isOptionalTask) {
    if (dueDate !== null) {
      return (
        <ParagraphSmall margin='0' color={theme.colors.mono400}>
          Due date:
          <span
            className={css({
              color: theme.colors.mono700,
              marginLeft: theme.sizing.scale200
            })}
          >
            {children}
          </span>
        </ParagraphSmall>
      );
    }
    return null;
  }
  return (
    <ParagraphSmall margin='0' color={theme.colors.mono400}>
      Optional
    </ParagraphSmall>
  );
};

export default DueDateText;
