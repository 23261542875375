import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const HeadsetIcon = ({ ...props }) => {
  const { title = 'headset icon', size = 22, ...restProps } = props;
  const [, theme] = useStyletron();
  return (
    <Icon viewBox='0 0 22 24' title={title} size={size} {...restProps}>
      <path
        d='M18.6727 16.8V21C18.6727 22.4666 17.4945 23.6666 16.0545 23.6666H14.7455M14.7455 23.6666C14.7455 22.9333 14.1564 22.3333 13.4364 22.3333H11.4727C10.7527 22.3333 10.1636 22.9333 10.1636 23.6667C10.1636 24.4 10.7527 25 11.4727 25H13.4364C14.1564 25 14.7455 24.4 14.7455 23.6666ZM2.96364 9C2.96364 4.6 6.49818 1 10.8182 1C15.1382 1 18.6727 4.6 18.6727 9M16.0545 8.33333C18.6073 8.33333 20.6364 10.4 20.6364 13C20.6364 15.6 18.6073 17.6667 16.0545 17.6667V9C16.0545 6.06667 13.6982 3.66667 10.8182 3.66667C7.93818 3.66667 5.58182 6.06667 5.58182 9V17.6667C3.02909 17.6667 1 15.6 1 13C1 10.4 3.02909 8.33333 5.58182 8.33333'
        stroke={theme.colors.backgroundAccent}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={theme.colors.mono100}
      />
    </Icon>
  );
};
export default HeadsetIcon;
