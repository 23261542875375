import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const AlertTriangle = ({ size, color, ...props }) => {
  const [, theme] = useStyletron();
  const { title = 'AlertTriangle', ...restProps } = props;

  return (
    <Icon
      viewBox='0 0 16 16'
      title={title}
      size={size}
      color={color || theme.colors.backgroundNegative}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.409117 13.7586L7.56223 0.793617C7.75236 0.449002 8.24768
        0.449003 8.43781 0.793618L15.5909 13.7586C15.7747 14.0918 15.5337
        14.5001 15.1531 14.5001H0.846905C0.466311 14.5001 0.22526 14.0918 0.409117
        13.7586ZM8 9.00159C7.44772 9.00159 7 8.55387 7 8.00159V6.002C7 5.44972 7.44772
        5.002 8 5.002C8.55228 5.002 9 5.44972 9 6.002V8.00159C9 8.55387 8.55229 9.00159
        8 9.00159ZM8 10.0011C7.44772 10.0011 7 10.4488 7 11.0009C7 11.5531 7.44772 12.0007
        8 12.0007C8.55228 12.0007 9 11.5531 9 11.0009C9 10.4488 8.55229 10.0011 8 10.0011Z'
      />
    </Icon>
  );
};

export default AlertTriangle;
