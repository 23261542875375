import React from 'react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import AccentAlert from '../../../../../../components/icons/AccentAlert';
import EmailSupport from '../../../../components/EmailSupport';

const AlertInfoWithEmailListItem = ({
  text,
  mailId,
  suffixText,
  typographyType,
  styles,
  iconColor,
  dataTestId
}) => {
  const [css] = useStyletron();
  return (
    <Block data-testid={dataTestId} display='flex' flexDirection='row'>
      <span className={css({ lineHeight: '24px' })}>
        <AccentAlert
          className={css({ verticalAlign: 'sub' })}
          color={iconColor}
        />
      </span>
      <span
        className={css({
          ...typographyType,
          paddingBottom: '17px',
          minHeight: '46px',
          paddingLeft: '3%',
          lineHeight: '24px',
          marginTop: '0px',
          ...styles
        })}
      >
        {text} <EmailSupport email={mailId} marginLeft='0px' /> {suffixText}
      </span>
    </Block>
  );
};

export default AlertInfoWithEmailListItem;
