import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import {
  responsiveHeadingStyle,
  textEllipsis,
  responsiveImageStyle
} from 'newHirePortalRedesign/newHire/Styles';
import { Heading, HeadingLevel } from 'baseui/heading';
import ImageWithDescription from 'newHirePortalRedesign/newHire/component/imageWithDescription/ImageWithDescription';
import TitleWithDescription from 'newHirePortalRedesign/newHire/component/titleWithDescription/TitleWithDescription';
import { useFirstWeekDataByTheme } from 'newHirePortalRedesign/newHire/features/yourFirstWeek/hooks/useFirstWeekDataByTheme/useFirstWeekDataByTheme';
import { YourFirstWeekContentWrapper } from 'newHirePortalRedesign/newHire/features/yourFirstWeek/Styles';
import { StyledLink } from 'baseui/link';
import {
  ReadMoreObject,
  YourFirstWeekObject
} from 'newHirePortalRedesign/newHire/features/yourFirstWeek/data/cash';
import ReadMoreModal from 'newHirePortalRedesign/newHire/features/yourFirstWeek/readMoreModal/ReadMoreModal';

const YourFirstWeek = React.forwardRef((props, ref) => {
  const [css, theme] = useStyletron();
  const yourFirstWeekData = useFirstWeekDataByTheme(theme);
  const [isReadMoreModalOpen, setIsReadMoreModalOpen] = useState(false);

  const [readMoreData, setReadMoreData] = useState<ReadMoreObject>({
    description: '',
    name: '',
    imgAlt: '',
    modalImgSrc: ''
  });

  const handleReadMoreClick = (modalData: YourFirstWeekObject) => {
    const { imgSrc, ...readMoreContent } = modalData;
    setReadMoreData(readMoreContent);
    setIsReadMoreModalOpen(true);
  };

  const handleOnClose = () => {
    setIsReadMoreModalOpen(false);
  };

  return (
    <>
      <HeadingLevel>
        <span ref={ref} />
        <Heading
          className={css({
            ...responsiveHeadingStyle(theme),
            paddingBottom: theme.sizing.scale300
          })}
        >
          Your first week
        </Heading>
      </HeadingLevel>
      <YourFirstWeekContentWrapper>
        {yourFirstWeekData.map((content) => (
          <ImageWithDescription
            overflow='hidden'
            key={content.name}
            flexDirection='row'
            gridGap={[
              theme.sizing.scale600,
              theme.sizing.scale600,
              theme.sizing.scale800
            ]}
            image={
              <img
                src={content.imgSrc}
                alt={content.imgAlt}
                className={css({
                  height: '80px',
                  borderRadius: theme.borders.radius300,
                  [theme.mediaQuery.small]: {
                    ...responsiveImageStyle,
                    width: theme.sizing.scale1200
                  },
                  [theme.mediaQuery.medium]: {
                    width: '80px'
                  },
                  [theme.mediaQuery.large]: {
                    width: '80px'
                  }
                })}
              />
            }
          >
            <TitleWithDescription
              overflow='hidden'
              maxWidth='500px'
              title={
                <span
                  className={css({
                    display: 'block',
                    ...textEllipsis
                  })}
                >
                  {content.name}
                </span>
              }
              description={
                <>
                  <span
                    className={css({
                      flex: '1',
                      ...textEllipsis,
                      display: 'inline-block'
                    })}
                  >
                    {content.description}
                  </span>
                  <StyledLink
                    className={css({
                      cursor: 'pointer',
                      color: theme.colors.buttonPrimaryFill
                    })}
                    onClick={() => handleReadMoreClick(content)}
                  >
                    Read more
                  </StyledLink>
                </>
              }
            />
          </ImageWithDescription>
        ))}
      </YourFirstWeekContentWrapper>
      {isReadMoreModalOpen && (
        <ReadMoreModal
          readMoreData={readMoreData}
          handleOnClose={handleOnClose}
          isModalOpen={isReadMoreModalOpen}
        />
      )}
    </>
  );
});

export default YourFirstWeek;
