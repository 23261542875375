import onboarding from 'newHirePortalRedesign/newHire/features/yourFirstWeek/assets/cash/onboarding.png';
import itOfficeHours from 'newHirePortalRedesign/newHire/features/yourFirstWeek/assets/cash/itOfficeHours.png';
import orientation from 'newHirePortalRedesign/newHire/features/yourFirstWeek/assets/cash/orientation.png';
import benefitsOfficeHours from 'newHirePortalRedesign/newHire/features/yourFirstWeek/assets/cash/benefitsOfficeHours.png';
import goNewHire from 'newHirePortalRedesign/newHire/features/yourFirstWeek/assets/cash/goNewHire.png';
import roundTable from 'newHirePortalRedesign/newHire/features/yourFirstWeek/assets/cash/roundTable.png';
import goNewHireReadMore from 'newHirePortalRedesign/newHire/features/yourFirstWeek/assets/cash/readmore/goNewhireReadMore.png';

export interface ReadMoreObject {
  name: string;
  description: string;
  imgAlt: string;
  modalImgSrc: string;
}

export interface YourFirstWeekObject extends ReadMoreObject {
  imgSrc: string;
}

export const yourFirstWeekData: YourFirstWeekObject[] = [
  {
    name: 'Onboarding',
    description:
      'Onboarding will be conducted virtually via Google' +
      'Meet video call.Please note: Google Meet works best using a' +
      'Chrome browser and you’ll need your video and audio on.' +
      'You’ll receive calendar invites to your personal email with' +
      'these links and specific times for each of the below sessions.',
    imgSrc: onboarding,
    imgAlt: 'onboarding',
    modalImgSrc: goNewHireReadMore // TODO: need to update the image for onboarding, once we get it from Young
  },
  {
    name: 'IT Office Hours',
    description:
      'On Day 1, use the setup instructions in the' +
      '"Useful Links" section below to get started. Join this' +
      'onboarding session to get technical support from a member of' +
      'our IT Team if you run into any questions.',
    imgSrc: itOfficeHours,
    imgAlt: 'IT Office Hours ',
    modalImgSrc: goNewHireReadMore // TODO: need to update the image for IT Office Hours, once we get it from Young
  },
  {
    name: 'Cash App Orientation',
    description:
      'On Day 1, this session will walk you through the' +
      'product, history, and culture of Cash App. As a part of' +
      'this, you’ll have the chance to make new connections with' +
      'other new hires.',
    imgSrc: orientation,
    imgAlt: 'Orientation',
    modalImgSrc: goNewHireReadMore // TODO: need to update the image for Orientation, once we get it from Young
  },
  {
    name: 'Benefits Office Hours (USA only)',
    description:
      'On Day 2, a member of our Benefits' +
      'Team will be available for this optional session to learn' +
      'more about benefits options and ask any questions you may' +
      'have. You have 30 days from your start date to complete' +
      'benefits information in Workday.',
    imgSrc: benefitsOfficeHours,
    imgAlt: 'Benefits Office Hours (USA only)',
    modalImgSrc: goNewHireReadMore // TODO: need to update the image for Benefits Office Hours (USA only), once we get it from Young
  },
  {
    name: 'Product orientation',
    description:
      'On Day 3, this optional session will offer an in-depth overview of the app, hosted by the Release Team.',
    imgSrc: orientation, // TODO: Need to update the image for Product orientation, once we get it from Young
    imgAlt: 'Product orientation',
    modalImgSrc: goNewHireReadMore // TODO: need to update the image for Product orientation, once we get it from Young
  },

  {
    name: 'go/$newhire',
    description:
      'This portal will expire in 10 days from your start date at which point you can access everything here.',
    imgSrc: goNewHire,
    imgAlt: 'go New hire',
    modalImgSrc: goNewHireReadMore
  },
  {
    name: 'Roundtable with Brian Grassadonia',
    description:
      'The fun continues beyond Week 1! Expect to also receive a calendar invite for a Roundtable with Brian Grassadonia, to meet the Head of Cash App!',
    imgSrc: roundTable,
    imgAlt: 'Roundtable with Brian Grassadonia',
    modalImgSrc: goNewHireReadMore // TODO: need to update the image for Roundtable with Brian Grassadonia, once we get it from Young
  }
];
