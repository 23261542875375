import React from 'react';
import { PLACEMENT, StatefulTooltip } from 'baseui/tooltip';
import { useStyletron } from 'baseui';

const TooltipWithIcon = ({ icon, msg, content, containerOverride }) => {
  const [css, theme] = useStyletron();

  return (
    <StatefulTooltip
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.mono100
          })
        },
        Inner: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.contentPrimary,
            borderRadius: '7px'
          })
        }
      }}
      accessibilityType='tooltip'
      content={content || msg}
      placement={PLACEMENT.bottomLeft}
      focusLock
    >
      <div
        className={css({
          alignSelf: 'center',
          color: theme.colors.mono400,
          ':hover': { color: theme.colors.contentPrimary },
          ...containerOverride
        })}
      >
        {icon}
      </div>
    </StatefulTooltip>
  );
};
export default TooltipWithIcon;
