import React from 'react';
import InfoMsg from './InfoMsg';
import SuccessSmiley from '../../../../../components/icons/SuccessSmiley';
import CurrentInfo from './CurrentInfo';
import BackToDashboardButton from '../../../components/BackToDashboardButton';

const UsernameAlreadyAssigned = ({ username }) => (
  <div data-testid='one-username'>
    <InfoMsg
      logo={<SuccessSmiley />}
      mainMsg="We have configured a username for you. We'll have it set up for you on your start date."
    />
    <CurrentInfo defaultUsername={username} />
    <BackToDashboardButton compName='Username Already Configured' />
  </div>
);

export default UsernameAlreadyAssigned;
