import React, { ReactNode } from 'react';
import { Button, SHAPE, SIZE, ButtonProps } from 'baseui/button';
import { StyleObject } from 'styletron-react';
import { allDirectionStyle } from 'newHirePortalRedesign/newHire/util/styleHelpers';

interface ScrollButtonIconProps {
  children: ReactNode;
  overideStyle?: StyleObject;
  handleClick: () => void;
}

const ScrollButtonIcon = ({
  handleClick,
  overideStyle = {},
  children,
  ...restProps
}: ButtonProps & ScrollButtonIconProps) => (
  <Button
    onClick={handleClick}
    shape={SHAPE.round}
    size={SIZE.mini}
    overrides={{
      BaseButton: {
        style: ({ $theme }) => ({
          backgroundColor: $theme.colors.mono100,
          position: 'absolute',
          ':hover': {
            backgroundColor: $theme.colors.buttonSecondaryHover,
            border: 'none'
          },
          zIndex: '99999',
          width: '37px',
          height: '37px',
          top: '188px',
          ...allDirectionStyle({
            width: $theme.borders.radius100,
            prop: 'border',
            style: 'solid',
            color: $theme.colors.backgroundSecondary
          }),
          ...overideStyle
        })
      }
    }}
    {...restProps}
  >
    {children}
  </Button>
);

export default ScrollButtonIcon;
