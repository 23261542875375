import React from 'react';
import { ListItem, ListItemLabel } from 'baseui/list';
import { useStyletron } from 'baseui';
import Bullet from './Bullet';

const Item = ({ children, color, paddingTop, paddingBottom, minHeight }) => {
  const [css, theme] = useStyletron();
  return (
    <ListItem
      overrides={{
        Content: {
          style: () => ({
            display: 'inline-flex',
            justifyContent: 'flex-start',
            paddingTop: paddingTop || '8px',
            paddingBottom: paddingBottom || '8px',
            minHeight: minHeight || '48px',
            textAlign: 'justify'
          })
        }
      }}
    >
      <>
        <span>
          <Bullet color={color} />
        </span>
        <ListItemLabel
          className={css({
            ...theme.typography.ParagraphLarge
          })}
        >
          {children}
        </ListItemLabel>
      </>
    </ListItem>
  );
};

export default Item;
