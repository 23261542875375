import React from 'react';
import InfoMsg from './InfoMsg';
import BackToDashboardButton from '../../../components/BackToDashboardButton';
import SuccessSmiley from '../../../../../components/icons/SuccessSmiley';
import CurrentInfo from './CurrentInfo';

const RehireUsernamePage = ({ workEmail }) => (
  <>
    <InfoMsg
      logo={<SuccessSmiley />}
      mainMsg='We have found your old username. We’ll have it set up for you on your start date.'
    />
    <CurrentInfo defaultUsername={workEmail} />
    <BackToDashboardButton compName='Rehire Username' />
  </>
);

export default RehireUsernamePage;
