import React, { memo } from 'react';
import { FlexGridItem } from 'baseui/flex-grid';
import { useAppSelector } from 'newHirePortalRedesign/newHire/store/hooks';
import { API_STATUS } from 'newHirePortalRedesign/newHire/util/constants';
import CompletedWorkdayCard from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/completedWorkdayCard/CompletedWorkdayCard';
import FailedWorkdayCard from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/failedWorkdayCard/FailedWorkdayCard';
import {
  selectTotalCardCount,
  workdayTasksLoadState
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/store/selectors/selectors';
import { itemProps } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/Styles';
import CustomSkeleton from 'newHirePortalRedesign/newHire/component/customSkeleton/CustomSkeleton';

interface WorkdayProps {
  apiFailedCallCount: number;
}

const Workday = ({ apiFailedCallCount }: WorkdayProps) => {
  const totalCardCount = useAppSelector(selectTotalCardCount);
  const workdayTasksLoadStatus = useAppSelector(workdayTasksLoadState);

  if (
    workdayTasksLoadStatus === API_STATUS.FAILED ||
    (workdayTasksLoadStatus !== API_STATUS.COMPLETED && apiFailedCallCount >= 1)
  ) {
    return (
      <FlexGridItem {...itemProps(totalCardCount)}>
        <FailedWorkdayCard
          isLoading={workdayTasksLoadStatus === API_STATUS.LOADING}
          apiFailedCallCount={apiFailedCallCount}
        />
      </FlexGridItem>
    );
  }

  if (workdayTasksLoadStatus === API_STATUS.COMPLETED) {
    return (
      <FlexGridItem {...itemProps(totalCardCount)}>
        <CompletedWorkdayCard />
      </FlexGridItem>
    );
  }

  return (
    apiFailedCallCount === 0 && (
      <FlexGridItem {...itemProps(totalCardCount)}>
        <CustomSkeleton
          dataTestId='workday-widget-loader'
          rootStyleOverride={{ width: 'inherit', height: '416px' }}
        />
      </FlexGridItem>
    )
  );
};

export default memo(Workday);
