import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as StyletronProvider } from 'styletron-react';
import { Provider } from 'react-redux';
import { Client as Styletron } from 'styletron-engine-atomic';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './newHirePortal/newHire/store';
import App from './newHirePortal/newHire/App';
import { DATADOG_RUM_CONFIG } from './newHirePortal/newHire/config/monitoring/datadog';
import { VERSION as APP_VERSION } from './newHirePortal/newHire/config/version';
import { init as initDatadog } from './utils/datadog';

initDatadog({
  ...DATADOG_RUM_CONFIG,
  version: APP_VERSION
});

const renderApp = (dom) => {
  const root = createRoot(dom);
  const engine = new Styletron();
  root.render(
    <Provider store={store}>
      <StyletronProvider value={engine}>
        <Router>
          <App />
        </Router>
      </StyletronProvider>
    </Provider>
  );
};

const startUp = (root) => {
  if (process.env.MOCK === 'true' && process.env.NODE_ENV === 'development') {
    import('./newHirePortal/newHireDevModule/api/mocks/base')
      .then((Module) => {
        // eslint-disable-next-line no-console
        console.warn(
          'Mock mode enabled, all APIS will be mocked locally without reaching remote server!'
        );
        Module.DevMock();
      })
      .then(() => renderApp(root));
  } else {
    renderApp(root);
  }
};

window.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementById('root');

  startUp(root);
});
