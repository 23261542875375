import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import welcomeImage from 'newHirePortalRedesign/newHire/features/banner/assets/CashAppWelcomeImage.png';
import jackDorsey from 'newHirePortalRedesign/newHire/features/banner/assets/jackDorsey.png';
import { CardContainer } from 'newHirePortalRedesign/newHire/features/usefulResources/Styles';
import MediaCard from 'newHirePortalRedesign/newHire/features/banner/mediaCard/MediaCard';
import BuVideoModal from 'newHirePortalRedesign/newHire/features/banner/buVideoModal/BuVideoModal';
import BuLeadModal from 'newHirePortalRedesign/newHire/features/banner/buLeadModal/BuLeadModal';
import { RootState } from 'newHirePortalRedesign/newHire/store';
import { API_STATUS } from 'newHirePortalRedesign/newHire/util/constants';
import { getCoreMemberMsg } from 'newHirePortalRedesign/newHire/util/coreMemMsg';
import { Skeleton } from 'baseui/skeleton';
import { getCoreMember } from 'newHirePortal/newHire/store/coreMembers/thunk';
import {
  useAppDispatch,
  useAppSelector
} from 'newHirePortalRedesign/newHire/store/hooks';

const MessageBanner = () => {
  const [, theme] = useStyletron();
  const dispatch = useAppDispatch();

  const coreMemberLoadStatus = useAppSelector(
    (state: RootState) => state.coreMember.loadStatus
  );

  useEffect(() => {
    if (coreMemberLoadStatus === API_STATUS.IDLE) {
      dispatch(getCoreMember());
    }
  }, [coreMemberLoadStatus]);

  const coreMemberData = useAppSelector(
    (state: RootState) => state.coreMember.data
  );

  // TODO: Note For the below function to work in dev we need to change the theme name.
  const memberData = getCoreMemberMsg(coreMemberData, theme.name);

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isBuLeadModalOpen, setIsBuLeadModalOpen] = useState(false);
  const handleBuLeadModalClose = () => setIsBuLeadModalOpen(false);

  const handlePlayVideoButton = () => {
    setIsVideoModalOpen(true);
  };
  const handleVideoModalOnClose = () => {
    setIsVideoModalOpen(false);
  };

  const handleReadLetterButton = () => {
    setIsBuLeadModalOpen(true);
  };

  return (
    <CardContainer>
      <MediaCard
        image={jackDorsey}
        altName='jack dorsey'
        title='Welcome from Jack Dorsey'
        description='Onboarding will be conducted virtually via Google Meet video call.'
        buttonName='Play video'
        onClick={handlePlayVideoButton}
      />
      {coreMemberLoadStatus === API_STATUS.COMPLETED && memberData && (
        <MediaCard
          image={welcomeImage}
          altName='welcome letter'
          title={`Welcome letter from ${memberData.name}`}
          description='Onboarding will be conducted virtually via Google Meet video call.'
          buttonName='Read letter'
          onClick={handleReadLetterButton}
        />
      )}
      {coreMemberLoadStatus === API_STATUS.LOADING && (
        <Skeleton width='650px' height='530px' animation />
      )}
      <BuVideoModal
        isModalOpen={isVideoModalOpen}
        handleOnClose={handleVideoModalOnClose}
      />
      {coreMemberLoadStatus === API_STATUS.COMPLETED && memberData && (
        <BuLeadModal
          buLeadsData={memberData}
          coreMemberImage={coreMemberData.profile_image}
          // TODO: Change below name to employee name after implmenting the user
          empName='John Doe'
          handleOnClose={handleBuLeadModalClose}
          isModalOpen={isBuLeadModalOpen}
        />
      )}
    </CardContainer>
  );
};

export default MessageBanner;
