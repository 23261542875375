import React from 'react';
import { useStyletron } from 'baseui';
import ButtonComp from './ButtonComp';

const AccentButton = (props) => {
  const [, theme] = useStyletron();

  return (
    <ButtonComp
      backColor={theme.colors.backgroundAccent}
      color={theme.colors.mono100}
      {...props}
    />
  );
};

export default AccentButton;
