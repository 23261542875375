import React from 'react';
import { useStyletron } from 'baseui';
import {
  isBlockTheme,
  isSquareTheme
} from 'newHirePortal/newHire/store/user/helpers';
import InfoWrapper from 'newHirePortal/newHire/components/InfoWrapper';
import Messages from './Messages';

const BeforeDayOne = () => {
  let dayOneMsg = 'Before Day 1';
  const [, theme] = useStyletron();
  if (isSquareTheme(theme) || isBlockTheme(theme)) {
    dayOneMsg = 'Before Day 1 Checklist:';
  }
  return (
    <InfoWrapper
      Header={dayOneMsg}
      ModalHead={dayOneMsg}
      Modalcontent={<Messages />}
      height='auto'
    >
      <Messages />
    </InfoWrapper>
  );
};
export default BeforeDayOne;
