import React, { JSX } from 'react';
import { StyleObject } from 'styletron-react';
import { Card, StyledAction, StyledBody } from 'baseui/card';
import { allDirectionStyle } from 'newHirePortalRedesign/newHire/util/styleHelpers';

interface CustomCardProps {
  bodySection: JSX.Element;
  action?: JSX.Element;
  contentStyleOverride?: StyleObject;
  rootStyleOverride?: StyleObject;
}

const CustomCard = ({
  bodySection,
  action,
  contentStyleOverride = {},
  rootStyleOverride = {}
}: CustomCardProps) => (
  <Card
    overrides={{
      Contents: {
        style: ({ $theme }) => ({
          marginBottom: $theme.sizing.scale800,
          marginTop: $theme.sizing.scale800,
          marginRight: $theme.sizing.scale800,
          marginLeft: $theme.sizing.scale800,
          ...contentStyleOverride
        })
      },
      Root: {
        style: ({ $theme }) => ({
          ...allDirectionStyle({
            radius: $theme.borders.radius300,
            prop: 'border',
            color: $theme.colors.mono200,
            width: '1px'
          }),
          boxShadow: $theme.lighting.shadow500,
          ...rootStyleOverride
        })
      }
    }}
  >
    <StyledBody>{bodySection}</StyledBody>
    {action && <StyledAction data-testid='card-action'> {action}</StyledAction>}
  </Card>
);

export default CustomCard;
