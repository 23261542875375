import { configureStore } from '@reduxjs/toolkit';
import appReducer from 'newHirePortalRedesign/newHire/app/store/slice/slice';
import workdayTasksReducer from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/slice/slice';
import coreMemberReducer from 'newHirePortalRedesign/newHire/features/banner/store/slice/slice';
import profileReducer from 'newHirePortalRedesign/newHire/features/profileSubmit/store/slice/slice';

export const store = configureStore({
  reducer: {
    workdayTasks: workdayTasksReducer,
    coreMember: coreMemberReducer,
    profile: profileReducer,
    app: appReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type SelectorReturnType<S> = (state: RootState) => S;
