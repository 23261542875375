import React from 'react';
import { Theme, useStyletron } from 'baseui';
import { StyleObject } from 'styletron-react';

interface CssStyleObject {
  [key: string]: string;
}

const responsiveHeadingStyle = (theme: Theme): StyleObject => ({
  [theme.mediaQuery.small]: {
    ...theme.typography.HeadingSmall
  },
  [theme.mediaQuery.medium]: {
    ...theme.typography.HeadingMedium
  },
  [theme.mediaQuery.large]: {
    ...theme.typography.HeadingMedium
  }
});

const responsiveImageStyle: CssStyleObject = {
  objectFit: 'cover',
  width: '100%'
};

const textEllipsis: CssStyleObject = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
};

const capitalizeFirstLetter: CssStyleObject = {
  textTransform: 'capitalize'
};

const CapitalizedThemeName = () => {
  const [css, theme] = useStyletron();
  return (
    <span className={css(capitalizeFirstLetter)}>
      {theme.name.toLowerCase()}
    </span>
  );
};

export {
  responsiveHeadingStyle,
  responsiveImageStyle,
  textEllipsis,
  capitalizeFirstLetter,
  CapitalizedThemeName
};
