import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid } from 'baseui/layout-grid';
import MainNav from 'newHirePortalRedesign/newHire/features/mainNav/MainNav';
import Dashboard from 'newHirePortalRedesign/newHire/pages/dashboard/Dashboard';
import { useStyletron } from 'baseui';
import { CustomCell } from 'newHirePortalRedesign/newHire/app/Styles';
import { mediaQueryStyles } from 'newHirePortalRedesign/newHire/util/styleHelpers';
import { HeadingLevel } from 'baseui/heading';
import { store } from 'newHirePortalRedesign/newHire/store';
import { Provider } from 'react-redux';
import { HumanTask } from 'newHirePortalRedesign/newHire/app/store/slice/slice';
import Profile from 'newHirePortalRedesign/newHire/pages/profile/Profile';
import {
  USERNAME_URL_PATH,
  PROFILE_URL_PATH
} from 'newHirePortalRedesign/newHire/util/routes';
import Username from 'newHirePortalRedesign/newHire/pages/username/Username';

// TODO: Need to remove the props. once, we call the user api within nhp-redesign app
const App = ({ humanTasks }: { humanTasks: HumanTask[] }) => {
  const [, theme] = useStyletron();
  return (
    <Provider store={store}>
      <Grid
        overrides={{
          Grid: {
            style: ({ $theme }) => ({
              ...mediaQueryStyles({
                $theme,
                mediaSizes: ['small', 'medium', 'large'],
                styles: {
                  paddingLeft: '0px',
                  paddingRight: '0px'
                }
              })
            })
          }
        }}
      >
        <CustomCell
          span={[4, 8, 12]}
          styles={{
            borderBottom: `1px solid ${theme.colors.primary200}`
          }}
        >
          <MainNav />
        </CustomCell>
        <HeadingLevel>
          <Switch>
            <Route path={USERNAME_URL_PATH}>
              <Username />
            </Route>
            <Route path={PROFILE_URL_PATH}>
              <Profile />
            </Route>
            <Route path='*'>
              <Dashboard humanTasks={humanTasks} />
            </Route>
          </Switch>
        </HeadingLevel>
      </Grid>
    </Provider>
  );
};

export default App;
