import { Theme } from 'baseui';

const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const spin = {
  from: {
    transform: 'rotate(0deg)'
  },
  to: {
    transform: 'rotate(360deg)'
  }
};

const buttonStyle = (theme: Theme) => ({
  color: theme.colors.positive400,
  ...theme.typography.ParagraphMedium,
  background: 'none',
  border: 'none',
  cursor: 'pointer'
});

const iconAnimation = {
  animationName: spin,
  animationDuration: '900ms',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear'
};

const apiRetryMsgStyle = (theme: Theme) => ({
  [theme.mediaQuery.small]: {
    display: 'inline'
  },
  [theme.mediaQuery.medium]: flexCenter,
  [theme.mediaQuery.large]: flexCenter
});

export { flexCenter, iconAnimation, buttonStyle, apiRetryMsgStyle };
