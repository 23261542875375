import React from 'react';
import { MonoParagraphLarge } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';

const InfoWrap = styled('div', () => ({
  display: 'flex',
  marginBottom: '20px',
  alignItems: 'flex-start'
}));

const Info = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '20px'
}));

const InfoMsg = ({ logo, mainMsg, infoLine }) => {
  const [css, theme] = useStyletron();

  return (
    <InfoWrap>
      <span className={css({ lineHeight: '44px' })}>
        <span className={css({ verticalAlign: 'text-top' })}>{logo}</span>
      </span>
      <Info>
        <MonoParagraphLarge>{mainMsg}</MonoParagraphLarge>
        <span
          className={css({
            ...theme.typography.ParagraphLarge,
            paddingBottom: '17px',
            fontWeight: '300',
            color: theme.colors.mono600
          })}
        >
          {infoLine}
        </span>
      </Info>
    </InfoWrap>
  );
};

export default InfoMsg;
