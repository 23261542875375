/* eslint-disable no-nested-ternary */
import React from 'react';
import { RadioGroup, Radio, ALIGN } from 'baseui/radio';
import { styled, useStyletron } from 'baseui';
import PrimaryUserAvatar from '../../../../../components/icons/PrimaryUserAvatar';

const LogoInfoWrap = styled('div', () => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
}));

const DefaultUsername = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '25px'
}));

const DefaultUserNameOption = ({ username }) => {
  const [css, theme] = useStyletron();
  return (
    <LogoInfoWrap>
      <DefaultUsername>
        <span
          className={css({
            ...theme.typography.MonoParagraphLarge,
            color: theme.colors.contentAccent,
            paddingBottom: '5px'
          })}
        >
          {username}
        </span>

        <span
          className={css({
            ...theme.typography.ParagraphMedium,
            color: theme.colors.mono500
          })}
        >
          Default
        </span>
      </DefaultUsername>
      <PrimaryUserAvatar
        className={css({
          marginTop: '10px'
        })}
      />
    </LogoInfoWrap>
  );
};

const UserNameOption = ({ username }) => {
  const [css, theme] = useStyletron();
  return (
    <LogoInfoWrap>
      <span
        className={css({
          ...theme.typography.MonoParagraphLarge,
          color: theme.colors.contentAccent,
          marginLeft: '25px'
        })}
      >
        {username}
      </span>
      <PrimaryUserAvatar />
    </LogoInfoWrap>
  );
};

const UsernameFrame = ({ usernamesList, option, setOption }) => {
  const [css, theme] = useStyletron();
  const CheckedOption = ({ name }) =>
    name === usernamesList[0] ? (
      <DefaultUserNameOption username={name} />
    ) : (
      <UserNameOption username={name} />
    );
  return (
    <RadioGroup
      value={option}
      onChange={(e) => setOption(e.currentTarget.value)}
      align={ALIGN.vertical}
      labelPlacement='right'
      data-dd-action-name='Select Username'
    >
      {usernamesList.map((name) => (
        <Radio
          overrides={{
            Root: {
              style: ({ $theme, $checked }) => ({
                backgroundColor: $checked
                  ? $theme.colors.accent50
                  : $theme.colors.mono100,
                width: '93%',
                padding: '0 24px',
                borderRadius: '6px',
                height: $checked ? '80px' : '56px',
                marginLeft: '10px',
                marginTop: '10px'
              })
            },
            RadioMarkOuter: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.contentAccent
              })
            },
            Label: ({ $checked }) => (
              <div className={css({ width: '97%' })}>
                {!$checked ? (
                  <span
                    className={css({
                      ...theme.typography.MonoParagraphLarge,
                      marginLeft: '25px'
                    })}
                  >
                    {name}
                  </span>
                ) : (
                  <CheckedOption name={name} />
                )}
              </div>
            )
          }}
          key={name}
          value={name}
        >
          {name}
        </Radio>
      ))}
    </RadioGroup>
  );
};

export default UsernameFrame;
