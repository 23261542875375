import React from 'react';
import { Icon, IconProps } from 'baseui/icon';

const AlertIcon = ({ size, color, ...props }: IconProps) => {
  const { title = 'AlertIcon', ...restProps } = props;
  return (
    <Icon title={title} size={size} color={color} {...restProps}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 6.5C12.5523 6.5 13 6.94772 13 7.5V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V7.5C11 6.94772 11.4477 6.5 12 6.5ZM12 18C12.8284 18 13.5 17.3284 13.5 16.5C13.5 15.6716 12.8284 15 12 15C11.1716 15 10.5 15.6716 10.5 16.5C10.5 17.3284 11.1716 18 12 18Z'
        fill={color}
      />
    </Icon>
  );
};

export default AlertIcon;
