import React from 'react';
import { Skeleton } from 'baseui/skeleton';

const HeadingSkeleton = () => (
  <Skeleton
    rows={1}
    width='100px'
    animation
    overrides={{
      Root: {
        props: { 'data-testid': 'heading-skeleton' }
      },
      Row: {
        style: {
          height: '26px',
          marginBottom: '36px'
        }
      }
    }}
  />
);

export default HeadingSkeleton;
