import {
  HARDWARE_ITEMS,
  DEFAULT_HARDWARE_CHOICE,
  OPT_OUT_CHOICE
} from 'newHirePortal/newHire/util/hardwareContants';

// convert to bool
const sanitizeShippingPayload = (shippingAddress) => ({
  ...shippingAddress,
  wfhKit: shippingAddress.wfhKit === 'true'
});

const sanitizeSelectedHardwarePayload = (selectedHardware) => {
  const { laptop, specialRequirements, ...filteredSelectedHardwares } =
    selectedHardware;

  // Formatting the selectedHardwares so as to match the payload structure for this POST API
  const devices = Object.entries(filteredSelectedHardwares)
    .map(([deviceType, device]) => {
      if (!device) return null;

      if (!device.sq_part_number) {
        return null;
      }

      // This check skips the keyboard device if there is keyboard data available for the selected laptop other than the 'default keyboard choice' or 'Do not want'.
      // It allows either the default keyboard device when there is no keyboard data available for
      // the selected laptop, or the 'Do not want' choice when the user does not select a keyboard,
      // in order to send the corresponding error message.

      if (
        device.sq_part_number !== DEFAULT_HARDWARE_CHOICE.SQ_PART_NUMBER &&
        device.sq_part_number !== OPT_OUT_CHOICE.SQ_PART_NUMBER &&
        deviceType === HARDWARE_ITEMS.KEYBOARD
      ) {
        return null;
      }

      let type;
      let hardwareName;

      if (deviceType === 'laptopLanguage') {
        type = HARDWARE_ITEMS.LAPTOP;
        hardwareName = device.device_name;
      } else if (deviceType === 'keyboardLanguage') {
        type = HARDWARE_ITEMS.KEYBOARD;
        hardwareName = device.device_name;
      } else if (deviceType === HARDWARE_ITEMS.KEYBOARD) {
        type = HARDWARE_ITEMS.KEYBOARD;
        hardwareName = device.name;
      } else {
        type = deviceType;
        hardwareName = device.name;
      }

      return {
        device_name: hardwareName,
        device_sqpn: device.sq_part_number,
        device_type: type
      };
    })
    .filter((entry) => entry);

  return { devices, notes: specialRequirements };
};

export { sanitizeShippingPayload, sanitizeSelectedHardwarePayload };
