import { RefObject } from 'react';
import { useIsWidthOverflow } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/hooks/useIsWidthOverflow/useIsWidthOverflow';
import { useScrollDirection } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/hooks/useScrollDirection/useScrollDirection';
import { SCROLL_DIRECTION } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/constants';

export const useScrollFadeEffects = (scrollRef: RefObject<HTMLElement>) => {
  const isWidthOverflow = useIsWidthOverflow(scrollRef);

  const scrollDirection = useScrollDirection(scrollRef, isWidthOverflow);

  const isFadeLeft =
    isWidthOverflow &&
    (scrollDirection === SCROLL_DIRECTION.right ||
      scrollDirection === SCROLL_DIRECTION.inbetween);

  const isFadeRight =
    isWidthOverflow &&
    (scrollDirection === SCROLL_DIRECTION.left ||
      scrollDirection === SCROLL_DIRECTION.inbetween);

  return { isFadeLeft, isFadeRight };
};
