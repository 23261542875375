import React from 'react';
import {
  DEFAULT_HARDWARE_CHOICE,
  MONITOR_AND_ADAPTER,
  OPT_OUT_CHOICE
} from 'newHirePortal/newHire/util/hardwareContants';
import HardwareTitleWithIcon from '../HardwareTitleWithIcon';
import { OuterWapper } from '../CommonStyle';
import MonitorIcon from '../../../../../../components/icons/Monitor';

const MonitorDetails = ({ monitorData }) => (
  <>
    {monitorData &&
    monitorData.sq_part_number !== OPT_OUT_CHOICE.SQ_PART_NUMBER ? (
      <OuterWapper>
        <HardwareTitleWithIcon
          dataTestId='monitor-component'
          icon={<MonitorIcon size={20} />}
          deviceCategory={MONITOR_AND_ADAPTER}
          selectedDeviceName={
            monitorData?.device_sqpn === DEFAULT_HARDWARE_CHOICE.SQ_PART_NUMBER
              ? DEFAULT_HARDWARE_CHOICE.DEFAULT_VALUE
              : monitorData.display_name
          }
        />
      </OuterWapper>
    ) : null}
  </>
);
export default MonitorDetails;
