import React from 'react';
import { styled } from 'baseui';
import TitleInfo from './TitleInfo';
import IconsWithBg from '../../../../../components/icons/IconsWithBg';

const LogoInfoWrap = styled('div', () => ({
  display: 'flex',
  marginBottom: '20px',
  alignItems: 'flex-start'
}));

const HardwareTitle = ({ icon, deviceName, infoText = [] }) => (
  <LogoInfoWrap>
    <IconsWithBg logo={icon} />
    <TitleInfo
      heading={deviceName}
      subText={infoText.map((line) => (
        <div key={line}>{line}</div>
      ))}
      marginLeft='15px'
    />
  </LogoInfoWrap>
);

export default HardwareTitle;
