import React from 'react';
import { styled } from 'baseui';
import { datadogRum } from '@datadog/browser-rum';

const Highlight = styled('a', ({ $theme, $marginLeft = '4px' }) => ({
  color: $theme.colors.contentAccent,
  textDecoration: 'inherit',
  marginLeft: $marginLeft
}));

const EmailSupport = ({ email, marginLeft }) => (
  <Highlight
    href={`mailto: ${email}`}
    onClick={() => {
      datadogRum.addAction(`click to send email to ${email}`);
    }}
    $marginLeft={marginLeft}
  >
    {email}
  </Highlight>
);

export default EmailSupport;
