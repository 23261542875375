import React from 'react';
import { useStyletron } from 'baseui';
import { THEME_NAME } from 'newHirePortal/newHire/util/enums';
import SquareMessages from 'newHirePortal/newHire/containers/Dashboard/WeekOne/SquareMessages';
import CashMsgs from 'newHirePortal/newHire/containers/Dashboard/WeekOne//CashMsgs';
import SquareTidalMsgs from 'newHirePortal/newHire/containers/Dashboard/WeekOne//SquareTidalMsgs';
import BlockMessages from 'newHirePortal/newHire/containers/Dashboard/WeekOne//BlockMessages';

const WeekOneMsgs = () => {
  const [css, theme] = useStyletron();
  let msgs;
  switch (theme.name) {
    case THEME_NAME.CASH:
      msgs = <CashMsgs />;
      break;
    case THEME_NAME.TIDAL:
      msgs = <SquareTidalMsgs />;
      break;
    case THEME_NAME.BLOCK:
      msgs = <BlockMessages />;
      break;
    default:
      msgs = <SquareMessages />;
  }

  return (
    <ul
      className={css({
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 0
      })}
    >
      {' '}
      {msgs}
    </ul>
  );
};

export default WeekOneMsgs;
