import { validate as validateEmail } from 'email-validator';
import {
  formatPhoneNumberIntl,
  isPossiblePhoneNumber
} from 'react-phone-number-input';
import PhoneField from './PhoneField';
import YesOrNoRadio, { DisplayMode } from './YesOrNoRadio';

export const WFH_KIT_ID = 'wfh-kit';

const FIELDS = [
  {
    id: 'address-line1',
    dataFieldName: 'addressLine1',
    placeholder: 'Address line 1: Street Address'
  },
  {
    id: 'address-line2',
    dataFieldName: 'addressLine2',
    placeholder: 'Address line 2: Apt, Suite, Unit, Building, Access Code',
    required: false
  },
  {
    id: 'postal-code',
    dataFieldName: 'postalCode',
    placeholder: 'Zip Code/Postal Code'
  },
  {
    id: 'address-level2',
    dataFieldName: 'city',
    placeholder: 'City'
  },
  {
    id: 'address-level1',
    dataFieldName: 'administrativeDistrictLevel1',
    placeholder: 'State/Province'
  },
  {
    id: 'country',
    dataFieldName: 'countryCode',
    placeholder: 'Country'
  },
  {
    id: 'phone-number',
    dataFieldName: 'phone',
    placeholder: 'Phone',
    CustomInput: PhoneField,
    validator: isPossiblePhoneNumber,
    formatter: formatPhoneNumberIntl,
    errorMessage: 'Please input a valid phone number with country code',
    tooltipMessage:
      'Please enter the phone number we can use to reach you with any urgent questions.'
  },
  {
    id: 'email',
    dataFieldName: 'email',
    placeholder: 'Email address',
    validator: validateEmail,
    errorMessage: 'Please input a valid email address',
    tooltipMessage:
      'Please enter your secondary email we can use to reach you if needed.'
  },
  {
    id: 'comments',
    dataFieldName: 'otherComments',
    placeholder: 'Other comments (255 characters limit)',
    tooltipMessage:
      'Please enter any other relevant info regarding your shipping such as moving ' +
      'places within the country, vacation holds, dates, new addresses, ' +
      'etc that might help our IT team.',
    skipConfirm: true,
    skipPresentation: true,
    required: false,
    maxLength: 255
  },
  {
    id: WFH_KIT_ID,
    CustomInput: YesOrNoRadio,
    dataFieldName: 'wfhKit',
    radioTitle:
      'Would you like to receive a WFH (Work From Home) Kit which includes a monitor, keyboard and mouse?',
    skipConfirm: true,
    presentationComponent: DisplayMode,
    formatter: (value) => value.toString()
  }
];
export default FIELDS;
