import React from 'react';
import { styled } from 'baseui';

const CashImgWrap = ({
  position,
  right,
  left,
  top,
  height,
  width,
  zIndex,
  src,
  alt
}) => {
  const Wrapper = styled('div', () => ({
    background: 'transparent',
    zIndex: !zIndex ? '1' : zIndex,
    position,
    right,
    left,
    top
  }));
  return (
    <Wrapper>
      <img src={src} alt={alt} height={height} width={width} />
    </Wrapper>
  );
};

export default CashImgWrap;
