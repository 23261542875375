import React from 'react';
import { HeadingXLarge, ParagraphLarge } from 'baseui/typography';
import { StyledLink } from 'baseui/link';
import { withStyle, styled, useStyletron } from 'baseui';
import ModalComp from '../../components/ModalComp';
import { LABELS } from '../../util/coreMemMsg';

const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
  color: $theme.colors.emphasis_fill,
  textDecoration: 'none',
  cursor: 'pointer',
  minWidth: '70px'
}));

const LinkWrapper = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline'
}));

const MsgComp = ({
  Heading,
  ModalHeading,
  ModalMessage,
  SmallMsg,
  ModVideo,
  ModImg,
  MemName,
  designation,
  ModHeight
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [css] = useStyletron();

  return (
    <>
      <LinkWrapper>
        <HeadingXLarge
          className={css({
            marginTop: '5px',
            marginRight: '2px'
          })}
        >
          {Heading}
        </HeadingXLarge>
        <MyStyledLink
          onClick={() => setIsOpen(true)}
          data-dd-action-name={ModalHeading}
        >
          {LABELS.SHOW_ALL}
        </MyStyledLink>
      </LinkWrapper>
      <ModalComp
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        ModalHeading={ModalHeading}
        ModalMessage={ModalMessage}
        ModVideo={ModVideo}
        ModImg={ModImg}
        MemName={MemName}
        designation={designation}
        ModHeight={ModHeight}
      />
      <div>
        <ParagraphLarge
          className={css({
            marginTop: '5px',
            marginBottom: '5px'
          })}
        >
          {SmallMsg}
        </ParagraphLarge>
      </div>
    </>
  );
};

export default MsgComp;
