import { styled } from 'baseui';

const YourFirstWeekContentWrapper = styled('div', ({ $theme }) => ({
  display: 'grid',

  [$theme.mediaQuery.small]: {
    gridTemplateColumns: '1fr',
    gridRowGap: $theme.sizing.scale900
  },
  [$theme.mediaQuery.medium]: {
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: $theme.sizing.scale1400,
    gridColumnGap: '42px'
  },
  [$theme.mediaQuery.large]: {
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '300px'
  }
}));

export { YourFirstWeekContentWrapper };
