import { overrides as defaultOverride } from 'newHirePortalRedesign/newHire/config/themes/default';
import { THEME_NAME } from 'newHirePortalRedesign/newHire/util/constants';
import CashAppLogo from 'assets/images/cashAppIcon.png';
import { Font } from 'baseui/theme';

const headingFont = 'Monument Extended, Helvetica, Arial, sans-serif';

const primitives: { primaryFontFamily: string } = {
  primaryFontFamily: 'CashMarket, Helvetica, Arial, sans-serif'
};

const cashTypography: { [key: string]: Font } = {
  HeadingXSmall: {
    ...defaultOverride.typography.HeadingXSmall,
    fontFamily: headingFont
  },

  HeadingSmall: {
    ...defaultOverride.typography.HeadingSmall,
    fontFamily: headingFont
  },
  HeadingMedium: {
    ...defaultOverride.typography.HeadingMedium,
    fontFamily: headingFont
  },

  HeadingLarge: {
    ...defaultOverride.typography.HeadingLarge,
    fontFamily: headingFont
  },
  HeadingXLarge: {
    ...defaultOverride.typography.HeadingXLarge,
    fontFamily: headingFont
  },

  HeadingXXLarge: {
    ...defaultOverride.typography.HeadingXXLarge,
    fontFamily: headingFont
  },

  ParagraphXSmall: {
    ...defaultOverride.typography.ParagraphXSmall,
    fontFamily: primitives.primaryFontFamily
  },
  ParagraphSmall: {
    ...defaultOverride.typography.ParagraphSmall,
    fontFamily: primitives.primaryFontFamily
  },
  ParagraphMedium: {
    ...defaultOverride.typography.ParagraphMedium,
    fontFamily: primitives.primaryFontFamily
  },
  ParagraphLarge: {
    ...defaultOverride.typography.ParagraphLarge,
    fontFamily: primitives.primaryFontFamily
  },

  LabelXSmall: {
    ...defaultOverride.typography.LabelXSmall,
    fontFamily: primitives.primaryFontFamily
  },

  LabelSmall: {
    ...defaultOverride.typography.LabelSmall,
    fontFamily: primitives.primaryFontFamily
  },

  LabelMedium: {
    ...defaultOverride.typography.LabelMedium,
    fontFamily: primitives.primaryFontFamily
  },
  LabelLarge: {
    ...defaultOverride.typography.LabelLarge,
    fontFamily: primitives.primaryFontFamily
  }
};

const baseColors: { [key: string]: string } = {
  // TODO: Need to Move the common colors to default.js file and keep only theme specific color
  accent50: '#B141FF',
  positive400: '#00B843',
  buttonPrimaryFill: '#00D64F',
  buttonPrimaryHover: '#00BD46',
  warning: '#F46E38',
  mono400: '#999999',
  mono500: '#8C8C8C',
  accent600: '#285EEE',
  contentAccent: '#00B728', // Cash Accent Green - Square Emphasis Text
  backgroundAccent: '#00D633',
  accent100: '#E5FFEE' // Square Emphasis Emphasis/40
};

const colors = {
  ...defaultOverride.colors,
  ...baseColors
};

const lighting: { [key: string]: string } = {
  shadow400: 'rgba(0, 214, 79, 0.2)',
  shadow500:
    '0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)'
};

const otherProperties = {
  favicon: CashAppLogo,
  displayName: 'Cash',
  displayAppName: 'Cash App',
  supportEmail: 'askpeople@squareup.com'
};

const overrides = {
  ...defaultOverride,
  name: THEME_NAME.CASH,
  colors,
  lighting,
  ...otherProperties,
  typography: cashTypography,
  fontFamily: primitives
};

const config = { primitives, overrides };

export { primitives, overrides };

export default config;
