import React from 'react';
import { styled, useStyletron } from 'baseui';
import { Textarea } from 'baseui/textarea';
import { useDispatch, useSelector } from 'react-redux';
import HardwareTitle from '../HardwareTitle';
import PeripheralsIcon from '../../../../../../components/icons/Peripherals';
import { allDirectionStyle } from '../../../../util/styleHelpers';
import { addSpecialRequirements } from '../../../../store/hardware/slice';

const Wrapper = styled('div', ({ $theme }) => ({
  border: `1px solid ${$theme.colors.mono300}`,
  ':hover': { border: `2px solid ${$theme.colors.backgroundAccent}` },
  borderRadius: '8px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px'
}));

const SpecialRequirements = () => {
  const [, theme] = useStyletron();
  const dispatch = useDispatch();

  const specialRequirements = useSelector(
    (state) => state.hardwareSelection.selectedHardware.specialRequirements
  );

  return (
    <Wrapper data-testid='special-requirements'>
      <HardwareTitle
        icon={<PeripheralsIcon size={30} />}
        deviceName='Special Requirements (optional)'
        infoText={[
          'Let us know about your special hardware requirements here.'
        ]}
      />

      <Textarea
        value={specialRequirements}
        onChange={(e) => dispatch(addSpecialRequirements(e.target.value))}
        placeholder='Special Requirements'
        clearOnEscape
        aria-label='special-requirements-textarea'
        maxLength={255}
        overrides={{
          InputContainer: {
            style: ({ $theme }) => ({ backgroundColor: $theme.colors.mono100 })
          },
          Root: {
            style: ({ $theme, $isFocused }) => ({
              ...allDirectionStyle({
                radius: '6px',
                prop: 'border',
                color: $isFocused ? $theme.colors.backgroundAccent : null
              }),
              height: '120px',
              width: '60%',
              marginLeft: '60px',
              marginBottom: theme.sizing.scale950
            })
          }
        }}
      />
    </Wrapper>
  );
};

export default SpecialRequirements;
