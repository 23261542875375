import React from 'react';
import { useStyletron, styled } from 'baseui';
import Alert from 'baseui/icon/alert';
import Clock from '../../../components/icons/Clock';

const IconWrap = styled('div', () => ({
  display: 'flex'
}));

const ExpiredIcon = () => {
  const [css, theme] = useStyletron();
  return (
    <IconWrap>
      <Clock />
      <Alert
        className={css({
          marginLeft: '-30px',
          marginTop: '-10px'
        })}
        size={34}
        color={theme.colors.backgroundWarning}
      />
    </IconWrap>
  );
};

export default ExpiredIcon;
