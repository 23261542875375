import React from 'react';
import { styled, useStyletron } from 'baseui';

const IconsWithBg = ({
  width = '48px',
  height = '48px',
  logo,
  size,
  ...props
}) => {
  const [css, theme] = useStyletron();
  const IconWrap = styled('div', () => ({
    background: theme.colors.backgroundAccent,
    borderRadius: '9px',
    width,
    height
  }));
  return (
    <IconWrap>
      <span
        className={css({
          float: 'left',
          alignItems: 'center',
          padding: '8px'
        })}
        size={size}
        {...props}
      >
        {logo}
      </span>
    </IconWrap>
  );
};
export default IconsWithBg;
