import { styled } from 'baseui';

export const ColumnContainer = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const RowContainer = styled('div', () => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '34px',
  marginLeft: '8px'
}));
