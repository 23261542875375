import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HeadingXXLarge } from 'baseui/typography';
import { PLACEMENT } from 'baseui/helper';
import { ToasterContainer, KIND } from 'baseui/toast';
import { useSelector } from 'react-redux';
import ShippingPage from './Shipping/Page';
import BreadCrumb from '../BreadCrumb';
import DismissToastClose from '../../components/DismissToastClose';
import ProfilePage from './ProfilePage/Page';
import UsernamePage from './Username/Page';
import HardwareShippingPage from './HardwareShipping/Page';
import { allDirectionStyle } from '../../util/styleHelpers';
import {
  getPathByName,
  PROFILE_PAGE_TITLE,
  SHIPPING_PAGE_TITLE,
  HARDWARE_SHIPPING_PAGE_TITLE,
  USERNAME_PAGE_TITLE
} from '../../util/routes';
import {
  isUsernameSelectionEnabled,
  isHardwareSelectionEnabled
} from '../../store/user/selectors';

const ToastWrapper = ({ children }) => (
  <ToasterContainer
    placement={PLACEMENT.bottom}
    autoHideDuration={5000} // milliseconds
    overrides={{
      ToastCloseIcon: DismissToastClose,
      ToastBody: {
        style: ({ $kind, $theme }) => {
          const styles = {
            ...allDirectionStyle({
              radius: '7px',
              prop: 'border'
            }),
            width: 'auto',
            minWidth: '65px',
            paddingTop: '0px',
            paddingBottom: '0px'
          };
          if ($kind === KIND.positive) {
            styles.backgroundColor = $theme.colors.contentPrimary;
          }
          return styles;
        }
      }
    }}
  >
    {children}
  </ToasterContainer>
);

const YourInfo = () => (
  <ToastWrapper>
    <HeadingXXLarge marginTop='24px' marginBottom='40px'>
      {' '}
      Your Information{' '}
    </HeadingXXLarge>

    <BreadCrumb />
    <Switch>
      {useSelector(isHardwareSelectionEnabled) ? (
        <Route path={getPathByName(HARDWARE_SHIPPING_PAGE_TITLE)}>
          <HardwareShippingPage />
        </Route>
      ) : (
        <Route path={getPathByName(SHIPPING_PAGE_TITLE)}>
          <ShippingPage />
        </Route>
      )}
      <Route path={getPathByName(PROFILE_PAGE_TITLE)}>
        <ProfilePage />
      </Route>

      {useSelector(isUsernameSelectionEnabled) ? (
        <Route path={getPathByName(USERNAME_PAGE_TITLE)}>
          {' '}
          <UsernamePage />{' '}
        </Route>
      ) : null}

      <Redirect to='' />
    </Switch>
  </ToastWrapper>
);

export default YourInfo;
