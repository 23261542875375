import React from 'react';

const BookSpiralIcon = () => (
  <svg
    viewBox='0 0 14 16'
    width='14'
    height='16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.00004 0.666626V15.3333M1.33337 3.99996H3.33337M1.33337 6.66663H3.33337M1.33337 9.33329H3.33337M1.33337 12H3.33337M2.66671 0.666626H13.3334V15.3333H2.66671V0.666626Z'
      stroke='black'
      strokeWidth='1.33333'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
  </svg>
);

export default BookSpiralIcon;
