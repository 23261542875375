import React from 'react';
import { useStyletron } from 'baseui';
import { HeadingXSmall, MonoParagraphMedium } from 'baseui/typography';

const SwagSection = () => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        maxWidth: '100%',
        justifyContent: 'space-evenly',
        gap: theme.sizing.scale300,
        display: 'flex',
        flexDirection: 'column',
        [theme.mediaQuery.medium]: {
          maxWidth: '65%'
        }
      })}
    >
      <HeadingXSmall
        className={css({
          marginTop: theme.sizing.scale400,
          marginBottom: theme.sizing.scale400,
          marginLeft: 0,
          marginRight: 0
        })}
      >
        Opt-in for New Hire Welcome Swag:
      </HeadingXSmall>
      <MonoParagraphMedium
        className={css({ fontWeight: 400, lineHeight: '20px' })}
      >
        We’d like to welcome you to the team with some Cash App branded merch.
        If you’d like to opt-in for new hire welcome swag, please fill out{' '}
        <a
          href='https://www.teamcashapp.com/newhire-2024'
          target='_blank'
          rel='noreferrer'
          className={css({ color: '#00D64F', fontWeight: 700 })}
        >
          this form
        </a>
        .
      </MonoParagraphMedium>
      <MonoParagraphMedium
        className={css({
          fontWeight: 400,
          lineHeight: '20px'
        })}
      >
        Password: <b>That&#39;s_Money!</b>
      </MonoParagraphMedium>
    </div>
  );
};

export default SwagSection;
