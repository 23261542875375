import { BU_NAME } from 'newHirePortalRedesign/newHire/util/constants';
import { CoreMemberType } from 'newHirePortalRedesign/newHire/features/banner/store/selectors/selectors';
import brianSignature from 'newHirePortalRedesign/newHire/features/banner/assets/brianSignature.png';
import { ParagraphSmall } from 'baseui/typography';
import React from 'react';

export const JESSE_DOROGUSKER = {
  name: 'Jesse Dorogusker',
  id: '000375'
};

export interface CoreMemberMessage {
  name: string;
  id: string;
  message:  React.ReactNode | string;
  buName: string;
  signatureImgSrc: string;
}

export const CORE_MEM_MSG: CoreMemberMessage[] = [
  {
    name: 'Steve Lee',
    id: '009436',
    message: 'Welcome to Block!\nBest,',
    buName: BU_NAME.BLOCK,
    signatureImgSrc: brianSignature, // TODO: Update with bu lead correct signature
  },
  {
    name: 'Brian Grassadonia',
    id: '000134',
    buName: BU_NAME.CASH,
    signatureImgSrc: brianSignature, // TODO: Update with bu lead correct signature
    message: (
      (
        <ParagraphSmall>
        Welcome to Cash. I’m thrilled that you’ve decided to join our team. No
        matter what your role is, our mission is the same: redefine the world’s
        relationship with money by making it more relatable, instantly available
        and universally accessible. We do this when we
        <i>
          {' '}
          Insist on Focus, Compete on Creativity, Feel it, Climb Walls and Fight
          Complacency
        </i>
        . That’s a lot of words, but I think you’ll find that these principles
        come naturally to you. I’m looking forward to seeing the positive impact
        you’ll make and the incredible things we’ll create together.
        <ParagraphSmall>Best,</ParagraphSmall>
      </ParagraphSmall>
      )
    ),
  },
  {
    name: 'Amrita Ahuja',
    id: '008623',
    buName: BU_NAME.BLOCK,
    signatureImgSrc: brianSignature, // TODO: Update with bu lead correct signature
    message: (
      (
        <ParagraphSmall>
          On behalf of the Guidance organization, welcome to Block!
          We are so excited to have you join our team. Our mission is to enable bold growth at scale, through functional excellence, operational partnership and strategic thoughtfulness.
          We do this by putting people first, striving for agility, enabling the organization to take principled risks, and driving new growth opportunities.
          We are excited to have you join the team to help us push this mission forward!
          I look forward to working with you and am around if there is anything I can help you with.
        <ParagraphSmall>Best,</ParagraphSmall>
      </ParagraphSmall>
      )
    ),
  },
  {
    name: JESSE_DOROGUSKER.name,
    id: JESSE_DOROGUSKER.id,
    buName: BU_NAME.BLOCK,
    message:
      (
        (
          <ParagraphSmall>
            Welcome to Block! We’re thrilled that you have joined the Hardware team.
            As a refresher, check out what we offer to customers today (and imagine how you will have impact on this in the near future):https://squareup.com/shop/. 
            In your first days, you’ll attend our interactive Building Block onboarding, which focuses on our Block products and ecosystem.
            It will give you a strong foundation before you dive in. Then you can get started on learning how we do things in Hardware.
            We"re looking forward to getting you involved fast.
            Please be in touch with me any time - I"m easy to reach on slack (#jessedoro).
            I look forward to working with you, and welcome to the team
            <ParagraphSmall>Best,</ParagraphSmall>
        </ParagraphSmall>
        )
      ),
      signatureImgSrc: brianSignature, // TODO: Update with bu lead correct signature
  },
  {
    name: JESSE_DOROGUSKER.name,
    id: JESSE_DOROGUSKER.id,
    buName: BU_NAME.TIDAL,
    message: (
        (
          <ParagraphSmall>
            On behalf of the TIDAL team, welcome! We’re thrilled to have you join our team.
            In your first few days, you’ll attend Track One, new hire orientation, to learn more
            about our organization’s history and ecosystem. It will give you a strong foundation before you
            dive into all things unique to TIDAL and your role. We’re looking forward to getting you up to speed
            quickly with a lot of great resources and people to help you along the way.

            <ParagraphSmall>Please be in touch with me any time - I’m easy to reach on slack (#jessedoro).</ParagraphSmall>
            <ParagraphSmall>I look forward to working with you, and welcome to the team.</ParagraphSmall>
            <ParagraphSmall>Best,</ParagraphSmall>
        </ParagraphSmall>
        )
      ),
      signatureImgSrc: brianSignature, // TODO: Update with bu lead correct signature
  }
];

export const getCoreMemberMsg = (member, buName) => {
  const byId = CORE_MEM_MSG.find(
    (msg) => msg.id === member.employee_id && buName === msg.buName
  );
  if (byId) return byId;

  const fullName = `${member.first_name} ${member.last_name}`;
  return CORE_MEM_MSG.find(
    (msg) =>
      fullName.toLowerCase() === msg.name.toLowerCase() && buName === msg.buName
  );
};
