import { MonoParagraphLarge, MonoParagraphMedium } from 'baseui/typography';
import React from 'react';
import { Cell, ALIGNMENT } from 'baseui/layout-grid';
import { styled } from 'baseui';
import MainLogo from '../components/MainLogo';

const NavWrapper = styled('div', ({ $theme }) => ({
  boxShadow: $theme.shadow.shadowStatic,
  height: $theme.sizing.scale1400,
  position: 'relative',
  justifyContent: 'space-between',
  paddingRight: $theme.sizing.scale200,
  display: 'flex'
}));

const MainNav = () => (
  <NavWrapper>
    <Cell
      span={1.5}
      align={ALIGNMENT.center}
      overrides={{
        Cell: { style: () => ({ textAlign: 'center' }) }
      }}
    >
      <MonoParagraphLarge>New Hire Portal</MonoParagraphLarge>
    </Cell>
    <Cell span={1} align={ALIGNMENT.center}>
      <MainLogo size={26} />
    </Cell>
    <Cell
      span={1}
      align={ALIGNMENT.center}
      overrides={{
        Cell: { style: () => ({ textAlign: 'right' }) }
      }}
    >
      <MonoParagraphMedium>Block Inc.</MonoParagraphMedium>
    </Cell>
  </NavWrapper>
);

export default MainNav;
