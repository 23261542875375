import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { MonoParagraphLarge } from 'baseui/typography';
import ConfirmModal from '../ConfirmModal';
import {
  setSubmitError,
  setIsModalOpen
} from '../../../store/shippingAddress/slice';
import shippingFormFields from './formFields';
import { postShippingAddress } from '../../../store/shippingAddress/thunk';
import { shippingAddressSubmitted } from '../../../store/user/slice';
import toastTrigger from '../toastTrigger';
import { isModalOpenOnFormSubmit } from '../../../store/shippingAddress/selectors';

const ModelContent = ({ shippingData }) => (
  <>
    {shippingFormFields
      .filter(
        (field) =>
          shippingData[field.dataFieldName].length > 0 && !field.skipConfirm
      )
      .map((field) => (
        <MonoParagraphLarge $style={{ margin: '2px' }} key={field.id}>
          {field.formatter
            ? field.formatter(shippingData[field.dataFieldName])
            : shippingData[field.dataFieldName]}
        </MonoParagraphLarge>
      ))}
  </>
);

const ShippingConfirmModal = () => {
  const dispatch = useDispatch();
  const postStatus = useSelector((state) => state.shippingAddress.postStatus);
  const isOpenModal = useSelector(isModalOpenOnFormSubmit);
  const shippingData = useSelector(
    (state) => state.shippingAddress.shippingInput
  );

  const onConfirm = () => {
    const payload = {
      shippingAddress: shippingData
    };
    dispatch(postShippingAddress(payload))
      .then(unwrapResult) // use unwrapResult to help throw the original error
      .then(() => {
        dispatch(setIsModalOpen(false));
        dispatch(shippingAddressSubmitted());
        toastTrigger({ success: true });
      })
      .catch((e) => {
        // calling toast here to avoid rendering during state transition warning
        toastTrigger({
          success: false,
          failureMsg: 'Please Verify the address is complete and try again.'
        });
        dispatch(setSubmitError(e.message));
        dispatch(setIsModalOpen(false));
      });
  };

  return (
    <ConfirmModal
      isOpen={isOpenModal}
      onClose={() => dispatch(setIsModalOpen(false))}
      onConfirm={onConfirm}
      submitStatus={postStatus}
      modelContent={<ModelContent shippingData={shippingData} />}
      dataNameToConfirm='Shipping Address'
    />
  );
};

export default ShippingConfirmModal;
