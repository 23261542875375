import React from 'react';
import { StyleObject } from 'styletron-react';
import { Avatar } from 'baseui/avatar';
import { HeadingSmall } from 'baseui/typography';

interface CustomHeaderWithAvatarProps {
  overrideStyle?: StyleObject;
  imageSrc: string;
  fullName: string;
  leadName: string;
  imageSize?: string;
}

const CustomHeaderWithAvatar = ({
  overrideStyle = {},
  imageSrc,
  fullName,
  leadName,
  imageSize = '84px'
}: CustomHeaderWithAvatarProps) => (
  <>
    <Avatar
      name={leadName}
      size={imageSize}
      src={imageSrc}
      overrides={{
        Root: {
          style: overrideStyle
        }
      }}
    />
    <HeadingSmall>{`${fullName},`}</HeadingSmall>
  </>
);

export default CustomHeaderWithAvatar;
