import { useStyletron } from 'baseui';
import { MonoParagraphLarge } from 'baseui/typography';
import React from 'react';
import AlertTriangle from '../../../components/icons/AlertTriangle';

const FormErrorLine = ({ message }) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center'
      })}
    >
      <AlertTriangle
        overrides={{
          Svg: {
            style: {
              marginRight: theme.sizing.scale600,
              transform: 'translateY(-5%)'
            }
          }
        }}
      />
      <MonoParagraphLarge
        color={theme.colors.backgroundNegative}
        marginTop={0}
        marginBottom={0}
      >
        {message}
      </MonoParagraphLarge>
    </div>
  );
};

export default FormErrorLine;
