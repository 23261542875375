import React from 'react';
import { useSelector } from 'react-redux';
import { shippingTaskCompleted } from '../../../store/user/selectors';
import PresentationPage from './PresentationPage';
import FormSubmissionPage from './FormSubmissionPage';

const Page = () => {
  const completed = useSelector(shippingTaskCompleted);

  return completed ? <PresentationPage /> : <FormSubmissionPage />;
};

export default Page;
