import React from 'react';
import { useAppSelector } from 'newHirePortalRedesign/newHire/store/hooks';
import { useHistory } from 'react-router-dom';
import { FlexGridItem } from 'baseui/flex-grid';
import { useStyletron } from 'baseui';
import TitleWithDescription from 'newHirePortalRedesign/newHire/component/titleWithDescription/TitleWithDescription';
import CustomImageWithDescription from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/components/customImageWithDescription/CustomImageWithDescription';
import CustomButton from 'newHirePortalRedesign/newHire/component/customButton/CustomButton';
import CustomCard from 'newHirePortalRedesign/newHire/component/customCard/CustomCard';
import TaskSection from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/humanTasks/taskSection/TaskSection';
import {
  getResponsiveCardStyle,
  getResponsiveButtonStyle
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/humanTasks/Styles';
import { TASK_LABEL } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/constants';
import { useGetNormalizedHumanTasks } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/humanTasks/hooks/useGetNormalizedHumanTasks/useGetNormalizedHumanTasks';
import { itemProps } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/Styles';
import {
  humanTasks,
  selectTotalCardCount
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/store/selectors/selectors';

const HumanTasks = () => {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const humanTaskList = useAppSelector(humanTasks);
  const totalCardCount = useAppSelector(selectTotalCardCount);
  const hydratedHumanTasks = useGetNormalizedHumanTasks(humanTaskList);

  return (
    <>
      {hydratedHumanTasks.map((task) => (
        <FlexGridItem {...itemProps(totalCardCount)} key={task.title}>
          <CustomCard
            contentStyleOverride={getResponsiveCardStyle(theme)}
            bodySection={
              <>
                <CustomImageWithDescription
                  image={
                    <img
                      src={task.imgSrc}
                      alt={task.imgAltText}
                      className={css(task.imageStyle)}
                    />
                  }
                  text={task.dueLabel}
                >
                  <TitleWithDescription
                    title={task.title}
                    label={
                      task.optional ? TASK_LABEL.optional : TASK_LABEL.required
                    }
                    labelStyleOverride={
                      task.optional ? { color: theme.colors.mono500 } : {}
                    }
                    description={task.description}
                    marginLeft={[
                      theme.sizing.scale800,
                      theme.sizing.scale800,
                      0
                    ]}
                    marginRight={[
                      theme.sizing.scale800,
                      theme.sizing.scale800,
                      0
                    ]}
                    opacity={task.opacity}
                  />
                </CustomImageWithDescription>

                <TaskSection taskList={task.taskList} opacity={task.opacity} />
              </>
            }
            action={
              <CustomButton
                handleClick={() => history.push(task.targetLink)}
                baseButtonStyleOverride={getResponsiveButtonStyle(
                  theme,
                  task.isTaskCompleted
                )}
              >
                {task.isTaskCompleted ? 'View' : 'Get started'}
              </CustomButton>
            }
          />
        </FlexGridItem>
      ))}
    </>
  );
};

export default HumanTasks;
