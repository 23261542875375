import React from 'react';
import { styled } from 'baseui';
import { datadogRum } from '@datadog/browser-rum';

interface EmailSupportProps {
  email: string;
  marginLeft?: string;
}

const Highlight = styled<'a', { $marginLeft?: string }>(
  'a',
  ({ $theme, $marginLeft = $theme.sizing.scale100 }) => ({
    color: $theme.colors.contentAccent,
    textDecoration: 'inherit',
    marginLeft: $marginLeft
  })
);

const EmailSupport = ({ email, marginLeft }: EmailSupportProps) => (
  <Highlight
    href={`mailto: ${email}`}
    onClick={() => {
      datadogRum.addAction(`click to send email to ${email}`);
    }}
    $marginLeft={marginLeft}
  >
    {email}
  </Highlight>
);

export default EmailSupport;
