import React from 'react';
import { Navigation } from 'baseui/side-navigation';
import { useHistory, useLocation } from 'react-router-dom';
import { SIDE_NAV_ROUTES, sideNavSearch } from '../util/routes';
import CashPathIcon from '../components/CashPathIcon';
import cubeAngle from '../../../assets/images/cubeAngle.png';
import cubeSteps from '../../../assets/images/cubeSteps.png';

const borderStyle = (dir, theme) => {
  const name = dir.charAt(0).toUpperCase() + dir.slice(1);
  // const widthName = `border${name}Width`;
  const colorName = `border${name}Color`;
  const styleName = `border${name}Style`;
  const styles = {};

  styles[colorName] = theme.colors.accent50;
  styles[styleName] = 'solid';
  return styles;
};

const SideBar = () => {
  const history = useHistory();
  const location = useLocation();
  return (
    <>
      <CashPathIcon
        shipTop='150px'
        shipSrc={cubeAngle}
        shipAlt='cubeAngle'
        profileTop='400px'
        profileSrc={cubeSteps}
        profileAlt='cubeSteps'
      />
      <Navigation
        items={SIDE_NAV_ROUTES}
        activeItemId={sideNavSearch(location.pathname, true)}
        onChange={({ event, item }) => {
          // prevent page reload
          event.preventDefault();
          history.push(item.itemId);
        }}
        overrides={{
          NavItem: {
            style: ({ $theme, $active }) => {
              let styles = {
                backgroundImage: 'none',
                ...$theme.typography.paragraph_em_30
              };
              if ($active) {
                styles = {
                  ...styles,
                  borderRadius: '6px',
                  color: $theme.colors.emphasis_text,
                  backgroundColor: $theme.colors.accent50,
                  ...borderStyle('left', $theme),
                  ...borderStyle('right', $theme)
                };
              }
              return styles;
            }
          },
          Root: {
            style: ({ $theme }) => ({
              paddingRight: $theme.sizing.scale500,
              paddingTop: $theme.sizing.scale800,
              paddingLeft: $theme.sizing.scale500,
              paddingBottom: $theme.sizing.scale800,
              height: '100%',
              minHeight: '100vh',
              boxShadow: $theme.shadow.shadowStatic,
              marginRight: 0
            })
          }
        }}
      />
    </>
  );
};

export default SideBar;
