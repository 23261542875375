import React from 'react';
import { ParagraphLarge, HeadingXXLarge } from 'baseui/typography';
import { Modal, ModalBody, ModalFooter } from 'baseui/modal';
import { styled } from 'baseui';
import MonoButton from './MonoButton';
import { allDirectionStyle } from '../util/styleHelpers';

const VideoContainer = styled('div', ({ $theme }) => ({
  padding: $theme.sizing.scale800
}));

const ModalComp = ({
  ModalHeading,
  ModalMessage,
  ModVideo,
  isOpen,
  setIsOpen,
  ModImg,
  contentHeight,
  MemName,
  designation = ''
}) => {
  if (isOpen) {
    return (
      <>
        <Modal
          onClose={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          unstable_ModalBackdropScroll
          closeable={false}
          overrides={{
            Root: { style: { zIndex: 10 } },
            Dialog: {
              style: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '680px',
                ...allDirectionStyle({
                  radius: '12px',
                  prop: 'border'
                })
              }
            }
          }}
        >
          {ModImg}
          <HeadingXXLarge
            $style={{ margin: '24px 0 2px 0', textAlign: 'center' }}
          >
            {ModalHeading}
          </HeadingXXLarge>
          <ModalBody $style={{ height: contentHeight }}>
            {ModalMessage}
            <ParagraphLarge $style={{ marginBottom: '-60px' }}>
              {MemName}
              <br />
              {designation}
            </ParagraphLarge>
          </ModalBody>

          <VideoContainer>{ModVideo}</VideoContainer>

          <ModalFooter $style={{ marginBottom: '24px', marginTop: 0 }}>
            <MonoButton
              onClick={() => setIsOpen(!isOpen)}
              borderRadius='6px'
              label='Close'
              width='194px'
            />
          </ModalFooter>
        </Modal>
      </>
    );
  }

  return null;
};

export default ModalComp;
