import { useStyletron } from 'baseui';
import React from 'react';
import MainLogo from '../../components/MainLogo';

const MiscCommonFrame = ({ children }) => {
  const [css, theme] = useStyletron();

  return (
    <>
      <div
        className={css({
          width: '100%',
          height: theme.sizing.scale3200,
          borderBottom: '1px solid',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        })}
      >
        <MainLogo size={54} />
      </div>

      <div
        className={css({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        })}
      >
        {children}
      </div>
    </>
  );
};

export default MiscCommonFrame;
