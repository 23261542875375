import { styled } from 'baseui';

export const Wrapper = styled('div', ({ $theme }) => ({
  border: `1px solid ${$theme.colors.mono300}`,
  ':hover': { border: `2px solid ${$theme.colors.backgroundAccent}` },
  borderRadius: $theme.borders.radius300,
  padding: $theme.sizing.scale800,
  display: 'flex',
  flexDirection: 'column',
  marginTop: $theme.sizing.scale1000
}));

export const AllDevices = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  width: '90%',
  marginLeft: '6%',
  [$theme.mediaQuery.large]: {
    flexDirection: 'row'
  },
  [$theme.mediaQuery.medium]: {
    flexDirection: 'column'
  }
}));
