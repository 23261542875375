import { Cell, Grid } from 'baseui/layout-grid';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { getSelectedHardwares } from 'newHirePortal/newHire/store/selectedHardwares/thunk';
import NhpRedesignApp from 'newHirePortalRedesign/newHire/app/App';
import { ERROR_MESSAGE } from 'utils/constants';
import MainNav from './containers/MainNav';
import SideBar from './containers/SideBar';
import { ROUTES } from './util/routes';
import YourInfo from './containers/YourInfo/YourInfo';
import Dashboard from './containers/Dashboard/Dashboard';
import TaskList from './containers/TaskList/TaskList';
import {
  isHardwareSelectionEnabled,
  isHardwareControllerV2Enabled,
  hardwareTaskCompleted,
  isRedesignV1Enabled,
  isContingentWorkersEnabled,
  isContingentWorkerTypeAvailable
} from './store/user/selectors';
import { getHardwares } from './store/hardware/thunk';
import ErrorPage from './containers/MiscPages/ErrorPage';
import { mediaQueryStyles } from './util/styleHelpers';
import { API_STATUS } from './util/enums';

const CustomCell = ({ styles, children, ...props }) => (
  <Cell
    {...props}
    overrides={{
      Cell: {
        style: ({ $theme }) => ({
          ...mediaQueryStyles({
            $theme,
            mediaSizes: ['medium', 'large'],
            ...styles
          })
        })
      }
    }}
  >
    {children}
  </Cell>
);

const NewHireApp = () => (
  <Grid
    overrides={{
      Grid: {
        style: ({ $theme }) => ({
          ...mediaQueryStyles({
            $theme,
            mediaSizes: ['medium', 'large'],
            paddingLeft: '0px',
            paddingRight: '0px'
          })
        }),
        props: {
          'data-testid': 'main-grid'
        }
      }
    }}
  >
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <CustomCell
        span={[4, 8, 12]}
        styles={{
          paddingRight: 0,
          paddingLeft: 0
        }}
      >
        <MainNav />
      </CustomCell>

      <CustomCell span={[1, 2, 2]} styles={{ paddingLeft: 0 }}>
        <SideBar />
      </CustomCell>

      <Cell span={[2, 3, 7]}>
        <Switch>
          <Route path={ROUTES.map((route) => route.path)}>
            <Sentry.ErrorBoundary fallback={<ErrorPage />} key={ROUTES[0].path}>
              <YourInfo />
            </Sentry.ErrorBoundary>
          </Route>
          <Route path='*'>
            <Sentry.ErrorBoundary fallback={<ErrorPage />} key='*'>
              <Dashboard />
            </Sentry.ErrorBoundary>
          </Route>
        </Switch>
      </Cell>

      <CustomCell span={[1, 3, 3]} styles={{ paddingRight: 0 }}>
        <TaskList />
      </CustomCell>
    </Sentry.ErrorBoundary>
  </Grid>
);
const Main = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const hardwareLoadStatus = useSelector(
    (state) => state.hardwareSelection.loadStatus
  );
  const isHardwareTaskcompleted = useSelector(hardwareTaskCompleted);
  const isHardwareSectionEnabled = useSelector(isHardwareSelectionEnabled);
  const isUsingHardwareControllerV2 = useSelector(
    isHardwareControllerV2Enabled
  );
  const contingentWorkersFeatureFlagValue = useSelector(
    isContingentWorkersEnabled
  );
  const isContingentWorkerTypePresent = useSelector(
    isContingentWorkerTypeAvailable
  );
  const selectedHardwareLoadStatus = useSelector(
    (state) => state.selectedHardwares.loadStatus
  );
  const hasRedesignV1Enabled = useSelector(isRedesignV1Enabled);
  const isUserContingentWorkerTypeWhenFFDisabled =
    contingentWorkersFeatureFlagValue === false &&
    isContingentWorkerTypePresent;

  useEffect(() => {
    if (isHardwareSectionEnabled) {
      if (
        isHardwareTaskcompleted &&
        selectedHardwareLoadStatus === API_STATUS.IDLE
      ) {
        dispatch(getSelectedHardwares());
      } else if (hardwareLoadStatus === API_STATUS.IDLE) {
        dispatch(
          getHardwares({
            hardwareControllerV2Enabled: isUsingHardwareControllerV2
          })
        );
      }
    }
  }, []);

  if (isUserContingentWorkerTypeWhenFFDisabled) {
    return (
      <ErrorPage
        errorTitle={ERROR_MESSAGE.LINK_NOT_AVAILABLE_TITLE}
        errorMsg={ERROR_MESSAGE.LINK_NOT_AVAILABLE_MSG}
      />
    );
  }

  if (hasRedesignV1Enabled)
    return <NhpRedesignApp humanTasks={userData.human_tasks} />;

  return <NewHireApp />;
};

export default Main;
