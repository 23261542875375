import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'baseui';
import PickLaptopFrame from './Laptop/PickLaptopFrame';
import PickLaptopFrameV2 from './Laptop/PickLaptopFrameV2';
import PickMonitor from './Monitor/PickMonitor';
import PickPeripherals from './Peripherals/PickPeripherals';
import PickDesktop from './Desktop/PickDesktop';
import SpecialRequirements from './SpecialRequirements/SpecialRequirements';
import ShippingForm from './Shipping/ShippingForm';
import { HIRE_TYPE } from '../../../util/enums';
import PickIpad from './Ipad/PickIpad';
import { isHardwareControllerV2Enabled } from '../../../store/user/selectors';
import {
  imacDevice,
  tabletDevice,
  laptopDevice
} from '../../../store/hardware/selectors';

const Divider = styled('div', ({ $theme }) => ({
  borderBottom: `1px solid ${$theme.colors.mono200}`,
  marginTop: '40px'
}));

const HardwareSelectionWithShipping = () => {
  const laptopData = useSelector(laptopDevice);
  const userData = useSelector((state) => state.user.data);
  const imacData = useSelector(imacDevice);
  const tabletData = useSelector(tabletDevice);
  const isUsingHardwareControllerV2 = useSelector(
    isHardwareControllerV2Enabled
  );

  return (
    <>
      {imacData ? (
        <>
          <PickDesktop />
          <Divider />
        </>
      ) : null}

      {laptopData.length > 0 && userData.hire_type !== HIRE_TYPE.CONVERSION ? (
        <>
          {isUsingHardwareControllerV2 ? (
            <PickLaptopFrameV2 />
          ) : (
            <PickLaptopFrame />
          )}
          <Divider />
        </>
      ) : null}

      <PickMonitor />
      <Divider />

      {tabletData.length > 0 ? (
        <>
          <PickIpad />
          <Divider />
        </>
      ) : null}

      <PickPeripherals />
      <Divider />

      <ShippingForm />
      <Divider />

      <SpecialRequirements />
    </>
  );
};

export default HardwareSelectionWithShipping;
