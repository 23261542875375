import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserWorkdayTasks as getUserWorkdayTasksApi } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/api/userWorkDayTasks';
import { handleApiResponse } from 'utils/api_helpers';
import { WorkdayTask } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/selectors/selectors';

export const getUserWorkdayTasks = createAsyncThunk<
  WorkdayTask[],
  void,
  CustomApiError
>('user/getUserWorkdayTasks', async (_args, thunkApi) => {
  const response = await getUserWorkdayTasksApi();
  const result = await handleApiResponse(response);
  if (result.ok) {
    return result.data as WorkdayTask[];
  }
  return thunkApi.rejectWithValue(result.error);
});
