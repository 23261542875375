import { createSlice } from '@reduxjs/toolkit';
import { getUser, getUserWorkdayTasks } from './thunk';
import { API_STATUS } from '../../util/enums';
import {
  completeTask,
  isProfileTask,
  isShippingTask,
  isUsernameSelectTask,
  isHardwareSelectTask
} from './helpers';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loadStatus: API_STATUS.IDLE,
    wdTaskLoadingStatus: API_STATUS.IDLE,
    data: {
      id: null,
      email: null,
      first_name: null,
      last_name: null,
      human_tasks: [],
      workday_tasks: [],
      business_unit: '',
      access_expired: false,
      flags: {
        is_username_selection_enabled: false
      },
      allow_cross_mfr_peripherals: false
    },
    error: ''
  },
  reducers: {
    shippingAddressSubmitted: (state) => {
      const task = state.data.human_tasks.find(isShippingTask);
      completeTask(task);
    },
    profileSubmitted: (state) => {
      const task = state.data.human_tasks.find(isProfileTask);
      completeTask(task);
    },
    usernameSubmitted: (state) => {
      const task = state.data.human_tasks.find(isUsernameSelectTask);
      completeTask(task);
    },
    hardwareSubmitted: (state) => {
      const task = state.data.human_tasks.find(isHardwareSelectTask);
      completeTask(task);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      })
      .addCase(getUserWorkdayTasks.pending, (state) => {
        state.wdTaskLoadingStatus = API_STATUS.LOADING;
      })
      .addCase(getUserWorkdayTasks.fulfilled, (state, action) => {
        state.wdTaskLoadingStatus = API_STATUS.COMPLETED;
        state.data.workday_tasks = action.payload;
      })
      .addCase(getUserWorkdayTasks.rejected, (state, action) => {
        state.wdTaskLoadingStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      });
  }
});

const { actions, reducer } = userSlice;

const {
  shippingAddressSubmitted,
  profileSubmitted,
  usernameSubmitted,
  hardwareSubmitted
} = actions;

export {
  shippingAddressSubmitted,
  profileSubmitted,
  usernameSubmitted,
  hardwareSubmitted
};

export default reducer;
