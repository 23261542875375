import React from 'react';
import { Skeleton } from 'baseui/skeleton';

const TitleSkeleton = ({
  rows = 1,
  taskHeight = '26px',
  taskWidth = '200px',
  subTaskWidth = '100px'
}) => (
  <Skeleton
    rows={rows}
    width={taskWidth}
    animation
    overrides={{
      Root: {
        style: {
          display: 'flex',
          alignSelf: 'center'
        }
      },
      Row: {
        style: {
          height: taskHeight,
          marginLeft: '20px',
          ':last-child': {
            width: subTaskWidth
          }
        }
      }
    }}
  />
);

export default TitleSkeleton;
