/* eslint-disable max-len */
import { createSelector } from 'reselect';
import { createTheme } from 'baseui';
import getThemeOfRedesign from 'newHirePortalRedesign/newHire/config/getTheme';
import { CONTINGENT } from 'newHirePortal/newHire/util/hardwareContants';
import {
  isProfileTask,
  isShippingTask,
  isTaskComplete,
  isUsernameSelectTask,
  isHardwareSelectTask
} from './helpers';
import {
  getPathByName,
  isNameMatchRoute,
  ROUTES,
  WORKDAY_TEMP_LINK
} from '../../util/routes';
import getTheme from '../../config/getTheme';
import { API_STATUS } from '../../util/enums';
import { LINK_EXPIRED, SESSION_EXPIRED } from '../../../../utils/constants';

export const tasks = (state) => {
  const humanTasks = state.user.data.human_tasks;
  return ROUTES.map((route) => {
    const matchTask = humanTasks.find((task) =>
      isNameMatchRoute(route, task.name)
    );
    return matchTask
      ? {
          ...matchTask,
          targetLink: getPathByName(matchTask.name),
          isInternalLink: true
        }
      : null;
  }).filter((t) => t !== null);
};

export const isUsernameSelectionEnabled = (state) =>
  state.user.data.flags.is_username_selection_enabled &&
  state.user.data.human_tasks.find((task) => isUsernameSelectTask(task));

export const isHardwareSelectionEnabled = (state) =>
  state.user.data.flags.is_hardware_selection_enabled &&
  state.user.data.human_tasks.find((task) => isHardwareSelectTask(task));

export const isHardwareControllerV2Enabled = (state) =>
  state.user.data.flags.is_hardware_controller_v2_enabled;

export const isContingentWorkersEnabled = (state) =>
  state.user.data.flags.is_contingent_workers_enabled;

export const isContingentWorkerTypeAvailable = (state) =>
  state.user.data.worker_type === CONTINGENT;

export const isMaintenanceModeEnabled = (state) =>
  state.user.data.flags.is_maintenance_mode_enabled;

export const isMaintenanceBannerEnabled = (state) =>
  state.user.data.flags.is_maintenance_banner_enabled;

export const MaintenanceBannerMessage = (state) =>
  state.user.data.flags.maintenance_banner_message;

export const enabledHumanTasks = createSelector(
  tasks,
  isUsernameSelectionEnabled,
  isHardwareSelectionEnabled,
  (taskList, usernameSelectionEnabled, hardwareSelectionEnabled) =>
    taskList.filter((task) => {
      if (isUsernameSelectTask(task)) return usernameSelectionEnabled;
      if (isHardwareSelectTask(task)) return hardwareSelectionEnabled;
      return true;
    })
);

const completedTasksSelector = createSelector(enabledHumanTasks, (taskArray) =>
  taskArray.filter(isTaskComplete)
);

export const nextPendingTaskSelector = createSelector(
  enabledHumanTasks,
  (taskArray) => taskArray.find((task) => !isTaskComplete(task))
);

export const taskCompletePercentageSelector = createSelector(
  completedTasksSelector,
  enabledHumanTasks,
  (completedTasks, taskArray) =>
    (completedTasks.length / taskArray.length).toFixed(2) * 100
);

export const shippingTaskCompleted = createSelector(
  completedTasksSelector,
  (completedTasks) => completedTasks.find(isShippingTask)
);

export const profileTaskCompleted = createSelector(
  completedTasksSelector,
  (completedTasks) => completedTasks.find(isProfileTask)
);

export const usernameSelectTaskCompleted = createSelector(
  completedTasksSelector,
  (completedTasks) => completedTasks.find(isUsernameSelectTask)
);

export const hardwareTaskCompleted = createSelector(
  completedTasksSelector,
  (completedTasks) => completedTasks.find(isHardwareSelectTask)
);

export const usernameSelectTaskCompletedBy = createSelector(
  completedTasksSelector,
  (completedTasks) => completedTasks.find(isUsernameSelectTask)?.completed_by
);

export const workdayTaskLoadStatus = (state) => state.user.wdTaskLoadingStatus;

export const workdayTasks = (state) =>
  state.user.data.workday_tasks.map((task) => ({
    ...task,
    targetLink: WORKDAY_TEMP_LINK,
    isInternalLink: false
  }));

export const userApiError = (state) => state.user.error;

export const userLoadStatus = (state) => state.user.loadStatus;

export const isRedesignV1Enabled = (state) =>
  state.user.data.flags.is_redesign_v1_enabled;

export const userAppTheme = createSelector(
  (state) => state.user.data.business_unit,
  userLoadStatus,
  isRedesignV1Enabled,
  (businessUnit, userloadStatus, isNhpRedesignV1Enabled) =>
    userloadStatus === API_STATUS.COMPLETED && isNhpRedesignV1Enabled === true
      ? getThemeOfRedesign({ businessUnit })
      : getTheme({ businessUnit })
);
export const getApiErrorOccurred = createSelector(
  [
    (state) => state.shippingAddress.error,
    (state) => state.usernames.error,
    (state) => state.profile.error
  ],
  (shippingAddressError, usernameError, profileError) =>
    shippingAddressError || usernameError || profileError
);
export const userAppThemeObject = createSelector(
  userAppTheme,
  ({ primitives, overrides }) => createTheme(primitives, overrides)
);

// checks if user's access to this portal has expired,
// which happens after 10 days of their start date
export const isUserExpiredAccess = (state) => state.user.data.access_expired;

// checks if user's current session for this portal has expired, which is 3 hours per session
export const userSessionExpired = (state) =>
  state.user.error.toLowerCase() === 'session expired';

export const userLinkExpired = (state) =>
  state.user.error.toLowerCase() === LINK_EXPIRED;

export const sessionExpired = createSelector(
  userSessionExpired,
  getApiErrorOccurred,
  (isUserSessionExpired, apiError) =>
    isUserSessionExpired || apiError === SESSION_EXPIRED
);

export const isMnAEmployeeSelector = (state) => state.user.data.is_mna_employee;
