import React, { useRef } from 'react';
import { useStyletron } from 'baseui';
import {
  MonoParagraphLarge,
  MonoParagraphMedium,
  ParagraphMedium
} from 'baseui/typography';
import AccentButton from '../../../components/AccentButton';
import Bullet from '../../../components/Bullet';
import toastTrigger from '../toastTrigger';

const ProfileImageForm = ({ setImageObj, imageObj, props }) => {
  const [css, theme] = useStyletron();
  const reader = new FileReader();
  const inputRef = useRef(null);
  let currentFile;
  let name;

  reader.onload = (e) => {
    setImageObj({
      ...imageObj,
      imageSrc: e.target.result,
      imageName: name,
      file: currentFile,
      imageField: inputRef
    });
  };

  const onImageUploaded = (e) => {
    const file = e.target.files[0];
    const oneMb = 1024 * 1024;
    if (file) {
      if (file.size > oneMb) {
        toastTrigger({ success: false, failureMsg: 'File size exceeds 1MB' });
        return;
      }
      reader.readAsDataURL(file);
      currentFile = file;
      name = file.name;
    }
  };

  return (
    <>
      <MonoParagraphMedium marginTop={0} marginBottom={0}>
        Follow the following guidelines when submitting a photo:
      </MonoParagraphMedium>
      <MonoParagraphMedium marginBottom={0} marginTop={0}>
        <Bullet /> Photo must be JPEG, PNG, or GIF file type.
      </MonoParagraphMedium>
      <MonoParagraphMedium marginTop={0}>
        <Bullet /> Maximum file size accepted is 1 MB.
      </MonoParagraphMedium>

      <input
        ref={inputRef}
        type='file'
        accept='.jpeg,.png,.gif'
        onChange={onImageUploaded}
        hidden
      />

      {!imageObj.imageSrc && (
        <AccentButton
          {...props}
          borderRadius='6px'
          label='Upload profile image'
          paddingLeft={theme.sizing.scale1600}
          paddingRight={theme.sizing.scale1600}
          float='unset'
          marginTop={theme.margins.normal}
          marginBottom={theme.margins.normal}
          onClick={() => inputRef.current.click()}
        />
      )}

      {imageObj.imageSrc && (
        <div>
          <div
            className={css({
              marginRight: theme.sizing.scale2400
            })}
          >
            <img
              className={css({
                width: '320px',
                borderRadius: '6px'
              })}
              src={imageObj.imageSrc}
              alt='uploaded profile'
            />
          </div>

          <div>
            <ParagraphMedium color={theme.colors.mono500} marginBottom='6px'>
              Image Preview
            </ParagraphMedium>
            <div
              className={css({
                display: 'flex'
              })}
            >
              <MonoParagraphLarge
                marginTop={0}
                marginRight={theme.sizing.scale1200}
              >
                {' '}
                {imageObj.imageName}
              </MonoParagraphLarge>
              <MonoParagraphLarge
                marginTop={0}
                onClick={() => inputRef.current.click()}
                color={theme.colors.contentAccent}
                className={css({
                  cursor: 'pointer'
                })}
              >
                Upload again
              </MonoParagraphLarge>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileImageForm;
