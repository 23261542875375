import React from 'react';
import InfoMsg from './InfoMsg';
import FailSmiley from '../../../../../components/icons/FailSmiley';
import BackToDashboardButton from '../../../components/BackToDashboardButton';

const NoUsernamePage = () => (
  <div data-testid='no-username'>
    <InfoMsg
      logo={<FailSmiley />}
      mainMsg='Sorry! All the username options have been taken.'
      infoLine="Don't worry! Our IT team is working on it and we'll assign you a username before your start date and display it here."
    />
    <BackToDashboardButton compName='No Username' />
  </div>
);

export default NoUsernamePage;
