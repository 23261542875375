import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface HumanTask {
  id: number;
  due_date: string;
  name: string;
  state: string;
  type: string;
  user_id: string;
  completed_by: string | null;
  optional: boolean;
}

interface AppState {
  humantasks: HumanTask[];
}

export const initialState: AppState = {
  humantasks: []
};

// TODO: Need to remove the 'setHumanTasks' slice, once we have user api call within nhp-redesign folder.
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setHumanTasks: (state, action: PayloadAction<HumanTask[]>) => {
      state.humantasks = action.payload;
    }
  }
});

export const { setHumanTasks } = appSlice.actions;

export default appSlice.reducer;
