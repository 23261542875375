import { styled } from 'baseui';

const Bullet = styled('span', ({ $theme, color }) => ({
  backgroundColor: color || $theme.colors.backgroundAccent,
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  display: 'inline-block',
  transform: 'translateY(-20%)',
  marginRight: '15px',
  border: color && `1px solid ${$theme.colors.backgroundAccent}`
}));

export default Bullet;
