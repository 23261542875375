import React from 'react';
import { useStyletron } from 'baseui';
import { ParagraphLarge } from 'baseui/typography';
import { useSelector } from 'react-redux';
import { ERROR_MESSAGE, NEW_HIRE_PORTAL } from 'utils/constants';
import HeadLine from './Headline';
import MiscCommonFrame from './MiscCommonFrame';
import { sessionExpired, userLinkExpired } from '../../store/user/selectors';
import SearchTool from '../../../../components/icons/SearchTool';
import ExpiredIcon from '../../components/ExpiredIcon';

const ErrorPage = ({ errorTitle, errorMsg }) => {
  const isSessionExpired = useSelector(sessionExpired);
  const isLinkExpired = useSelector(userLinkExpired);
  const [css] = useStyletron();

  let title;
  let icon;
  let msg;
  if (isSessionExpired) {
    title = ERROR_MESSAGE.SESSION_EXPIRED_TITLE;
    icon = <ExpiredIcon />;
    msg = ERROR_MESSAGE.SESSION_EXPIRED_MSG;
  } else if (isLinkExpired) {
    title = ERROR_MESSAGE.LINK_EXPIRED_TITLE;
    icon = <ExpiredIcon />;
    msg = ERROR_MESSAGE.LINK_EXPIRED_MSG;
  } else {
    title = errorTitle || ERROR_MESSAGE.DEFAULT_ERROR_MSG_TITLE;
    icon = <SearchTool />;
    msg = errorMsg || ERROR_MESSAGE.DEFAULT_ERROR_MSG;
  }
  return (
    <MiscCommonFrame>
      <span
        className={css({
          marginTop: '60px'
        })}
      >
        {icon}
      </span>

      <HeadLine margin='20px 0 0 0'>{NEW_HIRE_PORTAL}</HeadLine>
      <HeadLine fontWeight='300' margin='20px 0 0 0'>
        {title}
      </HeadLine>
      <ParagraphLarge marginTop='30px'>{msg}</ParagraphLarge>
    </MiscCommonFrame>
  );
};
export default ErrorPage;
