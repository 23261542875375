import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import React from 'react';
import { allDirectionStyle } from 'newHirePortal/newHire/util/styleHelpers';
import { MonoParagraphMedium } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';

const DeviceWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: `inset 0px -1px 0px ${$theme.colors.mono200}`,
  marginRight: '4%'
}));

const CheckBoxWithIcon = ({
  icon,
  label,
  data,
  disabled,
  optionKey,
  isOptionChecked,
  handleCheckboxChange
}) => {
  const [css] = useStyletron();

  return (
    <DeviceWrapper>
      {icon}
      <Checkbox
        key={label}
        disabled={disabled}
        checked={isOptionChecked}
        onChange={() =>
          handleCheckboxChange({
            checkboxKey: optionKey,
            checkboxLabel: label,
            data
          })
        }
        labelPlacement={LABEL_PLACEMENT.left}
        overrides={{
          Checkmark: {
            style: ({ $theme, $checked }) => ({
              ...allDirectionStyle({
                color: $theme.colors.contentAccent,
                radius: $theme.borders.radius200,
                prop: 'border'
              }),
              backgroundColor: $checked ? $theme.colors.contentAccent : 'white'
            })
          },
          Root: {
            style: ({ $theme }) => ({
              marginLeft: $theme.sizing.scale400
            })
          }
        }}
      >
        <MonoParagraphMedium
          className={css({
            marginTop: '0px',
            marginBottom: '0px',
            textTransform: 'capitalize'
          })}
        >
          {label}
        </MonoParagraphMedium>
      </Checkbox>
    </DeviceWrapper>
  );
};

export default CheckBoxWithIcon;
