import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useStyletron } from 'baseui';
import { HeadingXXLarge, ParagraphLarge } from 'baseui/typography';
import { API_STATUS } from '../../util/enums';
import { allDirectionStyle } from '../../util/styleHelpers';
import AccentButton from '../../components/AccentButton';
import MonoButton from '../../components/MonoButton';

const ConfirmModal = ({
  isOpen,
  onClose,
  modelContent,
  onConfirm,
  submitStatus,
  dataNameToConfirm,
  modalHeader = 'Confirm submission',
  modalBodyHeadLine = 'Please verify that the information is correct:'
}) => {
  const [, theme] = useStyletron();

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeable={false}
      unstable_ModalBackdropScroll
      animate
      overrides={{
        Root: { style: { zIndex: 10 } },
        Dialog: {
          style: { ...allDirectionStyle({ radius: '12px', prop: 'border' }) }
        },
        Close: () => null
      }}
    >
      <ModalHeader>
        <HeadingXXLarge>{modalHeader}</HeadingXXLarge>
      </ModalHeader>
      <ModalBody>
        <ParagraphLarge>{modalBodyHeadLine}</ParagraphLarge>

        {modelContent}
      </ModalBody>
      <ModalFooter
        $style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: theme.margins.normal
        }}
      >
        <MonoButton
          onClick={onClose}
          label='Cancel'
          buttonNameForTracking={`Cancel Submit ${dataNameToConfirm}`}
        />

        <AccentButton
          onClick={onConfirm}
          label='Submit'
          isLoading={submitStatus === API_STATUS.LOADING}
          buttonNameForTracking={`Confirm Submit ${dataNameToConfirm}`}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
