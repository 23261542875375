import React from 'react';
import { styled, useStyletron } from 'baseui';
import Clipboard from './Clipboard';

const ClipboardWithBackground = ({ completed, size = 24, ...props }) => {
  const [, theme] = useStyletron();
  const color = completed ? theme.colors.success_fill : theme.colors.mono300;
  const backgroundColor = completed
    ? theme.colors.success_40
    : theme.colors.mono200;

  const IconWrap = styled('div', () => ({
    border: `1px solid ${color}`,
    background: backgroundColor,
    padding: `${size / 2}px`,
    borderRadius: '10px'
  }));

  return (
    <IconWrap>
      <Clipboard color={color} size={size} {...props} />
    </IconWrap>
  );
};

export default ClipboardWithBackground;
