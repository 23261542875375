export const postProfileInfo = async ({ bioContent, profileImage }) => {
  const data = new FormData();
  data.set('bio_content', bioContent);
  data.set('profile_image', profileImage);

  return fetch('/api/v1/user/submit_bio', {
    method: 'POST',
    referrerPolicy: 'no-referrer',
    body: data
  });
};
