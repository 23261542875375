import { createSlice } from '@reduxjs/toolkit';
import { getUserWorkdayTasks } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/thunk/thunk';
import { API_STATUS } from 'newHirePortalRedesign/newHire/util/constants';
import { WorkdayTask } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/selectors/selectors';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface WorkdayTasksState {
  loadStatus: (typeof API_STATUS)[keyof typeof API_STATUS];
  data: WorkdayTask[];
  error: string;
  errorCode: string;
  countApiFailedCall: number;
}

export const initialState: WorkdayTasksState = {
  loadStatus: API_STATUS.IDLE,
  data: [],
  error: ' ',
  errorCode: '',
  countApiFailedCall: 0
};

const workdayTasksSlice = createSlice({
  name: 'workdayTasks',
  initialState,
  reducers: {
    setCountApiFailedCall: (state, action: PayloadAction<number>) => {
      state.countApiFailedCall = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserWorkdayTasks.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(getUserWorkdayTasks.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = action.payload;
      })
      .addCase(getUserWorkdayTasks.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
          state.errorCode = action.payload.code;
        } else {
          state.error = action.error.message || 'Something went wrong';
        }
      });
  }
});

const { reducer, actions } = workdayTasksSlice;

export const { setCountApiFailedCall } = actions;

export default reducer;
