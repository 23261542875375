import React from 'react';
import { Icon } from 'baseui/icon';

const MonitorIcon = ({ ...props }) => {
  const { title = 'monitor logo', size, ...restProps } = props;

  return (
    <Icon viewBox='0 0 30 24' title={title} size={size} {...restProps}>
      <path
        d='M24.8 1H5.2C2.876 1 1 2.8425 1 5.125V16.125C1 18.4075 2.876 20.25 5.2 20.25H9.4V23H20.6V20.25H24.8C27.124 20.25 29 18.4075 29 16.125V5.125C29 2.8425 27.124 1 24.8 1ZM26.2 16.125C26.2 16.8813 25.57 17.5 24.8 17.5H5.2C4.43 17.5 3.8 16.8813 3.8 16.125V5.125C3.8 4.36875 4.43 3.75 5.2 3.75H24.8C25.57 3.75 26.2 4.36875 26.2 5.125V16.125Z'
        fill='#E5F0FF'
        strokeWidth='0.6'
      />
    </Icon>
  );
};
export default MonitorIcon;
