export const utcToLocal = (utcDateString: string): Date =>
  new Date(`${utcDateString} UTC`);

// E.g Tue Mar 20 2021 - > Mar 20
export const dateWithDayInMonth = (dateString: string): string =>
  dateString.split(' ').slice(1, 3).join(' ');

export const daysDiff = (utcDate: Date): number => {
  const diff = new Date(utcDate).getTime() - new Date().getTime();
  return Math.ceil(diff / (1000 * 3600 * 24));
};
