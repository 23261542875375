export const utcToLocal = (utcDateString) => new Date(`${utcDateString} UTC`);

// E.g Tue Mar 20 2021 - > Mar 20
export const dateWithDayInMonth = (dateString) =>
  dateString.split(' ').slice(1, 3).join(' ');

// Eg. 2021-04-16 - > Mar 16
export const dateWithMonthdd = (str) => {
  const d = utcToLocal(str);
  const day = d.getDate();
  const month = d.toString().split(' ')[1];
  return `${month} ${day}`;
};
