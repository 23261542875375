/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { styled } from 'baseui';
import { Radio } from 'baseui/radio';
import { useDispatch, useSelector } from 'react-redux';
import { MonoParagraphMedium } from 'baseui/typography';
import {
  selectMonitor,
  setMonitorChoice,
  addAdapter
} from 'newHirePortal/newHire/store/hardware/slice';
import {
  monitorDevice,
  adapterDevice,
  createDefaultHardwareChoiceObject,
  createOptOutChoiceObject
} from 'newHirePortal/newHire/store/hardware/selectors';
import {
  OPT_OUT_CHOICE,
  DEFAULT_HARDWARE_CHOICE,
  HARDWARE_ITEMS,
  MONITOR_AND_ADAPTER
} from 'newHirePortal/newHire/util/hardwareContants';
import StyledRadioGroup, {
  radioOverrides
} from 'newHirePortal/newHire/containers/YourInfo/HardwareShipping/StyledRadioGroup';
import HardwareTitle from '../HardwareTitle';
import MonitorIcon from '../../../../../../components/icons/Monitor';
import { findHardwareByDSQPN } from '../../../../util/hardwareHelper';

const radioStyleOverrides = radioOverrides({ width: '150px' });

const Wrapper = styled('div', ({ $theme }) => ({
  border: `1px solid ${$theme.colors.mono300}`,
  ':hover': { border: `2px solid ${$theme.colors.backgroundAccent}` },
  borderRadius: '8px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px'
}));

export const MONITOR_SIZE_AVAILABILITY_INFO =
  'Choose you monitor size. Please note monitor may be different than choice based on the stock in your region.';

const PickMonitor = () => {
  const dispatch = useDispatch();
  const monitorData = useSelector(monitorDevice);
  const adapterData = useSelector(adapterDevice);
  const monitorDataExists = monitorData.length > 0;
  const monitorChoice = useSelector(
    (state) =>
      state.hardwareSelection.updateHardwareInputFieldValues.monitorChoice
  );

  useEffect(() => {
    if (!monitorDataExists) {
      dispatch(setMonitorChoice(DEFAULT_HARDWARE_CHOICE.SQ_PART_NUMBER));
    }
  }, []);

  const updateSelectedMonitorData = (selectedMonitorValue) => {
    if (selectedMonitorValue === DEFAULT_HARDWARE_CHOICE.SQ_PART_NUMBER) {
      dispatch(
        selectMonitor(createDefaultHardwareChoiceObject(HARDWARE_ITEMS.MONITOR))
      );
    } else {
      const selectedMonitor = findHardwareByDSQPN(
        monitorData,
        selectedMonitorValue
      );
      dispatch(selectMonitor(selectedMonitor));
    }
  };

  useEffect(() => {
    if (monitorChoice === OPT_OUT_CHOICE.SQ_PART_NUMBER || !monitorChoice) {
      // When the monitor choice is OPT_OUT or flasy value, select null for the monitor and adapter
      dispatch(selectMonitor(createOptOutChoiceObject(HARDWARE_ITEMS.MONITOR)));
      dispatch(addAdapter(createOptOutChoiceObject(HARDWARE_ITEMS.ADAPTER)));
    } else {
      // When the monitor choice is not OPT_OUT and falsy, update the monitor and adapter data

      // Update the selected monitor data
      updateSelectedMonitorData(monitorChoice);

      // Update the adapter data
      dispatch(
        addAdapter(
          adapterData.length > 0
            ? adapterData[0]
            : createDefaultHardwareChoiceObject(HARDWARE_ITEMS.ADAPTER)
        )
      );
    }
  }, [monitorChoice]);

  const handleChange = (value) => {
    dispatch(setMonitorChoice(value));
  };

  return (
    <Wrapper data-testid='pick-monitor'>
      <HardwareTitle
        icon={<MonitorIcon size={30} />}
        deviceName={MONITOR_AND_ADAPTER}
        infoText={[MONITOR_SIZE_AVAILABILITY_INFO]}
      />
      <StyledRadioGroup
        currentValue={monitorChoice}
        setCurrentValue={handleChange}
        testId='monitor-radio-group'
        radioBtnName='Monitor'
      >
        {monitorDataExists ? (
          monitorData.map((monitor) => (
            <Radio
              overrides={radioStyleOverrides}
              key={monitor.sq_part_number}
              value={monitor.sq_part_number}
            >
              <MonoParagraphMedium>{monitor.display_name}</MonoParagraphMedium>
            </Radio>
          ))
        ) : (
          <Radio
            overrides={radioStyleOverrides}
            value={DEFAULT_HARDWARE_CHOICE.SQ_PART_NUMBER}
          >
            <MonoParagraphMedium>
              {DEFAULT_HARDWARE_CHOICE.DEFAULT_VALUE}
            </MonoParagraphMedium>
          </Radio>
        )}
        <Radio
          overrides={radioStyleOverrides}
          value={OPT_OUT_CHOICE.SQ_PART_NUMBER}
        >
          <MonoParagraphMedium>{OPT_OUT_CHOICE.VALUE}</MonoParagraphMedium>
        </Radio>
      </StyledRadioGroup>
    </Wrapper>
  );
};
export default PickMonitor;
