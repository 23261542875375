import { createSelector } from 'reselect';
import { isAllTaskCompleted } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/utils/helpers/helpers';
import {
  RootState,
  SelectorReturnType
} from 'newHirePortalRedesign/newHire/store';

const humanTasks = (state: RootState) => state.app.humantasks;

const workdayTasks = (state: RootState) => state.workdayTasks.data;

const workdayTasksLoadState = (state: RootState) =>
  state.workdayTasks.loadStatus;

const selectTotalCardCount: SelectorReturnType<number> = createSelector(
  [humanTasks],
  (humanTaskList) => humanTaskList.length + 1
);

const isAllWorkdayTasksDone: SelectorReturnType<boolean> = createSelector(
  workdayTasks,
  (workdayTaskList) => isAllTaskCompleted(workdayTaskList)
);

const isAllHumanTasksDone: SelectorReturnType<boolean> = createSelector(
  humanTasks,
  (humanTaskList) => isAllTaskCompleted(humanTaskList)
);

const isAllWorkdayAndHumanTasksDone: SelectorReturnType<boolean> =
  createSelector(
    [isAllWorkdayTasksDone, isAllHumanTasksDone],
    (isAllWorkdayTasksCompleted, isAllHumanTasksCompleted) =>
      isAllWorkdayTasksCompleted && isAllHumanTasksCompleted
  );

export {
  humanTasks,
  workdayTasksLoadState,
  selectTotalCardCount,
  isAllWorkdayTasksDone,
  isAllHumanTasksDone,
  isAllWorkdayAndHumanTasksDone
};
