import {
  RootState,
  SelectorReturnType
} from 'newHirePortalRedesign/newHire/store';
import { createSelector } from 'reselect';
import { isTaskComplete } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/utils/helpers/helpers';
import {
  daysDiff,
  dateWithDayInMonth,
  utcToLocal
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/utils/dateHelpers/dateHelpers';
import {
  TASK_STATUS,
  WORKDAY_TEMP_LINK
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/constants';

export interface WorkdayTask {
  due_date: string | null;
  name: string;
  state: typeof TASK_STATUS.COMPLETED | 'pending';
}

type PendingStatus = typeof TASK_STATUS.OVERDUE | typeof TASK_STATUS.FUTURE;

export type TaskStatus = (typeof TASK_STATUS)[keyof typeof TASK_STATUS];

export interface HydratedWorkdayTask extends WorkdayTask {
  isTaskCompleted: boolean;
  status: TaskStatus;
  targetLink: string;
  dueDate: string | null;
}

const getPendingTaskStatus = (dueDate: string | null): PendingStatus => {
  const diff = daysDiff(new Date(`${dueDate} UTC`));
  return diff <= 0 ? TASK_STATUS.OVERDUE : TASK_STATUS.FUTURE;
};

export const hydratedWorkdayTasks: SelectorReturnType<HydratedWorkdayTask[]> =
  createSelector(
    (state: RootState) => state.workdayTasks.data,
    (workdayTasks) =>
      workdayTasks
        .map((task) => {
          const isTaskCompleted = isTaskComplete(task);
          return {
            ...task,
            isTaskCompleted,
            dueDate:
              task.due_date &&
              dateWithDayInMonth(utcToLocal(task?.due_date).toDateString()),
            targetLink: WORKDAY_TEMP_LINK,
            status: isTaskCompleted
              ? TASK_STATUS.COMPLETED
              : getPendingTaskStatus(task.due_date)
          };
        })
        .sort((taskA, taskB) => {
          const statusOrder = {
            [TASK_STATUS.OVERDUE]: 0,
            [TASK_STATUS.FUTURE]: 1,
            [TASK_STATUS.COMPLETED]: 2
          };

          const statusA = taskA.status;
          const statusB = taskB.status;

          return statusOrder[statusA] - statusOrder[statusB];
        })
  );
