/* eslint-disable max-len */
import { createSelector } from 'reselect';
import { API_STATUS, TASK_NAME } from '../../util/enums';
import {
  HARDWARE_ITEMS as HARDWARE_ITEM_TYPES,
  IMAC,
  MANUFACTURERS,
  DEFAULT_HARDWARE_CHOICE,
  OPT_OUT_CHOICE,
  HARDWARE_ITEMS
} from '../../util/hardwareContants';
import {
  isContingentWorkerTypeAvailable,
  isHardwareSelectionEnabled
} from '../user/selectors';

const hardwareData = (state) => state.hardwareSelection.data.devices;
export const laptopDevice = createSelector(hardwareData, (items) =>
  items.filter((data) => data.type.toLowerCase() === HARDWARE_ITEM_TYPES.LAPTOP)
);
export const monitorDevice = createSelector(hardwareData, (items) =>
  items.filter(
    (data) => data.type.toLowerCase() === HARDWARE_ITEM_TYPES.MONITOR
  )
);

export const adapterDevice = createSelector(hardwareData, (items) =>
  items.filter(
    (data) => data.type.toLowerCase() === HARDWARE_ITEM_TYPES.ADAPTER
  )
);

export const headsetDeviceList = createSelector(hardwareData, (items) =>
  items.filter(
    (data) => data.type.toLowerCase() === HARDWARE_ITEM_TYPES.HEADSET
  )
);
export const imacDevice = createSelector(hardwareData, (items) =>
  items.find(
    (data) =>
      data.type.toLowerCase() === HARDWARE_ITEM_TYPES.DESKTOP &&
      data.manufacturer.toLowerCase() === MANUFACTURERS.APPLE.toLowerCase() &&
      data.name.toLowerCase().includes(IMAC.NAME)
  )
);
export const tabletDevice = createSelector(hardwareData, (items) =>
  items.filter((data) => data.type.toLowerCase() === HARDWARE_ITEM_TYPES.TABLET)
);

export const submittedHardwareSelections = (state) =>
  state.selectedHardwares.data.devices;

export const getSubmittedHardwareDetails = createSelector(
  submittedHardwareSelections,
  (devices) =>
    Object.values(HARDWARE_ITEM_TYPES).reduce((outputHash, type) => {
      outputHash[type] = devices.find(
        (data) => data.device_type.toLowerCase() === type
      );
      return outputHash;
    }, {})
);

export const isTabletEnable = createSelector(
  hardwareData,
  (items) =>
    items.filter(
      (data) => data.type.toLowerCase() === HARDWARE_ITEM_TYPES.TABLET
    ).length > 0
);

export const isTabletIncludedInSubmission = createSelector(
  submittedHardwareSelections,
  (items) =>
    items.filter(
      (data) => data.device_type.toLowerCase() === HARDWARE_ITEM_TYPES.TABLET
    ).length > 0
);

const selectedLaptop = (state) =>
  state.hardwareSelection.selectedHardware.laptop;

const isAppleManufacturer = (entry) =>
  entry.manufacturer.toLowerCase() === MANUFACTURERS.APPLE.toLowerCase();

export const getPeripheralBasedOnLaptop = createSelector(
  (state) => state.user.data.allow_cross_mfr_peripherals,
  hardwareData,
  selectedLaptop,
  (allowCrossMfrPeripherals, devices, laptop) => {
    if (!laptop) return [];

    const isAppleLaptop = isAppleManufacturer(laptop);

    const mouseList = devices.filter(
      (entry) => entry.type.toLowerCase() === HARDWARE_ITEM_TYPES.MOUSE
    );

    const keyboardList = devices.filter(
      (entry) => entry.type.toLowerCase() === HARDWARE_ITEM_TYPES.KEYBOARD
    );

    if (!isAppleLaptop) {
      return [
        mouseList.find(
          (peripheralDevice) => !isAppleManufacturer(peripheralDevice)
        ),
        keyboardList.find(
          (peripheralDevice) => !isAppleManufacturer(peripheralDevice)
        )
      ].filter((entry) => entry);
    }

    const mouseForAppleLaptop =
      mouseList.find((mouse) => isAppleManufacturer(mouse)) ||
      (allowCrossMfrPeripherals && mouseList[0]);

    const keyboardForAppleLaptop =
      keyboardList.find((keyboard) => isAppleManufacturer(keyboard)) ||
      (allowCrossMfrPeripherals && keyboardList[0]);

    return [mouseForAppleLaptop, keyboardForAppleLaptop].filter(
      (entry) => entry
    );
  }
);

const getKeyboardBasedOnLaptop = createSelector(
  getPeripheralBasedOnLaptop,
  // Uses the first keyboard device from the hardwareData, backend should sort the data properly in order based on default and required
  (devices) =>
    devices.find(
      (entry) => entry.type.toLowerCase() === HARDWARE_ITEM_TYPES.KEYBOARD
    )
);

export const createDefaultHardwareChoiceObject = (type) => ({
  name: DEFAULT_HARDWARE_CHOICE.ERROR_MSG,
  type,
  sq_part_number: DEFAULT_HARDWARE_CHOICE.SQ_PART_NUMBER,
  display_name:
    type === HARDWARE_ITEM_TYPES.MONITOR
      ? DEFAULT_HARDWARE_CHOICE.DEFAULT_VALUE
      : type
});

export const createOptOutChoiceObject = (type) => ({
  name: OPT_OUT_CHOICE.VALUE,
  type,
  sq_part_number: OPT_OUT_CHOICE.SQ_PART_NUMBER
});

const getKeyboardDataBasedOnKeyboardAvailability = createSelector(
  getKeyboardBasedOnLaptop,
  (keyBoardData) => {
    if (keyBoardData && Object.keys(keyBoardData).length > 0) {
      return keyBoardData;
    }
    const defaultKeyBoardData = createDefaultHardwareChoiceObject(
      HARDWARE_ITEM_TYPES.KEYBOARD
    );
    return defaultKeyBoardData;
  }
);

export const getMouseBasedOnLaptop = createSelector(
  getPeripheralBasedOnLaptop,
  // Uses the first MOUSE device from the hardwareData, backend should sort the data properly in order based on default and required
  (devices) =>
    devices.find(
      (entry) => entry.type.toLowerCase() === HARDWARE_ITEM_TYPES.MOUSE
    )
);

export const getHeadset = createSelector(
  headsetDeviceList,
  (devices) => devices[0]
);

export const getPeripherals = createSelector(
  [
    getMouseBasedOnLaptop,
    getHeadset,
    getKeyboardDataBasedOnKeyboardAvailability
  ],
  (mouseData, headsetData, keyboardDataWithLanguage) => ({
    [HARDWARE_ITEMS.MOUSE]: mouseData,
    [HARDWARE_ITEMS.HEADSET]: headsetData,
    [HARDWARE_ITEMS.KEYBOARD]: keyboardDataWithLanguage
  })
);

export const getIpad = createSelector(tabletDevice, (devices) => devices[0]);

export const isAllMandatoryHardwareSelected = (state) => {
  const selectedHardware = state.hardwareSelection.selectedHardware;
  const keyboardLanguageDataExists =
    state.hardwareSelection.selectedHardware.keyboard?.language_choices
      ?.length > 0;

  if (keyboardLanguageDataExists) {
    return (
      selectedHardware.laptop !== null &&
      selectedHardware.laptopLanguage !== null &&
      selectedHardware.keyboardLanguage !== null
    );
  }

  return (
    selectedHardware.laptop !== null && selectedHardware.laptopLanguage !== null
  );
};

export const showHardwareSelection = createSelector(
  [hardwareData, laptopDevice, isContingentWorkerTypeAvailable],
  (devices, laptopData, isContingentWorkerTypePresent) =>
    !isContingentWorkerTypePresent &&
    devices.length > 0 &&
    laptopData.length > 0
);

export const isHardwareApiCompleted = createSelector(
  (state) => state.hardwareSelection.loadStatus,
  (hardwareApiLoadStatus) => hardwareApiLoadStatus === API_STATUS.COMPLETED
);

export const isSelectedHardwareApiCompleted = createSelector(
  (state) => state.selectedHardwares.loadStatus,
  (selectedHardwareApiLoadStatus) =>
    selectedHardwareApiLoadStatus === API_STATUS.COMPLETED
);

export const getTitleBasedOnHardwareVisibility = createSelector(
  [
    showHardwareSelection,
    isHardwareApiCompleted,
    isSelectedHardwareApiCompleted,
    submittedHardwareSelections,
    (state, taskName) => taskName
  ],
  (
    showHardwareVisible,
    isGetHardwareApiCompleted,
    isGetSelectedHardwareApiCompleted,
    userSubmittedHardware,
    taskName
  ) => {
    const isTaskNameAvailable = taskName === TASK_NAME.HARDWARE;

    const shouldShowShipping =
      (isGetHardwareApiCompleted && !showHardwareVisible) ||
      (isGetSelectedHardwareApiCompleted &&
        userSubmittedHardware?.length === 0);

    return isTaskNameAvailable && shouldShowShipping
      ? TASK_NAME.SHIPPING
      : taskName;
  }
);

export const checkDeviceAvailability = createSelector(
  [(state) => state.hardwareSelection.data.error, laptopDevice],
  (noDevicesForUserErrorMsg, laptopData) => {
    let missingDevicesErrorMsg = '';

    if (noDevicesForUserErrorMsg) {
      missingDevicesErrorMsg = noDevicesForUserErrorMsg;
    } else {
      const laptopDevicesIsAvailable = laptopData.length > 0;

      if (!laptopDevicesIsAvailable) {
        missingDevicesErrorMsg = 'Device not found';
      }
    }
    return missingDevicesErrorMsg;
  }
);

export const isTaskNameChangedBasedOnHardwareVisibility = createSelector(
  (state) => state.hardwareSelection.loadStatus,
  (state) => state.selectedHardwares.loadStatus,
  isHardwareSelectionEnabled,
  (
    hardwareApiLoadStatus,
    selectedHardwaresApiLoadStatus,
    isHardwareSectionEnabled
  ) => {
    if (isHardwareSectionEnabled) {
      return (
        hardwareApiLoadStatus === API_STATUS.COMPLETED ||
        hardwareApiLoadStatus === API_STATUS.FAILED ||
        selectedHardwaresApiLoadStatus === API_STATUS.COMPLETED ||
        selectedHardwaresApiLoadStatus === API_STATUS.FAILED
      );
    }
    return true;
  }
);
