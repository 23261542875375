import React from 'react';
import { Block } from 'baseui/block';
import { styled, useStyletron } from 'baseui';
import { MonoParagraphMedium } from 'baseui/typography';
import {
  DEFAULT_HARDWARE_CHOICE,
  ENGLISH_LANG_CHOICE_VALUE,
  HARDWARE_ITEMS,
  OPT_OUT_CHOICE
} from 'newHirePortal/newHire/util/hardwareContants';
import HardwareTitleWithIcon from '../HardwareTitleWithIcon';
import { OuterWapper } from '../CommonStyle';
import PeripheralsIcon from '../../../../../../components/icons/Peripherals';
import MouseIcon from '../../../../../../components/icons/Mouse';
import HeadsetIcon from '../../../../../../components/icons/Headset';
import KeyboardIcon from '../../../../../../components/icons/Keyboard';
import { capitalizeFirstLetter } from '../../../../util/dataParseHelpers';

const DeviceWrapper = styled('div', () => ({
  display: 'flex',
  alignItems: 'center'
}));

const PeripheralsInfo = ({ icon, peripheralName }) => {
  const [css, theme] = useStyletron();
  return (
    <DeviceWrapper data-testid='peripheral-devices'>
      {icon}
      <MonoParagraphMedium
        className={css({
          marginLeft: '26px',
          color: theme.colors.contentAccent
        })}
      >
        {capitalizeFirstLetter(peripheralName)}
      </MonoParagraphMedium>
    </DeviceWrapper>
  );
};

const PeripheralsDetails = ({
  keyboardLanguageData,
  mouse,
  keyboard,
  headset
}) => {
  const isValidMouseData =
    mouse && mouse.sq_part_number !== OPT_OUT_CHOICE.SQ_PART_NUMBER;
  const isValidkeyboardData =
    keyboard && keyboard.sq_part_number !== OPT_OUT_CHOICE.SQ_PART_NUMBER;
  const isValidHeadsetData =
    headset && headset.sq_part_number !== OPT_OUT_CHOICE.SQ_PART_NUMBER;

  return isValidHeadsetData || isValidkeyboardData || isValidMouseData ? (
    <OuterWapper>
      <HardwareTitleWithIcon
        icon={<PeripheralsIcon size={20} />}
        deviceCategory='Peripherals'
      />
      <Block paddingLeft='10px' paddingTop='10px'>
        {isValidMouseData ? (
          <PeripheralsInfo
            icon={<MouseIcon size={18} />}
            peripheralName={mouse?.display_name}
          />
        ) : null}
        {isValidHeadsetData ? (
          <PeripheralsInfo
            icon={<HeadsetIcon size={18} />}
            peripheralName={headset.display_name}
          />
        ) : null}
        {isValidkeyboardData ? (
          <Block data-testid='keyboard-language-component'>
            <PeripheralsInfo
              icon={<KeyboardIcon size={18} />}
              peripheralName={`${
                keyboard?.device_sqpn === DEFAULT_HARDWARE_CHOICE.SQ_PART_NUMBER
                  ? HARDWARE_ITEMS.KEYBOARD
                  : keyboard.display_name
              } ${
                keyboardLanguageData
                  ? `(${
                      keyboardLanguageData === ENGLISH_LANG_CHOICE_VALUE
                        ? `${ENGLISH_LANG_CHOICE_VALUE}-US`
                        : keyboardLanguageData
                    })`
                  : ''
              }`}
            />
          </Block>
        ) : null}
      </Block>
    </OuterWapper>
  ) : null;
};

export default PeripheralsDetails;
