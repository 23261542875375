import React from 'react';
import { Skeleton } from 'baseui/skeleton';
import TitleSkeleton from './TitleSkeleton';
import { RowContainer } from '../commonStyle/commonStyleForSkeleton';
import { allDirectionStyle } from '../../util/styleHelpers';

const TaskWithIconSkeleton = ({ noOfTasks = 1 }) =>
  [...Array(noOfTasks).keys()].map((item) => (
    <RowContainer key={item} data-testid='task-with-icon-skeleton'>
      <Skeleton
        width='50px'
        height='54px'
        animation
        overrides={{
          Root: {
            style: {
              ...allDirectionStyle({ radius: '10px', prop: 'border' })
            },
            props: { 'data-testid': 'square-icon-skeleton' }
          }
        }}
      />

      <TitleSkeleton
        rows={2}
        taskHeight='17px'
        taskWidth='220px'
        subTaskWidth='100px'
      />
    </RowContainer>
  ));

export default TaskWithIconSkeleton;
