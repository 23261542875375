import benefitsGuideLogo from 'newHirePortalRedesign/newHire/features/usefulResources/assets/benefitsGuideLogo.png';
import contactUsLogo from 'newHirePortalRedesign/newHire/features/usefulResources/assets/contactUsLogo.png';
import setupInstructionsLogo from 'newHirePortalRedesign/newHire/features/usefulResources/assets/setupInstructionsLogo.png';
import EmailWithText from 'newHirePortalRedesign/newHire/component/emailWithText/EmailWithText';
import LinkWithText from 'newHirePortalRedesign/newHire/features/usefulResources/components/linkWithText/LinkWithText';
import Benefits from 'assets/docs/Benefits.pdf';

export const usefulResourcesCashData = [
  {
    title: 'Pre onboarding Benefits guide',
    description: LinkWithText({
      text: `Please download the pre-onboarding FAQ with the link: `,
      link: Benefits,
      linkText: 'Pre-Onboarding Benefits FAQ',
      download: 'Onboarding FAQ: Pre-Start Date Qs'
    }),
    image: benefitsGuideLogo,
    altText: 'benefits guide logo',
    cardType: 'narrow'
  },
  {
    title: 'Laptop setup instructions',
    description: LinkWithText({
      text: `You will receive an email on your Day 1
    with instructions to set up your password. Once you have successfully
    created your password, you can set up your computer by following the
    instructions at `
    }),
    image: setupInstructionsLogo,
    altText: 'setup instructions logo',
    cardType: 'narrow'
  },
  {
    title: 'Contact us',
    description: EmailWithText({
      text: 'If you have any questions, contact',
      mailId: 'askpeople@squareup.com',
      suffixText:
        'Please include your full name, start date, role and question to best expedite things!'
    }),
    image: contactUsLogo,
    altText: 'contact us logo',
    cardType: 'wide'
  }
];
