import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  postProfileInfo,
  PostProfileInfoProps
} from 'newHirePortalRedesign/newHire/features/profileSubmit/api/profileSubmit';
import { handleApiResponse } from 'utils/api_helpers';

export const postProfile = createAsyncThunk<File, PostProfileInfoProps>(
  'profile/postProfile',
  async ({ bioContent, profileImage }, { rejectWithValue }) => {
    const response = await postProfileInfo({ bioContent, profileImage });
    const result = await handleApiResponse(response);
    if (result.ok) {
      return result.data;
    }
    return rejectWithValue(result.error);
  }
);
