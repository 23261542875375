import React from 'react';
import { Icon } from 'baseui/icon';

const SuccessSmiley = ({ size = 32, color, ...props }) => {
  const { title = 'SuccessSmiley', ...restProps } = props;
  return (
    <Icon
      viewBox='0 0 32 32'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 16C0 7.16444 7.16444 0 16 0C24.8356 0 32 7.16444 32 16C32 24.8356 24.8356 32 16 32C7.16444 32 0 24.8356 0 16ZM3.55556 16C3.55556 22.8622 9.13778 28.4444 16 28.4444C22.8622 28.4444 28.4444 22.8622 28.4444 16C28.4444 9.13778 22.8622 3.55556 16 3.55556C9.13778 3.55556 3.55556 9.13778 3.55556 16ZM11.0933 17.9733C11.9111 20 13.8489 21.3155 16 21.3155C18.1511 21.3155 20.0889 20.0178 20.9067 17.9733L24.2133 19.3244C22.8445 22.6844 19.6089 24.8711 16 24.8711C12.3911 24.8711 9.17334 22.7022 7.78668 19.3244L11.0933 17.9733ZM20.4444 14.2222C21.9172 14.2222 23.1111 13.0283 23.1111 11.5556C23.1111 10.0828 21.9172 8.88889 20.4444 8.88889C18.9717 8.88889 17.7778 10.0828 17.7778 11.5556C17.7778 13.0283 18.9717 14.2222 20.4444 14.2222ZM14.2222 11.5556C14.2222 13.0283 13.0283 14.2222 11.5556 14.2222C10.0828 14.2222 8.88889 13.0283 8.88889 11.5556C8.88889 10.0828 10.0828 8.88889 11.5556 8.88889C13.0283 8.88889 14.2222 10.0828 14.2222 11.5556Z'
        fill='#00D632'
      />
    </Icon>
  );
};

export default SuccessSmiley;
