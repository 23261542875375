import { createAsyncThunk } from '@reduxjs/toolkit';
import { healthCheck as healthCheckApi } from '../../api/healthCheck';
import { handleApiResponse } from '../../../../utils/api_helpers';

export const healthCheck = createAsyncThunk(
  'healthCheck',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await healthCheckApi();
      const result = await handleApiResponse(response);
      if (result.ok) {
        return result.data;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
