import { createSlice } from '@reduxjs/toolkit';
import { healthCheck } from './thunk';
import { API_STATUS } from '../../util/enums';

const healthCheckSlice = createSlice({
  name: 'healthCheck',
  initialState: {
    loadStatus: API_STATUS.IDLE,
    data: {
      online: null
    },
    error: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(healthCheck.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(healthCheck.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = action.payload;
      })
      .addCase(healthCheck.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      });
  }
});

const { reducer } = healthCheckSlice;

export default reducer;
