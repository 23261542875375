import React from 'react';
import { Block } from 'baseui/block';
import { MonoParagraphMedium } from 'baseui/typography';
import { Wrapper, ContentWapper } from '../CommonStyle';

const ShippingAddressDisplay = ({
  shippingData,
  wrapperStyle,
  ...contentStyle
}) => {
  const {
    addressLine1,
    addressLine2,
    administrativeDistrictLevel1,
    countryCode,
    city,
    postalCode,
    phone,
    email
  } = shippingData;

  return (
    <Wrapper data-testid='submitted-shipping-address' $style={wrapperStyle}>
      <MonoParagraphMedium>Shipping Address:</MonoParagraphMedium>
      <ContentWapper $style={contentStyle}>
        {addressLine1 || addressLine2 ? (
          <Block data-testid='shipping-address-line'>
            {addressLine1} {addressLine2 || null}
          </Block>
        ) : null}
        {city || administrativeDistrictLevel1 || postalCode ? (
          <Block data-testid='shipping-address-region'>
            {city ? `${city},` : null}
            {administrativeDistrictLevel1
              ? `${administrativeDistrictLevel1},`
              : null}
            {postalCode}
          </Block>
        ) : null}
        {countryCode ? (
          <Block data-testid='shipping-address-country-code'>
            {countryCode}
          </Block>
        ) : null}
        {phone ? (
          <Block data-testid='shipping-address-phone'>{phone}</Block>
        ) : null}
        {email ? (
          <Block data-testid='shipping-address-email'>{email}</Block>
        ) : null}
      </ContentWapper>
    </Wrapper>
  );
};
export default ShippingAddressDisplay;
