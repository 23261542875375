import React from 'react';
import { Banner, KIND, HIERARCHY } from 'baseui/banner';
import { MaintenanceBannerMessage } from 'newHirePortal/newHire/store/user/selectors';
import { useSelector } from 'react-redux';

const BannerMessage = () => {
  const message = useSelector(MaintenanceBannerMessage);

  return (
    <Banner
      title='Maintenance Update!'
      hierarchy={HIERARCHY.high}
      kind={KIND.warning}
    >
      {message}
    </Banner>
  );
};

export default BannerMessage;
