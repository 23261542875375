import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const PrimaryUserAvatar = ({ ...props }) => {
  const { title = 'primary user logo', size = 24, color, ...restProps } = props;
  const [, theme] = useStyletron();
  return (
    <Icon
      viewBox='0 0 24 23'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.9985 8.13351C19.0779 4.26823 16.0089 1.07503 12.1438 1.0013C8.27861 0.927564 5.08091 4.00121 5.00151 7.86649C4.9221 11.7318 7.99107 14.925 11.8562 14.9987C15.7214 15.0724 18.9191 11.9988 18.9985 8.13351Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 22C3.39426 18.3145 7.56408 15.9123 12.2593 16.0024C16.7751 16.0891 20.7112 18.4607 23 22M12.1438 1.0013C16.0089 1.07503 19.0779 4.26823 18.9985 8.13351C18.9191 11.9988 15.7214 15.0724 11.8562 14.9987C7.99107 14.925 4.9221 11.7318 5.00151 7.86649C5.08091 4.00121 8.27861 0.927564 12.1438 1.0013Z'
        fill={theme.colors.accent50}
        stroke='black'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
export default PrimaryUserAvatar;
