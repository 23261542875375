import React from 'react';

const SquareOneVideo = (
  <iframe
    src='https://cdnapisec.kaltura.com/p/3193793/sp/319379300/embedIframeJs/uiconf_id/47398243/partner_id/3193793?iframeembed=true&playerId=kaltura_player_1639410470&entry_id=1_9ouuy7k4'
    width='100%'
    height='738px'
    title="Jack's Square One Video"
    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
    allowFullScreen
    style={{ border: '2px solid #ffffff', paddingTop: '10px' }}
  />
);

export default SquareOneVideo;
