import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCoreMember as getCoreMemberApi } from 'newHirePortalRedesign/newHire/features/banner/api/coreMember';
import { handleApiResponse } from 'utils/api_helpers';
import { CoreMemberType } from 'newHirePortalRedesign/newHire/features/banner/store/selectors/selectors';

export const getCoreMember = createAsyncThunk<
  CoreMemberType,
  void,
  CustomApiError
>('coreMember/show', async (_args, thunkApi) => {
  try {
    const response = await getCoreMemberApi();
    const result = await handleApiResponse(response);
    if (result.ok) {
      return result.data as CoreMemberType;
    }
    return thunkApi.rejectWithValue(result.error);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return thunkApi.rejectWithValue(e);
  }
});
