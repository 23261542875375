import React from 'react';
import { Spinner } from 'baseui/spinner';
import { withStyle } from 'baseui';
import MiscCommonFrame from './MiscCommonFrame';

const ExtraLargeSpinner = withStyle(Spinner, {
  width: '96px',
  height: '96px',
  borderLeftWidth: '12px',
  borderRightWidth: '12px',
  borderTopWidth: '12px',
  borderBottomWidth: '12px',
  marginTop: '128px'
});

const LoadingPage = () => (
  <MiscCommonFrame>
    <ExtraLargeSpinner />
  </MiscCommonFrame>
);

export default LoadingPage;
