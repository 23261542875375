import React, { useState } from 'react';
import { Input } from 'baseui/input';
import { FormControl } from 'baseui/form-control';
import { useStyletron } from 'baseui';
import { Alert } from 'baseui/icon';
import { ParagraphMedium } from 'baseui/typography';
import TooltipWithIcon from './TooltipWithIcon';
import { allDirectionStyle } from '../util/styleHelpers';

const InputTooltip = ({ msg, theme }) => (
  <TooltipWithIcon
    content={() => (
      <ParagraphMedium
        width='100%'
        maxWidth='400px'
        color={theme.colors.mono100}
        marginBottom={0}
        marginTop={0}
      >
        {msg}
      </ParagraphMedium>
    )}
    icon={<Alert size={16} />}
    containerOverride={{ marginLeft: '24px' }}
  />
);

const inputRootStyle = ($isFocused, $isError) => {
  const [, theme] = useStyletron();
  let styles = {
    ...allDirectionStyle({
      radius: '5px',
      prop: 'border'
    })
  };
  let color;
  if ($isFocused) {
    color = theme.colors.backgroundAccent;
  } else if ($isError) {
    color = theme.colors.backgroundNegative;
  }

  if ($isError || $isFocused) {
    styles = {
      ...styles,
      ...allDirectionStyle({
        color,
        prop: 'border',
        style: 'solid'
      })
    };
  }
  return styles;
};

const FormField = ({
  id,
  name = id,
  type = 'text',
  value,
  dataFieldName,
  updateFormFunc,
  size,
  validator = () => true,
  required = true,
  placeholder = '',
  errorMessage = 'Invalid Input',
  CustomInput,
  tooltipMessage,
  testId,
  ...props
}) => {
  const [isValid, setIsValid] = React.useState(true);
  const [isVisited, setIsVisited] = useState(false);
  const shouldShowError = !isValid && isVisited;
  const [css, theme] = useStyletron();

  const handleChange = (e) => {
    const updatedValue = e.target.value;
    setIsValid(validator(updatedValue));
    setIsVisited(true);
    updateFormFunc(dataFieldName, updatedValue);
  };

  return (
    <div
      className={css({
        marginBottom: '24px',
        display: 'flex'
      })}
    >
      <FormControl
        error={shouldShowError ? errorMessage : null}
        overrides={{
          ControlContainer: {
            style: {
              maxWidth: '660px',
              marginBottom: 0,
              width: '65%'
            }
          }
        }}
      >
        {CustomInput ? (
          <CustomInput
            value={value}
            onChange={handleChange}
            error={shouldShowError}
            onBlurFunc={() => setIsVisited(true)}
            updateFormFunc={updateFormFunc}
            {...props}
          />
        ) : (
          <Input
            id={id}
            value={value}
            onChange={handleChange}
            onBlur={() => setIsVisited(true)}
            name={name}
            required={required ? 'required' : ''}
            size={size}
            placeholder={`${placeholder}${required ? '*' : ''}`}
            type={type}
            error={shouldShowError}
            overrides={{
              Root: {
                style: ({ $isFocused }) =>
                  inputRootStyle($isFocused, shouldShowError)
              },
              Input: {
                style: { backgroundColor: theme.colors.mono100 },
                props: { 'data-testid': testId }
              }
            }}
            {...props}
          />
        )}
      </FormControl>

      {tooltipMessage ? (
        <InputTooltip theme={theme} msg={tooltipMessage} />
      ) : null}
    </div>
  );
};

export default FormField;
