import { TASK_STATUS } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/constants';
import { WorkdayTask } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/selectors/selectors';
import { HumanTask } from 'newHirePortalRedesign/newHire/app/store/slice/slice';

export const isTaskComplete = (task: WorkdayTask | HumanTask): boolean =>
  task.state === TASK_STATUS.COMPLETED;

export const isAllTaskCompleted = (
  tasks: (WorkdayTask | HumanTask)[]
): boolean => tasks.every((task) => isTaskComplete(task));
