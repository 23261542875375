import React from 'react';
import { useSelector } from 'react-redux';
import { profileTaskCompleted } from '../../../store/user/selectors';
import PresentationPage from './PresentationPage';
import ProfileSubmit from './ProfileSubmit';

const Page = () => {
  const completed = useSelector(profileTaskCompleted);
  return completed ? <PresentationPage /> : <ProfileSubmit />;
};

export default Page;
