import React from 'react';
import { Icon } from 'baseui/icon';

const HomeIcon = ({ ...props }) => {
  const { title = 'Home logo', size, ...restProps } = props;

  return (
    <Icon viewBox='0 0 30 29' title={title} size={size} {...restProps}>
      <path
        d='M29.2364 14.053L15.1431 0.177914C14.9028 -0.0593046 14.5179 -0.0593046 14.2773 0.177914L0.183907 14.053C0.0056655 14.2291 -0.0485819 14.4948 0.045708 14.7264C0.140855 14.9584 0.366458 15.1096 0.616593 15.1096H4.38158V27.7503C4.38158 28.0917 4.65798 28.3676 4.99897 28.3676H11.6907C12.0313 28.3676 12.3081 28.0917 12.3081 27.7503L12.3077 19.4018C12.3077 18.0767 13.3853 16.9978 14.7105 16.9978C16.0357 16.9978 17.1133 18.0758 17.1133 19.4018V27.7507C17.1133 28.0921 17.3893 28.3681 17.7307 28.3681H24.4225C24.7639 28.3681 25.0399 28.0921 25.0399 27.7507L25.0395 15.11H28.8045C29.055 15.11 29.2802 14.9589 29.3762 14.7268C29.4701 14.4948 29.4158 14.2287 29.2367 14.053L29.2364 14.053ZM23.8044 27.1335H18.3469V19.402C18.3469 17.3962 16.7152 15.7636 14.7098 15.7636C12.7044 15.7636 11.0727 17.3961 11.0727 19.402L11.0722 27.1335H5.61477V14.6284L14.6977 5.70173L23.8039 14.6512L23.8044 27.1335ZM24.8196 13.8756C24.8071 13.8756 24.7972 13.8816 24.7856 13.8825C24.7817 13.8799 24.7787 13.8764 24.7752 13.873L15.1313 4.39441C14.8907 4.15848 14.5062 4.15848 14.266 4.39441L4.6195 13.8755H2.12365L14.7103 1.48393L27.297 13.8755L24.8196 13.8756Z'
        fill='#E5F0FF'
      />
    </Icon>
  );
};
export default HomeIcon;
