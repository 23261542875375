import React from 'react';
import { useStyletron } from 'baseui';
import Check from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/icons/Check';
import { TASK_STATUS } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/constants';
import AlertIcon from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/icons/AlertIcon';
import { TaskStatus } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/selectors/selectors';

interface StatusIconProps {
  status: TaskStatus;
  isTaskCompleted: boolean;
}

const StatusIcon = ({ status, isTaskCompleted }: StatusIconProps) => {
  const [css, theme] = useStyletron();

  // Due in the future OR No due date (diff is NaN)
  let icon = (
    <span
      data-testid='empty-circle-icon'
      className={css({
        border: `3px solid ${theme.colors.mono300}`,
        borderRadius: theme.sizing.scale1400,
        height: theme.sizing.scale800,
        width: theme.sizing.scale800,
        boxSizing: 'border-box'
      })}
    />
  );

  if (isTaskCompleted) {
    icon = <Check />;
  } else if (status === TASK_STATUS.OVERDUE) {
    // Overdue - passed due date or it's today
    icon = (
      <AlertIcon size={theme.sizing.scale800} color={theme.colors.warning} />
    );
  }
  return icon;
};

export default StatusIcon;
