import React from 'react';
import { MonoParagraphMedium } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';
import { Alert } from 'baseui/icon';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import AccentButton from '../../../components/AccentButton';
import TooltipWithIcon from '../../../components/TooltipWithIcon';
import MonoButton from '../../../components/MonoButton';
import BioForm from './BioForm';
import ProfileImageForm from './ProfileImageForm';
import { profileSubmitted } from '../../../store/user/slice';
import toastTrigger from '../toastTrigger';
import { postProfile } from '../../../store/profile/thunk';
import ConfirmModal from '../ConfirmModal';
import FormErrorLine from '../../../components/FormErrorLine';
import { THEME_NAME } from '../../../util/enums';
import CashImgWrap from '../../../components/CashImgWrap';
import cubAngle from '../../../../../assets/images/cubAngle.png';
import PrivacyLink from '../../../components/PrivacyLink';

const FormWrap = styled('div', () => ({
  position: 'relative',
  bottom: '-55px'
}));

const LinkWrap = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const ButtonWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: $theme.sizing.scale900
}));

const ProfileSubmit = () => {
  const dispatch = useDispatch();
  const [bio, setBio] = React.useState('');
  const initialImageObj = {
    file: null,
    imageSrc: null,
    imageName: '',
    imageField: null
  };
  const [imageObj, setImageObj] = React.useState(initialImageObj);
  const [, theme] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const postStatus = useSelector((state) => state.profile.postStatus);
  const [submitError, setSubmitError] = React.useState(null);

  const onReset = () => {
    // TODO add test case for this https://jira.sqprod.co/browse/BTE-457
    // Clear the actual input (with type `file`) field
    if (imageObj.imageField && imageObj.imageField.current) {
      imageObj.imageField.current.value = null;
    }
    setImageObj(initialImageObj);
    setBio('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const onConfirm = () => {
    dispatch(
      postProfile({
        bioContent: bio,
        profileImage: imageObj.file
      })
    )
      .then(unwrapResult) // use unwrapResult to help throw the original error
      .then(() => {
        setIsOpen(false);
        dispatch(profileSubmitted());
        toastTrigger({ success: true });
      })
      .catch((e) => {
        setSubmitError(e.message);
        // calling toast here to avoid rendering during state transition warning
        toastTrigger({ success: false });
        setIsOpen(false);
      });
  };

  return (
    <>
      <BioForm bio={bio} setBio={setBio} />

      <ProfileImageForm imageObj={imageObj} setImageObj={setImageObj} />

      {submitError ? <FormErrorLine message={submitError} /> : null}
      <LinkWrap>
        <PrivacyLink marginTop='45px' />
        <ButtonWrapper>
          <TooltipWithIcon
            msg='Fields cannot be edited after submitting'
            icon={
              <Alert
                size={16}
                overrides={{ Svg: { style: { marginRight: '36px' } } }}
              />
            }
          />

          <MonoButton
            onClick={onReset}
            marginRight={theme.margins.normal}
            label='Reset'
            buttonNameForTracking='Reset Personal Profile'
          />

          <AccentButton
            onClick={onSubmit}
            label='Submit'
            disabled={bio.length === 0}
            buttonNameForTracking='Submit Personal Profile'
          />
        </ButtonWrapper>
      </LinkWrap>
      {theme.name === THEME_NAME.CASH ? (
        <FormWrap>
          <CashImgWrap
            position='absolute'
            left='-50px'
            top='60px'
            src={cubAngle}
            alt='cubeAngle'
          />
        </FormWrap>
      ) : (
        ' '
      )}

      <ConfirmModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={onConfirm}
        submitStatus={postStatus}
        modelContent={
          <MonoParagraphMedium>
            Bio and photo cannot be changed after submission.
          </MonoParagraphMedium>
        }
        dataNameToConfirm='Personal Profile'
      />
    </>
  );
};

export default ProfileSubmit;
