/* eslint-disable max-len */
import React from 'react';
import { Button } from 'baseui/button';
import { useStyletron } from 'baseui';

const ButtonComp = ({
  label,
  color,
  backColor,
  width,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  fontWeight = '600',
  height = '48px',
  borderRadius,
  paddingLeft = '16px',
  paddingRight = '16px',
  float,
  buttonNameForTracking,
  ...restProps
}) => {
  const [, theme] = useStyletron();

  if (!borderRadius) {
    borderRadius = theme.button.borderRadius;
  }

  return (
    <Button
      overrides={{
        BaseButton: {
          style: () => ({
            backgroundColor: backColor,
            color,
            width,
            marginTop,
            marginRight,
            marginBottom,
            marginLeft,
            fontWeight,
            height,
            paddingLeft,
            paddingRight,
            float,
            borderTopLeftRadius: borderRadius,
            borderBottomLeftRadius: borderRadius,
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius
          }),
          props: { 'data-dd-action-name': buttonNameForTracking }
        }
      }}
      {...restProps}
    >
      {label}
    </Button>
  );
};
export default ButtonComp;
