import React from 'react';
import { MonoParagraphMedium, ParagraphMedium } from 'baseui/typography';
import Form from './Form';

const Messages = () => (
  <>
    <MonoParagraphMedium>
      Please provide an address where the selected hardware and accessories
      should be shipped to. This may be different from your home address.
    </MonoParagraphMedium>

    <MonoParagraphMedium>
      Please note that we do not ship to PO boxes and the information is not
      editable after submission.
    </MonoParagraphMedium>

    <ParagraphMedium>The fields marked by * are required.</ParagraphMedium>
  </>
);

const FormSubmissionPage = () => (
  <>
    <Messages />
    <Form />
  </>
);

export default FormSubmissionPage;
