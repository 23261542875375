import React from 'react';
import { useStyletron } from 'baseui';
import PrivacyNotice from 'assets/docs/PrivacyNotice.pdf';
import arrow from 'newHirePortalRedesign/newHire/features/assets/arrow.svg';
import CustomizableImage from 'newHirePortalRedesign/newHire/component/customizableImage/CustomizableImage';
import { ParagraphMedium, HeadingLarge } from 'baseui/typography';
import EmailSupport from 'newHirePortalRedesign/newHire/component/emailSupport/EmailSupport';
import LinkWithText from 'newHirePortalRedesign/newHire/features/usefulResources/components/linkWithText/LinkWithText';

const YourProfile = () => {
  const [css, theme] = useStyletron();
  return (
    <>
      <HeadingLarge>Your profile</HeadingLarge>
      <ParagraphMedium>
        Thank you for submitting your bio and profile picture. An email
        confirmation has been sent to you upon submission.
      </ParagraphMedium>

      <ParagraphMedium>
        To request a change please contact&nbsp;
        <EmailSupport email='mission-control@squareup.com' />
      </ParagraphMedium>

      <ParagraphMedium className={css({ fontWeight: 700 })}>
        {LinkWithText({
          text: '',
          link: PrivacyNotice,
          linkText: 'Read our Privacy Notice here',
          download: 'Square Employee Privacy Notice'
        })}
      </ParagraphMedium>

      <a
        className={css({
          ...theme.typography.LabelMedium,
          color: theme.colors.buttonPrimaryFill,
          textDecorationLine: 'none'
        })}
        href='/'
      >
        <CustomizableImage
          style={{
            display: 'inline',
            width: theme.sizing.scale600,
            marginRight: theme.sizing.scale400
          }}
          src={arrow}
          alt='Back arrow'
        />
        Back to portal dashboard
      </a>
    </>
  );
};

export default YourProfile;
