import React from 'react';
import { useStyletron } from 'baseui';
import { ParagraphLarge } from 'baseui/typography';
import CustomCard from 'newHirePortalRedesign/newHire/component/customCard/CustomCard';
import {
  flexCenter,
  iconAnimation,
  buttonStyle,
  apiRetryMsgStyle
} from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/failedWorkdayCard/Styles';
import { setCountApiFailedCall } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/slice/slice';
import { getUserWorkdayTasks } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/thunk/thunk';
import { useAppDispatch } from 'newHirePortalRedesign/newHire/store/hooks';
import RefreshCircleArrowIcon from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/icons/RefreshCircleArrowIcon';

interface FailedWorkdayCardProps {
  isLoading: boolean;
  apiFailedCallCount: number;
}

const FailedWorkdayCard = ({
  isLoading,
  apiFailedCallCount
}: FailedWorkdayCardProps) => {
  const [css, theme] = useStyletron();
  const dispatch = useAppDispatch();

  const handleRefreshClick = () => {
    dispatch(setCountApiFailedCall(apiFailedCallCount + 1));
    dispatch(getUserWorkdayTasks());
  };

  return (
    <CustomCard
      rootStyleOverride={{
        ...flexCenter,
        height: '416px'
      }}
      bodySection={
        <div
          className={css({
            ...flexCenter,
            flexDirection: 'column'
          })}
        >
          {apiFailedCallCount < 2 || isLoading ? (
            <>
              <RefreshCircleArrowIcon
                iconStyle={isLoading ? iconAnimation : {}}
              />
              {!isLoading && (
                <>
                  <ParagraphLarge marginBottom={theme.sizing.scale100}>
                    Failed to load
                  </ParagraphLarge>
                  <button
                    onClick={handleRefreshClick}
                    className={css(buttonStyle(theme))}
                  >
                    Refresh
                  </button>
                </>
              )}
            </>
          ) : (
            <ParagraphLarge>
              Workday is having some technical issues.
              <span className={css(apiRetryMsgStyle(theme))}>
                &nbsp;Please retry in a few hours.
              </span>
            </ParagraphLarge>
          )}
        </div>
      }
    />
  );
};

export default FailedWorkdayCard;
