/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { styled, useStyletron } from 'baseui';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import UsernameErrorPage from '../../MiscPages/UsernameErrorPage';
import toastTrigger from '../toastTrigger';
import ConfirmModal from '../ConfirmModal';
import { enabledHumanTasks as humanTasks } from '../../../store/user/selectors';
import { dateWithMonthdd } from '../../../util/dateHelpers';
import { getUsernames, postUsername } from '../../../store/username/thunk';
import { usernameSubmitted } from '../../../store/user/slice';
import UsernameFrame from './UsernameFrame';
import AccentButton from '../../../components/AccentButton';
import UseravatarWithBg from '../../../../../components/icons/UseravatarWithBg';
import LoadingIndicator from '../../../components/LoadingIndicator';
import NoUsernamePage from './NoUsernames';
import UsernameAlreadyAssigned from './UsernameAlreadyAssigned';
import { getUsernameTask } from '../../../store/user/helpers';
import AlertInfo from './AlertInfo';
import { API_STATUS } from '../../../util/enums';
import PrimaryUserAvatar from '../../../../../components/icons/PrimaryUserAvatar';
import { RESERVED_USERNAME_ACTION_WHEN_GET_USERNAMES } from './constants';

const UsernameFrameWrap = styled('div', ({ $theme }) => ({
  boxShadow: $theme.shadow.shadowStatic,
  borderRadius: '6px',
  position: 'relative',
  overflow: 'hidden',
  display: 'block',
  margin: '25px 0',
  padding: '15px'
}));

const LogoInfoWrap = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  backgroundColor: $theme.colors.accent50,
  padding: '16px 24px',
  borderRadius: '6px'
}));

const ButtonWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: $theme.sizing.scale900
}));

const LogoMsgWrap = styled('div', ({ $theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${$theme.colors.mono200}`,
  marginBottom: '20px',
  alignItems: 'flex-start'
}));

const PickupDueDate = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '15px',
  marginBottom: '15px'
}));

const PickupInfo = () => {
  const [css, theme] = useStyletron();
  const tasks = useSelector(humanTasks);
  const usernameSelectTask = getUsernameTask(tasks);

  return (
    <LogoMsgWrap>
      <UseravatarWithBg />
      <PickupDueDate>
        <span
          className={css({
            ...theme.typography.MonoParagraphMedium,
            lineHeight: '17px',
            paddingBottom: '5px'
          })}
        >
          Pick the username of your choice. We’ll have it set up for you on your
          start date.
        </span>
        <div
          className={css({
            ...theme.typography.ParagraphMedium,
            display: 'inline-flex'
          })}
        >
          <span
            className={css({
              color: theme.colors.mono500
            })}
          >
            Due date for username selection :
          </span>
          <span
            className={css({
              color: theme.colors.warning300,
              marginLeft: '5px'
            })}
          >
            {dateWithMonthdd(usernameSelectTask.due_date)}
          </span>
        </div>
      </PickupDueDate>
    </LogoMsgWrap>
  );
};

const UsernameSelection = () => {
  const dispatch = useDispatch();
  const usernamesData = useSelector((state) => state.usernames.data.usernames);
  const actionPerformedWhenGetUsernames = useSelector(
    (state) => state.usernames.data.performed_action
  );
  const usernamesLoadStatus = useSelector(
    (state) => state.usernames.loadStatus
  );
  const postStatus = useSelector((state) => state.usernames.postStatus);
  const [chosenUsername, setChosenUsername] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [css, theme] = useStyletron();

  const onSubmit = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const onConfirm = () => {
    dispatch(postUsername({ selectedUsername: chosenUsername }))
      .then(unwrapResult)
      .then(() => {
        setIsOpen(false);
        dispatch(usernameSubmitted());
        toastTrigger({ success: true });
      })
      .catch(() => {
        toastTrigger({
          success: false,
          failureMsg:
            'Something went wrong. Please refresh the page and try again. Thanks'
        });
        setIsOpen(false);
      });
  };

  useEffect(() => {
    if (usernamesLoadStatus === API_STATUS.IDLE) {
      dispatch(getUsernames());
    }
    if (
      usernamesLoadStatus === API_STATUS.COMPLETED &&
      usernamesData.length !== 0
    ) {
      setChosenUsername(usernamesData[0]);
    }
  }, [usernamesLoadStatus]);

  if (usernamesLoadStatus === API_STATUS.FAILED) {
    return <UsernameErrorPage />;
  }

  if (usernamesLoadStatus === API_STATUS.COMPLETED) {
    if (usernamesData.length === 0) {
      return <NoUsernamePage />;
    }

    if (
      usernamesData.length === 1 &&
      actionPerformedWhenGetUsernames.type ===
        RESERVED_USERNAME_ACTION_WHEN_GET_USERNAMES
    ) {
      return (
        <UsernameAlreadyAssigned
          username={actionPerformedWhenGetUsernames.output}
        />
      );
    }

    return (
      <form onSubmit={onSubmit} data-testid='username-form'>
        <PickupInfo />
        <UsernameFrameWrap>
          <UsernameFrame
            usernamesList={usernamesData}
            option={chosenUsername}
            setOption={setChosenUsername}
          />
        </UsernameFrameWrap>
        <AlertInfo
          infoLines={[
            'Available username options may change based on the availability.',
            'If a choice is not made, we’ll assign you one of the available usernames once the due date is passed.'
          ]}
        />
        <ButtonWrapper>
          <AccentButton
            label='Submit'
            type='submit'
            buttonNameForTracking='Submit Username Selection'
          />
        </ButtonWrapper>
        <ConfirmModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onConfirm={onConfirm}
          submitStatus={postStatus}
          chosenUsername={chosenUsername}
          modalBodyHeadLine='Please verify your selection:'
          modalHeader='Confirm your username'
          dataNameToConfirm='Username Selection'
          modelContent={
            <LogoInfoWrap>
              <PrimaryUserAvatar />
              <span
                className={css({
                  ...theme.typography.MonoParagraphLarge,
                  color: theme.colors.contentAccent,
                  marginLeft: '25px'
                })}
              >
                {chosenUsername}
              </span>
            </LogoInfoWrap>
          }
        />
      </form>
    );
  }

  return <LoadingIndicator width='100%' />;
};

export default UsernameSelection;
