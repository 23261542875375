import React from 'react';
import CustomModal from 'newHirePortalRedesign/newHire/features/banner/components/modal/customModal/CustomModal';
import SquareOneVideo from 'newHirePortalRedesign/newHire/features/banner/video/SquareOneVideo';
import { allDirectionStyle } from 'newHirePortalRedesign/newHire/util/styleHelpers';
import { useStyletron } from 'baseui';

interface BuVideoModalProps {
  isModalOpen: boolean;
  handleOnClose: () => void;
}

const BuVideoModal = ({ isModalOpen, handleOnClose }: BuVideoModalProps) => {
  const [, theme] = useStyletron();
  return (
    <CustomModal
      isOpen={isModalOpen}
      handleOnClose={handleOnClose}
      bodySection={SquareOneVideo}
      dialogStyleOverride={{
        padding: 0,
        width: '1318px',
        height: '795px',
        ...allDirectionStyle({ radius: '0', prop: 'border' }),
        backgroundColor: theme.colors.mono1000
      }}
      closeStyleOverride={{
        marginTop: theme.sizing.scale400,
        backgroundColor: theme.colors.mono100
      }}
    />
  );
};

export default BuVideoModal;
