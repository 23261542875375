import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { validate as validateEmail } from 'email-validator';

export const isValidShippingData = (state) => {
  const shippingData = state.shippingAddress.shippingInput;

  return (
    isPossiblePhoneNumber(shippingData.phone) &&
    validateEmail(shippingData.email)
  );
};

export const isModalOpenOnFormSubmit = (state) =>
  state.shippingAddress.isModalOpen;

export const shippingAddressSubmitError = (state) =>
  state.shippingAddress.submitError;
