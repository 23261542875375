import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from 'baseui';
import TaskListItem from '../../../../components/TaskList/TaskListItem';
import { isTaskComplete } from '../../store/user/helpers';
import { enabledHumanTasks as humanTasks } from '../../store/user/selectors';
import { dateWithDayInMonth, utcToLocal } from '../../util/dateHelpers';
import CashPathIcon from '../../components/CashPathIcon';
import home from '../../../../assets/images/home.png';
import windowFace from '../../../../assets/images/windowFace.png';

const List = styled('ul', ({ $theme }) => ({
  color: $theme.colors.accent,
  width: '100%',
  paddingLeft: 0,
  paddingRight: 0
}));

const YourInfoTaskList = () => {
  const tasks = useSelector(humanTasks);

  return (
    <List>
      {tasks.map((task) => (
        <TaskListItem
          key={task.name}
          task={task}
          completed={isTaskComplete(task)}
          name={task.name}
          dueDate={dateWithDayInMonth(utcToLocal(task.due_date).toDateString())}
          path={task.targetLink}
          completedBy={task.completed_by}
        />
      ))}

      <CashPathIcon
        right='-23px'
        shipTop='370px'
        shipSrc={home}
        shipAlt='home'
        profileTop='460px'
        profileSrc={windowFace}
        profileAlt='windowFace'
      />
    </List>
  );
};

export default YourInfoTaskList;
