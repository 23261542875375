import React from 'react';
import { useStyletron } from 'baseui';
import {
  isBlockTheme,
  isSquareTheme
} from 'newHirePortal/newHire/store/user/helpers';
import InfoWrapper from 'newHirePortal/newHire/components/InfoWrapper';
import WeekOneMsgs from './WeekOneMsgs';

const WeekOne = () => {
  const [, theme] = useStyletron();
  let weekOneMsg = 'Week 1';

  if (isSquareTheme(theme) || isBlockTheme(theme)) {
    weekOneMsg = 'What to Expect During Week 1';
  }
  return (
    <>
      <InfoWrapper
        Header={weekOneMsg}
        ModalHead={weekOneMsg}
        Modalcontent={<WeekOneMsgs />}
        height='auto'
      >
        <WeekOneMsgs />
      </InfoWrapper>
    </>
  );
};
export default WeekOne;
