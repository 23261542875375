import React from 'react';
import { ListItem, ListItemLabel } from 'baseui/list';
import { MonoParagraphSmall } from 'baseui/typography';
import { useSelector } from 'react-redux';
import { Block } from 'baseui/block';
import { useStyletron, withStyle } from 'baseui';
import { StyledLink } from 'baseui/link';
import { useHistory } from 'react-router-dom';
import DueDateText from '../../newHirePortal/newHire/components/DueDateText';
import Check from '../icons/Check';
import ClipboardWithBackground from '../icons/ClipboardWithBackground';
import XCircle from '../icons/XCircle';
import { TASK_COMPLETED_BY } from '../../newHirePortal/newHire/util/enums';
import { RESERVED_USERNAME_ACTION_WHEN_GET_USERNAMES } from '../../newHirePortal/newHire/containers/YourInfo/Username/constants';
import {
  isTaskOptional,
  isTaskNameUsername
} from '../../newHirePortal/newHire/store/user/helpers';
import { getTitleBasedOnHardwareVisibility } from '../../newHirePortal/newHire/store/hardware/selectors';

const ItemEnd = ({ completed }) => {
  const [, theme] = useStyletron();
  let icon;
  let content;
  let contentColor;
  if (completed) {
    icon = <Check size={24} color={theme.colors.success_fill} />;
    content = 'Submitted';
    contentColor = theme.colors.success_text;
  } else {
    icon = <XCircle size={24} color={theme.colors.mono300} />;
    content = 'Not submitted';
    contentColor = theme.colors.contentPrimary;
  }

  return (
    <Block
      overrides={{
        Block: {
          style: {
            textAlign: 'center',
            width: '100px'
          },
          props: {
            'data-testid': 'icon-block'
          }
        }
      }}
    >
      {icon}
      <MonoParagraphSmall color={contentColor} margin='0px'>
        {content}
      </MonoParagraphSmall>
    </Block>
  );
};

const TaskListItem = ({ completed, path, name, dueDate, completedBy }) => {
  const [, theme] = useStyletron();
  const history = useHistory();
  const titleName = useSelector((state) =>
    getTitleBasedOnHardwareVisibility(state, name)
  );

  const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
    color: completed
      ? $theme.colors.success_text
      : $theme.colors.contentPrimary,
    ...$theme.typography.MonoParagraphMedium,
    textDecoration: 'inherit'
  }));

  let endEnhancer = <ItemEnd completed={completed} />;
  if (isTaskNameUsername(name)) {
    const usernamesData = useSelector(
      (state) => state.usernames.data.usernames
    );

    const actionPerformedWhenGetUsernames = useSelector(
      (state) => state.usernames.data.performed_action
    );
    if (
      completedBy === TASK_COMPLETED_BY.ADMIN ||
      actionPerformedWhenGetUsernames.type ===
        RESERVED_USERNAME_ACTION_WHEN_GET_USERNAMES ||
      usernamesData.length === 0
    ) {
      endEnhancer = null;
    }
  }

  return (
    <ListItem
      artwork={() => <ClipboardWithBackground completed={completed} />}
      endEnhancer={() => endEnhancer}
      overrides={{
        Content: {
          style: () => ({
            borderBottomStyle: 'none',
            paddingRight: '0px'
          })
        },
        ArtworkContainer: {
          style: () => ({ marginRight: '8px' })
        },
        Root: {
          style: { marginBottom: theme.sizing.scale850 },
          props: { 'data-testid': 'task-list-item-component' }
        }
      }}
    >
      <ListItemLabel
        description={dueDate}
        overrides={{
          LabelDescription: {
            component: ({ children }) => (
              <DueDateText
                isOptionalTask={isTaskOptional(name)}
                dueDate={dueDate}
              >
                {children}
              </DueDateText>
            )
          }
        }}
      >
        <MyStyledLink
          onClick={(e) => {
            e.preventDefault();
            history.push(path);
          }}
          href={path}
        >
          {' '}
          {titleName}
        </MyStyledLink>
      </ListItemLabel>
    </ListItem>
  );
};

export default TaskListItem;
