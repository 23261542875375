import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'baseui/radio';
import { MonoParagraphMedium } from 'baseui/typography';
import { styled } from 'baseui';
import StyledRadioGroup, {
  radioOverrides
} from 'newHirePortal/newHire/containers/YourInfo/HardwareShipping/StyledRadioGroup';
import { getLaptopLanguageDisplay } from 'newHirePortal/newHire/util/languageOptions';
import { selectLanguage } from '../../../../store/hardware/slice';
import { findLanguageBySQPN } from '../../../../util/hardwareHelper';

const radioStyleOverrides = radioOverrides({ width: '155px' });

const PickLangWrap = styled('span', () => ({
  display: 'flex',
  justifyContent: 'flex-start'
}));

const PickLanguage = ({ languageArray }) => {
  const dispatch = useDispatch();

  const selectedLaptopLanguage = useSelector(
    (state) => state.hardwareSelection.selectedHardware.laptopLanguage
  );

  const [languageOption, setLanguageOption] = useState(
    selectedLaptopLanguage
      ? selectedLaptopLanguage.sq_part_number
      : languageArray[0].sq_part_number
  );

  const selectedLang = findLanguageBySQPN(languageArray, languageOption);

  useEffect(() => {
    dispatch(selectLanguage(selectedLang));
  }, [selectedLang]);

  useEffect(() => {
    if (
      selectedLaptopLanguage &&
      Object.keys(selectedLaptopLanguage).length > 0
    ) {
      setLanguageOption(selectedLaptopLanguage.sq_part_number);
    } else {
      setLanguageOption(languageArray[0].sq_part_number);
    }
  }, [languageArray, selectedLaptopLanguage]);

  return (
    <PickLangWrap>
      <StyledRadioGroup
        currentValue={languageOption}
        setCurrentValue={setLanguageOption}
        testId='lang-radio-group'
        radioBtnName='Laptop Language'
      >
        {languageArray.map((language) => (
          <Radio
            overrides={radioStyleOverrides}
            key={language.name}
            value={language.sq_part_number}
          >
            <MonoParagraphMedium>
              {getLaptopLanguageDisplay(language)}
            </MonoParagraphMedium>
          </Radio>
        ))}
      </StyledRadioGroup>
    </PickLangWrap>
  );
};
export default PickLanguage;
