import React from 'react';
import { useStyletron, withStyle } from 'baseui';
import { StyledLink } from 'baseui/link';
import CustomCard from 'newHirePortalRedesign/newHire/component/customCard/CustomCard';
import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import { Block } from 'baseui/block';
import CustomImageWithDescription from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/components/customImageWithDescription/CustomImageWithDescription';

interface MediaCardProps {
  image: string;
  altName: string;
  title: string;
  description: string;
  buttonName: string;
  onClick?: () => void;
}

const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
  textDecoration: 'inherit',
  cursor: 'pointer',
  color: $theme.colors.contentAccent,
  ':visited': $theme.colors.contentAccent
}));

const MediaCard = ({
  image,
  altName,
  title,
  description,
  buttonName,
  onClick
}: MediaCardProps) => {
  const [, theme] = useStyletron();

  return (
    <CustomCard
      contentStyleOverride={{
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0
      }}
      rootStyleOverride={{
        [theme.mediaQuery.small]: {
          marginBottom: theme.sizing.scale700,
          width: '100%',
          height: '460px'
        },
        [theme.mediaQuery.medium]: {
          marginBottom: theme.sizing.scale700,
          width: '100%',
          height: '528px'
        },
        [theme.mediaQuery.large]: {
          marginRight: theme.sizing.scale700,
          width: '649px',
          height: '528px'
        }
      }}
      bodySection={
        <CustomImageWithDescription
          height='auto'
          flexDirection='column'
          text='New'
          image={<img src={image} alt={altName} width='100%' height='100%' />}
          overrideStyle={{
            [theme.mediaQuery.small]: {
              height: '248px'
            },
            [theme.mediaQuery.medium]: {
              height: '365px'
            },
            [theme.mediaQuery.large]: {
              height: '365px'
            }
          }}
        >
          <Block paddingLeft={theme.sizing.scale750}>
            <HeadingSmall margin={0}>{title}</HeadingSmall>
            <ParagraphMedium
              marginTop={theme.sizing.scale400}
              marginBottom={theme.sizing.scale300}
              color={theme.colors.mono700}
            >
              {description}
            </ParagraphMedium>
            <MyStyledLink onClick={onClick}>{buttonName}</MyStyledLink>
          </Block>
        </CustomImageWithDescription>
      }
    />
  );
};

export default MediaCard;
