import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MonoParagraphLarge } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';
import { useHistory } from 'react-router-dom';
import { Block } from 'baseui/block';
import ShowField from './ShowField';
import shippingFormFields from './formFields';
import { API_STATUS, THEME_NAME } from '../../../util/enums';
import { getShippingAddress } from '../../../store/shippingAddress/thunk';
import AccentButton from '../../../components/AccentButton';
import { getPathByName } from '../../../util/routes';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PrivacyLink from '../../../components/PrivacyLink';
import HandsUpNotes from '../../../components/HandsUpNotes';
import PresentationErrorPage from '../PresentationErrorPage';
import { nextPendingTaskSelector } from '../../../store/user/selectors';

const Highlight = styled('a', ({ $theme }) => ({
  color: $theme.colors.contentAccent,
  textDecoration: 'inherit',
  marginLeft: '4px'
}));

const LinkWrap = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const ContactSupport = ({ email }) => (
  <Highlight href={`mailto: ${email}`}>{email}</Highlight>
);

const PresentationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const shippingData = useSelector((state) => state.shippingAddress.data);
  const loadStatus = useSelector((state) => state.shippingAddress.loadStatus);
  const nextTask = useSelector(nextPendingTaskSelector);
  const [, theme] = useStyletron();

  useEffect(() => {
    if (loadStatus === API_STATUS.IDLE) {
      dispatch(getShippingAddress());
    }
  }, [loadStatus]);

  if (loadStatus === API_STATUS.LOADING) {
    return <LoadingIndicator height='50%' />;
  }

  if (loadStatus === API_STATUS.FAILED) {
    return <PresentationErrorPage dataTestId='shipping' />;
  }

  return (
    <Block data-testid='presentation-page'>
      <MonoParagraphLarge marginBottom='0px' marginTop='0px' paddingTop='8px'>
        To request a change, please contact
        <ContactSupport email={theme.supportEmail} />
      </MonoParagraphLarge>

      {shippingFormFields.map((field) => {
        const fieldValue = shippingData[field.dataFieldName];
        if (
          field.skipPresentation ||
          fieldValue === undefined ||
          fieldValue === null ||
          fieldValue.length === 0
        ) {
          return null;
        }

        const value = field.formatter
          ? field.formatter(fieldValue)
          : fieldValue;
        const Field = field.presentationComponent || ShowField;
        return (
          <Field
            key={field.id}
            value={value}
            label={field.placeholder}
            {...field}
          />
        );
      })}
      {theme.name === THEME_NAME.CASH && <HandsUpNotes />}
      <LinkWrap>
        <PrivacyLink marginTop='37px' />
        <AccentButton
          label='Next'
          onClick={() => history.push(getPathByName(nextTask.name))}
          float='right'
          marginTop='24px'
        />
      </LinkWrap>
    </Block>
  );
};

export default PresentationPage;
