import React from 'react';
import { Icon } from 'baseui/icon';

const LaptopIcon = ({ ...props }) => {
  const { title = 'laptop logo', size, ...restProps } = props;

  return (
    <Icon viewBox='0 0 28 17' title={title} size={size} {...restProps}>
      <path
        d='M25.6667 14V3.5C25.6667 1.56333 24.1033 0 22.1667 0H5.83333C3.89667 0 2.33333 1.56333 2.33333 3.5V14H0V16.3333H2.33333H25.6667H28V14H25.6667ZM4.66667 14V3.5C4.66667 2.85833 5.19167 2.33333 5.83333 2.33333H22.1667C22.8083 2.33333 23.3333 2.85833 23.3333 3.5V14H4.66667Z'
        fill='#E5F0FF'
      />
    </Icon>
  );
};
export default LaptopIcon;
