import React from 'react';
import { useStyletron } from 'baseui';
import { ParagraphLarge } from 'baseui/typography';
import ExpiredIcon from '../../components/ExpiredIcon';
import HeadLine from './Headline';
import MiscCommonFrame from './MiscCommonFrame';
import { getLinkExpiryMsg } from './LinkExpiryMessages';

const LinkExpiredPage = () => {
  const [css, theme] = useStyletron();

  return (
    <MiscCommonFrame>
      <HeadLine fontWeight='300'>Link Expired!</HeadLine>
      <span
        className={css({
          marginTop: '60px'
        })}
      >
        <ExpiredIcon />
      </span>
      <ParagraphLarge marginTop='64px'>
        {getLinkExpiryMsg(theme)}
      </ParagraphLarge>
    </MiscCommonFrame>
  );
};

export default LinkExpiredPage;
