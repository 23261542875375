import React, { useEffect } from 'react';
import { ParagraphLarge } from 'baseui/typography';
import { styled, useStyletron } from 'baseui';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BackToDashboardButton from 'newHirePortal/newHire/components/BackToDashboardButton';
import AccentButton from '../../../components/AccentButton';
import SubmittedHardwareDetails from './SubmittedHardwareAndShipping/SubmittedHardwareDetails';
import ShippingAddressDisplay from './SubmittedHardwareAndShipping/ShippingAddressDisplay';
import NoteDetails from './SubmittedHardwareAndShipping/NoteDetails';
import CashImgWrap from '../../../components/CashImgWrap';
import { THEME_NAME, API_STATUS } from '../../../util/enums';
import { getShippingAddress } from '../../../store/shippingAddress/thunk';
import { getSelectedHardwares } from '../../../store/selectedHardwares/thunk';
import LoadingIndicator from '../../../components/LoadingIndicator';
import pyramid from '../../../../../assets/images/pyramid.png';
import SpecialRequirementsDetails from './SpecialRequirements/SpecialRequirementsDetails';
import PresentationErrorPage from '../PresentationErrorPage';
import { getPathByName } from '../../../util/routes';
import { submittedHardwareSelections } from '../../../store/hardware/selectors';
import { nextPendingTaskSelector } from '../../../store/user/selectors';

const ButtonWrapper = styled('div', () => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

const RowContainer = styled('div', ({ $theme }) => ({
  display: 'flex',
  [$theme.mediaQuery.large]: {
    flexDirection: 'row',
    width: '95%'
  },
  [$theme.mediaQuery.medium]: {
    flexDirection: 'column',
    width: '100%'
  }
}));

const Container = styled('div', ({ style }) => ({
  ...style,
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}));

const PresentationPageWrap = styled('div', () => ({
  position: 'relative'
}));

const PresentationPage = () => {
  const [, theme] = useStyletron();
  const dispatch = useDispatch();
  const history = useHistory();
  const hasSubmittedHardwareSelections =
    useSelector(submittedHardwareSelections)?.length > 0;
  const shippingData = useSelector((state) => state.shippingAddress.data);
  const loadStatus = useSelector((state) => state.shippingAddress.loadStatus);
  const nextTask = useSelector(nextPendingTaskSelector);
  const hardwareLoadStatus = useSelector(
    (state) => state.selectedHardwares.loadStatus
  );
  const isCashTheme = theme.name === THEME_NAME.CASH;

  useEffect(() => {
    if (hardwareLoadStatus === API_STATUS.IDLE) {
      dispatch(getSelectedHardwares());
    }
  }, [hardwareLoadStatus]);

  useEffect(() => {
    if (loadStatus === API_STATUS.IDLE) {
      dispatch(getShippingAddress());
    }
  }, [loadStatus]);

  if (
    hardwareLoadStatus === API_STATUS.FAILED ||
    loadStatus === API_STATUS.FAILED
  ) {
    return <PresentationErrorPage dataTestId='hardware & shipping' />;
  }

  if (
    loadStatus === API_STATUS.COMPLETED &&
    hardwareLoadStatus === API_STATUS.COMPLETED
  ) {
    return (
      <div data-testid='presentation-page'>
        <ParagraphLarge>
          Thank you for submitting your information.
        </ParagraphLarge>

        {hasSubmittedHardwareSelections ? (
          <RowContainer>
            <Container style={{ marginRight: '4%' }}>
              <SubmittedHardwareDetails
                wrapperStyle={{
                  backgroundColor: theme.colors.mono200,
                  padding: '2% 6% 6% 6%',
                  marginBottom: '3%'
                }}
              />
            </Container>
            <Container>
              <ShippingAddressDisplay
                wrapperStyle={{
                  backgroundColor: theme.colors.mono200,
                  padding: '2% 6% 6% 6%',
                  marginBottom: '3%'
                }}
                color={theme.colors.mono500}
                shippingData={shippingData}
              />
              <SpecialRequirementsDetails
                wrapperStyle={{
                  backgroundColor: theme.colors.mono200,
                  padding: '2% 6% 6% 6%',
                  marginBottom: '3%'
                }}
                color={theme.colors.mono500}
              />
              <NoteDetails />
            </Container>
          </RowContainer>
        ) : (
          <RowContainer>
            <Container style={{ marginRight: '4%' }}>
              <ShippingAddressDisplay
                wrapperStyle={{
                  backgroundColor: theme.colors.mono200,
                  padding: '2% 6% 6% 6%',
                  marginBottom: '3%'
                }}
                color={theme.colors.mono500}
                shippingData={shippingData}
              />
            </Container>
            <Container>
              <NoteDetails />
            </Container>
          </RowContainer>
        )}
        {isCashTheme ? (
          <PresentationPageWrap>
            <CashImgWrap
              position='absolute'
              top='-80px'
              left='-40px'
              src={pyramid}
              alt='pyramid'
              zIndex='-1'
            />
          </PresentationPageWrap>
        ) : null}
        {nextTask ? (
          <ButtonWrapper>
            <AccentButton
              label='Next'
              buttonNameForTracking='Next on Hardware & Shipping Presentation Page'
              onClick={() => history.push(getPathByName(nextTask.name))}
            />
          </ButtonWrapper>
        ) : (
          <BackToDashboardButton compName='Hardware & Shipping Submitted' />
        )}
      </div>
    );
  }

  return <LoadingIndicator width='100%' />;
};

export default PresentationPage;
