import React from 'react';
import { Icon } from 'baseui/icon';
import { useStyletron } from 'baseui';

const AccentAlert = ({ size, color, ...props }) => {
  const { title = 'AccentAlert', ...restProps } = props;
  const [, theme] = useStyletron();
  return (
    <Icon
      viewBox='0 0 18 18'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0C4.04 0 0 4.04 0 9C0 13.96 4.04 18 9 18C13.96 18 18 13.96 18 9C18 4.04 13.96 0 9 0ZM9 16C5.14 16 2 12.86 2 9C2 5.14 5.14 2 9 2C12.86 2 16 5.14 16 9C16 12.86 12.86 16 9 16ZM8 7.5V14H10V7.5H8ZM10.25 5.25C10.25 5.94036 9.69036 6.5 9 6.5C8.30964 6.5 7.75 5.94036 7.75 5.25C7.75 4.55964 8.30964 4 9 4C9.69036 4 10.25 4.55964 10.25 5.25Z'
        fill={color || theme.colors.contentAccent}
      />
    </Icon>
  );
};

export default AccentAlert;
