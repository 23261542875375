import React from 'react';
import { useSelector } from 'react-redux';
import DashboardTaskList from './DashBoardTaskList';
import { enabledHumanTasks } from '../../../store/user/selectors';

const HumanTaskList = () => {
  const humanTasks = useSelector(enabledHumanTasks);

  return (
    <DashboardTaskList
      tasks={humanTasks}
      listHeading='Tasks to be completed in portal'
    />
  );
};

export default HumanTaskList;
