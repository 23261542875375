import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Block } from 'baseui/block';
import CheckBoxWithIcon from 'newHirePortal/newHire/components/CheckBoxWithIcon';
import {
  AllDevices,
  Wrapper
} from 'newHirePortal/newHire/components/commonStyle/styleForPeripherals';
import { updateSelectedHardware } from 'newHirePortal/newHire/store/hardware/slice';
import {
  getPeripherals,
  createOptOutChoiceObject
} from 'newHirePortal/newHire/store/hardware/selectors';
import MouseIcon from 'components/icons/Mouse';
import HeadsetIcon from 'components/icons/Headset';
import KeyboardIcon from 'components/icons/Keyboard';
import PeripheralsIcon from 'components/icons/Peripherals';
import {
  HARDWARE_ITEMS,
  KEYBOARD_LANGUAGE,
  OPT_OUT_CHOICE
} from 'newHirePortal/newHire/util/hardwareContants';
import KeyboardLanguageDropdown from 'newHirePortal/newHire/containers/YourInfo/HardwareShipping/Peripherals/KeyboardLanguageDropdown';
import HardwareTitle from 'newHirePortal/newHire/containers/YourInfo/HardwareShipping/HardwareTitle';

const PickPeripherals = () => {
  const dispatch = useDispatch();
  const peripheralsDetails = useSelector(getPeripherals);
  const selectedHardware = useSelector(
    (state) => state.hardwareSelection.selectedHardware
  );
  const {
    keyboard: keyboardData,
    mouse: mouseData,
    headset: headsetData
  } = peripheralsDetails;
  const {
    keyboard: selectedKeyboard,
    mouse: selectedMouse,
    keyboardLanguage: selectedKeyboardLanguage
  } = selectedHardware;

  const [checkedPeripherals, setCheckedPeripherals] = useState([]);

  useEffect(() => {
    const checkedOption = [];
    Object.keys(peripheralsDetails).forEach((peripheralKey) => {
      if (selectedHardware[peripheralKey]) {
        checkedOption.push(selectedHardware[peripheralKey].display_name);
      } else if (peripheralsDetails[peripheralKey]) {
        dispatch(
          updateSelectedHardware({
            [peripheralKey]: createOptOutChoiceObject(peripheralKey)
          })
        );
      }
    });
    setCheckedPeripherals(checkedOption);
  }, []);

  const keyboardLangInfo = keyboardData?.language_choices;
  const isKeyboardChecked =
    checkedPeripherals &&
    checkedPeripherals.some(
      (peripheral) => peripheral === keyboardData?.display_name
    );
  const selectedKeyboardLang =
    selectedKeyboardLanguage && Object.keys(selectedKeyboardLanguage).length > 0
      ? [selectedKeyboardLanguage]
      : [];

  const updatedPeripheralsDetails = [
    {
      optionKey: HARDWARE_ITEMS.MOUSE,
      data: mouseData,
      label: mouseData?.display_name,
      icon: <MouseIcon />
    },
    {
      optionKey: HARDWARE_ITEMS.HEADSET,
      data: headsetData,
      label: headsetData?.display_name,
      icon: <HeadsetIcon />
    },
    {
      optionKey: HARDWARE_ITEMS.KEYBOARD,
      data: keyboardData,
      label: keyboardData?.display_name,
      icon: <KeyboardIcon />
    }
  ];

  useEffect(() => {
    if (!selectedKeyboard) return;

    const isKeyboardSqPartNumberChanged =
      selectedKeyboard.sq_part_number !== OPT_OUT_CHOICE.SQ_PART_NUMBER &&
      selectedKeyboard.sq_part_number !== keyboardData.sq_part_number;

    if (isKeyboardSqPartNumberChanged) {
      setCheckedPeripherals((peripherals) =>
        peripherals.filter(
          (peripheral) => peripheral !== selectedKeyboard?.display_name
        )
      );
      dispatch(updateSelectedHardware({ [KEYBOARD_LANGUAGE]: null }));
      dispatch(
        updateSelectedHardware({
          [HARDWARE_ITEMS.KEYBOARD]: createOptOutChoiceObject(
            HARDWARE_ITEMS.KEYBOARD
          )
        })
      );
    }
  }, [keyboardData]);

  useEffect(() => {
    // Check if the mouse's sq_part_number has changed
    const isMouseSqPartNumberChanged =
      selectedMouse?.sq_part_number !== OPT_OUT_CHOICE.SQ_PART_NUMBER &&
      selectedMouse?.sq_part_number !== mouseData?.sq_part_number;

    // If there is no mouse data available, but there is a selected mouse, reset the selected mouse with null
    if (selectedMouse && !mouseData) {
      dispatch(updateSelectedHardware({ [HARDWARE_ITEMS.MOUSE]: null }));
    }
    // If the mouse data is available and the mouse's sq_part_number has changed, reset the selected mouse with opt-out choice
    else if (mouseData && isMouseSqPartNumberChanged) {
      dispatch(
        updateSelectedHardware({
          [HARDWARE_ITEMS.MOUSE]: createOptOutChoiceObject(HARDWARE_ITEMS.MOUSE)
        })
      );
    }

    // Remove the mouse from the list of checked peripherals, if there is selected mouse already available and the mouse's sq_part_number has changed.
    if (selectedMouse && isMouseSqPartNumberChanged) {
      setCheckedPeripherals((peripherals) =>
        peripherals.filter(
          (peripheral) => peripheral !== selectedMouse.display_name
        )
      );
    }
  }, [mouseData]);

  useEffect(() => {
    if (keyboardLangInfo?.length === 1 && isKeyboardChecked) {
      dispatch(
        updateSelectedHardware({
          [KEYBOARD_LANGUAGE]: keyboardLangInfo[0]
        })
      );
    }
  }, [isKeyboardChecked]);

  const handlePeripherals = ({ checkboxKey, checkboxLabel, data }) => {
    const checkedOptions = [...checkedPeripherals];
    const matchIndex = checkedOptions.indexOf(checkboxLabel);
    if (matchIndex > -1) {
      if (checkboxKey === HARDWARE_ITEMS.KEYBOARD) {
        dispatch(updateSelectedHardware({ [KEYBOARD_LANGUAGE]: null }));
      }
      checkedOptions.splice(matchIndex, 1);
      dispatch(
        updateSelectedHardware({
          [checkboxKey]: createOptOutChoiceObject(checkboxKey)
        })
      );
    } else {
      checkedOptions.push(checkboxLabel);
      dispatch(updateSelectedHardware({ [checkboxKey]: data }));
    }
    setCheckedPeripherals(checkedOptions);
  };

  return (
    <Wrapper>
      <HardwareTitle
        icon={<PeripheralsIcon size={30} />}
        deviceName='Peripherals'
        infoText={['Choose your peripherals.']}
      />
      <Block display='flex' flexDirection='column' width='100%'>
        <AllDevices>
          {updatedPeripheralsDetails.map(
            ({ label, icon, optionKey, data }) =>
              label && (
                <CheckBoxWithIcon
                  key={optionKey}
                  optionKey={optionKey}
                  data={data}
                  icon={icon}
                  label={label}
                  handleCheckboxChange={handlePeripherals}
                  isOptionChecked={checkedPeripherals.includes(label)}
                />
              )
          )}
          {keyboardLangInfo?.length > 0 && (
            <KeyboardLanguageDropdown
              onChange={({ value }) =>
                dispatch(
                  updateSelectedHardware({
                    [KEYBOARD_LANGUAGE]: value[0]
                  })
                )
              }
              value={selectedKeyboardLang}
              options={keyboardLangInfo}
              disabled={!isKeyboardChecked}
            />
          )}
        </AllDevices>
      </Block>
    </Wrapper>
  );
};

export default PickPeripherals;
