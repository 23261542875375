import React from 'react';
import { Block } from 'baseui/block';

const Username = () => (
  <Block
    width={['auto', '100%', '40%']}
    paddingLeft={['20px', '20px', '100px']}
    paddingRight={['20px', '20px', 'auto']}
    maxWidth='1440px'
  >
    <div>Username</div>
  </Block>
);

export default Username;
