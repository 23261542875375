import React from 'react';
import InfoWrapper from '../../../components/InfoWrapper';
import LinkListItem from './LinkListItem';

const UsefulLinks = () => (
  <>
    <InfoWrapper
      Header='Useful Links'
      ModalHead='Useful Links'
      Modalcontent={<LinkListItem />}
      height='auto'
    >
      <LinkListItem />
    </InfoWrapper>
  </>
);
export default UsefulLinks;
