import React from 'react';
import { RadioGroup, ALIGN } from 'baseui/radio';

export const radioOverrides = ({ width }) => ({
  Root: {
    style: ({ $theme, $checked }) => ({
      backgroundColor: $checked
        ? $theme.colors.accent50
        : $theme.colors.mono100,
      borderBottom: `1px solid ${$theme.colors.mono200}`,
      borderRadius: $theme.sizing.scale200,
      width,
      padding: `0 ${$theme.sizing.scale300}`,
      display: 'flex',
      justifyContent: 'space-between',
      marginRight: $theme.sizing.scale1000
    })
  },
  RadioMarkOuter: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.contentAccent,
      marginLeft: $theme.sizing.scale700
    })
  }
});

const StyledRadioGroup = ({
  children,
  currentValue,
  setCurrentValue,
  testId = 'styled-radio-group',
  radioBtnName
}) => (
  <RadioGroup
    value={currentValue}
    onChange={(e) => setCurrentValue(e.currentTarget.value)}
    data-dd-action-name={`Clicked to Select ${radioBtnName}`}
    align={ALIGN.horizontal}
    labelPlacement='left'
    overrides={{
      RadioGroupRoot: {
        style: () => ({
          display: 'flex',
          marginLeft: '60px'
        }),
        props: { 'data-testid': testId }
      }
    }}
  >
    {children}
  </RadioGroup>
);

export default StyledRadioGroup;
