import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HeadingXLarge } from 'baseui/typography';
import { styled } from 'baseui';
import { useSelector } from 'react-redux';
import { Block } from 'baseui/block';
import { ROUTES } from '../../util/routes';
import YourInfoTaskList from './YourInfoTaskList';
import HumanTaskList from './DashboardTaskList/HumanTaskList';
import WorkDayTaskList from './DashboardTaskList/WorkDayTaskList';
import { isTaskNameChangedBasedOnHardwareVisibility } from '../../store/hardware/selectors';
import HeadingSkeleton from '../../components/skeletonComponents/HeadingSkeleton';
import TaskWithIconSkeleton from '../../components/skeletonComponents/TaskWithIconSkeleton';
import SubTaskWithIconSkeleton from '../../components/skeletonComponents/SubTaskWithIconSkeleton';

const Container = styled('div', ({ $theme }) => ({
  boxShadow: $theme.shadow.shadowStatic,
  height: '100%',
  minHeight: '100vh',
  padding: $theme.sizing.scale800
}));

const DashboardTaskListSkeleton = () => (
  <Block data-testid='dashboard-task-list-skeleton'>
    <TaskWithIconSkeleton />
    <SubTaskWithIconSkeleton noOfSubTasks={3} />
    <br />
    <TaskWithIconSkeleton />
    <SubTaskWithIconSkeleton noOfSubTasks={9} />
  </Block>
);

const TaskList = () => {
  const isTaskNameChanged = useSelector(
    isTaskNameChangedBasedOnHardwareVisibility
  );

  return (
    <Container data-testid='task-list-component'>
      {isTaskNameChanged ? (
        <HeadingXLarge marginTop='0px' marginBottom='24px'>
          {' '}
          Task List{' '}
        </HeadingXLarge>
      ) : (
        <HeadingSkeleton />
      )}

      <Switch>
        <Route path={ROUTES.map((route) => route.path)}>
          {isTaskNameChanged ? (
            <YourInfoTaskList />
          ) : (
            <TaskWithIconSkeleton noOfTasks={3} />
          )}
        </Route>
        <Route path='*'>
          {isTaskNameChanged ? (
            <>
              <HumanTaskList />
              <WorkDayTaskList />
            </>
          ) : (
            <DashboardTaskListSkeleton />
          )}
        </Route>
      </Switch>
    </Container>
  );
};

export default TaskList;
