export const API_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  COMPLETED: 'completed',
  FAILED: 'failed'
};

export const BU_NAME = {
  CASH: 'CASH',
  SQ_GENERAL: 'SQUARE',
  TIDAL: 'TIDAL',
  BLOCK: 'BLOCK'
};

export const THEME_NAME = {
  SQUARE: BU_NAME.SQ_GENERAL,
  CASH: BU_NAME.CASH,
  TIDAL: BU_NAME.TIDAL,
  BLOCK: BU_NAME.BLOCK
};

export const TASK_STATUS = {
  COMPLETED: 'completed',
  PENDING: 'pending'
};

export const TASK_NAME = {
  SHIPPING: 'Shipping Address',
  PROFILE: 'Profile',
  USERNAME: 'Username Selection',
  HARDWARE: 'Hardware & Shipping'
};

export const HIRE_TYPE = {
  NEWHIRE: 'new_hire',
  REHIRE: 're_hire',
  CONVERSION: 'conversion'
};

export const TASK_COMPLETED_BY = {
  USER: 'user',
  ADMIN: 'admin'
};

export const SQUARE_MAIL_ID = {
  LEARNING: 'new@block.xyz',
  GENRAL: 'askpeople@squareup.com',
  IMMIGRATION: 'immigration@squareup.com',
  HARDWARE: 'it-logistics@squareup.com',
  SQUAREONE: 'squareone@squareup.com'
};
