import { createSlice } from '@reduxjs/toolkit';
import { getCoreMember } from './thunk';
import { API_STATUS } from '../../util/enums';

const coreMemberSlice = createSlice({
  name: 'coreMember',
  initialState: {
    loadStatus: API_STATUS.IDLE,
    data: {
      employee_id: null,
      cost_center: null,
      email: null,
      first_name: null,
      last_name: null,
      profile_image: null,
      team_code: null
    },
    error: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoreMember.pending, (state) => {
        state.loadStatus = API_STATUS.LOADING;
      })
      .addCase(getCoreMember.fulfilled, (state, action) => {
        state.loadStatus = API_STATUS.COMPLETED;
        state.data = action.payload;
      })
      .addCase(getCoreMember.rejected, (state, action) => {
        state.loadStatus = API_STATUS.FAILED;
        if (action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = action.error.message;
        }
      });
  }
});

const { reducer } = coreMemberSlice;

export default reducer;
