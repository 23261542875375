import React, { useEffect } from 'react';
import { MonoParagraphLarge } from 'baseui/typography';
import { useDispatch, useSelector } from 'react-redux';
import { useStyletron } from 'baseui';
import { getUsername } from '../../../store/selectedUsername/thunk';
import { API_STATUS, TASK_COMPLETED_BY } from '../../../util/enums';
import LoadingIndicator from '../../../components/LoadingIndicator';
import InfoMsg from './InfoMsg';
import SuccessSmiley from '../../../../../components/icons/SuccessSmiley';
import CurrentInfo from './CurrentInfo';
import { usernameSelectTaskCompletedBy } from '../../../store/user/selectors';
import UsernameAlreadyAssigned from './UsernameAlreadyAssigned';
import BackToDashboardButton from '../../../components/BackToDashboardButton';

const PresentationPage = () => {
  const dispatch = useDispatch();
  const usernameData = useSelector(
    (state) => state.selectedUsername.data.username
  );
  const error = useSelector((state) => state.selectedUsername.error);
  const usernameLoadStatus = useSelector(
    (state) => state.selectedUsername.loadStatus
  );
  const taskCompletedBy = useSelector(usernameSelectTaskCompletedBy);
  const [, theme] = useStyletron();

  useEffect(() => {
    if (usernameLoadStatus === API_STATUS.IDLE) {
      dispatch(getUsername());
    }
  }, [usernameLoadStatus]);

  if (usernameLoadStatus === API_STATUS.FAILED) {
    return (
      <MonoParagraphLarge color={theme.colors.contentNegative}>
        {' '}
        {error}{' '}
      </MonoParagraphLarge>
    );
  }

  if (usernameLoadStatus === API_STATUS.COMPLETED) {
    if (taskCompletedBy === TASK_COMPLETED_BY.USER) {
      return (
        <>
          <InfoMsg
            logo={<SuccessSmiley />}
            mainMsg="Thank you for submitting your user name. We'll have it set up for you on your start date."
          />
          <CurrentInfo defaultUsername={usernameData} />
          <BackToDashboardButton compName='Username Submitted' />
        </>
      );
    }
    return <UsernameAlreadyAssigned username={usernameData} />;
  }

  return <LoadingIndicator width='100%' />;
};

export default PresentationPage;
