import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HardwareErrorPage from '../../MiscPages/HardwareErrorPage';
import { isValidShippingData } from '../../../store/shippingAddress/selectors';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { API_STATUS } from '../../../util/enums';
import {
  isAllMandatoryHardwareSelected,
  showHardwareSelection
} from '../../../store/hardware/selectors';
import ShippingForm from './Shipping/ShippingForm';
import AccentButton from '../../../components/AccentButton';
import { setIsOnConfirmPage } from '../../../store/shippingAddress/slice';
import HardwareSelectionWithShipping from './HardwareSelectionWithShipping';

const HardwareShippingPage = () => {
  const dispatch = useDispatch();

  const isMandatoryHardwaresSelected = useSelector(
    isAllMandatoryHardwareSelected
  );

  const hardwareLoadStatus = useSelector(
    (state) => state.hardwareSelection.loadStatus
  );

  const displayHardwareSelection = useSelector(showHardwareSelection);
  const isAllValidShippingData = useSelector(isValidShippingData);

  const isEnabled = displayHardwareSelection
    ? isMandatoryHardwaresSelected && isAllValidShippingData
    : isAllValidShippingData;

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(setIsOnConfirmPage(isEnabled));
  };

  if (hardwareLoadStatus === API_STATUS.FAILED) {
    return <HardwareErrorPage />;
  }

  if (hardwareLoadStatus === API_STATUS.COMPLETED) {
    return (
      <form
        onSubmit={onSubmit}
        data-testid={
          displayHardwareSelection ? 'hardware-shipping-form' : 'shipping-form'
        }
      >
        {displayHardwareSelection ? (
          <HardwareSelectionWithShipping />
        ) : (
          <ShippingForm />
        )}
        <AccentButton
          type='submit'
          label='Verify and Confirm'
          float='right'
          marginTop='24px'
          disabled={!isEnabled}
        />
      </form>
    );
  }

  return <LoadingIndicator width='100%' />;
};

export default HardwareShippingPage;
