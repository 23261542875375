import React from 'react';
import { useStyletron } from 'baseui';
import { ParagraphLarge } from 'baseui/typography';
import HeadLine from './Headline';
import SearchTool from '../../../../components/icons/SearchTool';

const HardwareErrorPage = () => {
  const [css] = useStyletron();

  const title = 'Something went wrong';
  const icon = <SearchTool />;
  const msg = 'Page could not be loaded. Please try again.';

  return (
    <div
      className={css({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      })}
      data-testid='hardware-err-page'
    >
      <HeadLine fontWeight='300'>{title}</HeadLine>
      <span
        className={css({
          marginTop: '60px'
        })}
      >
        {icon}
      </span>
      <ParagraphLarge marginTop='64px'>{msg}</ParagraphLarge>
    </div>
  );
};
export default HardwareErrorPage;
