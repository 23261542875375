import React from 'react';
import { Icon } from 'baseui/icon';

const Clock = ({ ...props }) => {
  const {
    title = 'clock Logo',
    size = 80,
    color = 'white',
    ...restProps
  } = props;
  return (
    <Icon
      viewBox='0 0 80 80'
      title={title}
      size={size}
      color={color}
      {...restProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 16.6665V39.9998L50.5 50.4999M79 40C79 61.5391 61.5391 79 40 79C18.4609 79 1 61.5391 1 40C1 18.4609 18.4609 1 40 1C61.5391 1 79 18.4609 79 40ZM75 40C75 59.33 59.33 75 40 75C20.67 75 5 59.33 5 40C5 20.67 20.67 5 40 5C59.33 5 75 20.67 75 40Z'
        stroke='#12BF94'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
// fill="#1A1A1A"
export default Clock;
