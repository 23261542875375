import { MonoParagraphLarge } from 'baseui/typography';
import React, { forwardRef } from 'react';
import { useStyletron } from 'baseui';

const DismissToastClose = forwardRef(({ onClick }, ref) => {
  const [, theme] = useStyletron();
  return (
    <MonoParagraphLarge
      ref={ref}
      onClick={onClick}
      color={theme.colors.mono100}
      alignSelf='center'
      marginLeft={theme.margins.normal}
      $style={{
        ':hover': { color: theme.colors.contentAccent },
        cursor: 'pointer'
      }}
    >
      {' '}
      Dismiss
    </MonoParagraphLarge>
  );
});

export default DismissToastClose;
