const SCROLL_DIRECTION = {
  right: 'right',
  left: 'left',
  inbetween: 'inbetween'
} as const;

const TASK_LABEL = {
  required: 'Required',
  optional: 'Optional'
} as const;

export const TASK_STATUS = {
  COMPLETED: 'completed',
  OVERDUE: 'overdue',
  FUTURE: 'future'
} as const;

export const WORKDAY_TEMP_LINK =
  'https://wd5.myworkday.com/squareinc/login.flex?redirect=n';

const completeLabel = 'Complete';

export { SCROLL_DIRECTION, TASK_LABEL, completeLabel };
