import React, { ReactNode } from 'react';
import { Button, SHAPE, KIND } from 'baseui/button';
import { Theme } from 'baseui';
import { StyleObject } from 'styletron-standard';

interface CustomButtonProps {
  children: ReactNode;
  baseButtonStyleOverride?: StyleObject;
  kind?: keyof typeof KIND;
  handleClick?: () => void;
}

const CustomButton = ({
  handleClick = () => null,
  kind = KIND.primary,
  children,
  baseButtonStyleOverride = {}
}: CustomButtonProps) => (
  <Button
    onClick={handleClick}
    kind={kind}
    overrides={{
      BaseButton: {
        style: ({ $theme }: { $theme: Theme }) => ({
          width: '100%',
          backgroundColor: $theme.colors.buttonPrimaryFill,
          ':hover': {
            backgroundColor:
              kind === KIND.primary
                ? $theme.colors.buttonPrimaryHover
                : 'unset',
            boxShadow:
              kind === KIND.secondary
                ? '1px 1px 3px 0px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.25)'
                : ''
          },
          ...baseButtonStyleOverride
        })
      }
    }}
    shape={SHAPE.pill}
  >
    {children}
  </Button>
);

export default CustomButton;
