import { THEME_NAME } from 'newHirePortalRedesign/newHire/util/constants';
import BlockLogo from 'assets/images/blockLogo.gif';
import { ResponsiveTheme } from 'newHirePortalRedesign/newHire/util/styleHelpers';

export interface BreakpointsInterface {
  [key: string]: number;
}

const breakpoints: BreakpointsInterface = {
  small: 320,
  medium: 600,
  large: 1136
};

const responsiveTheme: ResponsiveTheme = Object.keys(breakpoints).reduce(
  (acc: ResponsiveTheme, key: string) => {
    acc.mediaQuery[
      key
    ] = `@media screen and (min-width: ${breakpoints[key]}px)`;
    return acc;
  },
  {
    breakpoints,
    mediaQuery: {}
  }
);

const primitives = {
  primaryFontFamily: 'Square Market, Helvetica, Arial, sans-serif'
};

const grid = {
  grid: {
    columns: [4, 8, 12],
    gutters: [16, 36, 24],
    margins: [16, 36, 64],
    gaps: 0,
    maxWidth: 1920
  }
};

const baseColors = {
  positive50: '#E5FFEE', // Square Success Green/40
  positive100: '#CCFFDD', // Square Success Green/30
  positive200: '#009933', // Square Success Green/20
  positive300: '#00802A', // Square Success Green/10
  backgroundPositive: '#00B23B', // Square Success Fill
  contentPositive: '#007D2A', // Square Success Text

  // Emphasis
  accent50: '#FFB89C', // Block Emphasis Emphasis/40
  accent100: '#CCE1FF', // Square Emphasis Emphasis/30
  accent200: '#005FE5', // Square Emphasis Emphasis/20
  accent300: '#0055CC', // Square Emphasis Emphasis/10
  backgroundAccent: '#C22A00', // Block Emphasis Fill
  contentAccent: '#C22A00', // Block Emphasis Text

  // Critical
  negative50: '#FFE5EA', // Square Critical Critical/40
  negative100: '#FFCCD5', // Square Critical Critical/30
  negative200: '#B2001E', // Square Critical Critical/20
  negative300: '#99001A', // Square Critical Critical/10
  backgroundNegative: '#CC0023', // Square Critical Fill
  contentNegative: '#BF0020', // Square Critical Text

  warning50: '#FFF2E6', // Square Warning/40
  warning100: '#FFE6CC', // Square Warning/30
  warning200: '#E68F39', // Square Warning/20
  warning300: '#CC8033', // Square Warning/10
  contentWarning: '#945C25',
  backgroundWarning: '#FF9F40',

  primary: '#148FCC', // Square Primary
  primary100: '#D6F1FF', // Square Primary Light
  primary700: '#0E648F', // Square Primary Dark

  yellow100: '#FFF2D9', // Yellow/100
  yellow400: '#FFC043', // Yellow/400

  // Square Grays from lightest - darkest
  mono100: '#FFFFFF',
  mono200: '#ECEDEE',
  mono300: '#D9D9D9',
  mono400: '#939CA3',
  mono500: '#66717A',
  mono600: '#323A42',
  mono700: '#666666',
  mono800: '#151C1F',

  // Block MnA notification
  mnaNotification: '#FBFCE1',

  contentPrimary: 'rgba(0, 0, 0, 0.9)',
  backgroundAccentLight: '#F4F4F4'
};

const baseTypography = {
  HeadingXSmall: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px'
  },
  HeadingSmall: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px'
  },
  HeadingMedium: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px'
  },

  HeadingLarge: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '40px'
  },
  HeadingXLarge: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '44px'
  },

  HeadingXXLarge: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '52px'
  },

  // Paragraph Settings
  ParagraphXSmall: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px'
  },
  ParagraphSmall: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px'
  },
  ParagraphMedium: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  ParagraphLarge: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px'
  },

  LabelXSmall: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px'
  },

  LabelSmall: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px'
  },
  LabelMedium: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px'
  },
  LabelLarge: {
    fontFamily: primitives.primaryFontFamily,
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '24px'
  }
};

// https://github.com/uber/baseweb/blob/570b0c01a9c6c4d0b582baf7949e0c85c4ee3f5b/documentation-site/pages/discover-more/roadmap.mdx

const componentStyles = {
  button: {
    borderRadius: '6px'
  }
};

const otherProperties = {
  favicon: BlockLogo,
  displayName: 'Block',
  displayAppName: 'Block',
  supportEmail: 'mission-control@squareup.com',
  learningEmail: 'new@block.xyz'
};

const overrides = {
  ...responsiveTheme,

  ...grid,

  colors: baseColors,

  typography: baseTypography,

  name: THEME_NAME.BLOCK,

  shadow: {
    shadowStatic:
      '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.1)'
  },

  margins: {
    normal: '16px'
  },

  fontFamily: primitives.primaryFontFamily,

  ...componentStyles,

  ...otherProperties
};

const config = {
  primitives,
  overrides
};

export { primitives, overrides };

export default config;
