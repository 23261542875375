export const allDirectionStyle = ({ prop, color, width, style, radius }) => {
  let styleObj = {};

  if (color) {
    styleObj = {
      ...styleObj,
      [`${prop}LeftColor`]: color,
      [`${prop}RightColor`]: color,
      [`${prop}BottomColor`]: color,
      [`${prop}TopColor`]: color
    };
  }

  if (style) {
    styleObj = {
      ...styleObj,
      [`${prop}LeftStyle`]: style,
      [`${prop}RightStyle`]: style,
      [`${prop}BottomStyle`]: style,
      [`${prop}TopStyle`]: style
    };
  }

  if (width) {
    styleObj = {
      ...styleObj,
      [`${prop}LeftWidth`]: width,
      [`${prop}RightWidth`]: width,
      [`${prop}BottomWidth`]: width,
      [`${prop}TopWidth`]: width
    };
  }

  if (radius) {
    styleObj = {
      ...styleObj,
      [`${prop}TopLeftRadius`]: radius,
      [`${prop}TopRightRadius`]: radius,
      [`${prop}BottomLeftRadius`]: radius,
      [`${prop}BottomRightRadius`]: radius
    };
  }
  return styleObj;
};

export const mediaQueryStyles = ({ $theme, mediaSizes, ...styles }) =>
  mediaSizes.reduce((hash, size) => {
    hash[$theme.mediaQuery[size]] = styles;
    return hash;
  }, {});
