import { styled, withStyle } from 'baseui';
import { Spinner } from 'baseui/spinner';
import React from 'react';

const LargeSpinner = withStyle(Spinner, {
  width: '48px',
  height: '48px'
});

const LoadingIndicator = (props) => {
  const Container = styled('div', () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...props
  }));

  return (
    <Container>
      <LargeSpinner />
    </Container>
  );
};

export default LoadingIndicator;
