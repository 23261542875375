import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getShippingAddress as getShippingAddressApi,
  postShippingAddress as postShippingAddressApi
} from '../../api/shippingAddress';
import {
  transformKeysToCamel,
  transformKeysToSnake
} from '../../util/dataParseHelpers';
import { handleApiResponse } from '../../../../utils/api_helpers';
import {
  sanitizeSelectedHardwarePayload,
  sanitizeShippingPayload
} from './helpers';

export const getShippingAddress = createAsyncThunk(
  'shippingAddress/show',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await getShippingAddressApi();
      const result = await handleApiResponse(response);
      if (result.ok) {
        return transformKeysToCamel(result.data);
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const postShippingAddress = createAsyncThunk(
  'shippingAddress/post',
  async (
    { shippingAddress = {}, selectedHardware = {} },
    { rejectWithValue }
  ) => {
    try {
      const shippingData = sanitizeShippingPayload(shippingAddress);
      const hardwarePayloadObject =
        sanitizeSelectedHardwarePayload(selectedHardware);
      const response = await postShippingAddressApi({
        shippingAddress: transformKeysToSnake(shippingData),
        selectedHardware: transformKeysToSnake(hardwarePayloadObject)
      });

      const result = await handleApiResponse(response);
      if (result.ok) {
        return null;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
