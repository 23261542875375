import React from 'react';
import { StyledLink } from 'baseui/link';
import { withStyle } from 'baseui';
import { datadogRum } from '@datadog/browser-rum';

interface LinkWithTextProps {
  text: string;
  download?: string;
  link?: string;
  linkText?: string;
}

const MyStyledLink = withStyle(StyledLink, ({ $theme }) => ({
  textDecoration: 'inherit',
  cursor: 'pointer',
  color: $theme.colors.contentAccent,
  ':visited': $theme.colors.contentAccent
}));

const LinkWithText = ({
  text,
  download = '',
  link = 'https://newcomputer.square.com/',
  linkText = 'https://newcomputer.square.com/'
}: LinkWithTextProps) => (
  <span>
    {text}
    <MyStyledLink
      href={link}
      download={download}
      target='_blank'
      onClick={() => {
        datadogRum.addAction(`click ${linkText}`);
      }}
    >
      {linkText}
    </MyStyledLink>
  </span>
);

export default LinkWithText;
