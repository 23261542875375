import { useState, useLayoutEffect, RefObject } from 'react';

export const useIsWidthOverflow = (
  elementRef: RefObject<HTMLElement>
): boolean => {
  const [isWidthOverflow, setIsWidthOverflow] = useState<boolean>(false);

  useLayoutEffect(() => {
    const { current } = elementRef;

    const trigger = () => {
      if (current) {
        const hasOverflow = current.scrollWidth > current.clientWidth;

        setIsWidthOverflow(hasOverflow);
      }
    };

    window.addEventListener('resize', trigger);
    trigger();

    return () => {
      window.removeEventListener('resize', trigger);
    };
  }, [elementRef?.current]);

  return isWidthOverflow;
};
