import React from 'react';
import { useStyletron } from 'baseui';
import teamsPhone from 'newHirePortalRedesign/newHire/features/banner/assets/teamsPhone.png';
import CustomizableImage from 'newHirePortalRedesign/newHire/component/customizableImage/CustomizableImage';

const CashImgWrap = () => {
  const [, theme] = useStyletron();
  return (
    <CustomizableImage
      style={{
        right: theme.sizing.scale1600,
        bottom: theme.sizing.scale800,
        zIndex: -2,
        position: 'absolute',
        [theme.mediaQuery.small]: {
          top: '250px'
        },
        [theme.mediaQuery.medium]: {
          top: '250px'
        },
        [theme.mediaQuery.large]: {
          top: '112px'
        }
      }}
      src={teamsPhone}
      alt='Banner image'
    />
  );
};

export default CashImgWrap;
