import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { useStyletron } from 'baseui';
import { ParagraphMedium, HeadingXLarge } from 'baseui/typography';
import { API_STATUS } from '../../../../util/enums';
import { allDirectionStyle } from '../../../../util/styleHelpers';
import AccentButton from '../../../../components/AccentButton';
import MonoButton from '../../../../components/MonoButton';

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  submitStatus,
  dataNameToConfirm,
  modalHeader = 'Confirmation',
  modalBodyHeadLine = 'Please verify your details. You will not be able to make any changes after submission.',
  dataTestId
}) => {
  const [, theme] = useStyletron();

  return (
    <div data-testid={dataTestId}>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        closeable={false}
        unstable_ModalBackdropScroll
        animate
        overrides={{
          Dialog: {
            style: { ...allDirectionStyle({ radius: '12px', prop: 'border' }) }
          },
          Close: () => null
        }}
      >
        <ModalHeader>
          <HeadingXLarge>{modalHeader}</HeadingXLarge>
        </ModalHeader>
        <ModalBody>
          <ParagraphMedium>{modalBodyHeadLine}</ParagraphMedium>
        </ModalBody>
        <ModalFooter
          $style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.margins.normal
          }}
        >
          <MonoButton
            onClick={onClose}
            label='Cancel'
            buttonNameForTracking={`Cancel Submit ${dataNameToConfirm}`}
          />

          <AccentButton
            onClick={onConfirm}
            label='Submit'
            isLoading={submitStatus === API_STATUS.LOADING}
            buttonNameForTracking={`Confirm Submit ${dataNameToConfirm}`}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
