import React from 'react';
import { styled } from 'baseui';
import { MonoParagraphMedium, ParagraphLarge } from 'baseui/typography';

const FieldContainer = styled('div', ({ $theme }) => ({
  boxShadow: $theme.shadow.shadowStatic,
  marginTop: '24px',
  marginBottom: '24px',
  maxWidth: '660px',
  borderRadius: '5px',
  width: '100%',
  paddingLeft: '16px'
}));

const ShowField = ({ label, value }) => (
  <FieldContainer>
    <MonoParagraphMedium marginBottom='0px' marginTop='0px' paddingTop='8px'>
      {label}
    </MonoParagraphMedium>
    <ParagraphLarge marginTop='0px' paddingBottom='8px'>
      {value}
    </ParagraphLarge>
  </FieldContainer>
);

export default ShowField;
