import React, { ReactNode } from 'react';
import { useStyletron } from 'baseui';
import { ListItem, ListItemLabel } from 'baseui/list';
import { ParagraphSmall } from 'baseui/typography';
import StatusIcon from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/completedWorkdayCard/statusIcon/StatusIcon';
import { textEllipsis } from 'newHirePortalRedesign/newHire/Styles';
import { HydratedWorkdayTask } from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/workday/store/selectors/selectors';

interface TaskListItemProps {
  task: HydratedWorkdayTask;
  opacity: number;
}

const TaskListItem = ({ task, opacity }: TaskListItemProps) => {
  const [css, theme] = useStyletron();
  const { dueDate, name, isTaskCompleted, status } = task;

  return (
    <ListItem
      key={name}
      artwork={() => (
        <StatusIcon status={status} isTaskCompleted={isTaskCompleted} />
      )}
      overrides={{
        ArtworkContainer: {
          style: ({ $theme }) => ({
            flexBasis: 'content',
            paddingBottom: dueDate ? $theme.sizing.scale600 : 0
          })
        },
        Content: {
          style: ({ $theme }) => ({
            borderBottomStyle: 'none',
            paddingTop: $theme.sizing.scale600,
            paddingBottom: $theme.sizing.scale600,
            maxHeight: '78px',
            minHeight: $theme.sizing.scale800,
            minWidth: 0,
            ':nth-child(2) > div': {
              minWidth: 0
            }
          })
        },
        Root: {
          style: ({ $theme }) => ({
            borderBottom: `1px solid ${$theme.colors.mono300}`,
            gap: $theme.sizing.scale600,
            opacity
          })
        }
      }}
    >
      <ListItemLabel
        description={dueDate}
        overrides={{
          LabelDescription: {
            /* eslint-disable react/no-unused-prop-types */
            component: ({ children }: { children: ReactNode }) =>
              dueDate && (
                <ParagraphSmall margin={0} color={theme.colors.mono400}>
                  Due date:
                  <span
                    className={css({
                      color: theme.colors.mono700,
                      marginLeft: theme.sizing.scale200
                    })}
                  >
                    {children}
                  </span>
                </ParagraphSmall>
              )
          }
        }}
      >
        <a
          href={task.targetLink}
          target='_blank'
          className={css({
            display: 'block',
            ...textEllipsis,
            ...theme.typography.ParagraphMedium,
            color: isTaskCompleted
              ? theme.colors.mono400
              : theme.colors.positive400,
            textDecoration: isTaskCompleted ? 'line-through' : 'underline',
            margin: 0
          })}
          rel='noreferrer'
        >
          {name}
        </a>
      </ListItemLabel>
    </ListItem>
  );
};

export default TaskListItem;
