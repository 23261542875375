export const GET_USERNAME_API_PATH = '/api/v1/username_selections/show';
export const POST_USERNAME_API_PATH = '/api/v1/username_selections';

export const getUsername = async () => fetch(GET_USERNAME_API_PATH);

export const postUsername = async ({ selectedUsername }) =>
  fetch(POST_USERNAME_API_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({ username_selection: selectedUsername })
  });
