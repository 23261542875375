import React from 'react';
import { styled, useStyletron } from 'baseui';
import { SIZE } from 'baseui/button';
import { useDispatch, useSelector } from 'react-redux';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { validate as validateEmail } from 'email-validator';
import Alert from 'baseui/icon/alert';
import shippingFormFields from 'newHirePortal/newHire/containers/YourInfo/Shipping/formFields';
import FormField from '../../../components/FormField';
import TooltipWithIcon from '../../../components/TooltipWithIcon';
import MonoButton from '../../../components/MonoButton';
import AccentButton from '../../../components/AccentButton';
import FormErrorLine from '../../../components/FormErrorLine';
import slime from '../../../../../assets/images/slime.png';
import pyramid from '../../../../../assets/images/pyramid.png';
import { THEME_NAME } from '../../../util/enums';
import CashImgWrap from '../../../components/CashImgWrap';
import PrivacyLink from '../../../components/PrivacyLink';
import {
  shippingAddressSubmitError,
  isModalOpenOnFormSubmit
} from '../../../store/shippingAddress/selectors';
import ShippingConfirmModal from './ShippingConfirmModal';
import {
  resetShippingInput,
  updateShippingInput,
  setIsModalOpen
} from '../../../store/shippingAddress/slice';
import HandsUpNotes from '../../../components/HandsUpNotes';

const ButtonWrapper = styled('div', () => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

const LinkWrap = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const FormWrap = styled('div', () => ({
  position: 'relative'
}));

const Form = () => {
  const dispatch = useDispatch();
  const [, theme] = useStyletron();
  const shippingData = useSelector(
    (state) => state.shippingAddress.shippingInput
  );

  const submitError = useSelector(shippingAddressSubmitError);
  const isOpenModal = useSelector(isModalOpenOnFormSubmit);
  const isCashTheme = theme.name === THEME_NAME.CASH;

  const isEnabled =
    isPossiblePhoneNumber(shippingData.phone) &&
    validateEmail(shippingData.email);

  const handleChange = (name, value) => {
    dispatch(updateShippingInput({ [name]: value }));
  };

  const resetForm = () => {
    dispatch(resetShippingInput());
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(setIsModalOpen(true));
  };

  return (
    <form onSubmit={onSubmit} data-testid='shipping-form'>
      {isCashTheme ? (
        <FormWrap>
          <CashImgWrap
            position='absolute'
            top='450px'
            left='-150px'
            src={pyramid}
            alt='pyramid'
          />
          <CashImgWrap
            position='absolute'
            right='-50px'
            top='80px'
            src={slime}
            alt='slime'
          />
        </FormWrap>
      ) : (
        ' '
      )}
      {shippingFormFields.map((field) => (
        <FormField
          key={field.id}
          value={shippingData[field.dataFieldName]}
          {...field}
          size={SIZE.large}
          updateFormFunc={handleChange}
        />
      ))}
      {submitError ? <FormErrorLine message={submitError} /> : null}
      {isCashTheme && <HandsUpNotes />}
      <LinkWrap>
        <PrivacyLink marginTop='15px' />
        <ButtonWrapper>
          <TooltipWithIcon
            msg='Fields cannot be edited after submitting'
            icon={
              <Alert
                size={16}
                overrides={{ Svg: { style: { marginRight: '36px' } } }}
              />
            }
          />

          <MonoButton
            onClick={resetForm}
            marginRight={theme.margins.normal}
            label='Reset'
            buttonNameForTracking='Reset Shipping Address Form'
          />

          <AccentButton
            label='Submit'
            type='submit'
            buttonNameForTracking='Submit Shipping Address'
            disabled={!isEnabled}
          />
        </ButtonWrapper>
      </LinkWrap>
      {isOpenModal && <ShippingConfirmModal />}
    </form>
  );
};

export default Form;

// https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
