import { toaster } from 'baseui/toast';
import { ParagraphLarge } from 'baseui/typography';
import React from 'react';
import { Alert } from 'baseui/icon';
import { useStyletron } from 'baseui';
import Check from '../../../../components/icons/Check';

const ModelParagraph = ({ children }) => {
  const [, theme] = useStyletron();
  return (
    <ParagraphLarge
      color={theme.colors.mono100}
      display='flex'
      alignItems='center'
    >
      {children}
    </ParagraphLarge>
  );
};

const toastTrigger = ({ success, failureMsg = 'Please try again.' }) => {
  const marginOverride = {
    Svg: {
      style: ({ title, $theme }) => {
        const styles = {
          marginRight: $theme.margins.normal
        };
        if (title === 'Check') {
          styles.color = $theme.colors.backgroundPositive;
        }
        return styles;
      }
    }
  };

  if (success) {
    toaster.positive(
      <ModelParagraph>
        <Check overrides={marginOverride} />
        Submitted successfully!
      </ModelParagraph>
    );
  } else {
    toaster.negative(
      <ModelParagraph>
        <Alert overrides={marginOverride} />
        Submission failed. {failureMsg}
      </ModelParagraph>
    );
  }
};

export default toastTrigger;
