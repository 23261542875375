import React, { useEffect, useRef } from 'react';
import {
  Divider,
  OnboardingContentWrapper
} from 'newHirePortalRedesign/newHire/pages/dashboard/Styles';
import TasksBeforeDayOne from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/TasksBeforeDayOne';
import YourFirstWeek from 'newHirePortalRedesign/newHire/features/yourFirstWeek/YourFirstWeek';
import UsefulResources from 'newHirePortalRedesign/newHire/features/usefulResources/UsefulResources';
import Banner from 'newHirePortalRedesign/newHire/features/banner/Banner';
import {
  HumanTask,
  setHumanTasks
} from 'newHirePortalRedesign/newHire/app/store/slice/slice';
import { useAppDispatch } from 'newHirePortalRedesign/newHire/store/hooks';

const Dashboard = ({ humanTasks }: { humanTasks: HumanTask[] }) => {
  const dispatch = useAppDispatch();

  // TODO: Need to remove the useEffect. once, we call the user api within nhp-redesign app
  useEffect(() => {
    dispatch(setHumanTasks(humanTasks));
  }, []);

  const firstWeekRef = useRef();

  return (
    <>
      <Banner firstWeekRef={firstWeekRef} />
      <OnboardingContentWrapper>
        <TasksBeforeDayOne />
        <YourFirstWeek ref={firstWeekRef} />
        <Divider />
        <UsefulResources />
      </OnboardingContentWrapper>
    </>
  );
};

export default Dashboard;
