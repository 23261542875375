import React from 'react';
import { MonoParagraphMedium } from 'baseui/typography';
import { Wrapper } from '../CommonStyle';
import LaptopDetails from '../Laptop/LaptopDetails';
import DesktopDetails from '../Desktop/DesktopDetails';
import MonitorDetails from '../Monitor/MonitorDetails';
import IPadDetails from '../Ipad/IPadDetails';
import PeripheralsDetails from '../Peripherals/PeripheralsDetails';
import { capitalizeFirstLetter } from '../../../../util/dataParseHelpers';

const HardwareDetailsDisplay = ({
  desktop,
  laptop,
  monitor,
  tablet,
  keyboard,
  mouse,
  headset,
  wrapperStyle,
  testId
}) => (
  <Wrapper data-testid={testId} $style={wrapperStyle}>
    <MonoParagraphMedium>Hardware:</MonoParagraphMedium>

    <DesktopDetails desktopData={desktop} />

    <LaptopDetails laptopData={laptop} laptopLanguageData={laptop.language} />

    <MonitorDetails monitorData={monitor} />

    {tablet ? (
      <IPadDetails
        tabletData={capitalizeFirstLetter(tablet.display_name)}
        isTabletEnableFlag
      />
    ) : null}

    <PeripheralsDetails
      keyboardLanguageData={keyboard?.language}
      keyboard={keyboard}
      mouse={mouse}
      headset={headset}
    />
  </Wrapper>
);

export default HardwareDetailsDisplay;
