import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCoreMember as getCoreMemberApi } from '../../api/coreMember';
import { handleApiResponse } from '../../../../utils/api_helpers';

export const getCoreMember = createAsyncThunk(
  'coreMember/show',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await getCoreMemberApi();
      const result = await handleApiResponse(response);
      if (result.ok) {
        return result.data;
      }
      return rejectWithValue(result.error);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
