/* eslint-disable max-len */
import { TASK_NAME, TASK_STATUS, THEME_NAME } from '../../util/enums';

export const completeTask = (task) => {
  task.state = TASK_STATUS.COMPLETED;
  return null;
};

export const isAllTaskCompleted = (tasks) => {
  const taskState = tasks.map((task) => task.state);
  return taskState.every((task) => task === TASK_STATUS.COMPLETED);
};

export const isTaskComplete = (task) => task.state === TASK_STATUS.COMPLETED;

export const isShippingTask = (task) => task.name === TASK_NAME.SHIPPING;
export const isProfileTask = (task) => task.name === TASK_NAME.PROFILE;
export const isUsernameSelectTask = (task) => task.name === TASK_NAME.USERNAME;
export const isHardwareSelectTask = (task) => task.name === TASK_NAME.HARDWARE;

export const getUsernameTask = (tasks) =>
  tasks.find((task) => task.name === TASK_NAME.USERNAME);

export const isTaskOptional = (name) =>
  name === TASK_NAME.USERNAME || name === TASK_NAME.PROFILE;
export const isTaskNameUsername = (name) => name === TASK_NAME.USERNAME;

export const isCashTheme = (theme) => theme.name === THEME_NAME.CASH;
export const isSquareTheme = (theme) => theme.name === THEME_NAME.SQUARE;
export const isBlockTheme = (theme) => theme.name === THEME_NAME.BLOCK;
export const isTidalTheme = (theme) => theme.name === THEME_NAME.TIDAL;
