import React from 'react';
import { styled, useStyletron } from 'baseui';
import { MonoParagraphMedium, ParagraphMedium } from 'baseui/typography';
import { Textarea } from 'baseui/textarea';
import { allDirectionStyle } from '../../../util/styleHelpers';
import { THEME_NAME } from '../../../util/enums';
import CashImgWrap from '../../../components/CashImgWrap';
import holeSpace from '../../../../../assets/images/holeSpace.png';

const FormWrap = styled('div', () => ({
  position: 'relative'
}));

const BioForm = ({ bio, setBio }) => {
  const [css, theme] = useStyletron();
  return (
    <>
      {theme.name === THEME_NAME.CASH ? (
        <FormWrap>
          <CashImgWrap
            position='absolute'
            right='-50px'
            top='280px'
            src={holeSpace}
            alt='holeSpace'
          />
        </FormWrap>
      ) : (
        ' '
      )}
      <MonoParagraphMedium>
        Tell us about yourself! Please write a 3-4 sentence bio and submit the
        photo of your choosing. Your manager will use this to introduce you to
        folks at {theme.displayAppName} through email or Slack.
      </MonoParagraphMedium>

      <ParagraphMedium>
        Please note that information is not editable after submission.
      </ParagraphMedium>

      <Textarea
        value={bio}
        onChange={(e) => setBio(e.target.value)}
        placeholder='Write your bio here. What are your interests, hobbies and unique skills? Got any fun facts about yourself? Here’s the place to share!'
        clearOnEscape
        maxLength={1000}
        overrides={{
          InputContainer: {
            style: ({ $theme }) => ({ backgroundColor: $theme.colors.mono100 })
          },
          Root: {
            style: {
              ...allDirectionStyle({
                radius: '6px',
                prop: 'border'
              }),
              height: '120px',
              marginBottom: theme.sizing.scale950
            }
          }
        }}
      />

      <div
        className={css({
          marginTop: `-${theme.sizing.scale900}`,
          marginBottom: theme.sizing.scale800,
          display: 'flex',
          justifyContent: 'flex-end'
        })}
      >
        <MonoParagraphMedium
          marginTop={0}
          marginBottom={0}
          onClick={() => setBio('')}
          className={css({
            cursor: 'pointer',
            color: theme.colors.contentAccent
          })}
        >
          Clear
        </MonoParagraphMedium>
      </div>
    </>
  );
};

export default BioForm;
