import React from 'react';
import { useStyletron } from 'baseui';
import { ParagraphSmall, LabelSmall } from 'baseui/typography';
import { ListItem } from 'baseui/list';
import BookSpiralIcon from 'newHirePortalRedesign/newHire/features/tasksBeforeDayOne/icons/BookSpiralIcon';

interface TaskSectionProps {
  taskList: string[];
  opacity: number;
}

const TaskSection = ({ taskList, opacity }: TaskSectionProps) => {
  const [css, theme] = useStyletron();

  return (
    <section
      className={css({
        background: theme.lighting.shadow400,
        minHeight: '88px',
        padding: `${theme.sizing.scale600} ${theme.sizing.scale600} ${theme.sizing.scale800}`,
        [theme.mediaQuery.small]: {
          margin: theme.sizing.scale800
        },
        [theme.mediaQuery.medium]: {
          margin: `${theme.sizing.scale800} 0px`
        },
        [theme.mediaQuery.large]: {
          margin: `${theme.sizing.scale800} 0px`
        },
        opacity
      })}
    >
      <LabelSmall>What you’ll do</LabelSmall>
      {taskList.map((task: string) => (
        <ListItem
          key={task}
          overrides={{
            ArtworkContainer: {
              style: () => ({
                width: theme.sizing.scale500,
                paddingTop: theme.sizing.scale0
              })
            },
            Root: {
              style: () => ({
                backgroundColor: 'none',
                paddingTop: theme.sizing.scale600,
                gap: theme.sizing.scale300,
                alignItems: 'flex-start'
              })
            },
            Content: {
              style: () => ({
                borderBottomStyle: 'none',
                minHeight: 0
              })
            }
          }}
          artwork={() => <BookSpiralIcon />}
        >
          <ParagraphSmall margin={0}>{task}</ParagraphSmall>
        </ListItem>
      ))}
    </section>
  );
};

export default TaskSection;
