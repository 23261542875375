export const getShippingAddress = async () =>
  fetch('/api/v1/user/shipping_address_show');

export const postShippingAddress = async ({
  shippingAddress = {},
  selectedHardware = {}
}) =>
  fetch('/api/v1/user/submit_shipping', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      shipping_address: shippingAddress,
      hardware_selection: selectedHardware
    })
  });
