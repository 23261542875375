import React, { ReactNode } from 'react';
import { ToasterContainer, KIND } from 'baseui/toast';
import { PLACEMENT } from 'baseui/helper';
import { allDirectionStyle } from 'newHirePortalRedesign/newHire/util/styleHelpers';

interface ToastWrapperProps {
  children: ReactNode;
}

const ToastWrapper = ({ children }: ToastWrapperProps): JSX.Element => (
  <ToasterContainer
    placement={PLACEMENT.bottom}
    autoHideDuration={5000} // milliseconds
    overrides={{
      ToastBody: {
        style: ({ $kind, $theme }) => {
          const styles = {
            ...allDirectionStyle({
              radius: '7px',
              prop: 'border'
            }),
            width: 'auto',
            minWidth: '65px',
            paddingTop: '0px',
            paddingBottom: '0px'
          };
          if ($kind === KIND.positive) {
            styles.backgroundColor = $theme.colors.contentPrimary;
          }
          return styles;
        }
      }
    }}
  >
    {children}
  </ToasterContainer>
);

export default ToastWrapper;
