import React from 'react';
import { THEME_NAME } from '../../util/enums';
import MyGoLinks from '../../components/MyGoLinks';

export const getLinkExpiryMsg = (theme) => {
  let msg;

  if (theme.name === THEME_NAME.SQUARE) {
    msg = (
      <>
        Looks like you have already started at Square, the link will no longer
        work. Please visit{' '}
        <span>
          <MyGoLinks goLink='go/go ' source='http://go/go' />
        </span>
        on your office laptop to access all the information you need.
      </>
    );
  }
  if (theme.name === THEME_NAME.CASH) {
    msg = (
      <>
        Looks like you have already started at Cash App, the link will no longer
        work. Please visit{' '}
        <span>
          <MyGoLinks goLink='go/$onboarding ' source='http://go/$onboarding' />
        </span>
        on your office laptop to access all the information you need.
      </>
    );
  }
  if (theme.name === THEME_NAME.TIDAL) {
    msg = (
      <>
        Looks like you have already started at Tidal, the link will no longer
        work. Please visit{' '}
        <span>
          <MyGoLinks goLink='go/Tidal ' source='http://go/Tidal' />
        </span>
        on your office laptop to access all the information you need.
      </>
    );
  }
  return msg;
};
