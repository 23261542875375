import {
  primitives as defaultPrim,
  overrides as defaultOverride
} from 'newHirePortalRedesign/newHire/config/themes/default';
import { THEME_NAME } from 'newHirePortalRedesign/newHire/util/constants';
import SquareLogo from 'assets/images/squareLogo.svg';

const primitives = defaultPrim;

const baseColors = {
  // Emphasis
  accent50: '#E5FAFA', // Square Emphasis Emphasis/40
  backgroundAccent: '#006AFF', // SQ Accent - Square Emphasis Fill
  contentAccent: '#005AD9' // SQ Accent - Square Emphasis Text
};

const squareMarketColors = {
  // emphasis color scheme
  emphasis_40: baseColors.accent50,
  emphasis_text: baseColors.contentAccent,
  emphasis_fill: baseColors.backgroundAccent
};

const colors = {
  ...defaultOverride.colors,
  ...baseColors,
  ...squareMarketColors
};

const otherProperties = {
  favicon: SquareLogo,
  displayName: 'Square',
  displayAppName: 'Square',
  supportEmail: 'mission-control@squareup.com'
};

const overrides = {
  ...defaultOverride,
  name: THEME_NAME.SQUARE,
  colors,
  ...otherProperties
};

const config = { primitives, overrides };

export { primitives, overrides };

export default config;
