import React from 'react';
import HardwareTitleWithIcon from '../HardwareTitleWithIcon';
import { OuterWapper } from '../CommonStyle';
import IpadIcon from '../../../../../../components/icons/Ipad';

const IPadDetails = ({ isTabletEnableFlag, tabletData }) => (
  <>
    {isTabletEnableFlag ? (
      <OuterWapper>
        <HardwareTitleWithIcon
          dataTestId='ipad-component'
          icon={<IpadIcon size={20} />}
          deviceCategory='Ipad'
          selectedDeviceName={tabletData}
        />
      </OuterWapper>
    ) : null}
  </>
);
export default IPadDetails;
