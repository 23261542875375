import { Theme, styled } from 'baseui';
import { BlockProps } from 'baseui/block';

const SkeletonWrapper = styled('div', () => ({
  display: 'flex'
}));

const itemProps = (totalCardCount: number): BlockProps => {
  const maxWidth = totalCardCount > 2 ? '538px' : '644px';

  return {
    overrides: {
      Block: {
        style: ({ $theme }: { $theme: Theme }) => ({
          flexShrink: 0,
          [$theme.mediaQuery.small]: {
            maxWidth: '100%',
            marginBottom: '0px'
          },
          [$theme.mediaQuery.medium]: {
            maxWidth,
            marginBottom: '38px'
          },
          [$theme.mediaQuery.large]: {
            maxWidth,
            marginBottom: '38px'
          }
        })
      }
    }
  };
};

export { itemProps, SkeletonWrapper };
